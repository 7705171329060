@import url(https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap);
*,.close:active,.close:focus{
    outline:none
}
a{
    transition:color .2s
}
.z-over{
    position:relative;
    z-index:25
}
.left-0{
    left:0!important
}
.z-below{
    z-index:4;
    position:relative
}
.pulse-animation{
    transition:all .2s ease-in-out;
    transform:scale(1.1);
    -webkit-animation:pulse 1s infinite;
    animation:pulse 1s infinite
}
@-webkit-keyframes pulse{
    0%{
        transform:scale(.75)
    }
    20%{
        transform:scale(1.1)
    }
    40%{
        transform:scale(.75)
    }
    60%{
        transform:scale(1.05)
    }
    80%{
        transform:scale(.75)
    }
    to{
        transform:scale(.75)
    }
}
@keyframes pulse{
    0%{
        transform:scale(.75)
    }
    20%{
        transform:scale(1.1)
    }
    40%{
        transform:scale(.75)
    }
    60%{
        transform:scale(1.05)
    }
    80%{
        transform:scale(.75)
    }
    to{
        transform:scale(.75)
    }
}
.br-tl{
    border-top-left-radius:.29rem!important
}
.br-tr{
    border-top-right-radius:.29rem!important
}
.br-bl{
    border-bottom-left-radius:.29rem!important
}
.br-br{
    border-bottom-right-radius:.29rem!important
}
body .bl-0{
    border-top-left-radius:0!important;
    border-bottom-left-radius:0!important
}
body .br-0{
    border-top-right-radius:0!important;
    border-bottom-right-radius:0!important
}
@media (min-width:1100px){
    body .br-lg-right-0{
        border-top-right-radius:0!important;
        border-bottom-right-radius:0!important
    }
    body .br-lg-left-0{
        border-top-left-radius:0!important;
        border-bottom-left-radius:0!important
    }
}
@media (min-width:1381px){
    body .br-xl-right-0{
        border-top-right-radius:0!important;
        border-bottom-right-radius:0!important
    }
    body .br-xl-left-0{
        border-top-left-radius:0!important;
        border-bottom-left-radius:0!important
    }
}
.progress-constrained{
    box-sizing:content-box
}
.opacity-1{
    opacity:.1!important
}
.opacity-2{
    opacity:.2!important
}
.opacity-3{
    opacity:.3!important
}
.opacity-4{
    opacity:.4!important
}
.opacity-5{
    opacity:.5!important
}
.opacity-6{
    opacity:.6!important
}
.opacity-7{
    opacity:.7!important
}
.opacity-8{
    opacity:.8!important
}
.opacity-9{
    opacity:.9!important
}
.d-20{
    height:20px!important;
    line-height:20px!important;
    width:20px!important
}
.d-30{
    height:30px!important;
    line-height:30px!important;
    width:30px!important
}
.d-40{
    height:40px!important;
    line-height:40px!important;
    width:40px!important
}
.d-50{
    height:50px!important;
    line-height:50px!important;
    width:50px!important
}
.d-60{
    height:60px!important;
    line-height:60px!important;
    width:60px!important
}
.d-70{
    height:70px!important;
    line-height:70px!important;
    width:70px!important
}
.d-80{
    height:80px!important;
    line-height:80px!important;
    width:80px!important
}
.d-90{
    height:90px!important;
    line-height:90px!important;
    width:90px!important
}
.d-100{
    height:100px!important;
    line-height:100px!important;
    width:100px!important
}
.d-110{
    height:110px!important;
    line-height:110px!important;
    width:110px!important
}
.d-120{
    height:120px!important;
    line-height:120px!important;
    width:120px!important
}
.d-130{
    height:130px!important;
    line-height:130px!important;
    width:130px!important
}
.d-140{
    height:140px!important;
    line-height:140px!important;
    width:140px!important
}
.line-height-sm{
    line-height:1.3
}
.line-height-md{
    line-height:2
}
.line-height-1{
    line-height:1!important
}
.line-height-normal{
    line-height:normal
}
.text-indent-0{
    text-indent:0!important
}
.h-auto{
    height:auto
}
.h-100{
    height:100%
}
.h-180px{
    height:180px
}
.h-240px{
    height:240px
}
.h-280px{
    height:280px
}
.h-320px{
    height:320px
}
.w-auto{
    width:auto
}
.w-43{
    width:43%
}
@media (min-width:768px){
    .w-50{
        min-width:50%;
        width:50%
    }
}
@media (max-width:767.98px){
    body .w-50{
        width:100%!important
    }
}
.max-w-70{
    max-width:70%
}
.divider{
    background:#eeeff8;
    height:1px;
    overflow:hidden
}
.border-1{
    border-width:1px!important
}
.border-1,.border-2{
    border-style:solid!important
}
.border-2{
    border-width:2px!important
}
.border-3{
    border-width:3px!important;
    border-style:solid!important
}
.font-size-xs{
    font-size:.83333rem!important
}
.font-size-sm{
    font-size:.875rem!important
}
.font-size-md{
    font-size:1rem!important
}
.font-size-lg{
    font-size:1.15rem!important
}
.font-size-xl{
    font-size:1.265rem!important
}
.font-size-xxl{
    font-size:1.38rem!important
}
.bg-composed-wrapper--bg,.bg-composed-wrapper--image{
    position:absolute;
    left:0;
    top:0;
    height:100%;
    width:100%;
    z-index:3;
    opacity:.15
}
.bg-composed-wrapper{
    position:relative;
    display:flex;
    align-items:center
}
.bg-composed-wrapper,.bg-composed-wrapper--image{
    border-top-left-radius:inherit;
    border-top-right-radius:inherit
}
.bg-composed-wrapper--image{
    -webkit-filter:grayscale(80%);
    filter:grayscale(80%);
    background-size:cover
}
.bg-composed-wrapper--content{
    position:relative;
    z-index:7;
    width:100%
}
.bg-composed-wrapper--bg{
    z-index:5
}
.bg-composed-filter-rm{
    -webkit-filter:grayscale(0);
    filter:grayscale(0)
}
.bg-repeat{
    background-size:auto
}
.bg-filter-grayscale{
    -webkit-filter:grayscale(100%);
    filter:grayscale(100%)
}
.transition-base{
    transition:all .2s ease-in-out!important
}
.shadow-xxl{
    box-shadow:0 .18rem .3rem rgba(166,166,185,.5),0 .326rem 3rem rgba(122,123,151,.3)!important
}
.shadow-sm-dark{
    box-shadow:0 .313rem .8rem rgba(7,9,25,.3),0 .126rem .225rem rgba(7,9,25,.1)!important
}
.bg-white-50{
    background-color:hsla(0,0%,100%,.2)
}
.text-black{
    color:#070919
}
.img-fit-container{
    width:100%;
    height:100%;
    object-fit:cover
}
.gradient-icon{
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;
    background-clip:text;
    text-fill-color:transparent
}
.blockquote{
    font-family:"Times New Roman",Times,serif
}
.p-top-a{
    top:auto!important
}
.pos-r{
    left:auto;
    right:0
}
.hover-scale-sm{
    transition:all .2s ease-in-out!important;
    transform:scale(1)!important
}
.hover-scale-sm:hover{
    transform:scale(1.08)!important
}
.hover-scale-rounded{
    transition:all .2s ease-in-out!important;
    transform:scale(1)!important;
    box-shadow:none
}
.hover-scale-rounded:hover{
    border-radius:.65rem!important;
    transform:scale(1.08)!important;
    box-shadow:0 .46875rem 2.1875rem rgba(59,62,102,.03),0 .9375rem 1.40625rem rgba(59,62,102,.03),0 .25rem .53125rem rgba(59,62,102,.05),0 .125rem .1875rem rgba(59,62,102,.03)
}
.hover-scale-lg{
    transition:all .2s ease-in-out!important;
    transform:scale(1)!important
}
.hover-scale-lg:hover{
    transform:scale(1.12)!important
}
@media (min-width:1100px){
    .object-skew{
        transform:scale(.95) perspective(924px) rotateY(-8deg) rotateX(9deg) rotate(3deg)!important
    }
}
.rounded-left,.rounded-right{
    border-radius:0!important
}
.nav-link{
    cursor:pointer
}
.bg-neutral-primary{
    background-color:#eceef7!important
}
a.bg-neutral-primary:focus,a.bg-neutral-primary:hover,button.bg-neutral-primary:focus,button.bg-neutral-primary:hover{
    background-color:#c8cde8!important
}
.bg-neutral-secondary{
    background-color:#fff!important
}
a.bg-neutral-secondary:focus,a.bg-neutral-secondary:hover,button.bg-neutral-secondary:focus,button.bg-neutral-secondary:hover{
    background-color:#e6e6e6!important
}
.bg-neutral-success{
    background-color:#e5f9ed!important
}
a.bg-neutral-success:focus,a.bg-neutral-success:hover,button.bg-neutral-success:focus,button.bg-neutral-success:hover{
    background-color:#bbf0d0!important
}
.bg-neutral-info{
    background-color:#e8fbfd!important
}
a.bg-neutral-info:focus,a.bg-neutral-info:hover,button.bg-neutral-info:focus,button.bg-neutral-info:hover{
    background-color:#b9f4f8!important
}
.bg-neutral-warning{
    background-color:#fffbf2!important
}
a.bg-neutral-warning:focus,a.bg-neutral-warning:hover,button.bg-neutral-warning:focus,button.bg-neutral-warning:hover{
    background-color:#ffedbf!important
}
.bg-neutral-danger{
    background-color:#fff5f6!important
}
a.bg-neutral-danger:focus,a.bg-neutral-danger:hover,button.bg-neutral-danger:focus,button.bg-neutral-danger:hover{
    background-color:#ffc2c9!important
}
.bg-neutral-dark{
    background-color:#f5f5f7!important
}
a.bg-neutral-dark:focus,a.bg-neutral-dark:hover,button.bg-neutral-dark:focus,button.bg-neutral-dark:hover{
    background-color:#d9d9e1!important
}
.bg-neutral-first{
    background-color:#eaf6ff!important
}
a.bg-neutral-first:focus,a.bg-neutral-first:hover,button.bg-neutral-first:focus,button.bg-neutral-first:hover{
    background-color:#b8e0fe!important
}
.bg-neutral-second{
    background-color:#e1e2e7!important
}
a.bg-neutral-second:focus,a.bg-neutral-second:hover,button.bg-neutral-second:focus,button.bg-neutral-second:hover{
    background-color:#c5c7d1!important
}
:root{
    --neutral-primary:#eceef7;
    --neutral-secondary:#fff;
    --neutral-success:#e5f9ed;
    --neutral-info:#e8fbfd;
    --neutral-warning:#fffbf2;
    --neutral-danger:#fff5f6;
    --neutral-dark:#f5f5f7;
    --neutral-first:#eaf6ff;
    --neutral-second:#e1e2e7
}
.bg-brand-facebook{
    background-color:#3b5999!important
}
a.bg-brand-facebook:focus,a.bg-brand-facebook:hover,button.bg-brand-facebook:focus,button.bg-brand-facebook:hover{
    background-color:#2d4474!important
}
.bg-brand-discord{
    background-color:#7189da!important
}
a.bg-brand-discord:focus,a.bg-brand-discord:hover,button.bg-brand-discord:focus,button.bg-brand-discord:hover{
    background-color:#4967cf!important
}
.bg-brand-twitter{
    background-color:#1da1f2!important
}
a.bg-brand-twitter:focus,a.bg-brand-twitter:hover,button.bg-brand-twitter:focus,button.bg-brand-twitter:hover{
    background-color:#0c85d0!important
}
.bg-brand-google{
    background-color:#dd4b39!important
}
a.bg-brand-google:focus,a.bg-brand-google:hover,button.bg-brand-google:focus,button.bg-brand-google:hover{
    background-color:#c23321!important
}
.bg-brand-instagram{
    background-color:#e4405f!important
}
a.bg-brand-instagram:focus,a.bg-brand-instagram:hover,button.bg-brand-instagram:focus,button.bg-brand-instagram:hover{
    background-color:#d31e40!important
}
.bg-brand-pinterest{
    background-color:#bd081c!important
}
a.bg-brand-pinterest:focus,a.bg-brand-pinterest:hover,button.bg-brand-pinterest:focus,button.bg-brand-pinterest:hover{
    background-color:#8c0615!important
}
.bg-brand-youtube{
    background-color:#cd201f!important
}
a.bg-brand-youtube:focus,a.bg-brand-youtube:hover,button.bg-brand-youtube:focus,button.bg-brand-youtube:hover{
    background-color:#a11918!important
}
.bg-brand-slack{
    background-color:#3aaf85!important
}
a.bg-brand-slack:focus,a.bg-brand-slack:hover,button.bg-brand-slack:focus,button.bg-brand-slack:hover{
    background-color:#2d8968!important
}
.bg-brand-dribbble{
    background-color:#ea4c89!important
}
a.bg-brand-dribbble:focus,a.bg-brand-dribbble:hover,button.bg-brand-dribbble:focus,button.bg-brand-dribbble:hover{
    background-color:#e51e6b!important
}
.bg-brand-github{
    background-color:#222!important
}
a.bg-brand-github:focus,a.bg-brand-github:hover,button.bg-brand-github:focus,button.bg-brand-github:hover{
    background-color:#090909!important
}
.text-facebook{
    color:#3b5999!important
}
a.text-facebook:focus,a.text-facebook:hover{
    color:#263962!important
}
.text-discord{
    color:#7189da!important
}
a.text-discord:focus,a.text-discord:hover{
    color:#3557ca!important
}
.text-twitter{
    color:#1da1f2!important
}
a.text-twitter:focus,a.text-twitter:hover{
    color:#0b76b8!important
}
.text-google{
    color:#dd4b39!important
}
a.text-google:focus,a.text-google:hover{
    color:#ac2d1e!important
}
.text-instagram{
    color:#e4405f!important
}
a.text-instagram:focus,a.text-instagram:hover{
    color:#bd1b39!important
}
.text-pinterest{
    color:#bd081c!important
}
a.text-pinterest:focus,a.text-pinterest:hover{
    color:#740511!important
}
.text-youtube{
    color:#cd201f!important
}
a.text-youtube:focus,a.text-youtube:hover{
    color:#8b1615!important
}
.text-slack{
    color:#3aaf85!important
}
a.text-slack:focus,a.text-slack:hover{
    color:#277659!important
}
.text-dribbble{
    color:#ea4c89!important
}
a.text-dribbble:focus,a.text-dribbble:hover{
    color:#d11960!important
}
.text-github{
    color:#222!important
}
a.text-github:focus,a.text-github:hover{
    color:#000!important
}
.shadow-primary{
    box-shadow:0 .313rem .8rem rgba(60,68,177,.5),0 .126rem .225rem rgba(60,68,177,.3)!important
}
.shadow-primary-sm{
    box-shadow:0 .46875rem 2.1875rem rgba(60,68,177,.03),0 .9375rem 1.40625rem rgba(60,68,177,.03),0 .25rem .53125rem rgba(60,68,177,.05),0 .125rem .1875rem rgba(60,68,177,.03)!important
}
.shadow-secondary{
    box-shadow:0 .313rem .8rem rgba(248,249,255,.5),0 .126rem .225rem rgba(248,249,255,.3)!important
}
.shadow-secondary-sm{
    box-shadow:0 .46875rem 2.1875rem rgba(248,249,255,.03),0 .9375rem 1.40625rem rgba(248,249,255,.03),0 .25rem .53125rem rgba(248,249,255,.05),0 .125rem .1875rem rgba(248,249,255,.03)!important
}
.shadow-success{
    box-shadow:0 .313rem .8rem rgba(27,201,67,.5),0 .126rem .225rem rgba(27,201,67,.3)!important
}
.shadow-success-sm{
    box-shadow:0 .46875rem 2.1875rem rgba(27,201,67,.03),0 .9375rem 1.40625rem rgba(27,201,67,.03),0 .25rem .53125rem rgba(27,201,67,.05),0 .125rem .1875rem rgba(27,201,67,.03)!important
}
.shadow-info{
    box-shadow:0 .313rem .8rem rgba(17,197,219,.5),0 .126rem .225rem rgba(17,197,219,.3)!important
}
.shadow-info-sm{
    box-shadow:0 .46875rem 2.1875rem rgba(17,197,219,.03),0 .9375rem 1.40625rem rgba(17,197,219,.03),0 .25rem .53125rem rgba(17,197,219,.05),0 .125rem .1875rem rgba(17,197,219,.03)!important
}
.shadow-warning{
    box-shadow:0 .313rem .8rem rgba(244,119,46,.5),0 .126rem .225rem rgba(244,119,46,.3)!important
}
.shadow-warning-sm{
    box-shadow:0 .46875rem 2.1875rem rgba(244,119,46,.03),0 .9375rem 1.40625rem rgba(244,119,46,.03),0 .25rem .53125rem rgba(244,119,46,.05),0 .125rem .1875rem rgba(244,119,46,.03)!important
}
.shadow-danger{
    box-shadow:0 .313rem .8rem rgba(248,50,69,.5),0 .126rem .225rem rgba(248,50,69,.3)!important
}
.shadow-danger-sm{
    box-shadow:0 .46875rem 2.1875rem rgba(248,50,69,.03),0 .9375rem 1.40625rem rgba(248,50,69,.03),0 .25rem .53125rem rgba(248,50,69,.05),0 .125rem .1875rem rgba(248,50,69,.03)!important
}
.shadow-light{
    box-shadow:0 .313rem .8rem rgba(244,245,253,.5),0 .126rem .225rem rgba(244,245,253,.3)!important
}
.shadow-light-sm{
    box-shadow:0 .46875rem 2.1875rem rgba(244,245,253,.03),0 .9375rem 1.40625rem rgba(244,245,253,.03),0 .25rem .53125rem rgba(244,245,253,.05),0 .125rem .1875rem rgba(244,245,253,.03)!important
}
.shadow-gray{
    box-shadow:0 .313rem .8rem rgba(248,249,255,.5),0 .126rem .225rem rgba(248,249,255,.3)!important
}
.shadow-gray-sm{
    box-shadow:0 .46875rem 2.1875rem rgba(248,249,255,.03),0 .9375rem 1.40625rem rgba(248,249,255,.03),0 .25rem .53125rem rgba(248,249,255,.05),0 .125rem .1875rem rgba(248,249,255,.03)!important
}
.shadow-dark{
    box-shadow:0 .313rem .8rem rgba(122,123,151,.5),0 .126rem .225rem rgba(122,123,151,.3)!important
}
.shadow-dark-sm{
    box-shadow:0 .46875rem 2.1875rem rgba(122,123,151,.03),0 .9375rem 1.40625rem rgba(122,123,151,.03),0 .25rem .53125rem rgba(122,123,151,.05),0 .125rem .1875rem rgba(122,123,151,.03)!important
}
.shadow-first{
    box-shadow:0 .313rem .8rem rgba(65,145,255,.5),0 .126rem .225rem rgba(65,145,255,.3)!important
}
.shadow-first-sm{
    box-shadow:0 .46875rem 2.1875rem rgba(65,145,255,.03),0 .9375rem 1.40625rem rgba(65,145,255,.03),0 .25rem .53125rem rgba(65,145,255,.05),0 .125rem .1875rem rgba(65,145,255,.03)!important
}
.shadow-second{
    box-shadow:0 .313rem .8rem rgba(7,9,25,.5),0 .126rem .225rem rgba(7,9,25,.3)!important
}
.shadow-second-sm{
    box-shadow:0 .46875rem 2.1875rem rgba(7,9,25,.03),0 .9375rem 1.40625rem rgba(7,9,25,.03),0 .25rem .53125rem rgba(7,9,25,.05),0 .125rem .1875rem rgba(7,9,25,.03)!important
}
.bg-warm-flame{
    background-image:linear-gradient(45deg,#ff9a9e,#fad0c4 99%,#fad0c4)!important
}
.bg-night-fade{
    background-image:linear-gradient(0deg,#a18cd1 0,#fbc2eb)!important
}
.bg-sunny-morning{
    background-image:linear-gradient(120deg,#f6d365,#fda085)!important
}
.bg-tempting-azure{
    background-image:linear-gradient(120deg,#84fab0,#8fd3f4)!important
}
.bg-amy-crisp{
    background-image:linear-gradient(120deg,#a6c0fe,#f68084)!important
}
.bg-heavy-rain{
    background-image:linear-gradient(0deg,#cfd9df 0,#e2ebf0)!important
}
.bg-mean-fruit{
    background-image:linear-gradient(120deg,#fccb90,#d57eeb)!important
}
.bg-malibu-beach{
    background-image:linear-gradient(90deg,#4facfe 0,#00f2fe)!important
}
.bg-deep-blue{
    background-image:linear-gradient(120deg,#e0c3fc,#8ec5fc)!important
}
.bg-ripe-malin{
    background-image:linear-gradient(120deg,#f093fb,#f5576c)!important
}
.bg-arielle-smile{
    background-image:radial-gradient(circle 248px at center,#16d9e3 0,#30c7ec 47%,#46aef7 100%)!important
}
.bg-plum-plate{
    background-image:linear-gradient(135deg,#667eea,#764ba2)!important
}
.bg-happy-fisher{
    background-image:linear-gradient(120deg,#89f7fe,#66a6ff)!important
}
.bg-happy-itmeo{
    background-image:linear-gradient(180deg,#2af598,#009efd)!important
}
.bg-mixed-hopes{
    background-image:linear-gradient(0deg,#c471f5 0,#fa71cd)!important
}
.bg-strong-bliss{
    background-image:linear-gradient(90deg,#f78ca0 0,#f9748f 19%,#fd868c 60%,#fe9a8b)!important
}
.bg-grow-early{
    background-image:linear-gradient(0deg,#0ba360 0,#3cba92)!important
}
.bg-love-kiss{
    background-image:linear-gradient(0deg,#ff0844 0,#ffb199)!important
}
.bg-premium-dark{
    background-image:linear-gradient(90deg,#434343 0,#000)!important
}
.bg-happy-green{
    background-image:linear-gradient(180deg,#00b09b,#96c93d)!important
}
.bg-vicious-stance{
    background-image:linear-gradient(60deg,#29323c,#485563)!important
}
.bg-midnight-bloom{
    background-image:linear-gradient(-20deg,#2b5876,#4e4376)!important
}
.bg-night-sky{
    background-image:linear-gradient(0deg,#1e3c72 0,#1e3c72 1%,#2a5298)!important
}
.bg-slick-carbon{
    background-image:linear-gradient(180deg,#323232 0,#3f3f3f 40%,#1c1c1c 150%),linear-gradient(0deg,hsla(0,0%,100%,.4) 0,rgba(0,0,0,.25) 200%)!important;
    background-blend-mode:multiply
}
.bg-royal{
    background-image:linear-gradient(90deg,#141e30,#243b55)!important
}
.bg-asteroid{
    background-image:linear-gradient(90deg,#0f2027,#203a43,#2c5364)!important
}
.bg-skim-blue{
    background-image:linear-gradient(135deg,#abdcff 10%,#0396ff)!important
}
.bg-light-pure{
    background-image:linear-gradient(135deg,#ce9ffc 10%,#7367f0)!important
}
.bg-nice-redora{
    background-image:linear-gradient(135deg,#f761a1 10%,#8c1bab)!important
}
.bg-red-lights{
    background-image:linear-gradient(135deg,#f05f57 10%,#360940)!important
}
.bg-serious-blue{
    background-image:linear-gradient(135deg,#97abff 10%,#123597)!important
}
.bg-deep-sky{
    background-image:linear-gradient(135deg,#6b73ff 10%,#000dff)!important
}
.bg-sunrise-purple{
    background-image:linear-gradient(135deg,#3b2667 10%,#bc78ec)!important
}

.bg-gray-100{
    background:#f8f9ff!important
}
.bg-gray-200{
    background:#f4f5fd!important
}
.bg-gray-300{
    background:#eeeff8!important
}
.bg-gray-400{
    background:#e6e7f1!important
}
.bg-gray-500{
    background:#dfe0ea!important
}
.bg-white-50{
    background:hsla(0,0%,100%,.45)!important
}
.bg-white-10{
    background:hsla(0,0%,100%,.1)!important
}
.bg-white-5{
    background:hsla(0,0%,100%,.06)!important
}
.color-swatch{
    position:relative
}
.color-swatch--bg{
    border-top-left-radius:.75rem;
    border-top-right-radius:.75rem;
    height:84px;
    width:100%;
    margin:0 auto
}
.color-swatch--title{
    background:#fff;
    border:1px solid #e6e7f1;
    border-bottom-right-radius:1.5rem;
    border-bottom-left-radius:.75rem;
    padding:.66667rem 1rem;
    text-align:center;
    color:#a6a6b9;
    margin:0
}
.heading-3{
    text-transform:uppercase;
    font-size:.90909rem;
    font-weight:700;
    margin:0 0 1rem;
    padding:0;
    color:#6c401c
}
.demo-box-positions{
    height:100px;
    border-radius:0
}
.icon-demo-box{
    padding:2rem 0 0;
    flex-wrap:wrap
}
.icon-demo-box,.icon-demo-box .MuiCard-root{
    text-align:center;
    display:flex;
    align-items:center;
    justify-content:center
}
.icon-demo-box .MuiCard-root{
    margin:0 1rem 2rem;
    padding:0!important;
    width:100px;
    height:80px;
    transition:all .2s ease-in-out!important
}
.icon-demo-box .MuiCard-root i,.icon-demo-box .MuiCard-root span{
    font-size:2.5rem
}
.icon-demo-box .MuiCard-root:hover{
    transform:scale(1.8);
    z-index:9;
    border-radius:30px;
    box-shadow:0 5rem 14rem 0 hsla(0,0%,100%,.3),0 .8rem 2.3rem rgba(0,0,0,.6),0 .2rem .3rem rgba(0,0,0,.45)
}
.heading-2-demo{
    padding-left:0;
    font-size:2.3rem;
    text-align:center;
    font-weight:700
}
.heading-2-demo:before{
    display:none
}
.heading-2-demo+p{
    margin:0;
    color:#fff;
    opacity:.7;
    font-size:1.3rem;
    text-align:center
}
.slider-item{
    background:#eeeff8;
    border-radius:.65rem;
    color:#d1d2db;
    font-size:36px;
    padding:0;
    position:relative;
    height:150px;
    line-height:150px;
    text-align:center;
    transition:all .2s ease-in-out
}
.btn-go-back{
    position:fixed;
    left:2rem;
    top:2rem;
    z-index:55
}
.demo-img-wrapper{
    background:#f8f9ff;
    color:#d1d2db;
    font-size:4rem;
    padding:3rem;
    text-align:center;
    height:100%;
    position:relative;
    display:flex;
    justify-content:center;
    align-items:center
}
.demo-img-wrapper,.demo-img-wrapper:after{
    border-radius:.29rem;
    transition:all .2s ease-in-out
}
.demo-img-wrapper:after{
    content:"This demo placeholder should be replaced with either images or components.";
    position:absolute;
    left:50%;
    width:300px;
    margin-left:-150px;
    font-size:1rem;
    background:#070919;
    visibility:hidden;
    opacity:0;
    padding:1rem;
    color:#fff;
    line-height:1.6rem
}
.demo-img-wrapper:hover{
    border-color:#00892c
}
.demo-img-wrapper:hover:after{
    visibility:visible;
    opacity:.9
}
.demo-img-wrapper i{
    margin:auto
}
.card.demo-img-wrapper-discrete{
    background-color:hsla(0,0%,100%,.1);
    border:0!important;
    font-size:3rem;
    color:hsla(0,0%,100%,.6)
}
.card.demo-img-wrapper-discrete:after{
    font-size:.86rem!important
}
.card.demo-img-wrapper-discrete:hover{
    background-color:hsla(0,0%,100%,.15)
}
.card.demo-img-wrapper-dark{
    background-color:#a6a6b9;
    border-color:#070919
}
.card.demo-img-wrapper-dark:after{
    background-color:#282828;
    color:#e6e7f1
}
.card.demo-img-wrapper-dark:hover{
    border-color:#eeeff8
}
.header-drawer-open .theme-configurator{
    opacity:0!important;
    visibility:hidden!important
}
.details-helper{
    position:absolute;
    width:100%;
    bottom:13px;
    left:0
}
body .MuiButtonBase-root{
    min-width:0;
    outline:none!important
}
body .MuiButton-root{
    line-height:normal
}
body .MuiButton-root:focus{
    outline:none
}
body .MuiPaper-root{
    color:#282828
}
.MuiGrid-item,.MuiPaper-root{
    position:relative
}
@media (max-width:1380.98px){
    .MuiGrid-item{
        width:100%
    }
}
.MuiCard-root{
    transition:all .2s ease-in-out
}
a.MuiButton-outlined:hover{
    color:#000;
    color:initial
}
.min-w-auto{
    min-width:5px!important
}
.overflow-visible{
    overflow:visible!important
}
.overflow-hidden{
    outline:none!important
}
.MuiButton-root.btn-inverse:hover{
    background-color:hsla(0,0%,100%,.07)
}
.mb-spacing-6{
    margin-bottom:24px
}
.mb-spacing-6-x2{
    margin-bottom:48px
}
body .MuiOutlinedInput-notchedOutline{
    transition:all .2s ease-in-out
}
body .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
    border-color:#00892c
}
:root{
    --blue:#00892c;
    --indigo:#7420ff;
    --purple:#793de6;
    --pink:#fc26a4;
    --red:#f83245;
    --orange:#f4772e;
    --yellow:#ffc926;
    --green:#1bc943;
    --teal:#18e1a5;
    --cyan:#11c5db;
    --white:#fff;
    --gray:#d1d2db;
    --gray-dark:#6c401c;
    --primary:#00892c;
    --secondary:#f8f9ff;
    --success:#1bc943;
    --info:#11c5db;
    --warning:#f4772e;
    --danger:#f83245;
    --light:#f4f5fd;
    --gray:#f8f9ff;
    --dark:#6c401c;
    --first:#00892c;
    --second:#070919;
    --breakpoint-xs:0;
    --breakpoint-sm:576px;
    --breakpoint-md:768px;
    --breakpoint-lg:1100px;
    --breakpoint-xl:1381px;
    --font-family-sans-serif:"Heebo",sans-serif;
    --font-family-monospace:"Times New Roman",Times,serif
}
*,:after,:before{
    box-sizing:border-box
}
html{
    font-family:sans-serif;
    line-height:1.15;
    -webkit-text-size-adjust:100%;
    -webkit-tap-highlight-color:rgba(7,9,25,0)
}
article,aside,figcaption,figure,footer,header,hgroup,main,nav,section{
    display:block
}
body{
    margin:0;
    font-family:"Heebo",sans-serif;
    font-size:1rem;
    font-weight:400;
    line-height:1.5;
    color:#282828;
    text-align:left;
    background-color:#fff
}
[tabindex="-1"]:focus:not(:focus-visible){
    outline:0!important
}
hr{
    box-sizing:content-box;
    height:0;
    overflow:visible
}
h1,h2,h3,h4,h5,h6{
    margin-top:0;
    margin-bottom:.5rem
}
p{
    margin-top:0;
    margin-bottom:1rem
}
abbr[data-original-title],abbr[title]{
    text-decoration:underline;
    -webkit-text-decoration:underline dotted;
    text-decoration:underline dotted;
    cursor:help;
    border-bottom:0;
    -webkit-text-decoration-skip-ink:none;
    text-decoration-skip-ink:none
}
address{
    font-style:normal;
    line-height:inherit
}
address,dl,ol,ul{
    margin-bottom:1rem
}
dl,ol,ul{
    margin-top:0
}
ol ol,ol ul,ul ol,ul ul{
    margin-bottom:0
}
dt{
    font-weight:700
}
dd{
    margin-bottom:.5rem;
    margin-left:0
}
blockquote{
    margin:0 0 1rem
}
b,strong{
    font-weight:bolder
}
small{
    font-size:80%
}
sub,sup{
    position:relative;
    font-size:75%;
    line-height:0;
    vertical-align:baseline
}
sub{
    bottom:-.25em
}
sup{
    top:-.5em
}
a:not(.MuiButtonBase-root){
    color:#282828;
    text-decoration:none;
    background-color:transparent
}
a:not(.MuiButtonBase-root):hover{
    color:#1057c2;
    text-decoration:none
}
a.card.card-box{
    background-color:#fff
}
a:not([href]),a:not([href]):hover{
    color:inherit;
    text-decoration:none
}
code,kbd,pre,samp{
    font-family:"Times New Roman",Times,serif;
    font-size:1em
}
pre{
    margin-top:0;
    margin-bottom:1rem;
    overflow:auto
}
figure{
    margin:0 0 1rem
}
img{
    border-style:none
}
img,svg{
    vertical-align:middle
}
svg{
    overflow:hidden
}
table{
    border-collapse:collapse
}
caption{
    padding-top:.75rem;
    padding-bottom:.75rem;
    color:#d1d2db;
    text-align:left;
    caption-side:bottom
}
th{
    text-align:inherit
}
label{
    display:inline-block;
    margin-bottom:0
}
button{
    border-radius:0
}
button:focus{
    outline:1px dotted;
    outline:5px auto -webkit-focus-ring-color
}
button,input,optgroup,select,textarea{
    margin:0;
    font-family:inherit;
    font-size:inherit;
    line-height:inherit
}
button,input{
    overflow:visible
}
button,select{
    text-transform:none
}
select{
    word-wrap:normal
}
[type=button],[type=reset],[type=submit],button{
    -webkit-appearance:button
}
[type=button]:not(:disabled),[type=reset]:not(:disabled),[type=submit]:not(:disabled),button:not(:disabled){
    cursor:pointer
}
[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner{
    padding:0;
    border-style:none
}
input[type=checkbox],input[type=radio]{
    box-sizing:border-box;
    padding:0
}
input[type=date],input[type=datetime-local],input[type=month],input[type=time]{
    -webkit-appearance:listbox
}
textarea{
    overflow:auto;
    resize:vertical
}
fieldset{
    min-width:0;
    padding:0;
    margin:0;
    border:0
}
legend{
    display:block;
    width:100%;
    max-width:100%;
    padding:0;
    margin-bottom:.5rem;
    font-size:1.5rem;
    line-height:inherit;
    color:inherit;
    white-space:normal
}
progress{
    vertical-align:baseline
}
[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{
    height:auto
}
[type=search]{
    outline-offset:-2px;
    -webkit-appearance:none
}
[type=search]::-webkit-search-decoration{
    -webkit-appearance:none
}
::-webkit-file-upload-button{
    font:inherit;
    -webkit-appearance:button
}
output{
    display:inline-block
}
summary{
    display:list-item;
    cursor:pointer
}
template{
    display:none
}
[hidden]{
    display:none!important
}
.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6{
    margin-bottom:.5rem;
    font-weight:500;
    line-height:1.2
}
.h1,h1{
    font-size:2.5rem
}
.h2,h2{
    font-size:2rem
}
.h3,h3{
    font-size:1.75rem
}
.h4,h4{
    font-size:1.5rem
}
.h5,h5{
    font-size:1.25rem
}
.h6,h6{
    font-size:1rem
}
.lead{
    font-size:1.25rem;
    font-weight:300
}
.display-1{
    font-size:3.5rem
}
.display-1,.display-2{
    font-weight:300;
    line-height:1.2
}
.display-2{
    font-size:3rem
}
.display-3{
    font-size:2.5rem
}
.display-3,.display-4{
    font-weight:300;
    line-height:1.2
}
.display-4{
    font-size:1.7rem
}
hr{
    margin-top:1rem;
    margin-bottom:1rem;
    border:0;
    border-top:1px solid rgba(7,9,25,.1)
}
.small,small{
    font-size:80%;
    font-weight:400
}
.mark,mark{
    padding:.2em;
    background-color:#fcf8e3
}
.list-inline,.list-unstyled{
    padding-left:0;
    list-style:none
}
.list-inline-item{
    display:inline-block
}
.list-inline-item:not(:last-child){
    margin-right:.5rem
}
.initialism{
    font-size:90%;
    text-transform:uppercase
}
.blockquote{
    margin-bottom:1rem;
    font-size:1.25rem
}
.blockquote-footer{
    display:block;
    font-size:80%;
    color:#d1d2db
}
.blockquote-footer:before{
    content:"\2014\00A0"
}
.img-fluid,.img-thumbnail{
    max-width:100%;
    height:auto
}
.img-thumbnail{
    padding:.25rem;
    background-color:#fff;
    border:1px solid #eeeff8;
    border-radius:.65rem
}
.figure{
    display:inline-block
}
.figure-img{
    margin-bottom:.5rem;
    line-height:1
}
.figure-caption{
    font-size:90%;
    color:#d1d2db
}
.table{
    width:100%;
    margin-bottom:1rem;
    color:#282828
}
.table td,.table th{
    padding:.75rem;
    vertical-align:top;
    border-top:1px solid #dcdef1
}
.table thead th{
    vertical-align:bottom;
    border-bottom:2px solid #dcdef1
}
.table tbody+tbody{
    border-top:2px solid #dcdef1
}
.table-sm td,.table-sm th{
    padding:.3rem
}
.table-bordered,.table-bordered td,.table-bordered th{
    border:1px solid #dcdef1
}
.table-bordered thead td,.table-bordered thead th{
    border-bottom-width:2px
}
.table-borderless tbody+tbody,.table-borderless td,.table-borderless th,.table-borderless thead th{
    border:0
}
.table-striped tbody tr:nth-of-type(odd){
    background-color:#f9fafe
}
.table-hover tbody tr:hover{
    color:#282828;
    background-color:#fffbf2
}
.table-primary,.table-primary>td,.table-primary>th{
    background-color:#c8cbe9
}
.table-primary tbody+tbody,.table-primary td,.table-primary th,.table-primary thead th{
    border-color:#9a9ed6
}
.table-hover .table-primary:hover,.table-hover .table-primary:hover>td,.table-hover .table-primary:hover>th{
    background-color:#b6bae2
}
.table-secondary,.table-secondary>td,.table-secondary>th{
    background-color:#fdfdff
}
.table-secondary tbody+tbody,.table-secondary td,.table-secondary th,.table-secondary thead th{
    border-color:#fbfcff
}
.table-hover .table-secondary:hover,.table-hover .table-secondary:hover>td,.table-hover .table-secondary:hover>th{
    background-color:#e4e4ff
}
.table-success,.table-success>td,.table-success>th{
    background-color:#bff0ca
}
.table-success tbody+tbody,.table-success td,.table-success th,.table-success thead th{
    border-color:#88e39d
}
.table-hover .table-success:hover,.table-hover .table-success:hover>td,.table-hover .table-success:hover>th{
    background-color:#aaebb9
}
.table-info,.table-info>td,.table-info>th{
    background-color:#bceff5
}
.table-info tbody+tbody,.table-info td,.table-info th,.table-info thead th{
    border-color:#83e1ec
}
.table-hover .table-info:hover,.table-hover .table-info:hover>td,.table-hover .table-info:hover>th{
    background-color:#a6eaf2
}
.table-warning,.table-warning>td,.table-warning>th{
    background-color:#fcd9c4
}
.table-warning tbody+tbody,.table-warning td,.table-warning th,.table-warning thead th{
    border-color:#f9b892
}
.table-hover .table-warning:hover,.table-hover .table-warning:hover>td,.table-hover .table-warning:hover>th{
    background-color:#fbc9ac
}
.table-danger,.table-danger>td,.table-danger>th{
    background-color:#fdc6cb
}
.table-danger tbody+tbody,.table-danger td,.table-danger th,.table-danger thead th{
    border-color:#fb949e
}
.table-hover .table-danger:hover,.table-hover .table-danger:hover>td,.table-hover .table-danger:hover>th{
    background-color:#fcadb5
}
.table-light,.table-light>td,.table-light>th{
    background-color:#fcfcfe
}
.table-light tbody+tbody,.table-light td,.table-light th,.table-light thead th{
    border-color:#f9fafe
}
.table-hover .table-light:hover,.table-hover .table-light:hover>td,.table-hover .table-light:hover>th{
    background-color:#e9e9f8
}
.table-gray,.table-gray>td,.table-gray>th{
    background-color:#fdfdff
}
.table-gray tbody+tbody,.table-gray td,.table-gray th,.table-gray thead th{
    border-color:#fbfcff
}
.table-hover .table-gray:hover,.table-hover .table-gray:hover>td,.table-hover .table-gray:hover>th{
    background-color:#e4e4ff
}
.table-dark,.table-dark>td,.table-dark>th{
    background-color:#dadae2
}
.table-dark tbody+tbody,.table-dark td,.table-dark th,.table-dark thead th{
    border-color:#babac9
}
.table-hover .table-dark:hover,.table-hover .table-dark:hover>td,.table-hover .table-dark:hover>th{
    background-color:#ccccd7
}
.table-first,.table-first>td,.table-first>th{
    background-color:#cae0ff
}
.table-first tbody+tbody,.table-first td,.table-first th,.table-first thead th{
    border-color:#9cc6ff
}
.table-hover .table-first:hover,.table-hover .table-first:hover>td,.table-hover .table-first:hover>th{
    background-color:#b1d1ff
}
.table-second,.table-second>td,.table-second>th{
    background-color:#bababf
}
.table-second tbody+tbody,.table-second td,.table-second th,.table-second thead th{
    border-color:#7e7f87
}
.table-hover .table-second:hover,.table-hover .table-second:hover>td,.table-hover .table-second:hover>th{
    background-color:#adadb3
}
.table-active,.table-active>td,.table-active>th{
    background-color:#fffbf2
}
.table-hover .table-active:hover,.table-hover .table-active:hover>td,.table-hover .table-active:hover>th{
    background-color:#fff4d8
}
.table .thead-dark th{
    color:#fff;
    background-color:#282828;
    border-color:#282a46
}
.table .thead-light th{
    color:#282828;
    background-color:rgba(239,239,247,.7);
    border-color:#dcdef1
}
.table-dark{
    color:#fff;
    background-color:#282828
}
.table-dark td,.table-dark th,.table-dark thead th{
    border-color:#282a46
}
.table-dark.table-bordered{
    border:0
}
.table-dark.table-striped tbody tr:nth-of-type(odd){
    background-color:hsla(0,0%,100%,.05)
}
.table-dark.table-hover tbody tr:hover{
    color:#fff;
    background-color:hsla(0,0%,100%,.075)
}
@media (max-width:575.98px){
    .table-responsive-sm{
        display:block;
        width:100%;
        overflow-x:auto;
        -webkit-overflow-scrolling:touch
    }
    .table-responsive-sm>.table-bordered{
        border:0
    }
}
@media (max-width:767.98px){
    .table-responsive-md{
        display:block;
        width:100%;
        overflow-x:auto;
        -webkit-overflow-scrolling:touch
    }
    .table-responsive-md>.table-bordered{
        border:0
    }
}
@media (max-width:1099.98px){
    .table-responsive-lg{
        display:block;
        width:100%;
        overflow-x:auto;
        -webkit-overflow-scrolling:touch
    }
    .table-responsive-lg>.table-bordered{
        border:0
    }
}
@media (max-width:1380.98px){
    .table-responsive-xl{
        display:block;
        width:100%;
        overflow-x:auto;
        -webkit-overflow-scrolling:touch
    }
    .table-responsive-xl>.table-bordered{
        border:0
    }
}
.table-responsive{
    display:block;
    width:100%;
    overflow-x:auto;
    -webkit-overflow-scrolling:touch
}
.table-responsive>.table-bordered{
    border:0
}
.card{
    position:relative;
    display:flex;
    flex-direction:column;
    min-width:0;
    word-wrap:break-word;
    background-color:#fff;
    background-clip:border-box;
    border:0 solid rgba(122,123,151,.3);
    border-radius:.65rem
}
.card>hr{
    margin-right:0;
    margin-left:0
}
.card>.list-group:first-child .list-group-item:first-child{
    border-top-left-radius:.65rem;
    border-top-right-radius:.65rem
}
.card>.list-group:last-child .list-group-item:last-child{
    border-bottom-right-radius:.65rem;
    border-bottom-left-radius:.65rem
}
.card-body{
    flex:1 1 auto;
    min-height:1px;
    padding:1.25rem
}
.card-title{
    margin-bottom:.75rem
}
.card-subtitle{
    margin-top:-.375rem
}
.card-subtitle,.card-text:last-child{
    margin-bottom:0
}
.card-link:hover{
    text-decoration:none
}
.card-link+.card-link{
    margin-left:1.25rem
}
.card-header{
    padding:.75rem 1.25rem;
    margin-bottom:0;
    background-color:#fff;
    border-bottom:0 solid rgba(122,123,151,.3)
}
.card-header:first-child{
    border-radius:.65rem .65rem 0 0
}
.card-header+.list-group .list-group-item:first-child{
    border-top:0
}
.card-footer{
    padding:.75rem 1.25rem;
    background-color:#fff;
    border-top:0 solid rgba(122,123,151,.3)
}
.card-footer:last-child{
    border-radius:0 0 .65rem .65rem
}
.card-header-tabs{
    margin-bottom:-.75rem;
    border-bottom:0
}
.card-header-pills,.card-header-tabs{
    margin-right:-.625rem;
    margin-left:-.625rem
}
.card-img-overlay{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    padding:1.25rem
}
.card-img,.card-img-bottom,.card-img-top{
    flex-shrink:0;
    width:100%
}
.card-img,.card-img-top{
    border-top-left-radius:.65rem;
    border-top-right-radius:.65rem
}
.card-img,.card-img-bottom{
    border-bottom-right-radius:.65rem;
    border-bottom-left-radius:.65rem
}
.card-deck .card{
    margin-bottom:20px
}
@media (min-width:576px){
    .card-deck{
        display:flex;
        flex-flow:row wrap;
        margin-right:-20px;
        margin-left:-20px
    }
    .card-deck .card{
        flex:1 0;
        margin-right:20px;
        margin-bottom:0;
        margin-left:20px
    }
}
.card-group>.card{
    margin-bottom:20px
}
@media (min-width:576px){
    .card-group{
        display:flex;
        flex-flow:row wrap
    }
    .card-group>.card{
        flex:1 0;
        margin-bottom:0
    }
    .card-group>.card+.card{
        margin-left:0;
        border-left:0
    }
    .card-group>.card:not(:last-child){
        border-top-right-radius:0;
        border-bottom-right-radius:0
    }
    .card-group>.card:not(:last-child) .card-header,.card-group>.card:not(:last-child) .card-img-top{
        border-top-right-radius:0
    }
    .card-group>.card:not(:last-child) .card-footer,.card-group>.card:not(:last-child) .card-img-bottom{
        border-bottom-right-radius:0
    }
    .card-group>.card:not(:first-child){
        border-top-left-radius:0;
        border-bottom-left-radius:0
    }
    .card-group>.card:not(:first-child) .card-header,.card-group>.card:not(:first-child) .card-img-top{
        border-top-left-radius:0
    }
    .card-group>.card:not(:first-child) .card-footer,.card-group>.card:not(:first-child) .card-img-bottom{
        border-bottom-left-radius:0
    }
}
.card-columns .card{
    margin-bottom:.75rem
}
@media (min-width:576px){
    .card-columns{
        -webkit-column-count:3;
        column-count:3;
        grid-column-gap:1.25rem;
        -webkit-column-gap:1.25rem;
        column-gap:1.25rem;
        orphans:1;
        widows:1
    }
    .card-columns .card{
        display:inline-block;
        width:100%
    }
}
.accordion>.card{
    overflow:hidden
}
.accordion>.card:not(:last-of-type){
    border-bottom:0;
    border-bottom-right-radius:0;
    border-bottom-left-radius:0
}
.accordion>.card:not(:first-of-type){
    border-top-left-radius:0;
    border-top-right-radius:0
}
.accordion>.card>.card-header{
    border-radius:0;
    margin-bottom:0
}
.badge{
    display:inline-block;
    padding:0 .7em;
    font-size:70%;
    font-weight:700;
    line-height:1;
    text-align:center;
    white-space:nowrap;
    vertical-align:baseline;
    border-radius:.2rem;
    transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}
@media (prefers-reduced-motion:reduce){
    .badge{
        transition:none
    }
}
a.badge:focus,a.badge:hover{
    text-decoration:none
}
.badge:empty{
    display:none
}
.btn .badge{
    position:relative;
    top:-1px
}
.badge-pill{
    padding-right:.85em;
    padding-left:.85em;
    border-radius:10rem
}
.badge-primary{
    color:#fff;
    background-color:#00892c
}
a.badge-primary:focus,a.badge-primary:hover{
    color:#fff;
    background-color:#2f358b
}
a.badge-primary.focus,a.badge-primary:focus{
    outline:0;
    box-shadow:0 0 0 .2rem rgba(60,68,177,.5)
}
.badge-secondary{
    color:#282828;
    background-color:#f8f9ff
}
a.badge-secondary:focus,a.badge-secondary:hover{
    color:#282828;
    background-color:#c5cdff
}
a.badge-secondary.focus,a.badge-secondary:focus{
    outline:0;
    box-shadow:0 0 0 .2rem rgba(248,249,255,.5)
}
.badge-success{
    color:#fff;
    background-color:#1bc943
}
a.badge-success:focus,a.badge-success:hover{
    color:#fff;
    background-color:#159c34
}
a.badge-success.focus,a.badge-success:focus{
    outline:0;
    box-shadow:0 0 0 .2rem rgba(27,201,67,.5)
}
.badge-info{
    color:#fff;
    background-color:#11c5db
}
a.badge-info:focus,a.badge-info:hover{
    color:#fff;
    background-color:#0d9aac
}
a.badge-info.focus,a.badge-info:focus{
    outline:0;
    box-shadow:0 0 0 .2rem rgba(17,197,219,.5)
}
.badge-warning{
    color:#fff;
    background-color:#f4772e
}
a.badge-warning:focus,a.badge-warning:hover{
    color:#fff;
    background-color:#e35b0c
}
a.badge-warning.focus,a.badge-warning:focus{
    outline:0;
    box-shadow:0 0 0 .2rem rgba(244,119,46,.5)
}
.badge-danger{
    color:#fff;
    background-color:#f83245
}
a.badge-danger:focus,a.badge-danger:hover{
    color:#fff;
    background-color:#ef081e
}
a.badge-danger.focus,a.badge-danger:focus{
    outline:0;
    box-shadow:0 0 0 .2rem rgba(248,50,69,.5)
}
.badge-light{
    color:#282828;
    background-color:#f4f5fd
}
a.badge-light:focus,a.badge-light:hover{
    color:#282828;
    background-color:#c9cef5
}
a.badge-light.focus,a.badge-light:focus{
    outline:0;
    box-shadow:0 0 0 .2rem rgba(244,245,253,.5)
}
.badge-gray{
    color:#282828;
    background-color:#f8f9ff
}
a.badge-gray:focus,a.badge-gray:hover{
    color:#282828;
    background-color:#c5cdff
}
a.badge-gray.focus,a.badge-gray:focus{
    outline:0;
    box-shadow:0 0 0 .2rem rgba(248,249,255,.5)
}
.badge-dark{
    color:#fff;
    background-color:#6c401c
}
a.badge-dark:focus,a.badge-dark:hover{
    color:#fff;
    background-color:#61627d
}
a.badge-dark.focus,a.badge-dark:focus{
    outline:0;
    box-shadow:0 0 0 .2rem rgba(122,123,151,.5)
}
.badge-first{
    color:#fff;
    background-color:#00892c
}
a.badge-first:focus,a.badge-first:hover{
    color:#fff;
    background-color:#0e73ff
}
a.badge-first.focus,a.badge-first:focus{
    outline:0;
    box-shadow:0 0 0 .2rem rgba(65,145,255,.5)
}
.badge-second{
    color:#fff;
    background-color:#070919
}
a.badge-second:focus,a.badge-second:hover{
    color:#fff;
    background-color:#000
}
a.badge-second.focus,a.badge-second:focus{
    outline:0;
    box-shadow:0 0 0 .2rem rgba(7,9,25,.5)
}
.align-baseline{
    vertical-align:baseline!important
}
.align-top{
    vertical-align:top!important
}
.align-middle{
    vertical-align:middle!important
}
.align-bottom{
    vertical-align:bottom!important
}
.align-text-bottom{
    vertical-align:text-bottom!important
}
.align-text-top{
    vertical-align:text-top!important
}
.bg-primary{
    background-color:#00892c!important
}
a.bg-primary:focus,a.bg-primary:hover,button.bg-primary:focus,button.bg-primary:hover{
    background-color:#2f358b!important
}
.bg-secondary{
    background-color:#f8f9ff!important
}
a.bg-secondary:focus,a.bg-secondary:hover,button.bg-secondary:focus,button.bg-secondary:hover{
    background-color:#c5cdff!important
}
.bg-success{
    background-color:#1bc943!important
}
a.bg-success:focus,a.bg-success:hover,button.bg-success:focus,button.bg-success:hover{
    background-color:#159c34!important
}
.bg-info{
    background-color:#11c5db!important
}
a.bg-info:focus,a.bg-info:hover,button.bg-info:focus,button.bg-info:hover{
    background-color:#0d9aac!important
}
.bg-warning{
    background-color:#f4772e!important
}
a.bg-warning:focus,a.bg-warning:hover,button.bg-warning:focus,button.bg-warning:hover{
    background-color:#e35b0c!important
}
.bg-danger{
    background-color:#f83245!important
}
a.bg-danger:focus,a.bg-danger:hover,button.bg-danger:focus,button.bg-danger:hover{
    background-color:#ef081e!important
}
.bg-light{
    background-color:#f4f5fd!important
}
a.bg-light:focus,a.bg-light:hover,button.bg-light:focus,button.bg-light:hover{
    background-color:#c9cef5!important
}
.bg-gray{
    background-color:#f8f9ff!important
}
a.bg-gray:focus,a.bg-gray:hover,button.bg-gray:focus,button.bg-gray:hover{
    background-color:#c5cdff!important
}
.bg-dark{
    background-color:#6c401c!important
}
a.bg-dark:focus,a.bg-dark:hover,button.bg-dark:focus,button.bg-dark:hover{
    background-color:#61627d!important
}
.bg-first{
    background-color:#00892c!important
}
a.bg-first:focus,a.bg-first:hover,button.bg-first:focus,button.bg-first:hover{
    background-color:#0e73ff!important
}
.bg-second{
    background-color:#070919!important
}
a.bg-second:focus,a.bg-second:hover,button.bg-second:focus,button.bg-second:hover{
    background-color:#000!important
}
.bg-white{
    background-color:#fff!important
}
.bg-transparent{
    background-color:transparent!important
}
.border{
    border:1px solid #eeeff8!important
}
.border-top{
    border-top:1px solid #eeeff8!important
}
.border-right{
    border-right:1px solid #eeeff8!important
}
.border-bottom{
    border-bottom:1px solid #eeeff8!important
}
.border-left{
    border-left:1px solid #eeeff8!important
}
.border-0{
    border:0!important
}
.border-top-0{
    border-top:0!important
}
.border-right-0{
    border-right:0!important
}
.border-bottom-0{
    border-bottom:0!important
}
.border-left-0{
    border-left:0!important
}
.border-primary{
    border-color:#00892c!important
}
.border-secondary{
    border-color:#f8f9ff!important
}
.border-success{
    border-color:#1bc943!important
}
.border-info{
    border-color:#11c5db!important
}
.border-warning{
    border-color:#f4772e!important
}
.border-danger{
    border-color:#f83245!important
}
.border-light{
    border-color:#f4f5fd!important
}
.border-gray{
    border-color:#f8f9ff!important
}
.border-dark{
    border-color:#6c401c!important
}
.border-first{
    border-color:#00892c!important
}
.border-second{
    border-color:#070919!important
}
.border-white{
    border-color:#fff!important
}
.rounded-sm{
    border-radius:.29rem!important
}
.rounded{
    border-radius:.65rem!important
}
.rounded-top{
    border-top-left-radius:.65rem!important
}
.rounded-right,.rounded-top{
    border-top-right-radius:.65rem!important
}
.rounded-bottom,.rounded-right{
    border-bottom-right-radius:.65rem!important
}
.rounded-bottom,.rounded-left{
    border-bottom-left-radius:.65rem!important
}
.rounded-left{
    border-top-left-radius:.65rem!important
}
.rounded-lg{
    border-radius:.75rem!important
}
.rounded-circle{
    border-radius:50%!important
}
.rounded-pill{
    border-radius:50rem!important
}
.rounded-0{
    border-radius:0!important
}
.clearfix:after{
    display:block;
    clear:both;
    content:""
}
.d-none{
    display:none!important
}
.d-inline{
    display:inline!important
}
.d-inline-block{
    display:inline-block!important
}
.d-block{
    display:block!important
}
.d-table{
    display:table!important
}
.d-table-row{
    display:table-row!important
}
.d-table-cell{
    display:table-cell!important
}
.d-flex{
    display:flex!important
}
.d-inline-flex{
    display:inline-flex!important
}
@media (min-width:576px){
    .d-sm-none{
        display:none!important
    }
    .d-sm-inline{
        display:inline!important
    }
    .d-sm-inline-block{
        display:inline-block!important
    }
    .d-sm-block{
        display:block!important
    }
    .d-sm-table{
        display:table!important
    }
    .d-sm-table-row{
        display:table-row!important
    }
    .d-sm-table-cell{
        display:table-cell!important
    }
    .d-sm-flex{
        display:flex!important
    }
    .d-sm-inline-flex{
        display:inline-flex!important
    }
}
@media (min-width:768px){
    .d-md-none{
        display:none!important
    }
    .d-md-inline{
        display:inline!important
    }
    .d-md-inline-block{
        display:inline-block!important
    }
    .d-md-block{
        display:block!important
    }
    .d-md-table{
        display:table!important
    }
    .d-md-table-row{
        display:table-row!important
    }
    .d-md-table-cell{
        display:table-cell!important
    }
    .d-md-flex{
        display:flex!important
    }
    .d-md-inline-flex{
        display:inline-flex!important
    }
}
@media (min-width:1100px){
    .d-lg-none{
        display:none!important
    }
    .d-lg-inline{
        display:inline!important
    }
    .d-lg-inline-block{
        display:inline-block!important
    }
    .d-lg-block{
        display:block!important
    }
    .d-lg-table{
        display:table!important
    }
    .d-lg-table-row{
        display:table-row!important
    }
    .d-lg-table-cell{
        display:table-cell!important
    }
    .d-lg-flex{
        display:flex!important
    }
    .d-lg-inline-flex{
        display:inline-flex!important
    }
}
@media (min-width:1381px){
    .d-xl-none{
        display:none!important
    }
    .d-xl-inline{
        display:inline!important
    }
    .d-xl-inline-block{
        display:inline-block!important
    }
    .d-xl-block{
        display:block!important
    }
    .d-xl-table{
        display:table!important
    }
    .d-xl-table-row{
        display:table-row!important
    }
    .d-xl-table-cell{
        display:table-cell!important
    }
    .d-xl-flex{
        display:flex!important
    }
    .d-xl-inline-flex{
        display:inline-flex!important
    }
}
@media print{
    .d-print-none{
        display:none!important
    }
    .d-print-inline{
        display:inline!important
    }
    .d-print-inline-block{
        display:inline-block!important
    }
    .d-print-block{
        display:block!important
    }
    .d-print-table{
        display:table!important
    }
    .d-print-table-row{
        display:table-row!important
    }
    .d-print-table-cell{
        display:table-cell!important
    }
    .d-print-flex{
        display:flex!important
    }
    .d-print-inline-flex{
        display:inline-flex!important
    }
}
.embed-responsive{
    position:relative;
    display:block;
    width:100%;
    padding:0;
    overflow:hidden
}
.embed-responsive:before{
    display:block;
    content:""
}
.embed-responsive .embed-responsive-item,.embed-responsive embed,.embed-responsive iframe,.embed-responsive object,.embed-responsive video{
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    width:100%;
    height:100%;
    border:0
}
.embed-responsive-21by9:before{
    padding-top:42.85714%
}
.embed-responsive-16by9:before{
    padding-top:56.25%
}
.embed-responsive-4by3:before{
    padding-top:75%
}
.embed-responsive-1by1:before{
    padding-top:100%
}
.flex-row{
    flex-direction:row!important
}
.flex-column{
    flex-direction:column!important
}
.flex-row-reverse{
    flex-direction:row-reverse!important
}
.flex-column-reverse{
    flex-direction:column-reverse!important
}
.flex-wrap{
    flex-wrap:wrap!important
}
.flex-nowrap{
    flex-wrap:nowrap!important
}
.flex-wrap-reverse{
    flex-wrap:wrap-reverse!important
}
.flex-fill{
    flex:1 1 auto!important
}
.flex-grow-0{
    flex-grow:0!important
}
.flex-grow-1{
    flex-grow:1!important
}
.flex-shrink-0{
    flex-shrink:0!important
}
.flex-shrink-1{
    flex-shrink:1!important
}
.justify-content-start{
    justify-content:flex-start!important
}
.justify-content-end{
    justify-content:flex-end!important
}
.justify-content-center{
    justify-content:center!important
}
.justify-content-between{
    justify-content:space-between!important
}
.justify-content-around{
    justify-content:space-around!important
}
.align-items-start{
    align-items:flex-start!important
}
.align-items-end{
    align-items:flex-end!important
}
.align-items-center{
    align-items:center!important
}
.align-items-baseline{
    align-items:baseline!important
}
.align-items-stretch{
    align-items:stretch!important
}
.align-content-start{
    align-content:flex-start!important
}
.align-content-end{
    align-content:flex-end!important
}
.align-content-center{
    align-content:center!important
}
.align-content-between{
    align-content:space-between!important
}
.align-content-around{
    align-content:space-around!important
}
.align-content-stretch{
    align-content:stretch!important
}
.align-self-auto{
    align-self:auto!important
}
.align-self-start{
    align-self:flex-start!important
}
.align-self-end{
    align-self:flex-end!important
}
.align-self-center{
    align-self:center!important
}
.align-self-baseline{
    align-self:baseline!important
}
.align-self-stretch{
    align-self:stretch!important
}
@media (min-width:576px){
    .flex-sm-row{
        flex-direction:row!important
    }
    .flex-sm-column{
        flex-direction:column!important
    }
    .flex-sm-row-reverse{
        flex-direction:row-reverse!important
    }
    .flex-sm-column-reverse{
        flex-direction:column-reverse!important
    }
    .flex-sm-wrap{
        flex-wrap:wrap!important
    }
    .flex-sm-nowrap{
        flex-wrap:nowrap!important
    }
    .flex-sm-wrap-reverse{
        flex-wrap:wrap-reverse!important
    }
    .flex-sm-fill{
        flex:1 1 auto!important
    }
    .flex-sm-grow-0{
        flex-grow:0!important
    }
    .flex-sm-grow-1{
        flex-grow:1!important
    }
    .flex-sm-shrink-0{
        flex-shrink:0!important
    }
    .flex-sm-shrink-1{
        flex-shrink:1!important
    }
    .justify-content-sm-start{
        justify-content:flex-start!important
    }
    .justify-content-sm-end{
        justify-content:flex-end!important
    }
    .justify-content-sm-center{
        justify-content:center!important
    }
    .justify-content-sm-between{
        justify-content:space-between!important
    }
    .justify-content-sm-around{
        justify-content:space-around!important
    }
    .align-items-sm-start{
        align-items:flex-start!important
    }
    .align-items-sm-end{
        align-items:flex-end!important
    }
    .align-items-sm-center{
        align-items:center!important
    }
    .align-items-sm-baseline{
        align-items:baseline!important
    }
    .align-items-sm-stretch{
        align-items:stretch!important
    }
    .align-content-sm-start{
        align-content:flex-start!important
    }
    .align-content-sm-end{
        align-content:flex-end!important
    }
    .align-content-sm-center{
        align-content:center!important
    }
    .align-content-sm-between{
        align-content:space-between!important
    }
    .align-content-sm-around{
        align-content:space-around!important
    }
    .align-content-sm-stretch{
        align-content:stretch!important
    }
    .align-self-sm-auto{
        align-self:auto!important
    }
    .align-self-sm-start{
        align-self:flex-start!important
    }
    .align-self-sm-end{
        align-self:flex-end!important
    }
    .align-self-sm-center{
        align-self:center!important
    }
    .align-self-sm-baseline{
        align-self:baseline!important
    }
    .align-self-sm-stretch{
        align-self:stretch!important
    }
}
@media (min-width:768px){
    .flex-md-row{
        flex-direction:row!important
    }
    .flex-md-column{
        flex-direction:column!important
    }
    .flex-md-row-reverse{
        flex-direction:row-reverse!important
    }
    .flex-md-column-reverse{
        flex-direction:column-reverse!important
    }
    .flex-md-wrap{
        flex-wrap:wrap!important
    }
    .flex-md-nowrap{
        flex-wrap:nowrap!important
    }
    .flex-md-wrap-reverse{
        flex-wrap:wrap-reverse!important
    }
    .flex-md-fill{
        flex:1 1 auto!important
    }
    .flex-md-grow-0{
        flex-grow:0!important
    }
    .flex-md-grow-1{
        flex-grow:1!important
    }
    .flex-md-shrink-0{
        flex-shrink:0!important
    }
    .flex-md-shrink-1{
        flex-shrink:1!important
    }
    .justify-content-md-start{
        justify-content:flex-start!important
    }
    .justify-content-md-end{
        justify-content:flex-end!important
    }
    .justify-content-md-center{
        justify-content:center!important
    }
    .justify-content-md-between{
        justify-content:space-between!important
    }
    .justify-content-md-around{
        justify-content:space-around!important
    }
    .align-items-md-start{
        align-items:flex-start!important
    }
    .align-items-md-end{
        align-items:flex-end!important
    }
    .align-items-md-center{
        align-items:center!important
    }
    .align-items-md-baseline{
        align-items:baseline!important
    }
    .align-items-md-stretch{
        align-items:stretch!important
    }
    .align-content-md-start{
        align-content:flex-start!important
    }
    .align-content-md-end{
        align-content:flex-end!important
    }
    .align-content-md-center{
        align-content:center!important
    }
    .align-content-md-between{
        align-content:space-between!important
    }
    .align-content-md-around{
        align-content:space-around!important
    }
    .align-content-md-stretch{
        align-content:stretch!important
    }
    .align-self-md-auto{
        align-self:auto!important
    }
    .align-self-md-start{
        align-self:flex-start!important
    }
    .align-self-md-end{
        align-self:flex-end!important
    }
    .align-self-md-center{
        align-self:center!important
    }
    .align-self-md-baseline{
        align-self:baseline!important
    }
    .align-self-md-stretch{
        align-self:stretch!important
    }
}
@media (min-width:1100px){
    .flex-lg-row{
        flex-direction:row!important
    }
    .flex-lg-column{
        flex-direction:column!important
    }
    .flex-lg-row-reverse{
        flex-direction:row-reverse!important
    }
    .flex-lg-column-reverse{
        flex-direction:column-reverse!important
    }
    .flex-lg-wrap{
        flex-wrap:wrap!important
    }
    .flex-lg-nowrap{
        flex-wrap:nowrap!important
    }
    .flex-lg-wrap-reverse{
        flex-wrap:wrap-reverse!important
    }
    .flex-lg-fill{
        flex:1 1 auto!important
    }
    .flex-lg-grow-0{
        flex-grow:0!important
    }
    .flex-lg-grow-1{
        flex-grow:1!important
    }
    .flex-lg-shrink-0{
        flex-shrink:0!important
    }
    .flex-lg-shrink-1{
        flex-shrink:1!important
    }
    .justify-content-lg-start{
        justify-content:flex-start!important
    }
    .justify-content-lg-end{
        justify-content:flex-end!important
    }
    .justify-content-lg-center{
        justify-content:center!important
    }
    .justify-content-lg-between{
        justify-content:space-between!important
    }
    .justify-content-lg-around{
        justify-content:space-around!important
    }
    .align-items-lg-start{
        align-items:flex-start!important
    }
    .align-items-lg-end{
        align-items:flex-end!important
    }
    .align-items-lg-center{
        align-items:center!important
    }
    .align-items-lg-baseline{
        align-items:baseline!important
    }
    .align-items-lg-stretch{
        align-items:stretch!important
    }
    .align-content-lg-start{
        align-content:flex-start!important
    }
    .align-content-lg-end{
        align-content:flex-end!important
    }
    .align-content-lg-center{
        align-content:center!important
    }
    .align-content-lg-between{
        align-content:space-between!important
    }
    .align-content-lg-around{
        align-content:space-around!important
    }
    .align-content-lg-stretch{
        align-content:stretch!important
    }
    .align-self-lg-auto{
        align-self:auto!important
    }
    .align-self-lg-start{
        align-self:flex-start!important
    }
    .align-self-lg-end{
        align-self:flex-end!important
    }
    .align-self-lg-center{
        align-self:center!important
    }
    .align-self-lg-baseline{
        align-self:baseline!important
    }
    .align-self-lg-stretch{
        align-self:stretch!important
    }
}
@media (min-width:1381px){
    .flex-xl-row{
        flex-direction:row!important
    }
    .flex-xl-column{
        flex-direction:column!important
    }
    .flex-xl-row-reverse{
        flex-direction:row-reverse!important
    }
    .flex-xl-column-reverse{
        flex-direction:column-reverse!important
    }
    .flex-xl-wrap{
        flex-wrap:wrap!important
    }
    .flex-xl-nowrap{
        flex-wrap:nowrap!important
    }
    .flex-xl-wrap-reverse{
        flex-wrap:wrap-reverse!important
    }
    .flex-xl-fill{
        flex:1 1 auto!important
    }
    .flex-xl-grow-0{
        flex-grow:0!important
    }
    .flex-xl-grow-1{
        flex-grow:1!important
    }
    .flex-xl-shrink-0{
        flex-shrink:0!important
    }
    .flex-xl-shrink-1{
        flex-shrink:1!important
    }
    .justify-content-xl-start{
        justify-content:flex-start!important
    }
    .justify-content-xl-end{
        justify-content:flex-end!important
    }
    .justify-content-xl-center{
        justify-content:center!important
    }
    .justify-content-xl-between{
        justify-content:space-between!important
    }
    .justify-content-xl-around{
        justify-content:space-around!important
    }
    .align-items-xl-start{
        align-items:flex-start!important
    }
    .align-items-xl-end{
        align-items:flex-end!important
    }
    .align-items-xl-center{
        align-items:center!important
    }
    .align-items-xl-baseline{
        align-items:baseline!important
    }
    .align-items-xl-stretch{
        align-items:stretch!important
    }
    .align-content-xl-start{
        align-content:flex-start!important
    }
    .align-content-xl-end{
        align-content:flex-end!important
    }
    .align-content-xl-center{
        align-content:center!important
    }
    .align-content-xl-between{
        align-content:space-between!important
    }
    .align-content-xl-around{
        align-content:space-around!important
    }
    .align-content-xl-stretch{
        align-content:stretch!important
    }
    .align-self-xl-auto{
        align-self:auto!important
    }
    .align-self-xl-start{
        align-self:flex-start!important
    }
    .align-self-xl-end{
        align-self:flex-end!important
    }
    .align-self-xl-center{
        align-self:center!important
    }
    .align-self-xl-baseline{
        align-self:baseline!important
    }
    .align-self-xl-stretch{
        align-self:stretch!important
    }
}
.float-left{
    float:left!important
}
.float-right{
    float:right!important
}
.float-none{
    float:none!important
}
@media (min-width:576px){
    .float-sm-left{
        float:left!important
    }
    .float-sm-right{
        float:right!important
    }
    .float-sm-none{
        float:none!important
    }
}
@media (min-width:768px){
    .float-md-left{
        float:left!important
    }
    .float-md-right{
        float:right!important
    }
    .float-md-none{
        float:none!important
    }
}
@media (min-width:1100px){
    .float-lg-left{
        float:left!important
    }
    .float-lg-right{
        float:right!important
    }
    .float-lg-none{
        float:none!important
    }
}
@media (min-width:1381px){
    .float-xl-left{
        float:left!important
    }
    .float-xl-right{
        float:right!important
    }
    .float-xl-none{
        float:none!important
    }
}
.overflow-auto{
    overflow:auto!important
}
.overflow-hidden{
    overflow:hidden!important
}
.position-static{
    position:static!important
}
.position-relative{
    position:relative!important
}
.position-absolute{
    position:absolute!important
}
.position-fixed{
    position:fixed!important
}
.position-sticky{
    position:-webkit-sticky!important;
    position:sticky!important
}
.fixed-top{
    top:0
}
.fixed-bottom,.fixed-top{
    position:fixed;
    right:0;
    left:0;
    z-index:1330
}
.fixed-bottom{
    bottom:0
}
@supports ((position:-webkit-sticky) or (position:sticky)){
    .sticky-top{
        position:-webkit-sticky;
        position:sticky;
        top:0;
        z-index:1320
    }
}
.sr-only{
    position:absolute;
    width:1px;
    height:1px;
    padding:0;
    margin:-1px;
    overflow:hidden;
    clip:rect(0,0,0,0);
    white-space:nowrap;
    border:0
}
.sr-only-focusable:active,.sr-only-focusable:focus{
    position:static;
    width:auto;
    height:auto;
    overflow:visible;
    clip:auto;
    white-space:normal
}
.shadow-sm{
    box-shadow:0 .313rem .8rem rgba(122,123,151,.5),0 .126rem .225rem rgba(122,123,151,.3)!important
}
.shadow{
    box-shadow:0 .5rem 1rem rgba(7,9,25,.55)!important
}
.shadow-lg{
    box-shadow:0 1rem 3rem rgba(7,9,25,.575)!important
}
.shadow-none{
    box-shadow:none!important
}
.w-25{
    width:25%!important
}
.w-50{
    width:50%!important
}
.w-75{
    width:75%!important
}
.w-100{
    width:100%!important
}
.w-auto{
    width:auto!important
}
.h-25{
    height:25%!important
}
.h-50{
    height:50%!important
}
.h-75{
    height:75%!important
}
.h-100{
    height:100%!important
}
.h-auto{
    height:auto!important
}
.mw-100{
    max-width:100%!important
}
.mh-100{
    max-height:100%!important
}
.min-vw-100{
    min-width:100vw!important
}
.min-vh-100{
    min-height:100vh!important
}
.vw-100{
    width:100vw!important
}
.vh-100{
    height:100vh!important
}
.stretched-link:after{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index:1;
    pointer-events:auto;
    content:"";
    background-color:transparent
}
.m-0{
    margin:0!important
}
.mt-0,.my-0{
    margin-top:0!important
}
.mr-0,.mx-0{
    margin-right:0!important
}
.mb-0,.my-0{
    margin-bottom:0!important
}
.ml-0,.mx-0{
    margin-left:0!important
}
.m-1{
    margin:.25rem!important
}
.mt-1,.my-1{
    margin-top:.25rem!important
}
.mr-1,.mx-1{
    margin-right:.25rem!important
}
.mb-1,.my-1{
    margin-bottom:.25rem!important
}
.ml-1,.mx-1{
    margin-left:.25rem!important
}
.m-2{
    margin:.5rem!important
}
.mt-2,.my-2{
    margin-top:.5rem!important
}
.mr-2,.mx-2{
    margin-right:.5rem!important
}
.mb-2,.my-2{
    margin-bottom:.5rem!important
}
.ml-2,.mx-2{
    margin-left:.5rem!important
}
.m-3{
    margin:1rem!important
}
.mt-3,.my-3{
    margin-top:1rem!important
}
.mr-3,.mx-3{
    margin-right:1rem!important
}
.mb-3,.my-3{
    margin-bottom:1rem!important
}
.ml-3,.mx-3{
    margin-left:1rem!important
}
.m-4{
    margin:1.5rem!important
}
.mt-4,.my-4{
    margin-top:1.5rem!important
}
.mr-4,.mx-4{
    margin-right:1.5rem!important
}
.mb-4,.my-4{
    margin-bottom:1.5rem!important
}
.ml-4,.mx-4{
    margin-left:1.5rem!important
}
.m-5{
    margin:3rem!important
}
.mt-5,.my-5{
    margin-top:3rem!important
}
.mr-5,.mx-5{
    margin-right:3rem!important
}
.mb-5,.my-5{
    margin-bottom:3rem!important
}
.ml-5,.mx-5{
    margin-left:3rem!important
}
.p-0{
    padding:0!important
}
.pt-0,.py-0{
    padding-top:0!important
}
.pr-0,.px-0{
    padding-right:0!important
}
.pb-0,.py-0{
    padding-bottom:0!important
}
.pl-0,.px-0{
    padding-left:0!important
}
.p-1{
    padding:.25rem!important
}
.pt-1,.py-1{
    padding-top:.25rem!important
}
.pr-1,.px-1{
    padding-right:.25rem!important
}
.pb-1,.py-1{
    padding-bottom:.25rem!important
}
.pl-1,.px-1{
    padding-left:.25rem!important
}
.p-2{
    padding:.5rem!important
}
.pt-2,.py-2{
    padding-top:.5rem!important
}
.pr-2,.px-2{
    padding-right:.5rem!important
}
.pb-2,.py-2{
    padding-bottom:.5rem!important
}
.pl-2,.px-2{
    padding-left:.5rem!important
}
.p-3{
    padding:1rem!important
}
.pt-3,.py-3{
    padding-top:1rem!important
}
.pr-3,.px-3{
    padding-right:1rem!important
}
.pb-3,.py-3{
    padding-bottom:1rem!important
}
.pl-3,.px-3{
    padding-left:1rem!important
}
.p-4{
    padding:1.5rem!important
}
.pt-4,.py-4{
    padding-top:1.5rem!important
}
.pr-4,.px-4{
    padding-right:1.5rem!important
}
.pb-4,.py-4{
    padding-bottom:1.5rem!important
}
.pl-4,.px-4{
    padding-left:1.5rem!important
}
.p-5{
    padding:3rem!important
}
.pt-5,.py-5{
    padding-top:3rem!important
}
.pr-5,.px-5{
    padding-right:3rem!important
}
.pb-5,.py-5{
    padding-bottom:3rem!important
}
.pl-5,.px-5{
    padding-left:3rem!important
}
.m-n1{
    margin:-.25rem!important
}
.mt-n1,.my-n1{
    margin-top:-.25rem!important
}
.mr-n1,.mx-n1{
    margin-right:-.25rem!important
}
.mb-n1,.my-n1{
    margin-bottom:-.25rem!important
}
.ml-n1,.mx-n1{
    margin-left:-.25rem!important
}
.m-n2{
    margin:-.5rem!important
}
.mt-n2,.my-n2{
    margin-top:-.5rem!important
}
.mr-n2,.mx-n2{
    margin-right:-.5rem!important
}
.mb-n2,.my-n2{
    margin-bottom:-.5rem!important
}
.ml-n2,.mx-n2{
    margin-left:-.5rem!important
}
.m-n3{
    margin:-1rem!important
}
.mt-n3,.my-n3{
    margin-top:-1rem!important
}
.mr-n3,.mx-n3{
    margin-right:-1rem!important
}
.mb-n3,.my-n3{
    margin-bottom:-1rem!important
}
.ml-n3,.mx-n3{
    margin-left:-1rem!important
}
.m-n4{
    margin:-1.5rem!important
}
.mt-n4,.my-n4{
    margin-top:-1.5rem!important
}
.mr-n4,.mx-n4{
    margin-right:-1.5rem!important
}
.mb-n4,.my-n4{
    margin-bottom:-1.5rem!important
}
.ml-n4,.mx-n4{
    margin-left:-1.5rem!important
}
.m-n5{
    margin:-3rem!important
}
.mt-n5,.my-n5{
    margin-top:-3rem!important
}
.mr-n5,.mx-n5{
    margin-right:-3rem!important
}
.mb-n5,.my-n5{
    margin-bottom:-3rem!important
}
.ml-n5,.mx-n5{
    margin-left:-3rem!important
}
.m-auto{
    margin:auto!important
}
.mt-auto,.my-auto{
    margin-top:auto!important
}
.mr-auto,.mx-auto{
    margin-right:auto!important
}
.mb-auto,.my-auto{
    margin-bottom:auto!important
}
.ml-auto,.mx-auto{
    margin-left:auto!important
}
@media (min-width:576px){
    .m-sm-0{
        margin:0!important
    }
    .mt-sm-0,.my-sm-0{
        margin-top:0!important
    }
    .mr-sm-0,.mx-sm-0{
        margin-right:0!important
    }
    .mb-sm-0,.my-sm-0{
        margin-bottom:0!important
    }
    .ml-sm-0,.mx-sm-0{
        margin-left:0!important
    }
    .m-sm-1{
        margin:.25rem!important
    }
    .mt-sm-1,.my-sm-1{
        margin-top:.25rem!important
    }
    .mr-sm-1,.mx-sm-1{
        margin-right:.25rem!important
    }
    .mb-sm-1,.my-sm-1{
        margin-bottom:.25rem!important
    }
    .ml-sm-1,.mx-sm-1{
        margin-left:.25rem!important
    }
    .m-sm-2{
        margin:.5rem!important
    }
    .mt-sm-2,.my-sm-2{
        margin-top:.5rem!important
    }
    .mr-sm-2,.mx-sm-2{
        margin-right:.5rem!important
    }
    .mb-sm-2,.my-sm-2{
        margin-bottom:.5rem!important
    }
    .ml-sm-2,.mx-sm-2{
        margin-left:.5rem!important
    }
    .m-sm-3{
        margin:1rem!important
    }
    .mt-sm-3,.my-sm-3{
        margin-top:1rem!important
    }
    .mr-sm-3,.mx-sm-3{
        margin-right:1rem!important
    }
    .mb-sm-3,.my-sm-3{
        margin-bottom:1rem!important
    }
    .ml-sm-3,.mx-sm-3{
        margin-left:1rem!important
    }
    .m-sm-4{
        margin:1.5rem!important
    }
    .mt-sm-4,.my-sm-4{
        margin-top:1.5rem!important
    }
    .mr-sm-4,.mx-sm-4{
        margin-right:1.5rem!important
    }
    .mb-sm-4,.my-sm-4{
        margin-bottom:1.5rem!important
    }
    .ml-sm-4,.mx-sm-4{
        margin-left:1.5rem!important
    }
    .m-sm-5{
        margin:3rem!important
    }
    .mt-sm-5,.my-sm-5{
        margin-top:3rem!important
    }
    .mr-sm-5,.mx-sm-5{
        margin-right:3rem!important
    }
    .mb-sm-5,.my-sm-5{
        margin-bottom:3rem!important
    }
    .ml-sm-5,.mx-sm-5{
        margin-left:3rem!important
    }
    .p-sm-0{
        padding:0!important
    }
    .pt-sm-0,.py-sm-0{
        padding-top:0!important
    }
    .pr-sm-0,.px-sm-0{
        padding-right:0!important
    }
    .pb-sm-0,.py-sm-0{
        padding-bottom:0!important
    }
    .pl-sm-0,.px-sm-0{
        padding-left:0!important
    }
    .p-sm-1{
        padding:.25rem!important
    }
    .pt-sm-1,.py-sm-1{
        padding-top:.25rem!important
    }
    .pr-sm-1,.px-sm-1{
        padding-right:.25rem!important
    }
    .pb-sm-1,.py-sm-1{
        padding-bottom:.25rem!important
    }
    .pl-sm-1,.px-sm-1{
        padding-left:.25rem!important
    }
    .p-sm-2{
        padding:.5rem!important
    }
    .pt-sm-2,.py-sm-2{
        padding-top:.5rem!important
    }
    .pr-sm-2,.px-sm-2{
        padding-right:.5rem!important
    }
    .pb-sm-2,.py-sm-2{
        padding-bottom:.5rem!important
    }
    .pl-sm-2,.px-sm-2{
        padding-left:.5rem!important
    }
    .p-sm-3{
        padding:1rem!important
    }
    .pt-sm-3,.py-sm-3{
        padding-top:1rem!important
    }
    .pr-sm-3,.px-sm-3{
        padding-right:1rem!important
    }
    .pb-sm-3,.py-sm-3{
        padding-bottom:1rem!important
    }
    .pl-sm-3,.px-sm-3{
        padding-left:1rem!important
    }
    .p-sm-4{
        padding:1.5rem!important
    }
    .pt-sm-4,.py-sm-4{
        padding-top:1.5rem!important
    }
    .pr-sm-4,.px-sm-4{
        padding-right:1.5rem!important
    }
    .pb-sm-4,.py-sm-4{
        padding-bottom:1.5rem!important
    }
    .pl-sm-4,.px-sm-4{
        padding-left:1.5rem!important
    }
    .p-sm-5{
        padding:3rem!important
    }
    .pt-sm-5,.py-sm-5{
        padding-top:3rem!important
    }
    .pr-sm-5,.px-sm-5{
        padding-right:3rem!important
    }
    .pb-sm-5,.py-sm-5{
        padding-bottom:3rem!important
    }
    .pl-sm-5,.px-sm-5{
        padding-left:3rem!important
    }
    .m-sm-n1{
        margin:-.25rem!important
    }
    .mt-sm-n1,.my-sm-n1{
        margin-top:-.25rem!important
    }
    .mr-sm-n1,.mx-sm-n1{
        margin-right:-.25rem!important
    }
    .mb-sm-n1,.my-sm-n1{
        margin-bottom:-.25rem!important
    }
    .ml-sm-n1,.mx-sm-n1{
        margin-left:-.25rem!important
    }
    .m-sm-n2{
        margin:-.5rem!important
    }
    .mt-sm-n2,.my-sm-n2{
        margin-top:-.5rem!important
    }
    .mr-sm-n2,.mx-sm-n2{
        margin-right:-.5rem!important
    }
    .mb-sm-n2,.my-sm-n2{
        margin-bottom:-.5rem!important
    }
    .ml-sm-n2,.mx-sm-n2{
        margin-left:-.5rem!important
    }
    .m-sm-n3{
        margin:-1rem!important
    }
    .mt-sm-n3,.my-sm-n3{
        margin-top:-1rem!important
    }
    .mr-sm-n3,.mx-sm-n3{
        margin-right:-1rem!important
    }
    .mb-sm-n3,.my-sm-n3{
        margin-bottom:-1rem!important
    }
    .ml-sm-n3,.mx-sm-n3{
        margin-left:-1rem!important
    }
    .m-sm-n4{
        margin:-1.5rem!important
    }
    .mt-sm-n4,.my-sm-n4{
        margin-top:-1.5rem!important
    }
    .mr-sm-n4,.mx-sm-n4{
        margin-right:-1.5rem!important
    }
    .mb-sm-n4,.my-sm-n4{
        margin-bottom:-1.5rem!important
    }
    .ml-sm-n4,.mx-sm-n4{
        margin-left:-1.5rem!important
    }
    .m-sm-n5{
        margin:-3rem!important
    }
    .mt-sm-n5,.my-sm-n5{
        margin-top:-3rem!important
    }
    .mr-sm-n5,.mx-sm-n5{
        margin-right:-3rem!important
    }
    .mb-sm-n5,.my-sm-n5{
        margin-bottom:-3rem!important
    }
    .ml-sm-n5,.mx-sm-n5{
        margin-left:-3rem!important
    }
    .m-sm-auto{
        margin:auto!important
    }
    .mt-sm-auto,.my-sm-auto{
        margin-top:auto!important
    }
    .mr-sm-auto,.mx-sm-auto{
        margin-right:auto!important
    }
    .mb-sm-auto,.my-sm-auto{
        margin-bottom:auto!important
    }
    .ml-sm-auto,.mx-sm-auto{
        margin-left:auto!important
    }
}
@media (min-width:768px){
    .m-md-0{
        margin:0!important
    }
    .mt-md-0,.my-md-0{
        margin-top:0!important
    }
    .mr-md-0,.mx-md-0{
        margin-right:0!important
    }
    .mb-md-0,.my-md-0{
        margin-bottom:0!important
    }
    .ml-md-0,.mx-md-0{
        margin-left:0!important
    }
    .m-md-1{
        margin:.25rem!important
    }
    .mt-md-1,.my-md-1{
        margin-top:.25rem!important
    }
    .mr-md-1,.mx-md-1{
        margin-right:.25rem!important
    }
    .mb-md-1,.my-md-1{
        margin-bottom:.25rem!important
    }
    .ml-md-1,.mx-md-1{
        margin-left:.25rem!important
    }
    .m-md-2{
        margin:.5rem!important
    }
    .mt-md-2,.my-md-2{
        margin-top:.5rem!important
    }
    .mr-md-2,.mx-md-2{
        margin-right:.5rem!important
    }
    .mb-md-2,.my-md-2{
        margin-bottom:.5rem!important
    }
    .ml-md-2,.mx-md-2{
        margin-left:.5rem!important
    }
    .m-md-3{
        margin:1rem!important
    }
    .mt-md-3,.my-md-3{
        margin-top:1rem!important
    }
    .mr-md-3,.mx-md-3{
        margin-right:1rem!important
    }
    .mb-md-3,.my-md-3{
        margin-bottom:1rem!important
    }
    .ml-md-3,.mx-md-3{
        margin-left:1rem!important
    }
    .m-md-4{
        margin:1.5rem!important
    }
    .mt-md-4,.my-md-4{
        margin-top:1.5rem!important
    }
    .mr-md-4,.mx-md-4{
        margin-right:1.5rem!important
    }
    .mb-md-4,.my-md-4{
        margin-bottom:1.5rem!important
    }
    .ml-md-4,.mx-md-4{
        margin-left:1.5rem!important
    }
    .m-md-5{
        margin:3rem!important
    }
    .mt-md-5,.my-md-5{
        margin-top:3rem!important
    }
    .mr-md-5,.mx-md-5{
        margin-right:3rem!important
    }
    .mb-md-5,.my-md-5{
        margin-bottom:3rem!important
    }
    .ml-md-5,.mx-md-5{
        margin-left:3rem!important
    }
    .p-md-0{
        padding:0!important
    }
    .pt-md-0,.py-md-0{
        padding-top:0!important
    }
    .pr-md-0,.px-md-0{
        padding-right:0!important
    }
    .pb-md-0,.py-md-0{
        padding-bottom:0!important
    }
    .pl-md-0,.px-md-0{
        padding-left:0!important
    }
    .p-md-1{
        padding:.25rem!important
    }
    .pt-md-1,.py-md-1{
        padding-top:.25rem!important
    }
    .pr-md-1,.px-md-1{
        padding-right:.25rem!important
    }
    .pb-md-1,.py-md-1{
        padding-bottom:.25rem!important
    }
    .pl-md-1,.px-md-1{
        padding-left:.25rem!important
    }
    .p-md-2{
        padding:.5rem!important
    }
    .pt-md-2,.py-md-2{
        padding-top:.5rem!important
    }
    .pr-md-2,.px-md-2{
        padding-right:.5rem!important
    }
    .pb-md-2,.py-md-2{
        padding-bottom:.5rem!important
    }
    .pl-md-2,.px-md-2{
        padding-left:.5rem!important
    }
    .p-md-3{
        padding:1rem!important
    }
    .pt-md-3,.py-md-3{
        padding-top:1rem!important
    }
    .pr-md-3,.px-md-3{
        padding-right:1rem!important
    }
    .pb-md-3,.py-md-3{
        padding-bottom:1rem!important
    }
    .pl-md-3,.px-md-3{
        padding-left:1rem!important
    }
    .p-md-4{
        padding:1.5rem!important
    }
    .pt-md-4,.py-md-4{
        padding-top:1.5rem!important
    }
    .pr-md-4,.px-md-4{
        padding-right:1.5rem!important
    }
    .pb-md-4,.py-md-4{
        padding-bottom:1.5rem!important
    }
    .pl-md-4,.px-md-4{
        padding-left:1.5rem!important
    }
    .p-md-5{
        padding:3rem!important
    }
    .pt-md-5,.py-md-5{
        padding-top:3rem!important
    }
    .pr-md-5,.px-md-5{
        padding-right:3rem!important
    }
    .pb-md-5,.py-md-5{
        padding-bottom:3rem!important
    }
    .pl-md-5,.px-md-5{
        padding-left:3rem!important
    }
    .m-md-n1{
        margin:-.25rem!important
    }
    .mt-md-n1,.my-md-n1{
        margin-top:-.25rem!important
    }
    .mr-md-n1,.mx-md-n1{
        margin-right:-.25rem!important
    }
    .mb-md-n1,.my-md-n1{
        margin-bottom:-.25rem!important
    }
    .ml-md-n1,.mx-md-n1{
        margin-left:-.25rem!important
    }
    .m-md-n2{
        margin:-.5rem!important
    }
    .mt-md-n2,.my-md-n2{
        margin-top:-.5rem!important
    }
    .mr-md-n2,.mx-md-n2{
        margin-right:-.5rem!important
    }
    .mb-md-n2,.my-md-n2{
        margin-bottom:-.5rem!important
    }
    .ml-md-n2,.mx-md-n2{
        margin-left:-.5rem!important
    }
    .m-md-n3{
        margin:-1rem!important
    }
    .mt-md-n3,.my-md-n3{
        margin-top:-1rem!important
    }
    .mr-md-n3,.mx-md-n3{
        margin-right:-1rem!important
    }
    .mb-md-n3,.my-md-n3{
        margin-bottom:-1rem!important
    }
    .ml-md-n3,.mx-md-n3{
        margin-left:-1rem!important
    }
    .m-md-n4{
        margin:-1.5rem!important
    }
    .mt-md-n4,.my-md-n4{
        margin-top:-1.5rem!important
    }
    .mr-md-n4,.mx-md-n4{
        margin-right:-1.5rem!important
    }
    .mb-md-n4,.my-md-n4{
        margin-bottom:-1.5rem!important
    }
    .ml-md-n4,.mx-md-n4{
        margin-left:-1.5rem!important
    }
    .m-md-n5{
        margin:-3rem!important
    }
    .mt-md-n5,.my-md-n5{
        margin-top:-3rem!important
    }
    .mr-md-n5,.mx-md-n5{
        margin-right:-3rem!important
    }
    .mb-md-n5,.my-md-n5{
        margin-bottom:-3rem!important
    }
    .ml-md-n5,.mx-md-n5{
        margin-left:-3rem!important
    }
    .m-md-auto{
        margin:auto!important
    }
    .mt-md-auto,.my-md-auto{
        margin-top:auto!important
    }
    .mr-md-auto,.mx-md-auto{
        margin-right:auto!important
    }
    .mb-md-auto,.my-md-auto{
        margin-bottom:auto!important
    }
    .ml-md-auto,.mx-md-auto{
        margin-left:auto!important
    }
}
@media (min-width:1100px){
    .m-lg-0{
        margin:0!important
    }
    .mt-lg-0,.my-lg-0{
        margin-top:0!important
    }
    .mr-lg-0,.mx-lg-0{
        margin-right:0!important
    }
    .mb-lg-0,.my-lg-0{
        margin-bottom:0!important
    }
    .ml-lg-0,.mx-lg-0{
        margin-left:0!important
    }
    .m-lg-1{
        margin:.25rem!important
    }
    .mt-lg-1,.my-lg-1{
        margin-top:.25rem!important
    }
    .mr-lg-1,.mx-lg-1{
        margin-right:.25rem!important
    }
    .mb-lg-1,.my-lg-1{
        margin-bottom:.25rem!important
    }
    .ml-lg-1,.mx-lg-1{
        margin-left:.25rem!important
    }
    .m-lg-2{
        margin:.5rem!important
    }
    .mt-lg-2,.my-lg-2{
        margin-top:.5rem!important
    }
    .mr-lg-2,.mx-lg-2{
        margin-right:.5rem!important
    }
    .mb-lg-2,.my-lg-2{
        margin-bottom:.5rem!important
    }
    .ml-lg-2,.mx-lg-2{
        margin-left:.5rem!important
    }
    .m-lg-3{
        margin:1rem!important
    }
    .mt-lg-3,.my-lg-3{
        margin-top:1rem!important
    }
    .mr-lg-3,.mx-lg-3{
        margin-right:1rem!important
    }
    .mb-lg-3,.my-lg-3{
        margin-bottom:1rem!important
    }
    .ml-lg-3,.mx-lg-3{
        margin-left:1rem!important
    }
    .m-lg-4{
        margin:1.5rem!important
    }
    .mt-lg-4,.my-lg-4{
        margin-top:1.5rem!important
    }
    .mr-lg-4,.mx-lg-4{
        margin-right:1.5rem!important
    }
    .mb-lg-4,.my-lg-4{
        margin-bottom:1.5rem!important
    }
    .ml-lg-4,.mx-lg-4{
        margin-left:1.5rem!important
    }
    .m-lg-5{
        margin:3rem!important
    }
    .mt-lg-5,.my-lg-5{
        margin-top:3rem!important
    }
    .mr-lg-5,.mx-lg-5{
        margin-right:3rem!important
    }
    .mb-lg-5,.my-lg-5{
        margin-bottom:3rem!important
    }
    .ml-lg-5,.mx-lg-5{
        margin-left:3rem!important
    }
    .p-lg-0{
        padding:0!important
    }
    .pt-lg-0,.py-lg-0{
        padding-top:0!important
    }
    .pr-lg-0,.px-lg-0{
        padding-right:0!important
    }
    .pb-lg-0,.py-lg-0{
        padding-bottom:0!important
    }
    .pl-lg-0,.px-lg-0{
        padding-left:0!important
    }
    .p-lg-1{
        padding:.25rem!important
    }
    .pt-lg-1,.py-lg-1{
        padding-top:.25rem!important
    }
    .pr-lg-1,.px-lg-1{
        padding-right:.25rem!important
    }
    .pb-lg-1,.py-lg-1{
        padding-bottom:.25rem!important
    }
    .pl-lg-1,.px-lg-1{
        padding-left:.25rem!important
    }
    .p-lg-2{
        padding:.5rem!important
    }
    .pt-lg-2,.py-lg-2{
        padding-top:.5rem!important
    }
    .pr-lg-2,.px-lg-2{
        padding-right:.5rem!important
    }
    .pb-lg-2,.py-lg-2{
        padding-bottom:.5rem!important
    }
    .pl-lg-2,.px-lg-2{
        padding-left:.5rem!important
    }
    .p-lg-3{
        padding:1rem!important
    }
    .pt-lg-3,.py-lg-3{
        padding-top:1rem!important
    }
    .pr-lg-3,.px-lg-3{
        padding-right:1rem!important
    }
    .pb-lg-3,.py-lg-3{
        padding-bottom:1rem!important
    }
    .pl-lg-3,.px-lg-3{
        padding-left:1rem!important
    }
    .p-lg-4{
        padding:1.5rem!important
    }
    .pt-lg-4,.py-lg-4{
        padding-top:1.5rem!important
    }
    .pr-lg-4,.px-lg-4{
        padding-right:1.5rem!important
    }
    .pb-lg-4,.py-lg-4{
        padding-bottom:1.5rem!important
    }
    .pl-lg-4,.px-lg-4{
        padding-left:1.5rem!important
    }
    .p-lg-5{
        padding:3rem!important
    }
    .pt-lg-5,.py-lg-5{
        padding-top:3rem!important
    }
    .pr-lg-5,.px-lg-5{
        padding-right:3rem!important
    }
    .pb-lg-5,.py-lg-5{
        padding-bottom:3rem!important
    }
    .pl-lg-5,.px-lg-5{
        padding-left:3rem!important
    }
    .m-lg-n1{
        margin:-.25rem!important
    }
    .mt-lg-n1,.my-lg-n1{
        margin-top:-.25rem!important
    }
    .mr-lg-n1,.mx-lg-n1{
        margin-right:-.25rem!important
    }
    .mb-lg-n1,.my-lg-n1{
        margin-bottom:-.25rem!important
    }
    .ml-lg-n1,.mx-lg-n1{
        margin-left:-.25rem!important
    }
    .m-lg-n2{
        margin:-.5rem!important
    }
    .mt-lg-n2,.my-lg-n2{
        margin-top:-.5rem!important
    }
    .mr-lg-n2,.mx-lg-n2{
        margin-right:-.5rem!important
    }
    .mb-lg-n2,.my-lg-n2{
        margin-bottom:-.5rem!important
    }
    .ml-lg-n2,.mx-lg-n2{
        margin-left:-.5rem!important
    }
    .m-lg-n3{
        margin:-1rem!important
    }
    .mt-lg-n3,.my-lg-n3{
        margin-top:-1rem!important
    }
    .mr-lg-n3,.mx-lg-n3{
        margin-right:-1rem!important
    }
    .mb-lg-n3,.my-lg-n3{
        margin-bottom:-1rem!important
    }
    .ml-lg-n3,.mx-lg-n3{
        margin-left:-1rem!important
    }
    .m-lg-n4{
        margin:-1.5rem!important
    }
    .mt-lg-n4,.my-lg-n4{
        margin-top:-1.5rem!important
    }
    .mr-lg-n4,.mx-lg-n4{
        margin-right:-1.5rem!important
    }
    .mb-lg-n4,.my-lg-n4{
        margin-bottom:-1.5rem!important
    }
    .ml-lg-n4,.mx-lg-n4{
        margin-left:-1.5rem!important
    }
    .m-lg-n5{
        margin:-3rem!important
    }
    .mt-lg-n5,.my-lg-n5{
        margin-top:-3rem!important
    }
    .mr-lg-n5,.mx-lg-n5{
        margin-right:-3rem!important
    }
    .mb-lg-n5,.my-lg-n5{
        margin-bottom:-3rem!important
    }
    .ml-lg-n5,.mx-lg-n5{
        margin-left:-3rem!important
    }
    .m-lg-auto{
        margin:auto!important
    }
    .mt-lg-auto,.my-lg-auto{
        margin-top:auto!important
    }
    .mr-lg-auto,.mx-lg-auto{
        margin-right:auto!important
    }
    .mb-lg-auto,.my-lg-auto{
        margin-bottom:auto!important
    }
    .ml-lg-auto,.mx-lg-auto{
        margin-left:auto!important
    }
}
@media (min-width:1381px){
    .m-xl-0{
        margin:0!important
    }
    .mt-xl-0,.my-xl-0{
        margin-top:0!important
    }
    .mr-xl-0,.mx-xl-0{
        margin-right:0!important
    }
    .mb-xl-0,.my-xl-0{
        margin-bottom:0!important
    }
    .ml-xl-0,.mx-xl-0{
        margin-left:0!important
    }
    .m-xl-1{
        margin:.25rem!important
    }
    .mt-xl-1,.my-xl-1{
        margin-top:.25rem!important
    }
    .mr-xl-1,.mx-xl-1{
        margin-right:.25rem!important
    }
    .mb-xl-1,.my-xl-1{
        margin-bottom:.25rem!important
    }
    .ml-xl-1,.mx-xl-1{
        margin-left:.25rem!important
    }
    .m-xl-2{
        margin:.5rem!important
    }
    .mt-xl-2,.my-xl-2{
        margin-top:.5rem!important
    }
    .mr-xl-2,.mx-xl-2{
        margin-right:.5rem!important
    }
    .mb-xl-2,.my-xl-2{
        margin-bottom:.5rem!important
    }
    .ml-xl-2,.mx-xl-2{
        margin-left:.5rem!important
    }
    .m-xl-3{
        margin:1rem!important
    }
    .mt-xl-3,.my-xl-3{
        margin-top:1rem!important
    }
    .mr-xl-3,.mx-xl-3{
        margin-right:1rem!important
    }
    .mb-xl-3,.my-xl-3{
        margin-bottom:1rem!important
    }
    .ml-xl-3,.mx-xl-3{
        margin-left:1rem!important
    }
    .m-xl-4{
        margin:1.5rem!important
    }
    .mt-xl-4,.my-xl-4{
        margin-top:1.5rem!important
    }
    .mr-xl-4,.mx-xl-4{
        margin-right:1.5rem!important
    }
    .mb-xl-4,.my-xl-4{
        margin-bottom:1.5rem!important
    }
    .ml-xl-4,.mx-xl-4{
        margin-left:1.5rem!important
    }
    .m-xl-5{
        margin:3rem!important
    }
    .mt-xl-5,.my-xl-5{
        margin-top:3rem!important
    }
    .mr-xl-5,.mx-xl-5{
        margin-right:3rem!important
    }
    .mb-xl-5,.my-xl-5{
        margin-bottom:3rem!important
    }
    .ml-xl-5,.mx-xl-5{
        margin-left:3rem!important
    }
    .p-xl-0{
        padding:0!important
    }
    .pt-xl-0,.py-xl-0{
        padding-top:0!important
    }
    .pr-xl-0,.px-xl-0{
        padding-right:0!important
    }
    .pb-xl-0,.py-xl-0{
        padding-bottom:0!important
    }
    .pl-xl-0,.px-xl-0{
        padding-left:0!important
    }
    .p-xl-1{
        padding:.25rem!important
    }
    .pt-xl-1,.py-xl-1{
        padding-top:.25rem!important
    }
    .pr-xl-1,.px-xl-1{
        padding-right:.25rem!important
    }
    .pb-xl-1,.py-xl-1{
        padding-bottom:.25rem!important
    }
    .pl-xl-1,.px-xl-1{
        padding-left:.25rem!important
    }
    .p-xl-2{
        padding:.5rem!important
    }
    .pt-xl-2,.py-xl-2{
        padding-top:.5rem!important
    }
    .pr-xl-2,.px-xl-2{
        padding-right:.5rem!important
    }
    .pb-xl-2,.py-xl-2{
        padding-bottom:.5rem!important
    }
    .pl-xl-2,.px-xl-2{
        padding-left:.5rem!important
    }
    .p-xl-3{
        padding:1rem!important
    }
    .pt-xl-3,.py-xl-3{
        padding-top:1rem!important
    }
    .pr-xl-3,.px-xl-3{
        padding-right:1rem!important
    }
    .pb-xl-3,.py-xl-3{
        padding-bottom:1rem!important
    }
    .pl-xl-3,.px-xl-3{
        padding-left:1rem!important
    }
    .p-xl-4{
        padding:1.5rem!important
    }
    .pt-xl-4,.py-xl-4{
        padding-top:1.5rem!important
    }
    .pr-xl-4,.px-xl-4{
        padding-right:1.5rem!important
    }
    .pb-xl-4,.py-xl-4{
        padding-bottom:1.5rem!important
    }
    .pl-xl-4,.px-xl-4{
        padding-left:1.5rem!important
    }
    .p-xl-5{
        padding:3rem!important
    }
    .pt-xl-5,.py-xl-5{
        padding-top:3rem!important
    }
    .pr-xl-5,.px-xl-5{
        padding-right:3rem!important
    }
    .pb-xl-5,.py-xl-5{
        padding-bottom:3rem!important
    }
    .pl-xl-5,.px-xl-5{
        padding-left:3rem!important
    }
    .m-xl-n1{
        margin:-.25rem!important
    }
    .mt-xl-n1,.my-xl-n1{
        margin-top:-.25rem!important
    }
    .mr-xl-n1,.mx-xl-n1{
        margin-right:-.25rem!important
    }
    .mb-xl-n1,.my-xl-n1{
        margin-bottom:-.25rem!important
    }
    .ml-xl-n1,.mx-xl-n1{
        margin-left:-.25rem!important
    }
    .m-xl-n2{
        margin:-.5rem!important
    }
    .mt-xl-n2,.my-xl-n2{
        margin-top:-.5rem!important
    }
    .mr-xl-n2,.mx-xl-n2{
        margin-right:-.5rem!important
    }
    .mb-xl-n2,.my-xl-n2{
        margin-bottom:-.5rem!important
    }
    .ml-xl-n2,.mx-xl-n2{
        margin-left:-.5rem!important
    }
    .m-xl-n3{
        margin:-1rem!important
    }
    .mt-xl-n3,.my-xl-n3{
        margin-top:-1rem!important
    }
    .mr-xl-n3,.mx-xl-n3{
        margin-right:-1rem!important
    }
    .mb-xl-n3,.my-xl-n3{
        margin-bottom:-1rem!important
    }
    .ml-xl-n3,.mx-xl-n3{
        margin-left:-1rem!important
    }
    .m-xl-n4{
        margin:-1.5rem!important
    }
    .mt-xl-n4,.my-xl-n4{
        margin-top:-1.5rem!important
    }
    .mr-xl-n4,.mx-xl-n4{
        margin-right:-1.5rem!important
    }
    .mb-xl-n4,.my-xl-n4{
        margin-bottom:-1.5rem!important
    }
    .ml-xl-n4,.mx-xl-n4{
        margin-left:-1.5rem!important
    }
    .m-xl-n5{
        margin:-3rem!important
    }
    .mt-xl-n5,.my-xl-n5{
        margin-top:-3rem!important
    }
    .mr-xl-n5,.mx-xl-n5{
        margin-right:-3rem!important
    }
    .mb-xl-n5,.my-xl-n5{
        margin-bottom:-3rem!important
    }
    .ml-xl-n5,.mx-xl-n5{
        margin-left:-3rem!important
    }
    .m-xl-auto{
        margin:auto!important
    }
    .mt-xl-auto,.my-xl-auto{
        margin-top:auto!important
    }
    .mr-xl-auto,.mx-xl-auto{
        margin-right:auto!important
    }
    .mb-xl-auto,.my-xl-auto{
        margin-bottom:auto!important
    }
    .ml-xl-auto,.mx-xl-auto{
        margin-left:auto!important
    }
}
.text-monospace{
    font-family:"Times New Roman",Times,serif!important
}
.text-justify{
    text-align:justify!important
}
.text-wrap{
    white-space:normal!important
}
.text-nowrap{
    white-space:nowrap!important
}
.text-truncate{
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap
}
.text-left{
    text-align:left!important
}
.text-right{
    text-align:right!important
}
.text-center{
    text-align:center!important
}
@media (min-width:576px){
    .text-sm-left{
        text-align:left!important
    }
    .text-sm-right{
        text-align:right!important
    }
    .text-sm-center{
        text-align:center!important
    }
}
@media (min-width:768px){
    .text-md-left{
        text-align:left!important
    }
    .text-md-right{
        text-align:right!important
    }
    .text-md-center{
        text-align:center!important
    }
}
@media (min-width:1100px){
    .text-lg-left{
        text-align:left!important
    }
    .text-lg-right{
        text-align:right!important
    }
    .text-lg-center{
        text-align:center!important
    }
}
@media (min-width:1381px){
    .text-xl-left{
        text-align:left!important
    }
    .text-xl-right{
        text-align:right!important
    }
    .text-xl-center{
        text-align:center!important
    }
}
.text-lowercase{
    text-transform:lowercase!important
}
.text-uppercase{
    text-transform:uppercase!important
}
.text-capitalize{
    text-transform:capitalize!important
}
.font-weight-light{
    font-weight:300!important
}
.font-weight-lighter{
    font-weight:lighter!important
}
.font-weight-normal{
    font-weight:400!important
}
.font-weight-bold{
    font-weight:700!important
}
.font-weight-bolder{
    font-weight:bolder!important
}
.font-italic{
    font-style:italic!important
}
.text-white{
    color:#fff!important
}
.text-primary{
    color:#00892c!important
}
a.text-primary:focus,a.text-primary:hover{
    color:#096125!important
}
.text-secondary{
    color:#f8f9ff!important
}
a.text-secondary:focus,a.text-secondary:hover{
    color:#acb7ff!important
}
.text-success{
    color:#1bc943!important
}
a.text-success:focus,a.text-success:hover{
    color:#12862d!important
}
.text-info{
    color:#11c5db!important
}
a.text-info:focus,a.text-info:hover{
    color:#0b8594!important
}
.text-warning{
    color:#f4772e!important
}
a.text-warning:focus,a.text-warning:hover{
    color:#cb520b!important
}
.text-danger{
    color:#f83245!important
}
a.text-danger:focus,a.text-danger:hover{
    color:#d6071b!important
}
.text-light{
    color:#f4f5fd!important
}
a.text-light:focus,a.text-light:hover{
    color:#b3baf1!important
}
.text-gray{
    color:#f8f9ff!important
}
a.text-gray:focus,a.text-gray:hover{
    color:#acb7ff!important
}
.text-dark{
    color:#6c401c!important
}
a.text-dark:focus,a.text-dark:hover{
    color:#56576e!important
}
.text-first{
    color:#00892c!important
}
a.text-first:focus,a.text-first:hover{
    color:#0067f4!important
}
.text-second{
    color:#070919!important
}
a.text-second:focus,a.text-second:hover{
    color:#000!important
}
.text-body{
    color:#282828!important
}
.text-muted{
    color:#d1d2db!important
}
.text-black-50{
    color:rgba(59,62,102,.5)!important
}
.text-white-50{
    color:hsla(0,0%,100%,.5)!important
}
.text-hide{
    font:0/0 a;
    color:transparent;
    text-shadow:none;
    background-color:transparent;
    border:0
}
.text-decoration-none{
    text-decoration:none!important
}
.text-break{
    word-break:break-word!important;
    overflow-wrap:break-word!important
}
.text-reset{
    color:inherit!important
}
.visible{
    visibility:visible!important
}
.invisible{
    visibility:hidden!important
}
.dropzone-upload-wrapper{
    position:relative;
    display:flex;
    flex-direction:column;
    outline:none!important
}
.dropzone-upload-wrapper *{
    outline:none!important
}
.dropzone-upload-wrapper .dropzone-inner-wrapper{
    padding:3rem 1rem;
    cursor:pointer;
    transition:all .2s ease-in-out;
    text-align:center;
    color:#a6a6b9;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    border:2px dashed #e6e7f1;
    border-radius:.65rem;
    background-color:#f8f9ff
}
.dropzone-upload-wrapper .dropzone-inner-wrapper:hover{
    border-color:#00892c;
    color:#6c401c
}
.dropzone-upload-wrapper .dropzone-inner-wrapper .icon-blob-animated{
    transition:all .3s ease-in-out;
    -webkit-animation:float 2s ease-in-out infinite;
    animation:float 2s ease-in-out infinite
}
.dropzone-upload-wrapper .dropzone-inner-wrapper.dropzone-inner-wrapper-alt{
    background:transparent;
    border-color:transparent;
    border-width:1px
}
.dropzone-upload-wrapper .dropzone-inner-wrapper.dropzone-inner-wrapper-alt:hover{
    background:#f8f9ff;
    border-color:#a6a6b9
}
.dropzone-upload-wrapper .dropzone-inner-wrapper.dropzone-avatar{
    cursor:auto
}
.dropzone-upload-wrapper .dropzone-inner-wrapper.dropzone-avatar .avatar-image{
    position:absolute;
    left:0;
    top:0;
    z-index:4;
    width:100%;
    height:100%;
    overflow:hidden;
    border:0
}
.dropzone-upload-wrapper .dropzone-inner-wrapper.dropzone-avatar .avatar-button{
    z-index:5
}
@-webkit-keyframes float{
    0%{
        transform:rotateY(0deg) rotate(0deg) translateZ(0) rotateX(0deg) translateY(0)
    }
    50%{
        transform:rotateY(-2deg) rotate(-2deg) translateZ(-5px) rotateX(-4deg) translateY(-15px)
    }
    75%{
        transform:rotateY(4deg) rotate(4deg) translateZ(8px) rotateX(8deg) translateY(8px)
    }
    to{
        transform:rotateY(0deg) rotate(0deg) translateZ(0) rotateX(0deg) translateY(0)
    }
}
@keyframes float{
    0%{
        transform:rotateY(0deg) rotate(0deg) translateZ(0) rotateX(0deg) translateY(0)
    }
    50%{
        transform:rotateY(-2deg) rotate(-2deg) translateZ(-5px) rotateX(-4deg) translateY(-15px)
    }
    75%{
        transform:rotateY(4deg) rotate(4deg) translateZ(8px) rotateX(8deg) translateY(8px)
    }
    to{
        transform:rotateY(0deg) rotate(0deg) translateZ(0) rotateX(0deg) translateY(0)
    }
}
.rdw-option-wrapper{
    border:1px solid #d1d2db;
    padding:8px;
    min-width:25px;
    height:30px;
    border-radius:.29rem;
    margin:0 4px;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    background:#fff;
    text-transform:capitalize
}
.rdw-option-wrapper:hover{
    box-shadow:1px 1px 0 #e6e7f1
}
.rdw-option-active,.rdw-option-wrapper:active{
    box-shadow:inset 1px 1px 0 #e6e7f1
}
.rdw-option-disabled{
    opacity:.3;
    cursor:default
}
.rdw-dropdown-wrapper{
    height:30px;
    cursor:pointer;
    border:1px solid #d1d2db;
    border-radius:.29rem;
    margin:0 3px;
    text-transform:capitalize;
    background:#fff
}
.rdw-dropdown-wrapper:focus{
    outline:none
}
.rdw-dropdown-wrapper:hover{
    box-shadow:1px 1px 0 #e6e7f1;
    background-color:#fff
}
.rdw-dropdown-wrapper:active{
    box-shadow:inset 1px 1px 0 #e6e7f1
}
.rdw-dropdown-carettoopen{
    border-top:6px solid #070919
}
.rdw-dropdown-carettoclose,.rdw-dropdown-carettoopen{
    height:0;
    width:0;
    position:absolute;
    top:35%;
    right:10%;
    border-left:5px solid transparent;
    border-right:5px solid transparent
}
.rdw-dropdown-carettoclose{
    border-bottom:6px solid #070919
}
.rdw-dropdown-selectedtext{
    display:flex;
    position:relative;
    height:100%;
    align-items:center;
    padding:0 5px
}
.rdw-dropdown-optionwrapper{
    z-index:100;
    position:relative;
    border:1px solid #d1d2db;
    width:98%;
    background:#fff;
    border-radius:.29rem;
    margin:0;
    padding:0;
    max-height:250px;
    overflow-y:scroll
}
.rdw-dropdown-optionwrapper:hover{
    box-shadow:1px 1px 0 #e6e7f1;
    background-color:#fff
}
.rdw-dropdownoption-default{
    min-height:25px;
    display:flex;
    align-items:center;
    padding:0 5px
}
.rdw-dropdownoption-highlighted{
    background:#d1d2db
}
.rdw-dropdownoption-active{
    background:#f5f5f5
}
.rdw-dropdownoption-disabled{
    opacity:.3;
    cursor:default
}
.rdw-inline-wrapper{
    display:flex;
    align-items:center;
    margin-bottom:6px;
    flex-wrap:wrap
}
.rdw-inline-dropdown{
    width:50px
}
.rdw-inline-dropdownoption{
    height:40px;
    display:flex;
    justify-content:center
}
.rdw-block-wrapper{
    display:flex;
    align-items:center;
    margin-bottom:6px;
    flex-wrap:wrap
}
.rdw-block-dropdown{
    width:110px
}
.rdw-fontsize-wrapper{
    display:flex;
    align-items:center;
    margin-bottom:6px;
    flex-wrap:wrap
}
.rdw-fontsize-dropdown{
    min-width:40px
}
.rdw-fontsize-option{
    display:flex;
    justify-content:center
}
.rdw-fontfamily-wrapper{
    display:flex;
    align-items:center;
    margin-bottom:6px;
    flex-wrap:wrap
}
.rdw-fontfamily-dropdown{
    width:115px
}
.rdw-fontfamily-placeholder{
    white-space:nowrap;
    max-width:90px;
    overflow:hidden;
    text-overflow:ellipsis
}
.rdw-fontfamily-optionwrapper{
    width:140px
}
.rdw-list-wrapper{
    display:flex;
    align-items:center;
    margin-bottom:6px;
    flex-wrap:wrap
}
.rdw-list-dropdown{
    width:50px;
    z-index:90
}
.rdw-list-dropdownOption{
    height:40px;
    display:flex;
    justify-content:center
}
.rdw-text-align-wrapper{
    display:flex;
    align-items:center;
    margin-bottom:6px;
    flex-wrap:wrap
}
.rdw-text-align-dropdown{
    width:50px;
    z-index:90
}
.rdw-text-align-dropdownOption{
    height:40px;
    display:flex;
    justify-content:center
}
.rdw-right-aligned-block{
    text-align:right
}
.rdw-left-aligned-block{
    text-align:left!important
}
.rdw-center-aligned-block{
    text-align:center!important
}
.rdw-justify-aligned-block{
    text-align:justify!important
}
.rdw-center-aligned-block>div,.rdw-justify-aligned-block>div,.rdw-left-aligned-block>div,.rdw-right-aligned-block>div{
    display:inline-block
}
.rdw-colorpicker-wrapper{
    display:flex;
    align-items:center;
    margin-bottom:6px;
    position:relative;
    flex-wrap:wrap
}
.rdw-colorpicker-modal{
    position:absolute;
    top:35px;
    left:5px;
    display:flex;
    flex-direction:column;
    width:175px;
    height:175px;
    border:1px solid #d1d2db;
    padding:15px;
    border-radius:.29rem;
    z-index:100;
    background:#fff;
    box-shadow:3px 3px 5px #e6e7f1
}
.rdw-colorpicker-modal-header{
    display:flex;
    padding-bottom:5px
}
.rdw-colorpicker-modal-style-label{
    font-size:15px;
    width:50%;
    text-align:center;
    cursor:pointer;
    padding:0 10px 5px
}
.rdw-colorpicker-modal-style-label-active{
    border-bottom:2px solid #00892c
}
.rdw-colorpicker-modal-options{
    margin:5px auto;
    display:flex;
    width:100%;
    height:100%;
    flex-wrap:wrap;
    overflow:scroll
}
.rdw-colorpicker-cube{
    width:22px;
    height:22px;
    border:1px solid #d1d2db
}
.rdw-colorpicker-option{
    margin:3px;
    padding:0;
    min-height:20px;
    border:none;
    width:22px;
    height:22px;
    min-width:22px;
    box-shadow:inset 1px 2px 1px #e6e7f1
}
.rdw-colorpicker-option:hover{
    box-shadow:1px 2px 1px #e6e7f1
}
.rdw-colorpicker-option:active{
    box-shadow:-1px -2px 1px #e6e7f1
}
.rdw-colorpicker-option-active{
    box-shadow:0 0 2px 2px #e6e7f1
}
.rdw-link-wrapper{
    display:flex;
    align-items:center;
    margin-bottom:6px;
    position:relative;
    flex-wrap:wrap
}
.rdw-link-dropdown{
    width:50px
}
.rdw-link-dropdownOption{
    height:40px;
    display:flex;
    justify-content:center
}
.rdw-link-dropdownPlaceholder{
    margin-left:8px
}
.rdw-link-modal{
    position:absolute;
    top:35px;
    left:5px;
    display:flex;
    flex-direction:column;
    width:235px;
    height:205px;
    border:1px solid #d1d2db;
    padding:15px;
    border-radius:.29rem;
    z-index:100;
    background:#fff;
    box-shadow:3px 3px 5px #e6e7f1
}
.rdw-link-modal-label{
    font-size:15px
}
.rdw-link-modal-input{
    margin-top:5px;
    border-radius:.29rem;
    border:1px solid #d1d2db;
    height:25px;
    margin-bottom:15px;
    padding:0 5px
}
.rdw-link-modal-input:focus{
    outline:none
}
.rdw-link-modal-buttonsection{
    margin:0 auto
}
.rdw-link-modal-target-option{
    margin-bottom:20px
}
.rdw-link-modal-target-option>span{
    margin-left:5px
}
.rdw-link-modal-btn{
    margin-left:10px;
    width:75px;
    height:30px;
    border:1px solid #d1d2db;
    border-radius:.29rem;
    cursor:pointer;
    background:#fff;
    text-transform:capitalize
}
.rdw-link-modal-btn:hover{
    box-shadow:1px 1px 0 #e6e7f1
}
.rdw-link-modal-btn:active{
    box-shadow:inset 1px 1px 0 #e6e7f1
}
.rdw-link-modal-btn:focus{
    outline:none!important
}
.rdw-link-modal-btn:disabled{
    background:#eeeff8
}
.rdw-link-dropdownoption{
    height:40px;
    display:flex;
    justify-content:center
}
.rdw-embedded-wrapper{
    display:flex;
    align-items:center;
    margin-bottom:6px;
    position:relative;
    flex-wrap:wrap
}
.rdw-embedded-modal{
    position:absolute;
    top:35px;
    left:5px;
    display:flex;
    flex-direction:column;
    width:235px;
    height:180px;
    border:1px solid #d1d2db;
    padding:15px;
    border-radius:.29rem;
    z-index:100;
    background:#fff;
    justify-content:space-between;
    box-shadow:3px 3px 5px #e6e7f1
}
.rdw-embedded-modal-header{
    font-size:15px;
    display:flex
}
.rdw-embedded-modal-header-option{
    width:50%;
    cursor:pointer;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column
}
.rdw-embedded-modal-header-label{
    width:95px;
    margin-top:5px;
    background:#eceef7;
    border:1px solid #e6e7f1;
    border-bottom:2px solid #00892c
}
.rdw-embedded-modal-link-section{
    display:flex;
    flex-direction:column
}
.rdw-embedded-modal-link-input{
    width:88%;
    height:35px;
    margin:10px 0;
    border:1px solid #d1d2db;
    border-radius:.29rem;
    font-size:15px;
    padding:0 5px
}
.rdw-embedded-modal-link-input-wrapper{
    display:flex;
    align-items:center
}
.rdw-embedded-modal-link-input:focus{
    outline:none
}
.rdw-embedded-modal-btn-section{
    display:flex;
    justify-content:center
}
.rdw-embedded-modal-btn{
    margin:0 3px;
    width:75px;
    height:30px;
    border:1px solid #d1d2db;
    border-radius:.29rem;
    cursor:pointer;
    background:#fff;
    text-transform:capitalize
}
.rdw-embedded-modal-btn:hover{
    box-shadow:1px 1px 0 #e6e7f1
}
.rdw-embedded-modal-btn:active{
    box-shadow:inset 1px 1px 0 #e6e7f1
}
.rdw-embedded-modal-btn:focus{
    outline:none!important
}
.rdw-embedded-modal-btn:disabled{
    background:#eeeff8
}
.rdw-embedded-modal-size{
    align-items:center;
    display:flex;
    margin:8px 0;
    justify-content:space-between
}
.rdw-embedded-modal-size-input{
    width:80%;
    height:20px;
    border:1px solid #d1d2db;
    border-radius:.29rem;
    font-size:12px
}
.rdw-embedded-modal-size-input:focus{
    outline:none
}
.rdw-emoji-wrapper{
    display:flex;
    align-items:center;
    margin-bottom:6px;
    position:relative;
    flex-wrap:wrap
}
.rdw-emoji-modal{
    overflow:auto;
    position:absolute;
    top:35px;
    left:5px;
    display:flex;
    flex-wrap:wrap;
    width:235px;
    height:180px;
    border:1px solid #d1d2db;
    padding:15px;
    border-radius:.29rem;
    z-index:100;
    background:#fff;
    box-shadow:3px 3px 5px #e6e7f1
}
.rdw-emoji-icon{
    margin:2.5px;
    height:24px;
    width:24px;
    cursor:pointer;
    font-size:22px
}
.rdw-emoji-icon,.rdw-spinner{
    display:flex;
    justify-content:center;
    align-items:center
}
.rdw-spinner{
    height:100%;
    width:100%
}
.rdw-spinner>div{
    width:12px;
    height:12px;
    background-color:#333;
    border-radius:100%;
    display:inline-block;
    -webkit-animation:sk-bouncedelay 1.4s ease-in-out infinite both;
    animation:sk-bouncedelay 1.4s ease-in-out infinite both
}
.rdw-spinner .rdw-bounce1{
    -webkit-animation-delay:-.32s;
    animation-delay:-.32s
}
.rdw-spinner .rdw-bounce2{
    -webkit-animation-delay:-.16s;
    animation-delay:-.16s
}
@-webkit-keyframes sk-bouncedelay{
    0%,80%,to{
        -webkit-transform:scale(0)
    }
    40%{
        -webkit-transform:scale(1)
    }
}
@keyframes sk-bouncedelay{
    0%,80%,to{
        transform:scale(0)
    }
    40%{
        transform:scale(1)
    }
}
.rdw-image-wrapper{
    display:flex;
    align-items:center;
    margin-bottom:6px;
    position:relative;
    flex-wrap:wrap
}
.rdw-image-modal{
    position:absolute;
    top:35px;
    left:5px;
    display:flex;
    flex-direction:column;
    width:235px;
    border:1px solid #d1d2db;
    padding:15px;
    border-radius:.29rem;
    z-index:100;
    background:#fff;
    box-shadow:3px 3px 5px #e6e7f1
}
.rdw-image-modal-header{
    font-size:15px;
    margin:10px 0;
    display:flex
}
.rdw-image-modal-header-option{
    width:50%;
    cursor:pointer;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column
}
.rdw-image-modal-header-label{
    width:80px;
    background:#e6e7f1;
    border:1px solid #e6e7f1;
    margin-top:5px
}
.rdw-image-modal-header-label-highlighted{
    background:#eceef7;
    border-bottom:2px solid #00892c
}
.rdw-image-modal-upload-option{
    width:100%;
    color:grey;
    cursor:pointer;
    display:flex;
    border:none;
    font-size:15px;
    align-items:center;
    justify-content:center;
    background-color:#e6e7f1;
    outline:2px dashed grey;
    outline-offset:-10px;
    margin:10px 0;
    padding:9px 0
}
.rdw-image-modal-upload-option-highlighted{
    outline:2px dashed #00892c
}
.rdw-image-modal-upload-option-label{
    cursor:pointer;
    height:100%;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:15px
}
.rdw-image-modal-upload-option-label span{
    padding:0 20px
}
.rdw-image-modal-upload-option-image-preview{
    max-width:100%;
    max-height:200px
}
.rdw-image-modal-upload-option-input{
    width:.1px;
    height:.1px;
    opacity:0;
    overflow:hidden;
    position:absolute;
    z-index:-1
}
.rdw-image-modal-url-section{
    display:flex;
    align-items:center
}
.rdw-image-modal-url-input{
    width:90%;
    height:35px;
    margin:15px 0 12px;
    border:1px solid #d1d2db;
    border-radius:.29rem;
    font-size:15px;
    padding:0 5px
}
.rdw-image-modal-btn-section{
    margin:10px auto 0
}
.rdw-image-modal-url-input:focus{
    outline:none
}
.rdw-image-modal-btn{
    margin:0 5px;
    width:75px;
    height:30px;
    border:1px solid #d1d2db;
    border-radius:.29rem;
    cursor:pointer;
    background:#fff;
    text-transform:capitalize
}
.rdw-image-modal-btn:hover{
    box-shadow:1px 1px 0 #e6e7f1
}
.rdw-image-modal-btn:active{
    box-shadow:inset 1px 1px 0 #e6e7f1
}
.rdw-image-modal-btn:focus{
    outline:none!important
}
.rdw-image-modal-btn:disabled{
    background:#eeeff8
}
.rdw-image-modal-spinner{
    position:absolute;
    top:-3px;
    left:0;
    width:100%;
    height:100%;
    opacity:.5
}
.rdw-image-modal-alt-input{
    width:70%;
    height:20px;
    border:1px solid #d1d2db;
    border-radius:.29rem;
    font-size:12px;
    margin-left:5px
}
.rdw-image-modal-alt-input:focus{
    outline:none
}
.rdw-image-modal-alt-lbl{
    font-size:12px
}
.rdw-image-modal-size{
    align-items:center;
    display:flex;
    margin:8px 0;
    justify-content:space-between
}
.rdw-image-modal-size-input{
    width:40%;
    height:20px;
    border:1px solid #d1d2db;
    border-radius:.29rem;
    font-size:12px
}
.rdw-image-modal-size-input:focus{
    outline:none
}
.rdw-image-mandatory-sign{
    color:#f83245;
    margin-left:3px;
    margin-right:3px
}
.rdw-remove-wrapper{
    position:relative
}
.rdw-history-wrapper,.rdw-remove-wrapper{
    display:flex;
    align-items:center;
    margin-bottom:6px;
    flex-wrap:wrap
}
.rdw-history-dropdownoption{
    height:40px;
    display:flex;
    justify-content:center
}
.rdw-history-dropdown{
    width:50px
}
.rdw-link-decorator-wrapper{
    position:relative
}
.rdw-link-decorator-icon{
    position:absolute;
    left:40%;
    top:0;
    cursor:pointer;
    background-color:#fff
}
.rdw-mention-link{
    text-decoration:none;
    color:#1236ff;
    background-color:#f0fbff;
    padding:1px 2px;
    border-radius:.29rem
}
.rdw-suggestion-wrapper{
    position:relative
}
.rdw-suggestion-dropdown{
    position:absolute;
    display:flex;
    flex-direction:column;
    border:1px solid #d1d2db;
    min-width:100px;
    max-height:150px;
    overflow:auto;
    background:#fff;
    z-index:100
}
.rdw-suggestion-option{
    padding:7px 5px;
    border-bottom:1px solid #e6e7f1
}
.rdw-suggestion-option-active{
    background-color:#d1d2db
}
.rdw-hashtag-link{
    text-decoration:none;
    color:#1236ff;
    background-color:#f0fbff;
    padding:1px 2px;
    border-radius:.29rem
}
.rdw-image-alignment-options-popup{
    position:absolute;
    background:#fff;
    display:flex;
    padding:5px 2px;
    border-radius:.29rem;
    border:1px solid #d1d2db;
    width:105px;
    cursor:pointer;
    z-index:100
}
.rdw-alignment-option-left{
    justify-content:flex-start
}
.rdw-image-alignment-option{
    height:15px;
    width:15px;
    min-width:15px
}
.rdw-image-alignment,.rdw-image-imagewrapper{
    position:relative
}
.rdw-image-center{
    display:flex;
    justify-content:center
}
.rdw-image-left{
    display:flex
}
.rdw-image-right{
    display:flex;
    justify-content:flex-end
}
.rdw-image-alignment-options-popup-right{
    right:0
}
.rdw-editor-main{
    height:100%;
    overflow:auto;
    box-sizing:border-box
}
.rdw-editor-toolbar{
    padding:6px 5px 0;
    border-radius:.29rem;
    border:1px solid #d1d2db;
    display:flex;
    justify-content:flex-start;
    background:#fff;
    flex-wrap:wrap;
    font-size:15px;
    margin-bottom:5px;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}
.public-DraftStyleDefault-block{
    margin:1em 0
}
.rdw-editor-wrapper:focus{
    outline:none
}
.rdw-editor-wrapper{
    box-sizing:content-box
}
.rdw-editor-main blockquote{
    border-left:5px solid #e6e7f1;
    padding-left:5px
}
.rdw-editor-main pre{
    background:#e6e7f1;
    border-radius:3px;
    padding:1px 10px
}
.DraftEditor-editorContainer,.DraftEditor-root,.public-DraftEditor-content{
    height:inherit;
    text-align:left;
    text-align:initial
}
.public-DraftEditor-content[contenteditable=true]{
    -webkit-user-modify:read-write-plaintext-only
}
.DraftEditor-root{
    position:relative
}
.DraftEditor-editorContainer{
    background-color:hsla(0,0%,100%,0);
    border-left:.1px solid transparent;
    position:relative;
    z-index:1
}
.public-DraftEditor-block{
    position:relative
}
.DraftEditor-alignLeft .public-DraftStyleDefault-block{
    text-align:left
}
.DraftEditor-alignLeft .public-DraftEditorPlaceholder-root{
    left:0;
    text-align:left
}
.DraftEditor-alignCenter .public-DraftStyleDefault-block{
    text-align:center
}
.DraftEditor-alignCenter .public-DraftEditorPlaceholder-root{
    margin:0 auto;
    text-align:center;
    width:100%
}
.DraftEditor-alignRight .public-DraftStyleDefault-block{
    text-align:right
}
.DraftEditor-alignRight .public-DraftEditorPlaceholder-root{
    right:0;
    text-align:right
}
.public-DraftEditorPlaceholder-root{
    color:#9197a3;
    position:absolute;
    z-index:0
}
.public-DraftEditorPlaceholder-hasFocus{
    color:#bdc1c9
}
.DraftEditorPlaceholder-hidden{
    display:none
}
.public-DraftStyleDefault-block{
    position:relative;
    white-space:pre-wrap
}
.public-DraftStyleDefault-ltr{
    direction:ltr;
    text-align:left
}
.public-DraftStyleDefault-rtl{
    direction:rtl;
    text-align:right
}
.public-DraftStyleDefault-listLTR{
    direction:ltr
}
.public-DraftStyleDefault-listRTL{
    direction:rtl
}
.public-DraftStyleDefault-ol,.public-DraftStyleDefault-ul{
    margin:16px 0;
    padding:0
}
.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR{
    margin-left:1.5em
}
.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL{
    margin-right:1.5em
}
.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR{
    margin-left:3em
}
.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL{
    margin-right:3em
}
.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR{
    margin-left:4.5em
}
.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL{
    margin-right:4.5em
}
.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR{
    margin-left:6em
}
.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL{
    margin-right:6em
}
.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR{
    margin-left:7.5em
}
.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL{
    margin-right:7.5em
}
.public-DraftStyleDefault-unorderedListItem{
    list-style-type:square;
    position:relative
}
.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0{
    list-style-type:disc
}
.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1{
    list-style-type:circle
}
.public-DraftStyleDefault-orderedListItem{
    list-style-type:none;
    position:relative
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before{
    left:-36px;
    position:absolute;
    text-align:right;
    width:30px
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before{
    position:absolute;
    right:-36px;
    text-align:left;
    width:30px
}
.public-DraftStyleDefault-orderedListItem:before{
    content:counter(ol0) ". ";
    counter-increment:ol0
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before{
    content:counter(ol1) ". ";
    counter-increment:ol1
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before{
    content:counter(ol2) ". ";
    counter-increment:ol2
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before{
    content:counter(ol3) ". ";
    counter-increment:ol3
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before{
    content:counter(ol4) ". ";
    counter-increment:ol4
}
.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset{
    counter-reset:ol0
}
.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset{
    counter-reset:ol1
}
.public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset{
    counter-reset:ol2
}
.public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset{
    counter-reset:ol3
}
.public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset{
    counter-reset:ol4
}
.ql-container{
    box-sizing:border-box;
    height:100%;
    margin:0;
    position:relative
}
.ql-container.ql-disabled .ql-tooltip{
    visibility:hidden
}
.ql-container.ql-disabled .ql-editor ul[data-checked]>li:before{
    pointer-events:none
}
.ql-clipboard{
    left:-100000px;
    height:1px;
    overflow-y:hidden;
    position:absolute;
    top:50%
}
.ql-clipboard p{
    margin:0;
    padding:0
}
.ql-editor{
    box-sizing:border-box;
    line-height:1.42;
    height:100%;
    outline:none;
    overflow-y:auto;
    padding:12px 15px;
    tab-size:4;
    -moz-tab-size:4;
    text-align:left;
    white-space:pre-wrap;
    word-wrap:break-word
}
.ql-editor>*{
    cursor:text
}
.ql-editor blockquote,.ql-editor h1,.ql-editor h2,.ql-editor h3,.ql-editor h4,.ql-editor h5,.ql-editor h6,.ql-editor ol,.ql-editor p,.ql-editor pre,.ql-editor ul{
    margin:0;
    padding:0;
    counter-reset:list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9
}
.ql-editor ol,.ql-editor ul{
    padding-left:1.5em
}
.ql-editor ol>li,.ql-editor ul>li{
    list-style-type:none
}
.ql-editor ul>li:before{
    content:"\2022"
}
.ql-editor ul[data-checked=false],.ql-editor ul[data-checked=true]{
    pointer-events:none
}
.ql-editor ul[data-checked=false]>li *,.ql-editor ul[data-checked=true]>li *{
    pointer-events:all
}
.ql-editor ul[data-checked=false]>li:before,.ql-editor ul[data-checked=true]>li:before{
    color:#777;
    cursor:pointer;
    pointer-events:all
}
.ql-editor ul[data-checked=true]>li:before{
    content:"\2611"
}
.ql-editor ul[data-checked=false]>li:before{
    content:"\2610"
}
.ql-editor li:before{
    display:inline-block;
    white-space:nowrap;
    width:1.2em
}
.ql-editor li:not(.ql-direction-rtl):before{
    margin-left:-1.5em;
    margin-right:.3em;
    text-align:right
}
.ql-editor li.ql-direction-rtl:before{
    margin-left:.3em;
    margin-right:-1.5em
}
.ql-editor ol li:not(.ql-direction-rtl),.ql-editor ul li:not(.ql-direction-rtl){
    padding-left:1.5em
}
.ql-editor ol li.ql-direction-rtl,.ql-editor ul li.ql-direction-rtl{
    padding-right:1.5em
}
.ql-editor ol li{
    counter-reset:list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment:list-0
}
.ql-editor ol li:before{
    content:counter(list-0,decimal) ". "
}
.ql-editor ol li.ql-indent-1{
    counter-increment:list-1
}
.ql-editor ol li.ql-indent-1:before{
    content:counter(list-1,lower-alpha) ". "
}
.ql-editor ol li.ql-indent-1{
    counter-reset:list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9
}
.ql-editor ol li.ql-indent-2{
    counter-increment:list-2
}
.ql-editor ol li.ql-indent-2:before{
    content:counter(list-2,lower-roman) ". "
}
.ql-editor ol li.ql-indent-2{
    counter-reset:list-3 list-4 list-5 list-6 list-7 list-8 list-9
}
.ql-editor ol li.ql-indent-3{
    counter-increment:list-3
}
.ql-editor ol li.ql-indent-3:before{
    content:counter(list-3,decimal) ". "
}
.ql-editor ol li.ql-indent-3{
    counter-reset:list-4 list-5 list-6 list-7 list-8 list-9
}
.ql-editor ol li.ql-indent-4{
    counter-increment:list-4
}
.ql-editor ol li.ql-indent-4:before{
    content:counter(list-4,lower-alpha) ". "
}
.ql-editor ol li.ql-indent-4{
    counter-reset:list-5 list-6 list-7 list-8 list-9
}
.ql-editor ol li.ql-indent-5{
    counter-increment:list-5
}
.ql-editor ol li.ql-indent-5:before{
    content:counter(list-5,lower-roman) ". "
}
.ql-editor ol li.ql-indent-5{
    counter-reset:list-6 list-7 list-8 list-9
}
.ql-editor ol li.ql-indent-6{
    counter-increment:list-6
}
.ql-editor ol li.ql-indent-6:before{
    content:counter(list-6,decimal) ". "
}
.ql-editor ol li.ql-indent-6{
    counter-reset:list-7 list-8 list-9
}
.ql-editor ol li.ql-indent-7{
    counter-increment:list-7
}
.ql-editor ol li.ql-indent-7:before{
    content:counter(list-7,lower-alpha) ". "
}
.ql-editor ol li.ql-indent-7{
    counter-reset:list-8 list-9
}
.ql-editor ol li.ql-indent-8{
    counter-increment:list-8
}
.ql-editor ol li.ql-indent-8:before{
    content:counter(list-8,lower-roman) ". "
}
.ql-editor ol li.ql-indent-8{
    counter-reset:list-9
}
.ql-editor ol li.ql-indent-9{
    counter-increment:list-9
}
.ql-editor ol li.ql-indent-9:before{
    content:counter(list-9,decimal) ". "
}
.ql-editor .ql-indent-1:not(.ql-direction-rtl){
    padding-left:3em
}
.ql-editor li.ql-indent-1:not(.ql-direction-rtl){
    padding-left:4.5em
}
.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right{
    padding-right:3em
}
.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right{
    padding-right:4.5em
}
.ql-editor .ql-indent-2:not(.ql-direction-rtl){
    padding-left:6em
}
.ql-editor li.ql-indent-2:not(.ql-direction-rtl){
    padding-left:7.5em
}
.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right{
    padding-right:6em
}
.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right{
    padding-right:7.5em
}
.ql-editor .ql-indent-3:not(.ql-direction-rtl){
    padding-left:9em
}
.ql-editor li.ql-indent-3:not(.ql-direction-rtl){
    padding-left:10.5em
}
.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right{
    padding-right:9em
}
.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right{
    padding-right:10.5em
}
.ql-editor .ql-indent-4:not(.ql-direction-rtl){
    padding-left:12em
}
.ql-editor li.ql-indent-4:not(.ql-direction-rtl){
    padding-left:13.5em
}
.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right{
    padding-right:12em
}
.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right{
    padding-right:13.5em
}
.ql-editor .ql-indent-5:not(.ql-direction-rtl){
    padding-left:15em
}
.ql-editor li.ql-indent-5:not(.ql-direction-rtl){
    padding-left:16.5em
}
.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right{
    padding-right:15em
}
.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right{
    padding-right:16.5em
}
.ql-editor .ql-indent-6:not(.ql-direction-rtl){
    padding-left:18em
}
.ql-editor li.ql-indent-6:not(.ql-direction-rtl){
    padding-left:19.5em
}
.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right{
    padding-right:18em
}
.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right{
    padding-right:19.5em
}
.ql-editor .ql-indent-7:not(.ql-direction-rtl){
    padding-left:21em
}
.ql-editor li.ql-indent-7:not(.ql-direction-rtl){
    padding-left:22.5em
}
.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right{
    padding-right:21em
}
.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right{
    padding-right:22.5em
}
.ql-editor .ql-indent-8:not(.ql-direction-rtl){
    padding-left:24em
}
.ql-editor li.ql-indent-8:not(.ql-direction-rtl){
    padding-left:25.5em
}
.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right{
    padding-right:24em
}
.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right{
    padding-right:25.5em
}
.ql-editor .ql-indent-9:not(.ql-direction-rtl){
    padding-left:27em
}
.ql-editor li.ql-indent-9:not(.ql-direction-rtl){
    padding-left:28.5em
}
.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right{
    padding-right:27em
}
.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right{
    padding-right:28.5em
}
.ql-editor .ql-video{
    display:block;
    max-width:100%
}
.ql-editor .ql-video.ql-align-center{
    margin:0 auto
}
.ql-editor .ql-video.ql-align-right{
    margin:0 0 0 auto
}
.ql-editor .ql-bg-black{
    background-color:#000
}
.ql-editor .ql-bg-red{
    background-color:#e60000
}
.ql-editor .ql-bg-orange{
    background-color:#f90
}
.ql-editor .ql-bg-yellow{
    background-color:#ff0
}
.ql-editor .ql-bg-green{
    background-color:#008a00
}
.ql-editor .ql-bg-blue{
    background-color:#06c
}
.ql-editor .ql-bg-purple{
    background-color:#93f
}
.ql-editor .ql-color-white{
    color:#fff
}
.ql-editor .ql-color-red{
    color:#e60000
}
.ql-editor .ql-color-orange{
    color:#f90
}
.ql-editor .ql-color-yellow{
    color:#ff0
}
.ql-editor .ql-color-green{
    color:#008a00
}
.ql-editor .ql-color-blue{
    color:#06c
}
.ql-editor .ql-color-purple{
    color:#93f
}
.ql-editor .ql-font-serif{
    font-family:Georgia,Times New Roman,serif
}
.ql-editor .ql-font-monospace{
    font-family:Monaco,Courier New,monospace
}
.ql-editor .ql-size-small{
    font-size:.75em
}
.ql-editor .ql-size-large{
    font-size:1.5em
}
.ql-editor .ql-size-huge{
    font-size:2.5em
}
.ql-editor .ql-direction-rtl{
    direction:rtl;
    text-align:inherit
}
.ql-editor .ql-align-center{
    text-align:center
}
.ql-editor .ql-align-justify{
    text-align:justify
}
.ql-editor .ql-align-right{
    text-align:right
}
.ql-editor .ql-embed-selected{
    border:1px solid #777;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}
.ql-editor.ql-blank:before{
    color:rgba(0,0,0,.6);
    content:attr(data-placeholder);
    font-style:italic;
    pointer-events:none;
    position:absolute
}
.ql-snow.ql-toolbar:after,.ql-snow .ql-toolbar:after{
    clear:both;
    content:"";
    display:table
}
.ql-snow.ql-toolbar button,.ql-snow .ql-toolbar button{
    background:none;
    border:none;
    cursor:pointer;
    display:inline-block;
    float:left;
    height:24px;
    padding:3px 5px;
    width:28px
}
.ql-snow.ql-toolbar button svg,.ql-snow .ql-toolbar button svg{
    float:left;
    height:100%
}
.ql-snow.ql-toolbar button:active:hover,.ql-snow .ql-toolbar button:active:hover{
    outline:none
}
.ql-snow.ql-toolbar input.ql-image[type=file],.ql-snow .ql-toolbar input.ql-image[type=file]{
    display:none
}
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,.ql-snow .ql-toolbar .ql-picker-item.ql-selected,.ql-snow.ql-toolbar .ql-picker-item:hover,.ql-snow .ql-toolbar .ql-picker-item:hover,.ql-snow.ql-toolbar .ql-picker-label.ql-active,.ql-snow .ql-toolbar .ql-picker-label.ql-active,.ql-snow.ql-toolbar .ql-picker-label:hover,.ql-snow .ql-toolbar .ql-picker-label:hover,.ql-snow.ql-toolbar button.ql-active,.ql-snow .ql-toolbar button.ql-active,.ql-snow.ql-toolbar button:focus,.ql-snow .ql-toolbar button:focus,.ql-snow.ql-toolbar button:hover,.ql-snow .ql-toolbar button:hover{
    color:#06c
}
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,.ql-snow.ql-toolbar button.ql-active .ql-fill,.ql-snow .ql-toolbar button.ql-active .ql-fill,.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,.ql-snow.ql-toolbar button:focus .ql-fill,.ql-snow .ql-toolbar button:focus .ql-fill,.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,.ql-snow.ql-toolbar button:hover .ql-fill,.ql-snow .ql-toolbar button:hover .ql-fill,.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill{
    fill:#06c
}
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,.ql-snow.ql-toolbar button.ql-active .ql-stroke,.ql-snow .ql-toolbar button.ql-active .ql-stroke,.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,.ql-snow.ql-toolbar button:focus .ql-stroke,.ql-snow .ql-toolbar button:focus .ql-stroke,.ql-snow.ql-toolbar button:focus .ql-stroke-miter,.ql-snow .ql-toolbar button:focus .ql-stroke-miter,.ql-snow.ql-toolbar button:hover .ql-stroke,.ql-snow .ql-toolbar button:hover .ql-stroke,.ql-snow.ql-toolbar button:hover .ql-stroke-miter,.ql-snow .ql-toolbar button:hover .ql-stroke-miter{
    stroke:#06c
}
@media (pointer:coarse){
    .ql-snow.ql-toolbar button:hover:not(.ql-active),.ql-snow .ql-toolbar button:hover:not(.ql-active){
        color:#444
    }
    .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill,.ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-fill,.ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill,.ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill{
        fill:#444
    }
    .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke,.ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke,.ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter,.ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter{
        stroke:#444
    }
}
.ql-snow,.ql-snow *{
    box-sizing:border-box
}
.ql-snow .ql-hidden{
    display:none
}
.ql-snow .ql-out-bottom,.ql-snow .ql-out-top{
    visibility:hidden
}
.ql-snow .ql-tooltip{
    position:absolute;
    transform:translateY(10px)
}
.ql-snow .ql-tooltip a{
    cursor:pointer;
    text-decoration:none
}
.ql-snow .ql-tooltip.ql-flip{
    transform:translateY(-10px)
}
.ql-snow .ql-formats{
    display:inline-block;
    vertical-align:middle
}
.ql-snow .ql-formats:after{
    clear:both;
    content:"";
    display:table
}
.ql-snow .ql-stroke{
    fill:none;
    stroke:#444;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-width:2
}
.ql-snow .ql-stroke-miter{
    fill:none;
    stroke:#444;
    stroke-miterlimit:10;
    stroke-width:2
}
.ql-snow .ql-fill,.ql-snow .ql-stroke.ql-fill{
    fill:#444
}
.ql-snow .ql-empty{
    fill:none
}
.ql-snow .ql-even{
    fill-rule:evenodd
}
.ql-snow .ql-stroke.ql-thin,.ql-snow .ql-thin{
    stroke-width:1
}
.ql-snow .ql-transparent{
    opacity:.4
}
.ql-snow .ql-direction svg:last-child{
    display:none
}
.ql-snow .ql-direction.ql-active svg:last-child{
    display:inline
}
.ql-snow .ql-direction.ql-active svg:first-child{
    display:none
}
.ql-snow .ql-editor h1{
    font-size:2em
}
.ql-snow .ql-editor h2{
    font-size:1.5em
}
.ql-snow .ql-editor h3{
    font-size:1.17em
}
.ql-snow .ql-editor h4{
    font-size:1em
}
.ql-snow .ql-editor h5{
    font-size:.83em
}
.ql-snow .ql-editor h6{
    font-size:.67em
}
.ql-snow .ql-editor a{
    text-decoration:underline
}
.ql-snow .ql-editor blockquote{
    border-left:4px solid #ccc;
    margin-bottom:5px;
    margin-top:5px;
    padding-left:16px
}
.ql-snow .ql-editor code,.ql-snow .ql-editor pre{
    background-color:#f0f0f0;
    border-radius:3px
}
.ql-snow .ql-editor pre{
    white-space:pre-wrap;
    margin-bottom:5px;
    margin-top:5px;
    padding:5px 10px
}
.ql-snow .ql-editor code{
    font-size:85%;
    padding-bottom:2px;
    padding-top:2px
}
.ql-snow .ql-editor code:after,.ql-snow .ql-editor code:before{
    content:"\A0";
    letter-spacing:-2px
}
.ql-snow .ql-editor pre.ql-syntax{
    background-color:#23241f;
    color:#f8f8f2;
    overflow:visible
}
.ql-snow .ql-editor img{
    max-width:100%
}
.ql-snow .ql-picker{
    color:#444;
    display:inline-block;
    float:left;
    font-size:14px;
    font-weight:500;
    height:24px;
    position:relative;
    vertical-align:middle
}
.ql-snow .ql-picker-label{
    cursor:pointer;
    display:inline-block;
    height:100%;
    padding-left:8px;
    padding-right:2px;
    position:relative;
    width:100%
}
.ql-snow .ql-picker-label:before{
    display:inline-block;
    line-height:22px
}
.ql-snow .ql-picker-options{
    background-color:#fff;
    display:none;
    min-width:100%;
    padding:4px 8px;
    position:absolute;
    white-space:nowrap
}
.ql-snow .ql-picker-options .ql-picker-item{
    cursor:pointer;
    display:block;
    padding-bottom:5px;
    padding-top:5px
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label{
    color:#ccc;
    z-index:2
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill{
    fill:#ccc
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke{
    stroke:#ccc
}
.ql-snow .ql-picker.ql-expanded .ql-picker-options{
    display:block;
    margin-top:-1px;
    top:100%;
    z-index:1
}
.ql-snow .ql-color-picker,.ql-snow .ql-icon-picker{
    width:28px
}
.ql-snow .ql-color-picker .ql-picker-label,.ql-snow .ql-icon-picker .ql-picker-label{
    padding:2px 4px
}
.ql-snow .ql-color-picker .ql-picker-label svg,.ql-snow .ql-icon-picker .ql-picker-label svg{
    right:4px
}
.ql-snow .ql-icon-picker .ql-picker-options{
    padding:4px 0
}
.ql-snow .ql-icon-picker .ql-picker-item{
    height:24px;
    width:24px;
    padding:2px 4px
}
.ql-snow .ql-color-picker .ql-picker-options{
    padding:3px 5px;
    width:152px
}
.ql-snow .ql-color-picker .ql-picker-item{
    border:1px solid transparent;
    float:left;
    height:16px;
    margin:2px;
    padding:0;
    width:16px
}
.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg{
    position:absolute;
    margin-top:-9px;
    right:0;
    top:50%;
    width:18px
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=""]):before,.ql-snow .ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=""]):before,.ql-snow .ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=""]):before,.ql-snow .ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=""]):before,.ql-snow .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=""]):before,.ql-snow .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=""]):before{
    content:attr(data-label)
}
.ql-snow .ql-picker.ql-header{
    width:98px
}
.ql-snow .ql-picker.ql-header .ql-picker-item:before,.ql-snow .ql-picker.ql-header .ql-picker-label:before{
    content:"Normal"
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]:before,.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]:before{
    content:"Heading 1"
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]:before,.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]:before{
    content:"Heading 2"
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]:before,.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]:before{
    content:"Heading 3"
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]:before,.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]:before{
    content:"Heading 4"
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]:before,.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]:before{
    content:"Heading 5"
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]:before,.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]:before{
    content:"Heading 6"
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]:before{
    font-size:2em
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]:before{
    font-size:1.5em
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]:before{
    font-size:1.17em
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]:before{
    font-size:1em
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]:before{
    font-size:.83em
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]:before{
    font-size:.67em
}
.ql-snow .ql-picker.ql-font{
    width:108px
}
.ql-snow .ql-picker.ql-font .ql-picker-item:before,.ql-snow .ql-picker.ql-font .ql-picker-label:before{
    content:"Sans Serif"
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]:before,.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]:before{
    content:"Serif"
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]:before,.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]:before{
    content:"Monospace"
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]:before{
    font-family:Georgia,Times New Roman,serif
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]:before{
    font-family:Monaco,Courier New,monospace
}
.ql-snow .ql-picker.ql-size{
    width:98px
}
.ql-snow .ql-picker.ql-size .ql-picker-item:before,.ql-snow .ql-picker.ql-size .ql-picker-label:before{
    content:"Normal"
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]:before,.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]:before{
    content:"Small"
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]:before,.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]:before{
    content:"Large"
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]:before,.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]:before{
    content:"Huge"
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]:before{
    font-size:10px
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]:before{
    font-size:18px
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]:before{
    font-size:32px
}
.ql-snow .ql-color-picker.ql-background .ql-picker-item{
    background-color:#fff
}
.ql-snow .ql-color-picker.ql-color .ql-picker-item{
    background-color:#000
}
.ql-toolbar.ql-snow{
    border:1px solid #ccc;
    box-sizing:border-box;
    font-family:"Helvetica Neue","Helvetica","Arial",sans-serif;
    padding:8px
}
.ql-toolbar.ql-snow .ql-formats{
    margin-right:15px
}
.ql-toolbar.ql-snow .ql-picker-label{
    border:1px solid transparent
}
.ql-toolbar.ql-snow .ql-picker-options{
    border:1px solid transparent;
    box-shadow:0 2px 8px rgba(0,0,0,.2)
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options{
    border-color:#ccc
}
.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected,.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover{
    border-color:#000
}
.ql-toolbar.ql-snow+.ql-container.ql-snow{
    border-top:0
}
.ql-snow .ql-tooltip{
    background-color:#fff;
    border:1px solid #ccc;
    box-shadow:0 0 5px #ddd;
    color:#444;
    padding:5px 12px;
    white-space:nowrap
}
.ql-snow .ql-tooltip:before{
    content:"Visit URL:";
    line-height:26px;
    margin-right:8px
}
.ql-snow .ql-tooltip input[type=text]{
    display:none;
    border:1px solid #ccc;
    font-size:13px;
    height:26px;
    margin:0;
    padding:3px 5px;
    width:170px
}
.ql-snow .ql-tooltip a.ql-preview{
    display:inline-block;
    max-width:200px;
    overflow-x:hidden;
    text-overflow:ellipsis;
    vertical-align:top
}
.ql-snow .ql-tooltip a.ql-action:after{
    border-right:1px solid #ccc;
    content:"Edit";
    margin-left:16px;
    padding-right:8px
}
.ql-snow .ql-tooltip a.ql-remove:before{
    content:"Remove";
    margin-left:8px
}
.ql-snow .ql-tooltip a{
    line-height:26px
}
.ql-snow .ql-tooltip.ql-editing a.ql-preview,.ql-snow .ql-tooltip.ql-editing a.ql-remove{
    display:none
}
.ql-snow .ql-tooltip.ql-editing input[type=text]{
    display:inline-block
}
.ql-snow .ql-tooltip.ql-editing a.ql-action:after{
    border-right:0;
    content:"Save";
    padding-right:0
}
.ql-snow .ql-tooltip[data-mode=link]:before{
    content:"Enter link:"
}
.ql-snow .ql-tooltip[data-mode=formula]:before{
    content:"Enter formula:"
}
.ql-snow .ql-tooltip[data-mode=video]:before{
    content:"Enter video:"
}
.ql-snow a{
    color:#06c
}
.ql-container.ql-snow{
    border:1px solid #ccc
}
.rbc-btn{
    color:inherit;
    font:inherit;
    margin:0
}
button.rbc-btn{
    overflow:visible;
    text-transform:none;
    -webkit-appearance:button;
    cursor:pointer
}
button[disabled].rbc-btn{
    cursor:not-allowed
}
button.rbc-input::-moz-focus-inner{
    border:0;
    padding:0
}
.rbc-calendar{
    height:100%;
    display:flex;
    flex-direction:column;
    align-items:stretch
}
.rbc-calendar *,.rbc-calendar :after,.rbc-calendar :before{
    box-sizing:inherit
}
.rbc-abs-full,.rbc-row-bg{
    overflow:hidden;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0
}
.rbc-ellipsis,.rbc-event-label,.rbc-row-segment .rbc-event-content,.rbc-show-more{
    display:block;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap
}
.rbc-rtl{
    direction:rtl
}
.rbc-off-range{
    color:#d1d2db
}
.rbc-off-range-bg{
    background:#f8f9ff
}
.rbc-header{
    overflow:hidden;
    flex:1 0;
    text-overflow:ellipsis;
    white-space:nowrap;
    padding:.5rem 0;
    text-align:center;
    vertical-align:middle;
    font-weight:700;
    font-size:90%;
    min-height:0;
    border-bottom:1px solid #dcdef1
}
.rbc-header+.rbc-header{
    border-left:1px solid #dcdef1
}
.rbc-rtl .rbc-header+.rbc-header{
    border-left-width:0;
    border-right:1px solid #dcdef1
}
.rbc-header>a,.rbc-header>a:active,.rbc-header>a:visited{
    color:inherit;
    text-decoration:none
}
.rbc-row-content{
    position:relative;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    outline:none;
    z-index:4
}
.rbc-today{
    background-color:#00892c
}
.rbc-toolbar{
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    align-items:center;
    background:#f8f9ff;
    border-radius:.65rem;
    padding:1rem;
    margin-bottom:1rem;
    flex-direction:column
}
@media (min-width:1381px){
    .rbc-toolbar{
        flex-direction:row
    }
}
.rbc-toolbar .rbc-toolbar-label{
    flex-grow:1;
    padding:1rem 0;
    font-weight:700;
    text-align:center;
    font-size:1.15rem
}
@media (min-width:1381px){
    .rbc-toolbar .rbc-toolbar-label{
        padding:0
    }
}
.rbc-toolbar button{
    display:inline-block;
    text-align:center;
    vertical-align:middle;
    outline:none!important;
    cursor:pointer;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    color:#fff;
    background-color:#00892c;
    color:#00892c;
    background:transparent;
    border:2px solid #00892c;
    text-transform:uppercase;
    padding:.25rem 1.1rem;
    font-size:.82rem;
    line-height:1.5;
    border-radius:.29rem;
    transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    line-height:2
}
.rbc-toolbar button:hover{
    color:#fff;
    background-color:#00892c;
    border-color:#00892c
}
.rbc-toolbar button.focus,.rbc-toolbar button:focus{
    box-shadow:0 0 0 0 transparent
}
.rbc-toolbar button.disabled,.rbc-toolbar button:disabled{
    color:#fff;
    background-color:#00892c;
    border-color:#00892c
}
.rbc-toolbar button:not(:disabled):not(.disabled).active,.rbc-toolbar button:not(:disabled):not(.disabled):active,.show>.rbc-toolbar button.dropdown-toggle{
    color:#fff;
    background-color:#2f358b;
    border-color:#2c3281
}
.rbc-toolbar button:not(:disabled):not(.disabled).active:focus,.rbc-toolbar button:not(:disabled):not(.disabled):active:focus,.show>.rbc-toolbar button.dropdown-toggle:focus{
    box-shadow:0 0 0 0 transparent
}
@media (prefers-reduced-motion:reduce){
    .rbc-toolbar button{
        transition:none
    }
}
.rbc-toolbar button.rbc-active,.rbc-toolbar button:not(:disabled):active,.rbc-toolbar button:not(:disabled):focus,.rbc-toolbar button:not(:disabled):hover{
    background:#00892c;
    box-shadow:0 .25rem .55rem rgba(60,68,177,.35);
    color:#fff
}
.rbc-btn-group{
    display:inline-block;
    white-space:nowrap
}
.rbc-btn-group>button:first-child:not(:last-child){
    border-top-right-radius:0;
    border-bottom-right-radius:0
}
.rbc-btn-group>button:last-child:not(:first-child){
    border-top-left-radius:0;
    border-bottom-left-radius:0
}
.rbc-rtl .rbc-btn-group>button:first-child:not(:last-child){
    border-radius:.29rem;
    border-top-left-radius:0;
    border-bottom-left-radius:0
}
.rbc-rtl .rbc-btn-group>button:last-child:not(:first-child){
    border-radius:.29rem;
    border-top-right-radius:0;
    border-bottom-right-radius:0
}
.rbc-btn-group>button:not(:first-child):not(:last-child){
    border-radius:0
}
.rbc-btn-group button+button{
    margin-left:-2px
}
.rbc-rtl .rbc-btn-group button+button{
    margin-left:0;
    margin-right:-2px
}
.rbc-btn-group+.rbc-btn-group,.rbc-btn-group+button{
    margin-left:10px
}
.rbc-event{
    border:none;
    box-shadow:none;
    margin:0;
    padding:5px 10px;
    background-color:#00892c;
    border-radius:.29rem;
    color:#fff;
    cursor:pointer;
    width:100%;
    text-align:left
}
.rbc-slot-selecting .rbc-event{
    cursor:inherit;
    pointer-events:none
}
.rbc-event.rbc-selected{
    background-color:#2f358b
}
.rbc-event:focus{
    outline:5px auto transparent
}
.rbc-event-label{
    font-size:80%
}
.rbc-event-overlaps{
    box-shadow:-1px 1px 5px 0 rgba(51,51,51,.5)
}
.rbc-event-continues-prior{
    border-top-left-radius:0;
    border-bottom-left-radius:0
}
.rbc-event-continues-after{
    border-top-right-radius:0;
    border-bottom-right-radius:0
}
.rbc-event-continues-earlier{
    border-top-left-radius:0;
    border-top-right-radius:0
}
.rbc-event-continues-later{
    border-bottom-right-radius:0;
    border-bottom-left-radius:0
}
.rbc-row{
    display:flex;
    flex-direction:row
}
.rbc-row-segment{
    padding:0 1px 1px
}
.rbc-selected-cell{
    background-color:rgba(7,9,25,.1)
}
.rbc-show-more{
    background-color:hsla(0,0%,100%,.3);
    z-index:4;
    font-weight:700;
    font-size:85%;
    height:auto;
    line-height:normal
}
.rbc-month-view{
    position:relative;
    border:1px solid #dcdef1;
    display:flex;
    flex-direction:column;
    flex:1 0;
    width:100%;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    -webkit-user-select:none;
    height:100%
}
.rbc-month-header{
    display:flex;
    flex-direction:row
}
.rbc-month-row{
    display:flex;
    position:relative;
    flex-direction:column;
    flex:1 0;
    flex-basis:0px;
    overflow:hidden;
    height:100%
}
.rbc-month-row+.rbc-month-row{
    border-top:1px solid #dcdef1
}
.rbc-month-row:hover>div>.rbc-day-bg{
    background:#f8f9ff
}
.rbc-month-row:hover>div>.rbc-day-bg:hover{
    background:#eeeff8
}
.rbc-date-cell{
    flex:1 1;
    min-width:0;
    padding-right:.5rem;
    padding-top:.33333rem;
    text-align:right;
    font-size:1.1rem
}
.rbc-date-cell.rbc-now{
    font-weight:700
}
.rbc-date-cell>a,.rbc-date-cell>a:active,.rbc-date-cell>a:visited{
    color:inherit;
    text-decoration:none
}
.rbc-row-bg{
    display:flex;
    flex-direction:row;
    flex:1 0;
    overflow:hidden
}
.rbc-day-bg{
    flex:1 0
}
.rbc-day-bg+.rbc-day-bg{
    border-left:1px solid #dcdef1
}
.rbc-rtl .rbc-day-bg+.rbc-day-bg{
    border-left-width:0;
    border-right:1px solid #dcdef1
}
.rbc-overlay{
    position:absolute;
    z-index:5;
    border:1px solid #eeeff8;
    background-color:#fff;
    box-shadow:0 5px 15px rgba(0,0,0,.25);
    padding:10px
}
.rbc-overlay>*+*{
    margin-top:1px
}
.rbc-overlay-header{
    border-bottom:1px solid #eeeff8;
    margin:-10px -10px 5px;
    padding:2px 10px
}
.rbc-agenda-view{
    display:flex;
    flex-direction:column;
    flex:1 0;
    overflow:auto
}
.rbc-agenda-view table.rbc-agenda-table{
    width:100%;
    border:1px solid #dcdef1;
    border-spacing:0;
    border-collapse:collapse
}
.rbc-agenda-view table.rbc-agenda-table tbody>tr>td{
    padding:5px 10px;
    vertical-align:top
}
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell{
    padding-left:15px;
    padding-right:15px;
    text-transform:lowercase
}
.rbc-agenda-view table.rbc-agenda-table tbody>tr>td+td{
    border-left:1px solid #dcdef1
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody>tr>td+td{
    border-left-width:0;
    border-right:1px solid #dcdef1
}
.rbc-agenda-view table.rbc-agenda-table tbody>tr+tr{
    border-top:1px solid #dcdef1
}
.rbc-agenda-view table.rbc-agenda-table thead>tr>th{
    padding:3px 5px;
    text-align:left;
    border-bottom:1px solid #dcdef1
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead>tr>th{
    text-align:right
}
.rbc-agenda-time-cell{
    text-transform:lowercase
}
.rbc-agenda-time-cell .rbc-continues-after:after{
    content:" »"
}
.rbc-agenda-time-cell .rbc-continues-prior:before{
    content:"« "
}
.rbc-agenda-date-cell,.rbc-agenda-time-cell{
    white-space:nowrap
}
.rbc-agenda-event-cell{
    width:100%
}
.rbc-time-column{
    display:flex;
    flex-direction:column;
    min-height:100%
}
.rbc-time-column .rbc-timeslot-group{
    flex:1 1
}
.rbc-timeslot-group{
    border-bottom:1px solid #dcdef1;
    min-height:40px;
    display:flex;
    flex-flow:column nowrap
}
.rbc-header-gutter,.rbc-time-gutter{
    flex:none
}
.rbc-label{
    padding:0 5px
}
.rbc-day-slot{
    position:relative
}
.rbc-day-slot .rbc-events-container{
    bottom:0;
    left:0;
    position:absolute;
    right:0;
    margin-right:10px;
    top:0
}
.rbc-day-slot .rbc-events-container.rbc-rtl{
    left:10px;
    right:0
}
.rbc-day-slot .rbc-event{
    border:1px solid #265985;
    display:flex;
    max-height:100%;
    min-height:20px;
    flex-flow:column wrap;
    align-items:flex-start;
    overflow:hidden;
    position:absolute
}
.rbc-day-slot .rbc-event-label{
    flex:none;
    padding-right:5px;
    width:auto
}
.rbc-day-slot .rbc-event-content{
    width:100%;
    flex:1 1;
    word-wrap:break-word;
    line-height:1;
    height:100%;
    min-height:1em
}
.rbc-day-slot .rbc-time-slot{
    border-top:1px solid #eeeff8
}
.rbc-time-view-resources .rbc-time-gutter,.rbc-time-view-resources .rbc-time-header-gutter{
    position:-webkit-sticky;
    position:sticky;
    left:0;
    background-color:#fff;
    border-right:1px solid #dcdef1;
    z-index:10;
    margin-right:-1px
}
.rbc-time-view-resources .rbc-time-header{
    overflow:hidden
}
.rbc-time-view-resources .rbc-time-header-content{
    min-width:auto;
    flex:1 0;
    flex-basis:0px
}
.rbc-time-view-resources .rbc-time-header-cell-single-day{
    display:none
}
.rbc-time-view-resources .rbc-day-slot{
    min-width:140px
}
.rbc-time-view-resources .rbc-day-bg,.rbc-time-view-resources .rbc-header{
    width:140px;
    flex:1 1;
    flex-basis:0 px
}
.rbc-time-header-content+.rbc-time-header-content{
    margin-left:-1px
}
.rbc-time-slot{
    flex:1 0
}
.rbc-time-slot.rbc-now{
    font-weight:700
}
.rbc-day-header{
    text-align:center
}
.rbc-slot-selection{
    z-index:10;
    position:absolute;
    background-color:rgba(7,9,25,.5);
    color:#fff;
    font-size:75%;
    width:100%;
    padding:3px
}
.rbc-slot-selecting{
    cursor:move
}
.rbc-time-view{
    display:flex;
    flex-direction:column;
    flex:1 1;
    width:100%;
    border:1px solid #dcdef1;
    min-height:0
}
.rbc-time-view .rbc-time-gutter{
    white-space:nowrap
}
.rbc-time-view .rbc-allday-cell{
    box-sizing:content-box;
    width:100%;
    height:100%;
    position:relative
}
.rbc-time-view .rbc-allday-cell+.rbc-allday-cell{
    border-left:1px solid #dcdef1
}
.rbc-time-view .rbc-allday-events{
    position:relative;
    z-index:4
}
.rbc-time-view .rbc-row{
    min-height:20px
}
.rbc-time-header{
    display:flex;
    flex:0 0 auto;
    flex-direction:row
}
.rbc-time-header.rbc-overflowing{
    border-right:1px solid #dcdef1
}
.rbc-rtl .rbc-time-header.rbc-overflowing{
    border-right-width:0;
    border-left:1px solid #dcdef1
}
.rbc-time-header>.rbc-row.rbc-row-resource,.rbc-time-header>.rbc-row:first-child{
    border-bottom:1px solid #dcdef1
}
.rbc-time-header-cell-single-day{
    display:none
}
.rbc-time-header-content{
    flex:1 1;
    display:flex;
    min-width:0;
    flex-direction:column;
    border-left:1px solid #dcdef1
}
.rbc-rtl .rbc-time-header-content{
    border-left-width:0;
    border-right:1px solid #dcdef1
}
.rbc-time-header-content>.rbc-row.rbc-row-resource{
    border-bottom:1px solid #dcdef1;
    flex-shrink:0
}
.rbc-time-content{
    display:flex;
    flex:1 0;
    align-items:flex-start;
    width:100%;
    border-top:2px solid #dcdef1;
    overflow-y:auto;
    position:relative
}
.rbc-time-content>.rbc-time-gutter{
    flex:none
}
.rbc-time-content>*+*>*{
    border-left:1px solid #dcdef1
}
.rbc-rtl .rbc-time-content>*+*>*{
    border-left-width:0;
    border-right:1px solid #dcdef1
}
.rbc-time-content>.rbc-day-slot{
    width:100%;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    -webkit-user-select:none
}
.rbc-current-time-indicator{
    position:absolute;
    z-index:3;
    left:0;
    right:0;
    height:1px;
    background-color:#1bc943;
    pointer-events:none
}
.slick-slider{
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    touch-action:pan-y;
    max-width:1400px;
    margin-left:auto;
    margin-right:auto
}
.slick-list,.slick-slider{
    position:relative;
    display:block
}
.slick-list{
    overflow:hidden;
    margin:0;
    padding:0
}
.slick-list:focus{
    outline:none
}
.slick-list.dragging{
    cursor:hand
}
.slick-slider .slick-list,.slick-slider .slick-track{
    transform:translateZ(0)
}
.slick-track{
    position:relative;
    left:0;
    top:0;
    display:block;
    margin-left:auto;
    margin-right:auto
}
.slick-track:after,.slick-track:before{
    content:"";
    display:table
}
.slick-track:after{
    clear:both
}
.slick-loading .slick-track{
    visibility:hidden
}
.slick-slide{
    float:left;
    height:100%;
    min-height:1px;
    outline:none;
    display:none;
    border-radius:.75rem
}
.slick-slide>div{
    outline:none;
    padding-right:1px
}
.slick-slide>div>div{
    outline:none
}
.slick-slide.slick-loading img{
    display:none
}
.slick-slide.dragging>*{
    pointer-events:none
}
.slick-initialized .slick-slide{
    display:block
}
.slick-loading .slick-slide{
    visibility:hidden
}
.slick-vertical .slick-slide{
    display:block;
    height:auto;
    border:1px solid transparent
}
.slick-arrow.slick-hidden{
    display:none
}
.slick-slider-left .slick-arrow,.slick-slider-right .slick-arrow{
    display:none!important
}
.slick-slider-left .slick-list.draggable,.slick-slider-right .slick-list.draggable{
    cursor:all-scroll
}
.slick-slider-left .slick-dots li{
    margin:0 0 0 .66667rem
}
.slick-slider-left .slick-dots{
    text-align:left
}
.slick-slider-left .slick-dots li{
    margin:0 .66667rem 0 0
}
.slick-next,.slick-prev{
    position:absolute;
    display:flex;
    align-items:center;
    justify-content:center;
    height:44px;
    width:44px;
    cursor:pointer;
    color:#00892c;
    top:50%;
    padding:0;
    border:none;
    outline:none;
    box-shadow:0 .313rem .8rem rgba(122,123,151,.5),0 .126rem .225rem rgba(122,123,151,.3);
    transition:all .2s ease-in-out;
    transform:scale(1);
    border-radius:50px;
    background:#fff;
    z-index:6;
    overflow:hidden;
    opacity:0;
    margin-top:-22px;
    left:15px
}
.slick-next>*,.slick-prev>*{
    height:34px;
    width:34px
}
.slick-next:focus,.slick-next:hover,.slick-prev:focus,.slick-prev:hover{
    outline:none;
    transform:scale(1.15)
}
.slick-next:active,.slick-prev:active{
    outline:none;
    transform:scale(1.05)
}
.slick-next.slick-disabled:before,.slick-prev.slick-disabled:before{
    opacity:.25
}
.slick-prev>*{
    left:-1px;
    position:relative
}
.slick-next{
    left:auto;
    right:15px
}
.slick-slider:hover .slick-next,.slick-slider:hover .slick-prev{
    opacity:1
}
.slick-dots{
    list-style:none;
    display:block;
    text-align:center;
    padding:0;
    margin:0;
    width:100%;
    height:8px;
    position:absolute;
    bottom:15px;
    left:0;
    right:0
}
.slick-dots li{
    display:inline-block;
    margin:0 .33333rem
}
.slick-dots li,.slick-dots li button{
    position:relative;
    padding:0;
    cursor:pointer;
    transition:all .2s ease-in-out
}
.slick-dots li button{
    border:0;
    background:transparent;
    display:block;
    height:8px;
    width:8px;
    background:#d1d2db;
    border-radius:.65rem;
    color:transparent;
    outline:none!important
}
.slick-dots li.slick-active button{
    width:26px;
    background:#00892c
}
.slick-center{
    transform:scale(1.5)
}
.slider-dots-light .slick-dots li button{
    background:hsla(0,0%,100%,.3)
}
.slider-dots-light .slick-dots li.slick-active button{
    background:#fff
}
.slider-arrows-dark .slick-next,.slider-arrows-dark .slick-prev{
    background:#00892c;
    color:#fff;
    box-shadow:0 0 0 0 transparent
}
.slider-arrows-dark .slick-next:hover,.slider-arrows-dark .slick-prev:hover{
    background:#fff;
    color:#00892c
}
.slider-arrows-outside{
    overflow:visible!important
}
.slider-arrows-outside .slick-prev{
    left:-10px
}
.slider-arrows-outside .slick-next{
    right:-10px
}
.slider-dots-outside .slick-dots{
    bottom:-20px
}
.react-contextmenu{
    outline:none;
    opacity:0;
    pointer-events:none;
    transition:opacity .25s ease!important;
    margin-top:0
}
.react-contextmenu.rel-container{
    position:absolute!important;
    top:15%!important;
    left:50%!important
}
.react-contextmenu-item{
    outline:none!important
}
.react-contextmenu.react-contextmenu--visible{
    opacity:1;
    pointer-events:auto;
    z-index:9999;
    visibility:visible
}
.react-contextmenu-item--divider{
    border-bottom:1px solid #eeeff8;
    cursor:inherit;
    margin-bottom:3px;
    padding:2px 0
}
.react-contextmenu-item.react-contextmenu-submenu{
    padding:0
}
.react-contextmenu-item.react-contextmenu-submenu>.react-contextmenu-item:after{
    content:"▶";
    display:inline-block;
    position:absolute;
    right:7px
}
.example-multiple-targets:after{
    content:attr(data-count);
    display:block
}
.cropper-container{
    direction:ltr;
    font-size:0;
    line-height:0;
    position:relative;
    touch-action:none;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}
.cropper-container img{
    display:block;
    height:100%;
    image-orientation:0deg;
    max-height:none!important;
    max-width:none!important;
    min-height:0!important;
    min-width:0!important;
    width:100%
}
.cropper-canvas,.cropper-crop-box,.cropper-drag-box,.cropper-modal,.cropper-wrap-box{
    bottom:0;
    left:0;
    position:absolute;
    right:0;
    top:0
}
.cropper-canvas,.cropper-wrap-box{
    overflow:hidden
}
.cropper-drag-box{
    background-color:#fff;
    opacity:0
}
.cropper-modal{
    background-color:#000;
    opacity:.5
}
.cropper-view-box{
    display:block;
    height:100%;
    outline:1px solid #39f;
    outline-color:rgba(51,153,255,.75);
    overflow:hidden;
    width:100%
}
.cropper-dashed{
    border:0 dashed #eee;
    display:block;
    opacity:.5;
    position:absolute
}
.cropper-dashed.dashed-h{
    border-bottom-width:1px;
    border-top-width:1px;
    height:33.33333%;
    left:0;
    top:33.33333%;
    width:100%
}
.cropper-dashed.dashed-v{
    border-left-width:1px;
    border-right-width:1px;
    height:100%;
    left:33.33333%;
    top:0;
    width:33.33333%
}
.cropper-center{
    display:block;
    height:0;
    left:50%;
    opacity:.75;
    position:absolute;
    top:50%;
    width:0
}
.cropper-center:after,.cropper-center:before{
    background-color:#eee;
    content:" ";
    display:block;
    position:absolute
}
.cropper-center:before{
    height:1px;
    left:-3px;
    top:0;
    width:7px
}
.cropper-center:after{
    height:7px;
    left:0;
    top:-3px;
    width:1px
}
.cropper-face,.cropper-line,.cropper-point{
    display:block;
    height:100%;
    opacity:.1;
    position:absolute;
    width:100%
}
.cropper-face{
    background-color:#fff;
    left:0;
    top:0
}
.cropper-line{
    background-color:#39f
}
.cropper-line.line-e{
    cursor:ew-resize;
    right:-3px;
    top:0;
    width:5px
}
.cropper-line.line-n{
    cursor:ns-resize;
    height:5px;
    left:0;
    top:-3px
}
.cropper-line.line-w{
    cursor:ew-resize;
    left:-3px;
    top:0;
    width:5px
}
.cropper-line.line-s{
    bottom:-3px;
    cursor:ns-resize;
    height:5px;
    left:0
}
.cropper-point{
    background-color:#39f;
    height:5px;
    opacity:.75;
    width:5px
}
.cropper-point.point-e{
    cursor:ew-resize;
    margin-top:-3px;
    right:-3px;
    top:50%
}
.cropper-point.point-n{
    cursor:ns-resize;
    left:50%;
    margin-left:-3px;
    top:-3px
}
.cropper-point.point-w{
    cursor:ew-resize;
    left:-3px;
    margin-top:-3px;
    top:50%
}
.cropper-point.point-s{
    bottom:-3px;
    cursor:s-resize;
    left:50%;
    margin-left:-3px
}
.cropper-point.point-ne{
    cursor:nesw-resize;
    right:-3px;
    top:-3px
}
.cropper-point.point-nw{
    cursor:nwse-resize;
    left:-3px;
    top:-3px
}
.cropper-point.point-sw{
    bottom:-3px;
    cursor:nesw-resize;
    left:-3px
}
.cropper-point.point-se{
    bottom:-3px;
    cursor:nwse-resize;
    height:20px;
    opacity:1;
    right:-3px;
    width:20px
}
@media (min-width:768px){
    .cropper-point.point-se{
        height:15px;
        width:15px
    }
}
@media (min-width:992px){
    .cropper-point.point-se{
        height:10px;
        width:10px
    }
}
@media (min-width:1200px){
    .cropper-point.point-se{
        height:5px;
        opacity:.75;
        width:5px
    }
}
.cropper-point.point-se:before{
    background-color:#39f;
    bottom:-50%;
    content:" ";
    display:block;
    height:200%;
    opacity:0;
    position:absolute;
    right:-50%;
    width:200%
}
.cropper-invisible{
    opacity:0
}
.cropper-bg{
    background-image:url(data:image/png;
    base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC)
}
.cropper-hide{
    display:block;
    height:0;
    position:absolute;
    width:0
}
.cropper-hidden{
    display:none!important
}
.cropper-move{
    cursor:move
}
.cropper-crop{
    cursor:crosshair
}
.cropper-disabled .cropper-drag-box,.cropper-disabled .cropper-face,.cropper-disabled .cropper-line,.cropper-disabled .cropper-point{
    cursor:not-allowed
}
.loading-indicator{
    text-align:center
}
.loading-bullet{
    display:inline-block;
    opacity:0;
    font-size:2em;
    color:#00892c
}
.block-ui{
    position:relative;
    min-height:3em
}
.block-ui-container{
    position:absolute;
    z-index:1010;
    top:0;
    right:0;
    bottom:0;
    left:0;
    height:100%;
    min-height:2em;
    cursor:wait;
    overflow:hidden
}
.block-ui-container:focus{
    outline:none
}
.block-ui-overlay{
    width:100%;
    height:100%;
    background:hsla(0,0%,100%,.8)
}
.block-ui-message-container{
    position:absolute;
    top:50%;
    left:0;
    right:0;
    text-align:center;
    transform:translateY(-50%);
    z-index:10001;
    display:flex;
    align-items:center;
    justify-content:center
}
.block-ui-message{
    color:#282828;
    background:none;
    z-index:1011
}
.block-loading-overlay-dark .block-ui-overlay{
    background:rgba(7,9,25,.8)
}
.leaflet-image-layer,.leaflet-layer,.leaflet-marker-icon,.leaflet-marker-shadow,.leaflet-pane,.leaflet-pane>canvas,.leaflet-pane>svg,.leaflet-tile,.leaflet-tile-container,.leaflet-zoom-box{
    position:absolute;
    left:0;
    top:0
}
.leaflet-container{
    overflow:hidden;
    min-height:350px
}
.leaflet-marker-icon,.leaflet-marker-shadow,.leaflet-tile{
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}
.leaflet-tile::selection{
    background:transparent
}
.leaflet-safari .leaflet-tile{
    image-rendering:-webkit-optimize-contrast
}
.leaflet-safari .leaflet-tile-container{
    width:1600px;
    height:1600px;
    -webkit-transform-origin:0 0
}
.leaflet-marker-icon,.leaflet-marker-shadow{
    display:block
}
.leaflet-container .leaflet-marker-pane img,.leaflet-container .leaflet-overlay-pane svg,.leaflet-container .leaflet-shadow-pane img,.leaflet-container .leaflet-tile,.leaflet-container .leaflet-tile-pane img,.leaflet-container img.leaflet-image-layer{
    max-width:none!important;
    max-height:none!important
}
.leaflet-container.leaflet-touch-zoom{
    touch-action:pan-x pan-y
}
.leaflet-container.leaflet-touch-drag{
    touch-action:none;
    touch-action:pinch-zoom
}
.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom{
    touch-action:none
}
.leaflet-container{
    -webkit-tap-highlight-color:transparent
}
.leaflet-container a{
    -webkit-tap-highlight-color:rgba(51,181,229,.4)
}
.leaflet-tile{
    -webkit-filter:inherit;
    filter:inherit;
    visibility:hidden
}
.leaflet-tile-loaded{
    visibility:inherit
}
.leaflet-zoom-box{
    width:0;
    height:0;
    box-sizing:border-box;
    z-index:800
}
.leaflet-overlay-pane svg{
    -moz-user-select:none
}
.leaflet-pane{
    z-index:400
}
.leaflet-tile-pane{
    z-index:200
}
.leaflet-overlay-pane{
    z-index:400
}
.leaflet-shadow-pane{
    z-index:500
}
.leaflet-marker-pane{
    z-index:600
}
.leaflet-tooltip-pane{
    z-index:650
}
.leaflet-popup-pane{
    z-index:700
}
.leaflet-map-pane canvas{
    z-index:100
}
.leaflet-map-pane svg{
    z-index:200
}
.leaflet-vml-shape{
    width:1px;
    height:1px
}
.lvml{
    behavior:url(#default#VML);
    display:inline-block;
    position:absolute
}
.leaflet-control{
    position:relative;
    z-index:800;
    pointer-events:visiblePainted;
    pointer-events:auto
}
.leaflet-bottom,.leaflet-top{
    position:absolute;
    z-index:1000;
    pointer-events:none
}
.leaflet-top{
    top:0
}
.leaflet-right{
    right:0
}
.leaflet-bottom{
    bottom:0
}
.leaflet-left{
    left:0
}
.leaflet-control{
    float:left;
    clear:both
}
.leaflet-right .leaflet-control{
    float:right
}
.leaflet-top .leaflet-control{
    margin-top:10px
}
.leaflet-bottom .leaflet-control{
    margin-bottom:10px
}
.leaflet-left .leaflet-control{
    margin-left:10px
}
.leaflet-right .leaflet-control{
    margin-right:10px
}
.leaflet-fade-anim .leaflet-tile{
    will-change:opacity
}
.leaflet-fade-anim .leaflet-popup{
    opacity:0;
    transition:opacity .2s linear
}
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup{
    opacity:1
}
.leaflet-zoom-animated{
    transform-origin:0 0
}
.leaflet-zoom-anim .leaflet-zoom-animated{
    will-change:transform;
    transition:transform .25s cubic-bezier(0,0,.25,1)
}
.leaflet-pan-anim .leaflet-tile,.leaflet-zoom-anim .leaflet-tile{
    transition:none
}
.leaflet-zoom-anim .leaflet-zoom-hide{
    visibility:hidden
}
.leaflet-interactive{
    cursor:pointer
}
.leaflet-grab{
    cursor:-webkit-grab;
    cursor:grab
}
.leaflet-crosshair,.leaflet-crosshair .leaflet-interactive{
    cursor:crosshair
}
.leaflet-control,.leaflet-popup-pane{
    cursor:auto
}
.leaflet-dragging .leaflet-grab,.leaflet-dragging .leaflet-grab .leaflet-interactive,.leaflet-dragging .leaflet-marker-draggable{
    cursor:move;
    cursor:-webkit-grabbing;
    cursor:grabbing
}
.leaflet-image-layer,.leaflet-marker-icon,.leaflet-marker-shadow,.leaflet-pane>svg path,.leaflet-tile-container{
    pointer-events:none
}
.leaflet-image-layer.leaflet-interactive,.leaflet-marker-icon.leaflet-interactive,.leaflet-pane>svg path.leaflet-interactive,svg.leaflet-image-layer.leaflet-interactive path{
    pointer-events:visiblePainted;
    pointer-events:auto
}
.leaflet-container{
    background:#e6e7f1;
    outline:0
}
.leaflet-container a{
    color:#0078a8
}
.leaflet-container a.leaflet-active{
    outline:2px solid orange
}
.leaflet-zoom-box{
    border:2px dotted #38f;
    background:hsla(0,0%,100%,.5)
}
.leaflet-container{
    font:12px/1.5 "Helvetica Neue",Arial,Helvetica,sans-serif
}
.leaflet-bar{
    box-shadow:0 1px 5px rgba(7,9,25,.35);
    border-radius:4px
}
.leaflet-bar a,.leaflet-bar a:hover{
    background-color:#fff;
    border-bottom:1px solid #ccc;
    width:26px;
    height:26px;
    line-height:26px;
    display:block;
    text-align:center;
    text-decoration:none;
    color:#000
}
.leaflet-bar a,.leaflet-control-layers-toggle{
    background-position:50% 50%;
    background-repeat:no-repeat;
    display:block
}
.leaflet-bar a:hover{
    background-color:#f4f4f4
}
.leaflet-bar a:first-child{
    border-top-left-radius:4px;
    border-top-right-radius:4px
}
.leaflet-bar a:last-child{
    border-bottom-left-radius:4px;
    border-bottom-right-radius:4px;
    border-bottom:none
}
.leaflet-bar a.leaflet-disabled{
    cursor:default;
    background-color:#f4f4f4;
    color:#bbb
}
.leaflet-touch .leaflet-bar a{
    width:30px;
    height:30px;
    line-height:30px
}
.leaflet-touch .leaflet-bar a:first-child{
    border-top-left-radius:2px;
    border-top-right-radius:2px
}
.leaflet-touch .leaflet-bar a:last-child{
    border-bottom-left-radius:2px;
    border-bottom-right-radius:2px
}
.leaflet-control-zoom-in,.leaflet-control-zoom-out{
    font:700 18px "Lucida Console",Monaco,monospace;
    text-indent:1px
}
.leaflet-touch .leaflet-control-zoom-in,.leaflet-touch .leaflet-control-zoom-out{
    font-size:22px
}
.leaflet-control-layers{
    box-shadow:0 1px 5px rgba(7,9,25,.6);
    background:#fff;
    border-radius:5px
}
.leaflet-control-layers-toggle{
    width:36px;
    height:36px
}
.leaflet-retina .leaflet-control-layers-toggle{
    background-size:26px 26px
}
.leaflet-touch .leaflet-control-layers-toggle{
    width:44px;
    height:44px
}
.leaflet-control-layers-expanded .leaflet-control-layers-toggle,.leaflet-control-layers .leaflet-control-layers-list{
    display:none
}
.leaflet-control-layers-expanded .leaflet-control-layers-list{
    display:block;
    position:relative
}
.leaflet-control-layers-expanded{
    padding:6px 10px 6px 6px;
    color:#282828;
    background:#fff
}
.leaflet-control-layers-scrollbar{
    overflow-y:scroll;
    overflow-x:hidden;
    padding-right:5px
}
.leaflet-control-layers-selector{
    margin-top:2px;
    position:relative;
    top:1px
}
.leaflet-control-layers label{
    display:block
}
.leaflet-control-layers-separator{
    height:0;
    border-top:1px solid #e6e7f1;
    margin:5px -10px 5px -6px
}
.leaflet-container .leaflet-control-attribution{
    background:#fff;
    background:hsla(0,0%,100%,.3);
    margin:0
}
.leaflet-control-attribution,.leaflet-control-scale-line{
    padding:0 5px;
    color:#282828
}
.leaflet-control-attribution a{
    text-decoration:none
}
.leaflet-control-attribution a:hover{
    text-decoration:underline
}
.leaflet-container .leaflet-control-attribution,.leaflet-container .leaflet-control-scale{
    font-size:11px
}
.leaflet-left .leaflet-control-scale{
    margin-left:5px
}
.leaflet-bottom .leaflet-control-scale{
    margin-bottom:5px
}
.leaflet-control-scale-line{
    border:2px solid #777;
    border-top:none;
    line-height:1.1;
    padding:2px 5px 1px;
    font-size:11px;
    white-space:nowrap;
    overflow:hidden;
    box-sizing:border-box;
    background:#fff;
    background:hsla(0,0%,100%,.5)
}
.leaflet-control-scale-line:not(:first-child){
    border-top:2px solid #777;
    border-bottom:none;
    margin-top:-2px
}
.leaflet-control-scale-line:not(:first-child):not(:last-child){
    border-bottom:2px solid #777
}
.leaflet-touch .leaflet-bar,.leaflet-touch .leaflet-control-attribution,.leaflet-touch .leaflet-control-layers{
    box-shadow:none
}
.leaflet-touch .leaflet-bar,.leaflet-touch .leaflet-control-layers{
    border:2px solid rgba(7,9,25,.8);
    background-clip:padding-box
}
.leaflet-popup{
    position:absolute;
    text-align:center;
    margin-bottom:20px
}
.leaflet-popup-content-wrapper{
    padding:1px;
    text-align:left;
    border-radius:12px
}
.leaflet-popup-content{
    margin:13px 19px;
    line-height:1.4
}
.leaflet-popup-content p{
    margin:18px 0
}
.leaflet-popup-tip-container{
    width:40px;
    height:20px;
    position:absolute;
    left:50%;
    margin-left:-20px;
    overflow:hidden;
    pointer-events:none
}
.leaflet-popup-tip{
    width:17px;
    height:17px;
    padding:1px;
    margin:-10px auto 0;
    transform:rotate(45deg)
}
.leaflet-popup-content-wrapper,.leaflet-popup-tip{
    background:#fff;
    color:#282828;
    box-shadow:0 3px 14px rgba(7,9,25,.6)
}
.leaflet-container a.leaflet-popup-close-button{
    position:absolute;
    top:0;
    right:0;
    padding:4px 4px 0 0;
    border:none;
    text-align:center;
    width:18px;
    height:14px;
    color:#d1d2db;
    text-decoration:none;
    font-weight:700;
    background:transparent
}
.leaflet-container a.leaflet-popup-close-button:hover{
    color:#6c401c
}
.leaflet-popup-scrolled{
    overflow:auto;
    border-bottom:1px solid #e6e7f1;
    border-top:1px solid #e6e7f1
}
.leaflet-oldie .leaflet-popup-content-wrapper{
    -ms-zoom:1
}
.leaflet-oldie .leaflet-popup-tip{
    width:24px;
    margin:0 auto;
    -ms-filter:"progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
    filter:progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678,M12=0.70710678,M21=-0.70710678,M22=0.70710678)
}
.leaflet-oldie .leaflet-popup-tip-container{
    margin-top:-1px
}
.leaflet-oldie .leaflet-control-layers,.leaflet-oldie .leaflet-control-zoom,.leaflet-oldie .leaflet-popup-content-wrapper,.leaflet-oldie .leaflet-popup-tip{
    border:1px solid #6c401c
}
.leaflet-div-icon{
    background:#fff;
    border:1px solid #d1d2db
}
.leaflet-tooltip{
    position:absolute;
    padding:6px;
    background-color:#fff;
    border:1px solid #fff;
    border-radius:3px;
    color:#222;
    white-space:nowrap;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    pointer-events:none;
    box-shadow:0 1px 3px rgba(7,9,25,.6)
}
.leaflet-tooltip.leaflet-clickable{
    cursor:pointer;
    pointer-events:auto
}
.leaflet-tooltip-bottom:before,.leaflet-tooltip-left:before,.leaflet-tooltip-right:before,.leaflet-tooltip-top:before{
    position:absolute;
    pointer-events:none;
    border:6px solid transparent;
    background:transparent;
    content:""
}
.leaflet-tooltip-bottom{
    margin-top:6px
}
.leaflet-tooltip-top{
    margin-top:-6px
}
.leaflet-tooltip-bottom:before,.leaflet-tooltip-top:before{
    left:50%;
    margin-left:-6px
}
.leaflet-tooltip-top:before{
    bottom:0;
    margin-bottom:-12px;
    border-top-color:#fff
}
.leaflet-tooltip-bottom:before{
    top:0;
    margin-top:-12px;
    margin-left:-6px;
    border-bottom-color:#fff
}
.leaflet-tooltip-left{
    margin-left:-6px
}
.leaflet-tooltip-right{
    margin-left:6px
}
.leaflet-tooltip-left:before,.leaflet-tooltip-right:before{
    top:50%;
    margin-top:-6px
}
.leaflet-tooltip-left:before{
    right:0;
    margin-right:-12px;
    border-left-color:#fff
}
.leaflet-tooltip-right:before{
    left:0;
    margin-left:-12px;
    border-right-color:#fff
}
.CircularProgressbar{
    vertical-align:middle;
    width:90px
}
.CircularProgressbar .CircularProgressbar-path{
    stroke-linecap:round;
    transition:stroke-dashoffset .5s ease 0s
}
.CircularProgressbar .CircularProgressbar-trail{
    stroke-linecap:round
}
.CircularProgressbar .CircularProgressbar-text{
    font-size:1.1rem;
    dominant-baseline:middle;
    text-anchor:middle;
    font-weight:700
}
.CircularProgressbar.circular-progress-xs{
    width:48px
}
.CircularProgressbar.circular-progress-xs .CircularProgressbar-text{
    font-size:1.6rem
}
.CircularProgressbar.circular-progress-sm{
    width:62px
}
.CircularProgressbar.circular-progress-sm .CircularProgressbar-text{
    font-size:1.3rem
}
.CircularProgressbar.circular-progress-lg{
    width:120px
}
.CircularProgressbar.circular-progress-xl{
    width:140px
}
.CircularProgressbar.circular-progress-xxl{
    width:180px
}
.circular-progress-primary .CircularProgressbar-path{
    stroke:#00892c
}
.circular-progress-primary .CircularProgressbar-trail{
    stroke:rgba(60,68,177,.1)
}
.circular-progress-primary .CircularProgressbar-text{
    fill:#00892c
}
.circular-progress-primary .CircularProgressbar-background{
    fill:rgba(60,68,177,.05)
}
.circular-progress-secondary .CircularProgressbar-path{
    stroke:#f8f9ff
}
.circular-progress-secondary .CircularProgressbar-trail{
    stroke:rgba(248,249,255,.1)
}
.circular-progress-secondary .CircularProgressbar-text{
    fill:#f8f9ff
}
.circular-progress-secondary .CircularProgressbar-background{
    fill:rgba(248,249,255,.05)
}
.circular-progress-success .CircularProgressbar-path{
    stroke:#1bc943
}
.circular-progress-success .CircularProgressbar-trail{
    stroke:rgba(27,201,67,.1)
}
.circular-progress-success .CircularProgressbar-text{
    fill:#1bc943
}
.circular-progress-success .CircularProgressbar-background{
    fill:rgba(27,201,67,.05)
}
.circular-progress-info .CircularProgressbar-path{
    stroke:#11c5db
}
.circular-progress-info .CircularProgressbar-trail{
    stroke:rgba(17,197,219,.1)
}
.circular-progress-info .CircularProgressbar-text{
    fill:#11c5db
}
.circular-progress-info .CircularProgressbar-background{
    fill:rgba(17,197,219,.05)
}
.circular-progress-warning .CircularProgressbar-path{
    stroke:#f4772e
}
.circular-progress-warning .CircularProgressbar-trail{
    stroke:rgba(244,119,46,.1)
}
.circular-progress-warning .CircularProgressbar-text{
    fill:#f4772e
}
.circular-progress-warning .CircularProgressbar-background{
    fill:rgba(244,119,46,.05)
}
.circular-progress-danger .CircularProgressbar-path{
    stroke:#f83245
}
.circular-progress-danger .CircularProgressbar-trail{
    stroke:rgba(248,50,69,.1)
}
.circular-progress-danger .CircularProgressbar-text{
    fill:#f83245
}
.circular-progress-danger .CircularProgressbar-background{
    fill:rgba(248,50,69,.05)
}
.circular-progress-light .CircularProgressbar-path{
    stroke:#f4f5fd
}
.circular-progress-light .CircularProgressbar-trail{
    stroke:rgba(244,245,253,.1)
}
.circular-progress-light .CircularProgressbar-text{
    fill:#f4f5fd
}
.circular-progress-light .CircularProgressbar-background{
    fill:rgba(244,245,253,.05)
}
.circular-progress-gray .CircularProgressbar-path{
    stroke:#f8f9ff
}
.circular-progress-gray .CircularProgressbar-trail{
    stroke:rgba(248,249,255,.1)
}
.circular-progress-gray .CircularProgressbar-text{
    fill:#f8f9ff
}
.circular-progress-gray .CircularProgressbar-background{
    fill:rgba(248,249,255,.05)
}
.circular-progress-dark .CircularProgressbar-path{
    stroke:#6c401c
}
.circular-progress-dark .CircularProgressbar-trail{
    stroke:rgba(122,123,151,.1)
}
.circular-progress-dark .CircularProgressbar-text{
    fill:#6c401c
}
.circular-progress-dark .CircularProgressbar-background{
    fill:rgba(122,123,151,.05)
}
.circular-progress-first .CircularProgressbar-path{
    stroke:#00892c
}
.circular-progress-first .CircularProgressbar-trail{
    stroke:rgba(65,145,255,.1)
}
.circular-progress-first .CircularProgressbar-text{
    fill:#00892c
}
.circular-progress-first .CircularProgressbar-background{
    fill:rgba(65,145,255,.05)
}
.circular-progress-second .CircularProgressbar-path{
    stroke:#070919
}
.circular-progress-second .CircularProgressbar-trail{
    stroke:rgba(7,9,25,.1)
}
.circular-progress-second .CircularProgressbar-text{
    fill:#070919
}
.circular-progress-second .CircularProgressbar-background{
    fill:rgba(7,9,25,.05)
}
.ps{
    overflow:hidden!important;
    overflow-anchor:none;
    touch-action:auto
}
.ps__rail-x{
    display:none!important;
    height:15px;
    bottom:0
}
.ps__rail-x,.ps__rail-y{
    opacity:0;
    transition:background-color .2s linear,opacity .2s linear;
    position:absolute;
    z-index:7
}
.ps__rail-y{
    display:none;
    width:15px;
    right:0;
    border-radius:50px
}
.ps--active-x>.ps__rail-x,.ps--active-y>.ps__rail-y{
    display:block;
    background-color:transparent
}
.ps--focus>.ps__rail-x,.ps--focus>.ps__rail-y,.ps--scrolling-x>.ps__rail-x,.ps--scrolling-y>.ps__rail-y,.ps:hover>.ps__rail-x,.ps:hover>.ps__rail-y{
    opacity:.6
}
.ps__rail-x:focus,.ps__rail-x:hover,.ps__rail-y:focus,.ps__rail-y:hover{
    background-color:rgba(0,0,0,.1);
    opacity:.9
}
.ps__thumb-x{
    transition:background-color .2s linear,height .2s ease-in-out;
    height:6px;
    bottom:2px
}
.ps__thumb-x,.ps__thumb-y{
    background-color:rgba(0,0,0,.1);
    border-radius:6px;
    position:absolute
}
.ps__thumb-y{
    transition:background-color .2s linear,width .2s ease-in-out;
    width:6px;
    right:2px
}
.ps__rail-x:focus>.ps__thumb-x,.ps__rail-x:hover>.ps__thumb-x{
    background-color:rgba(0,0,0,.12);
    height:11px
}
.ps__rail-y:focus>.ps__thumb-y,.ps__rail-y:hover>.ps__thumb-y{
    background-color:rgba(0,0,0,.12);
    width:11px
}
@media (-ms-high-contrast:none),screen and (-ms-high-contrast:active){
    .ps{
        overflow:auto!important
    }
}
.scrollbar-container{
    position:relative;
    height:100%
}
.scroll-area{
    height:400px
}
.scroll-area-sm{
    height:200px
}
.scroll-area-md{
    height:300px;
    overflow-x:hidden
}
.scroll-area-lg{
    height:400px
}
.shadow-overflow:after,.shadow-overflow:before{
    z-index:10;
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#00ffffff",GradientType=0)
}
.shadow-overflow:after{
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#00ffffff",endColorstr="#ffffff",GradientType=0)
}
.hamburger{
    padding:0;
    display:inline-block;
    cursor:pointer;
    transition-property:opacity,-webkit-filter;
    transition-property:opacity,filter;
    transition-property:opacity,filter,-webkit-filter;
    transition-duration:.15s;
    transition-timing-function:linear;
    font:inherit;
    color:inherit;
    text-transform:none;
    background-color:transparent;
    border:0;
    margin:0;
    overflow:visible
}
.hamburger.is-active:hover,.hamburger:hover{
    opacity:.7
}
.hamburger.is-active .hamburger-inner,.hamburger.is-active .hamburger-inner:after,.hamburger.is-active .hamburger-inner:before{
    background-color:#070919
}
.hamburger-box{
    width:24px;
    height:14px;
    display:inline-block;
    position:relative
}
.hamburger-inner{
    display:block;
    top:50%;
    margin-top:-1px
}
.hamburger-inner,.hamburger-inner:after,.hamburger-inner:before{
    width:24px;
    height:2px;
    background-color:#070919;
    border-radius:10px;
    position:absolute;
    transition-property:transform;
    transition-duration:.15s;
    transition-timing-function:ease
}
.hamburger-inner:after,.hamburger-inner:before{
    content:"";
    display:block
}
.hamburger-inner:before{
    top:-6px
}
.hamburger-inner:after{
    bottom:-6px
}
.hamburger--elastic.collapsed.is-active .hamburger-inner,.hamburger--elastic .hamburger-inner{
    top:1px;
    transition-duration:.275s;
    transition-timing-function:cubic-bezier(.68,-.55,.265,1.55)
}
.hamburger--elastic.collapsed.is-active .hamburger-inner:before,.hamburger--elastic .hamburger-inner:before{
    top:6px;
    transition:opacity .125s ease .275s
}
.hamburger--elastic.collapsed.is-active .hamburger-inner:after,.hamburger--elastic .hamburger-inner:after{
    top:12px;
    transition:transform .275s cubic-bezier(.68,-.55,.265,1.55)
}
.hamburger--elastic.active .hamburger-inner,.hamburger--elastic.is-active:not(.collapsed) .hamburger-inner{
    transform:translate3d(0,6px,0) rotate(135deg);
    transition-delay:75ms
}
.hamburger--elastic.active .hamburger-inner:before,.hamburger--elastic.is-active:not(.collapsed) .hamburger-inner:before{
    transition-delay:0s;
    opacity:0
}
.hamburger--elastic.active .hamburger-inner:after,.hamburger--elastic.is-active:not(.collapsed) .hamburger-inner:after{
    transform:translate3d(0,-12px,0) rotate(-270deg);
    transition-delay:75ms
}
.navbar-toggler{
    display:flex;
    align-items:center;
    justify-content:center
}
.navbar-toggler:active,.navbar-toggler:focus{
    outline:none
}
body .accordion>.card-box{
    border-radius:0
}
body .accordion>.card-box>.MuiCard-root{
    box-shadow:none!important;
    border-radius:0!important
}
body .accordion>.card-box>.MuiCard-root>.card-header{
    padding:0;
    border-bottom:0;
    border-radius:0
}
body .accordion>.card-box>.MuiCard-root>.card-header>.panel-title,body .accordion>.card-box>.MuiCard-root>.card-header>.panel-title .accordion-toggle{
    width:100%
}
body .accordion>.card-box>.MuiCard-root>.card-header .MuiButton-root{
    display:block;
    width:100%;
    text-align:left;
    border-bottom:1px solid transparent;
    background:#f4f5fd
}
body .accordion>.card-box>.MuiCard-root>.card-header .MuiButton-root:hover{
    background:#f8f9ff!important;
    color:#070919
}
body .accordion>.card-box>.MuiCard-root>.card-header .MuiButton-root:not(:disabled):hover{
    transform:translateY(0)
}
body .accordion>.card-box>.MuiCard-root>.card-header .MuiButton-root .accordion-icon{
    transform:rotate(90deg);
    transition:all .2s ease-in-out
}
body .accordion>.card-box>.MuiCard-root .panel-body{
    padding:0
}
body .accordion>.card-box:first-of-type,body .accordion>.card-box:first-of-type .MuiCard-root{
    border-top-left-radius:.65rem!important;
    border-top-right-radius:.65rem!important
}
body .accordion>.card-box:not(:last-of-type){
    border-bottom:0
}
body .accordion>.card-box:not(:last-of-type) .MuiCard-root{
    border-radius:0
}
body .accordion>.card-box:last-of-type,body .accordion>.card-box:last-of-type .MuiCard-root{
    border-bottom-right-radius:.65rem!important;
    border-bottom-left-radius:.65rem!important
}
body .accordion>.card-box.panel-open>.MuiCard-root>.card-header .MuiButton-root{
    border-bottom-color:rgba(122,123,151,.3);
    background:#fff;
    color:#070919
}
body .accordion>.card-box.panel-open>.MuiCard-root>.card-header .MuiButton-root .accordion-icon{
    transform:rotate(180deg)
}
.avatar-icon-wrapper{
    display:inline-block;
    margin-right:.25rem;
    position:relative;
    font-size:1.2rem;
    transition:all .2s ease-in-out
}
.avatar-icon-wrapper:last-child{
    margin-right:0
}
.avatar-icon-wrapper .badge{
    position:absolute;
    right:0;
    bottom:0
}
.avatar-icon{
    display:block;
    width:44px;
    height:44px;
    line-height:44px;
    transition:all .2s ease-in-out;
    opacity:1;
    border-radius:50px
}
.avatar-icon.rounded{
    border-radius:.65rem!important
}
.avatar-icon-xl{
    font-size:1.4rem
}
.avatar-icon-xl .avatar-icon{
    width:64px;
    height:64px;
    line-height:64px
}
.avatar-icon-lg{
    font-size:1.2rem
}
.avatar-icon-lg .avatar-icon{
    width:54px;
    height:54px;
    line-height:54px
}
.avatar-icon-sm{
    font-size:.8rem;
    font-weight:700
}
.avatar-icon-sm .avatar-icon{
    width:34px;
    height:34px;
    line-height:34px
}
.avatar-icon-xs{
    font-size:.7rem;
    font-weight:700
}
.avatar-icon-xs .avatar-icon{
    width:26px;
    height:26px;
    line-height:26px;
    transition:transform .2s
}
.avatar-icon{
    box-shadow:0 0 0 3px #fff;
    overflow:hidden
}
.avatar-icon img{
    width:100%;
    height:100%;
    display:block
}
.avatar-wrapper-overlap{
    display:flex
}
.avatar-wrapper-overlap .avatar-icon-wrapper{
    z-index:5;
    margin-left:-18px
}
.avatar-wrapper-overlap .avatar-icon-wrapper:hover{
    z-index:7
}
.avatar-wrapper-overlap .avatar-icon-wrapper.avatar-icon-xl{
    margin-left:-30px
}
.avatar-wrapper-overlap .avatar-icon-wrapper.avatar-icon-lg{
    margin-left:-24px
}
.avatar-wrapper-overlap .avatar-icon-wrapper.avatar-icon-sm{
    margin-left:-14px
}
.avatar-wrapper-overlap .avatar-icon-wrapper.avatar-icon-xs{
    margin-left:-10px
}
.avatar-wrapper-overlap .avatar-icon-wrapper:first-child{
    margin-left:0!important
}
.avatar-wrapper-overlap-hover .avatar-icon-wrapper:hover{
    z-index:5
}
.avatar-wrapper-overlap-hover:hover .avatar-icon-wrapper{
    margin-left:-18px
}
.avatar-wrapper-overlap-hover:hover .avatar-icon-wrapper:first-child{
    transform:translateX(-22px)
}
.avatar-wrapper-overlap-hover:hover .avatar-icon-wrapper:last-child{
    transform:translateX(22px)
}
.avatar-wrapper-overlap-hover:hover .avatar-icon-xl{
    margin-left:-30px
}
.avatar-wrapper-overlap-hover:hover .avatar-icon-xl:first-child{
    transform:translateX(-34px)
}
.avatar-wrapper-overlap-hover:hover .avatar-icon-xl:last-child{
    transform:translateX(34px)
}
.avatar-wrapper-overlap-hover:hover .avatar-icon-lg{
    margin-left:-24px
}
.avatar-wrapper-overlap-hover:hover .avatar-icon-lg:first-child{
    transform:translateX(-28px)
}
.avatar-wrapper-overlap-hover:hover .avatar-icon-lg:last-child{
    transform:translateX(28px)
}
.avatar-wrapper-overlap-hover:hover .avatar-icon-sm{
    margin-left:-14px
}
.avatar-wrapper-overlap-hover:hover .avatar-icon-sm:first-child{
    transform:translateX(-18px)
}
.avatar-wrapper-overlap-hover:hover .avatar-icon-sm:last-child{
    transform:translateX(18px)
}
.avatar-wrapper-overlap-hover:hover .avatar-icon-xs{
    margin-left:-10px
}
.avatar-wrapper-overlap-hover:hover .avatar-icon-xs:first-child{
    transform:translateX(-14px)
}
.avatar-wrapper-overlap-hover:hover .avatar-icon-xs:last-child{
    transform:translateX(14px)
}
.avatar-initials{
    text-align:center
}
.avatar-initials,.badge{
    text-transform:uppercase
}
.badge{
    height:20px;
    line-height:21px
}
.badge-neutral-primary{
    background-color:#eceef7!important
}
.badge-neutral-secondary{
    background-color:#fff!important
}
.badge-neutral-success{
    background-color:#e5f9ed!important
}
.badge-neutral-info{
    background-color:#e8fbfd!important
}
.badge-neutral-warning{
    background-color:#fffbf2!important
}
.badge-neutral-danger{
    background-color:#fff5f6!important
}
.badge-neutral-dark{
    background-color:#f5f5f7!important
}
.badge-neutral-first{
    background-color:#eaf6ff!important
}
.badge-neutral-second{
    background-color:#e1e2e7!important
}
.badge.badge-circle,.badge.badge-circle-inner{
    text-indent:-999rem;
    border-radius:100%;
    height:10px;
    width:10px;
    padding:0;
    box-shadow:0 0 0 2px #fff
}
.badge.badge-circle-inner{
    width:16px;
    height:16px;
    position:relative
}
.badge.badge-circle-inner:after{
    content:"";
    background:#fff;
    width:8px;
    height:8px;
    position:absolute;
    left:50%;
    top:50%;
    margin:-4px 0 0 -4px;
    border-radius:100%
}
.badge-wrapper{
    position:relative
}
.badge-position{
    position:absolute!important
}
.badge-position--top-right.badge-circle{
    right:-5px;
    top:-5px
}
.badge-position--top-right.badge-circle-inner{
    right:-8px;
    top:-8px
}
.badge-position--top-center.badge-circle{
    left:50%;
    margin-left:-5px;
    top:-5px
}
.badge-position--top-center.badge-circle-inner{
    left:50%;
    margin-left:-8px;
    top:-8px
}
.badge-position--top-left.badge-circle{
    left:-5px;
    top:-5px
}
.badge-position--top-left.badge-circle-inner{
    left:-8px;
    top:-8px
}
.badge-position--bottom-right.badge-circle{
    right:-5px;
    bottom:-5px;
    top:auto
}
.badge-position--bottom-right.badge-circle-inner{
    right:-8px;
    bottom:-8px;
    top:auto
}
.badge-position--bottom-center.badge-circle{
    left:50%;
    margin-left:-5px;
    bottom:-5px;
    top:auto
}
.badge-position--bottom-center.badge-circle-inner{
    left:50%;
    margin-left:-8px;
    bottom:-8px;
    top:auto
}
.badge-position--bottom-left.badge-circle{
    left:-5px;
    bottom:-5px;
    top:auto
}
.badge-position--bottom-left.badge-circle-inner{
    left:-8px;
    bottom:-8px;
    top:auto
}
.btn-wrapper--label:not(:first-child){
    margin-left:.5rem
}
.btn-wrapper--label{
    display:inline-block
}
.btn-wrapper--label+.btn-wrapper--icon{
    margin-left:.5rem
}
.btn-wrapper--icon{
    display:inline-flex
}
.MuiButton-root.btn-pill{
    border-radius:4.5rem
}
body .MuiButton-root.Mui-disabled{
    background:#eeeff8;
    box-shadow:none;
    color:#282828
}
.MuiButton-root.btn-square{
    border-radius:0
}
body .MuiButtonBase-root{
    transition:all .2s ease-in-out
}
body .MuiButtonBase-root.card.card-box>.MuiTouchRipple-root{
    opacity:.3
}
body .MuiButtonBase-root.Mui-disabled{
    opacity:.65
}
body .MuiButtonBase-root:active{
    box-shadow:none!important
}
body .MuiButtonBase-root:hover .btn-icon-transition{
    transform:scale(1.1)
}
body .MuiButtonBase-root:not(.MuiListItem-button):not(.MuiTab-root):not(:disabled):not(.MuiCheckbox-root):not(.MuiSwitch-switchBase):hover{
    transform:translateY(-2px)
}
body .MuiButtonBase-root.btn-transition-none:hover{
    transform:translateY(0)!important
}
.MuiButton-root.btn-primary{
    box-shadow:0 .25rem .55rem rgba(60,68,177,.35);
    color:#fff;
    background:#00892c
}
.MuiButton-root.btn-primary:not(:disabled).active,.MuiButton-root.btn-primary:not(:disabled).active:hover,.MuiButton-root.btn-primary:not(:disabled):active{
    background:#2f358b
}
.MuiButton-root.btn-primary:not(:disabled):active{
    box-shadow:0 .22rem .525rem rgba(60,68,177,.4),0 .0625rem .385rem rgba(60,68,177,.54)
}
.MuiButton-root.btn-primary:not(:disabled):hover{
    background:#00892c
}
.MuiButton-root.btn-primary:hover .btn-wrapper--icon svg path,.MuiButton-root.btn-primary:not(:disabled).active .btn-wrapper--icon svg path{
    fill:#fff
}
.MuiButton-root.btn-outline-primary{
    background:transparent;
    color:#00892c;
    border:2px solid #00892c
}
.MuiButton-root.btn-outline-primary:not(:disabled):hover{
    box-shadow:0 .22rem .525rem rgba(60,68,177,.4),0 .0625rem .385rem rgba(60,68,177,.54)
}
.MuiButton-root.btn-outline-primary:hover,.MuiButton-root.btn-outline-primary:not(:disabled).active{
    background:#00892c;
    color:#fff
}
.MuiButtonBase-root.btn-neutral-primary{
    background:rgba(60,68,177,.15);
    color:#00892c;
    border-color:transparent
}
.MuiButtonBase-root.btn-neutral-primary.active,.MuiButtonBase-root.btn-neutral-primary:hover{
    background:#00892c;
    color:#fff
}
.MuiButtonBase-root.btn-neutral-primary.active .btn-wrapper--icon svg path,.MuiButtonBase-root.btn-neutral-primary:hover .btn-wrapper--icon svg path{
    fill:#fff
}
.btn-link-primary span{
    color:#00892c
}
.btn-link-primary span:before{
    background-color:#00892c
}
.btn-link-primary:hover span{
    color:#2f358b
}
.MuiButton-root.btn-secondary{
    box-shadow:0 .25rem .55rem rgba(248,249,255,.35);
    color:#282828;
    background:#f8f9ff
}
.MuiButton-root.btn-secondary:not(:disabled).active,.MuiButton-root.btn-secondary:not(:disabled).active:hover,.MuiButton-root.btn-secondary:not(:disabled):active{
    background:#c5cdff
}
.MuiButton-root.btn-secondary:not(:disabled):active{
    box-shadow:0 .22rem .525rem rgba(248,249,255,.4),0 .0625rem .385rem rgba(248,249,255,.54)
}
.MuiButton-root.btn-secondary:not(:disabled):hover{
    background:#f8f9ff
}
.MuiButton-root.btn-secondary:hover .btn-wrapper--icon svg path,.MuiButton-root.btn-secondary:not(:disabled).active .btn-wrapper--icon svg path{
    fill:#282828
}
.MuiButton-root.btn-outline-secondary{
    background:transparent;
    color:#f8f9ff;
    border:2px solid #f8f9ff
}
.MuiButton-root.btn-outline-secondary:not(:disabled):hover{
    box-shadow:0 .22rem .525rem rgba(248,249,255,.4),0 .0625rem .385rem rgba(248,249,255,.54)
}
.MuiButton-root.btn-outline-secondary:hover,.MuiButton-root.btn-outline-secondary:not(:disabled).active{
    background:#f8f9ff;
    color:#282828
}
.MuiButtonBase-root.btn-neutral-secondary{
    background:rgba(248,249,255,.15);
    color:#f8f9ff;
    border-color:transparent;
    color:#282828
}
.MuiButtonBase-root.btn-neutral-secondary.active,.MuiButtonBase-root.btn-neutral-secondary:hover{
    background:#f8f9ff;
    color:#282828
}
.MuiButtonBase-root.btn-neutral-secondary.active .btn-wrapper--icon svg path,.MuiButtonBase-root.btn-neutral-secondary:hover .btn-wrapper--icon svg path{
    fill:#282828
}
.btn-link-secondary span{
    color:#f8f9ff
}
.btn-link-secondary span:before{
    background-color:#f8f9ff
}
.btn-link-secondary:hover span{
    color:#c5cdff
}
.MuiButton-root.btn-success{
    box-shadow:0 .25rem .55rem rgba(27,201,67,.35);
    color:#fff;
    background:#1bc943
}
.MuiButton-root.btn-success:not(:disabled).active,.MuiButton-root.btn-success:not(:disabled).active:hover,.MuiButton-root.btn-success:not(:disabled):active{
    background:#159c34
}
.MuiButton-root.btn-success:not(:disabled):active{
    box-shadow:0 .22rem .525rem rgba(27,201,67,.4),0 .0625rem .385rem rgba(27,201,67,.54)
}
.MuiButton-root.btn-success:not(:disabled):hover{
    background:#1bc943
}
.MuiButton-root.btn-success:hover .btn-wrapper--icon svg path,.MuiButton-root.btn-success:not(:disabled).active .btn-wrapper--icon svg path{
    fill:#fff
}
.MuiButton-root.btn-outline-success{
    background:transparent;
    color:#1bc943;
    border:2px solid #1bc943
}
.MuiButton-root.btn-outline-success:not(:disabled):hover{
    box-shadow:0 .22rem .525rem rgba(27,201,67,.4),0 .0625rem .385rem rgba(27,201,67,.54)
}
.MuiButton-root.btn-outline-success:hover,.MuiButton-root.btn-outline-success:not(:disabled).active{
    background:#1bc943;
    color:#fff
}
.MuiButtonBase-root.btn-neutral-success{
    background:rgba(27,201,67,.15);
    color:#1bc943;
    border-color:transparent
}
.MuiButtonBase-root.btn-neutral-success.active,.MuiButtonBase-root.btn-neutral-success:hover{
    background:#1bc943;
    color:#fff
}
.MuiButtonBase-root.btn-neutral-success.active .btn-wrapper--icon svg path,.MuiButtonBase-root.btn-neutral-success:hover .btn-wrapper--icon svg path{
    fill:#fff
}
.btn-link-success span{
    color:#1bc943
}
.btn-link-success span:before{
    background-color:#1bc943
}
.btn-link-success:hover span{
    color:#159c34
}
.MuiButton-root.btn-info{
    box-shadow:0 .25rem .55rem rgba(17,197,219,.35);
    color:#fff;
    background:#11c5db
}
.MuiButton-root.btn-info:not(:disabled).active,.MuiButton-root.btn-info:not(:disabled).active:hover,.MuiButton-root.btn-info:not(:disabled):active{
    background:#0d9aac
}
.MuiButton-root.btn-info:not(:disabled):active{
    box-shadow:0 .22rem .525rem rgba(17,197,219,.4),0 .0625rem .385rem rgba(17,197,219,.54)
}
.MuiButton-root.btn-info:not(:disabled):hover{
    background:#11c5db
}
.MuiButton-root.btn-info:hover .btn-wrapper--icon svg path,.MuiButton-root.btn-info:not(:disabled).active .btn-wrapper--icon svg path{
    fill:#fff
}
.MuiButton-root.btn-outline-info{
    background:transparent;
    color:#11c5db;
    border:2px solid #11c5db
}
.MuiButton-root.btn-outline-info:not(:disabled):hover{
    box-shadow:0 .22rem .525rem rgba(17,197,219,.4),0 .0625rem .385rem rgba(17,197,219,.54)
}
.MuiButton-root.btn-outline-info:hover,.MuiButton-root.btn-outline-info:not(:disabled).active{
    background:#11c5db;
    color:#fff
}
.MuiButtonBase-root.btn-neutral-info{
    background:rgba(17,197,219,.15);
    color:#11c5db;
    border-color:transparent
}
.MuiButtonBase-root.btn-neutral-info.active,.MuiButtonBase-root.btn-neutral-info:hover{
    background:#11c5db;
    color:#fff
}
.MuiButtonBase-root.btn-neutral-info.active .btn-wrapper--icon svg path,.MuiButtonBase-root.btn-neutral-info:hover .btn-wrapper--icon svg path{
    fill:#fff
}
.btn-link-info span{
    color:#11c5db
}
.btn-link-info span:before{
    background-color:#11c5db
}
.btn-link-info:hover span{
    color:#0d9aac
}
.MuiButton-root.btn-warning{
    box-shadow:0 .25rem .55rem rgba(244,119,46,.35);
    color:#fff;
    background:#f4772e
}
.MuiButton-root.btn-warning:not(:disabled).active,.MuiButton-root.btn-warning:not(:disabled).active:hover,.MuiButton-root.btn-warning:not(:disabled):active{
    background:#e35b0c
}
.MuiButton-root.btn-warning:not(:disabled):active{
    box-shadow:0 .22rem .525rem rgba(244,119,46,.4),0 .0625rem .385rem rgba(244,119,46,.54)
}
.MuiButton-root.btn-warning:not(:disabled):hover{
    background:#f4772e
}
.MuiButton-root.btn-warning:hover .btn-wrapper--icon svg path,.MuiButton-root.btn-warning:not(:disabled).active .btn-wrapper--icon svg path{
    fill:#fff
}
.MuiButton-root.btn-outline-warning{
    background:transparent;
    color:#f4772e;
    border:2px solid #f4772e
}
.MuiButton-root.btn-outline-warning:not(:disabled):hover{
    box-shadow:0 .22rem .525rem rgba(244,119,46,.4),0 .0625rem .385rem rgba(244,119,46,.54)
}
.MuiButton-root.btn-outline-warning:hover,.MuiButton-root.btn-outline-warning:not(:disabled).active{
    background:#f4772e;
    color:#fff
}
.MuiButtonBase-root.btn-neutral-warning{
    background:rgba(244,119,46,.15);
    color:#f4772e;
    border-color:transparent
}
.MuiButtonBase-root.btn-neutral-warning.active,.MuiButtonBase-root.btn-neutral-warning:hover{
    background:#f4772e;
    color:#fff
}
.MuiButtonBase-root.btn-neutral-warning.active .btn-wrapper--icon svg path,.MuiButtonBase-root.btn-neutral-warning:hover .btn-wrapper--icon svg path{
    fill:#fff
}
.btn-link-warning span{
    color:#f4772e
}
.btn-link-warning span:before{
    background-color:#f4772e
}
.btn-link-warning:hover span{
    color:#e35b0c
}
.MuiButton-root.btn-danger{
    box-shadow:0 .25rem .55rem rgba(248,50,69,.35);
    color:#fff;
    background:#f83245
}
.MuiButton-root.btn-danger:not(:disabled).active,.MuiButton-root.btn-danger:not(:disabled).active:hover,.MuiButton-root.btn-danger:not(:disabled):active{
    background:#ef081e
}
.MuiButton-root.btn-danger:not(:disabled):active{
    box-shadow:0 .22rem .525rem rgba(248,50,69,.4),0 .0625rem .385rem rgba(248,50,69,.54)
}
.MuiButton-root.btn-danger:not(:disabled):hover{
    background:#f83245
}
.MuiButton-root.btn-danger:hover .btn-wrapper--icon svg path,.MuiButton-root.btn-danger:not(:disabled).active .btn-wrapper--icon svg path{
    fill:#fff
}
.MuiButton-root.btn-outline-danger{
    background:transparent;
    color:#f83245;
    border:2px solid #f83245
}
.MuiButton-root.btn-outline-danger:not(:disabled):hover{
    box-shadow:0 .22rem .525rem rgba(248,50,69,.4),0 .0625rem .385rem rgba(248,50,69,.54)
}
.MuiButton-root.btn-outline-danger:hover,.MuiButton-root.btn-outline-danger:not(:disabled).active{
    background:#f83245;
    color:#fff
}
.MuiButtonBase-root.btn-neutral-danger{
    background:rgba(248,50,69,.15);
    color:#f83245;
    border-color:transparent
}
.MuiButtonBase-root.btn-neutral-danger.active,.MuiButtonBase-root.btn-neutral-danger:hover{
    background:#f83245;
    color:#fff
}
.MuiButtonBase-root.btn-neutral-danger.active .btn-wrapper--icon svg path,.MuiButtonBase-root.btn-neutral-danger:hover .btn-wrapper--icon svg path{
    fill:#fff
}
.btn-link-danger span{
    color:#f83245
}
.btn-link-danger span:before{
    background-color:#f83245
}
.btn-link-danger:hover span{
    color:#ef081e
}
.MuiButton-root.btn-light{
    box-shadow:0 .25rem .55rem rgba(244,245,253,.35);
    color:#282828;
    background:#f4f5fd
}
.MuiButton-root.btn-light:not(:disabled).active,.MuiButton-root.btn-light:not(:disabled).active:hover,.MuiButton-root.btn-light:not(:disabled):active{
    background:#c9cef5
}
.MuiButton-root.btn-light:not(:disabled):active{
    box-shadow:0 .22rem .525rem rgba(244,245,253,.4),0 .0625rem .385rem rgba(244,245,253,.54)
}
.MuiButton-root.btn-light:not(:disabled):hover{
    background:#f4f5fd
}
.MuiButton-root.btn-light:hover .btn-wrapper--icon svg path,.MuiButton-root.btn-light:not(:disabled).active .btn-wrapper--icon svg path{
    fill:#282828
}
.MuiButton-root.btn-outline-light{
    background:transparent;
    color:#f4f5fd;
    border:2px solid #f4f5fd
}
.MuiButton-root.btn-outline-light:not(:disabled):hover{
    box-shadow:0 .22rem .525rem rgba(244,245,253,.4),0 .0625rem .385rem rgba(244,245,253,.54)
}
.MuiButton-root.btn-outline-light:hover,.MuiButton-root.btn-outline-light:not(:disabled).active{
    background:#f4f5fd;
    color:#282828
}
.MuiButtonBase-root.btn-neutral-light{
    background:rgba(244,245,253,.15);
    color:#f4f5fd;
    border-color:transparent
}
.MuiButtonBase-root.btn-neutral-light.active,.MuiButtonBase-root.btn-neutral-light:hover{
    background:#f4f5fd;
    color:#282828
}
.MuiButtonBase-root.btn-neutral-light.active .btn-wrapper--icon svg path,.MuiButtonBase-root.btn-neutral-light:hover .btn-wrapper--icon svg path{
    fill:#282828
}
.btn-link-light span{
    color:#f4f5fd
}
.btn-link-light span:before{
    background-color:#f4f5fd
}
.btn-link-light:hover span{
    color:#c9cef5
}
.MuiButton-root.btn-gray{
    box-shadow:0 .25rem .55rem rgba(248,249,255,.35);
    color:#282828;
    background:#f8f9ff
}
.MuiButton-root.btn-gray:not(:disabled).active,.MuiButton-root.btn-gray:not(:disabled).active:hover,.MuiButton-root.btn-gray:not(:disabled):active{
    background:#c5cdff
}
.MuiButton-root.btn-gray:not(:disabled):active{
    box-shadow:0 .22rem .525rem rgba(248,249,255,.4),0 .0625rem .385rem rgba(248,249,255,.54)
}
.MuiButton-root.btn-gray:not(:disabled):hover{
    background:#f8f9ff
}
.MuiButton-root.btn-gray:hover .btn-wrapper--icon svg path,.MuiButton-root.btn-gray:not(:disabled).active .btn-wrapper--icon svg path{
    fill:#282828
}
.MuiButton-root.btn-outline-gray{
    background:transparent;
    color:#f8f9ff;
    border:2px solid #f8f9ff
}
.MuiButton-root.btn-outline-gray:not(:disabled):hover{
    box-shadow:0 .22rem .525rem rgba(248,249,255,.4),0 .0625rem .385rem rgba(248,249,255,.54)
}
.MuiButton-root.btn-outline-gray:hover,.MuiButton-root.btn-outline-gray:not(:disabled).active{
    background:#f8f9ff;
    color:#282828
}
.MuiButtonBase-root.btn-neutral-gray{
    background:rgba(248,249,255,.15);
    color:#f8f9ff;
    border-color:transparent
}
.MuiButtonBase-root.btn-neutral-gray.active,.MuiButtonBase-root.btn-neutral-gray:hover{
    background:#f8f9ff;
    color:#282828
}
.MuiButtonBase-root.btn-neutral-gray.active .btn-wrapper--icon svg path,.MuiButtonBase-root.btn-neutral-gray:hover .btn-wrapper--icon svg path{
    fill:#282828
}
.btn-link-gray span{
    color:#f8f9ff
}
.btn-link-gray span:before{
    background-color:#f8f9ff
}
.btn-link-gray:hover span{
    color:#c5cdff
}
.MuiButton-root.btn-dark{
    box-shadow:0 .25rem .55rem rgba(122,123,151,.35);
    color:#fff;
    background:#6c401c
}
.MuiButton-root.btn-dark:not(:disabled).active,.MuiButton-root.btn-dark:not(:disabled).active:hover,.MuiButton-root.btn-dark:not(:disabled):active{
    background:#61627d
}
.MuiButton-root.btn-dark:not(:disabled):active{
    box-shadow:0 .22rem .525rem rgba(122,123,151,.4),0 .0625rem .385rem rgba(122,123,151,.54)
}
.MuiButton-root.btn-dark:not(:disabled):hover{
    background:#6c401c
}
.MuiButton-root.btn-dark:hover .btn-wrapper--icon svg path,.MuiButton-root.btn-dark:not(:disabled).active .btn-wrapper--icon svg path{
    fill:#fff
}
.MuiButton-root.btn-outline-dark{
    background:transparent;
    color:#6c401c;
    border:2px solid #6c401c
}
.MuiButton-root.btn-outline-dark:not(:disabled):hover{
    box-shadow:0 .22rem .525rem rgba(122,123,151,.4),0 .0625rem .385rem rgba(122,123,151,.54)
}
.MuiButton-root.btn-outline-dark:hover,.MuiButton-root.btn-outline-dark:not(:disabled).active{
    background:#6c401c;
    color:#fff
}
.MuiButtonBase-root.btn-neutral-dark{
    background:rgba(122,123,151,.15);
    color:#6c401c;
    border-color:transparent
}
.MuiButtonBase-root.btn-neutral-dark.active,.MuiButtonBase-root.btn-neutral-dark:hover{
    background:#6c401c;
    color:#fff
}
.MuiButtonBase-root.btn-neutral-dark.active .btn-wrapper--icon svg path,.MuiButtonBase-root.btn-neutral-dark:hover .btn-wrapper--icon svg path{
    fill:#fff
}
.btn-link-dark span{
    color:#6c401c
}
.btn-link-dark span:before{
    background-color:#6c401c
}
.btn-link-dark:hover span{
    color:#61627d
}
.MuiButton-root.btn-first{
    box-shadow:0 .25rem .55rem rgba(65,145,255,.35);
    color:#fff;
    background:#00892c
}
.MuiButton-root.btn-first:not(:disabled).active,.MuiButton-root.btn-first:not(:disabled).active:hover,.MuiButton-root.btn-first:not(:disabled):active{
    background:#0e73ff
}
.MuiButton-root.btn-first:not(:disabled):active{
    box-shadow:0 .22rem .525rem rgba(65,145,255,.4),0 .0625rem .385rem rgba(65,145,255,.54)
}
.MuiButton-root.btn-first:not(:disabled):hover{
    background:#00892c
}
.MuiButton-root.btn-first:hover .btn-wrapper--icon svg path,.MuiButton-root.btn-first:not(:disabled).active .btn-wrapper--icon svg path{
    fill:#fff
}
.MuiButton-root.btn-outline-first{
    background:transparent;
    color:#00892c;
    border:2px solid #00892c
}
.MuiButton-root.btn-outline-first:not(:disabled):hover{
    box-shadow:0 .22rem .525rem rgba(65,145,255,.4),0 .0625rem .385rem rgba(65,145,255,.54)
}
.MuiButton-root.btn-outline-first:hover,.MuiButton-root.btn-outline-first:not(:disabled).active{
    background:#00892c;
    color:#fff
}
.MuiButtonBase-root.btn-neutral-first{
    background:rgba(65,145,255,.15);
    color:#00892c;
    border-color:transparent
}
.MuiButtonBase-root.btn-neutral-first.active,.MuiButtonBase-root.btn-neutral-first:hover{
    background:#00892c;
    color:#fff
}
.MuiButtonBase-root.btn-neutral-first.active .btn-wrapper--icon svg path,.MuiButtonBase-root.btn-neutral-first:hover .btn-wrapper--icon svg path{
    fill:#fff
}
.btn-link-first span{
    color:#00892c
}
.btn-link-first span:before{
    background-color:#00892c
}
.btn-link-first:hover span{
    color:#0e73ff
}
.MuiButton-root.btn-second{
    box-shadow:0 .25rem .55rem rgba(7,9,25,.35);
    color:#fff;
    background:#070919
}
.MuiButton-root.btn-second:not(:disabled).active,.MuiButton-root.btn-second:not(:disabled).active:hover,.MuiButton-root.btn-second:not(:disabled):active{
    background:#000
}
.MuiButton-root.btn-second:not(:disabled):active{
    box-shadow:0 .22rem .525rem rgba(7,9,25,.4),0 .0625rem .385rem rgba(7,9,25,.54)
}
.MuiButton-root.btn-second:not(:disabled):hover{
    background:#070919
}
.MuiButton-root.btn-second:hover .btn-wrapper--icon svg path,.MuiButton-root.btn-second:not(:disabled).active .btn-wrapper--icon svg path{
    fill:#fff
}
.MuiButton-root.btn-outline-second{
    background:transparent;
    color:#070919;
    border:2px solid #070919
}
.MuiButton-root.btn-outline-second:not(:disabled):hover{
    box-shadow:0 .22rem .525rem rgba(7,9,25,.4),0 .0625rem .385rem rgba(7,9,25,.54)
}
.MuiButton-root.btn-outline-second:hover,.MuiButton-root.btn-outline-second:not(:disabled).active{
    background:#070919;
    color:#fff
}
.MuiButtonBase-root.btn-neutral-second{
    background:rgba(7,9,25,.15);
    color:#070919;
    border-color:transparent
}
.MuiButtonBase-root.btn-neutral-second.active,.MuiButtonBase-root.btn-neutral-second:hover{
    background:#070919;
    color:#fff
}
.MuiButtonBase-root.btn-neutral-second.active .btn-wrapper--icon svg path,.MuiButtonBase-root.btn-neutral-second:hover .btn-wrapper--icon svg path{
    fill:#fff
}
.btn-link-second span{
    color:#070919
}
.btn-link-second span:before{
    background-color:#070919
}
.btn-link-second:hover span{
    color:#000
}
.MuiButton-root.btn-facebook{
    box-shadow:0 .25rem .55rem rgba(59,89,153,.35)
}
.MuiButton-root.btn-facebook,.MuiButton-root.btn-facebook:hover{
    color:#fff;
    background-color:#3b5999;
    border-color:#3b5999
}
.MuiButton-root.btn-facebook.focus,.MuiButton-root.btn-facebook:focus{
    box-shadow:0 0 0 0 transparent
}
.MuiButton-root.btn-facebook.disabled,.MuiButton-root.btn-facebook:disabled{
    color:#fff;
    background-color:#3b5999;
    border-color:#3b5999
}
.MuiButton-root.btn-facebook:not(:disabled):not(.disabled).active,.MuiButton-root.btn-facebook:not(:disabled):not(.disabled):active,.show>.MuiButton-root.btn-facebook.dropdown-toggle{
    color:#fff;
    background-color:#2d4474;
    border-color:#293e6b
}
.MuiButton-root.btn-facebook:not(:disabled):not(.disabled).active:focus,.MuiButton-root.btn-facebook:not(:disabled):not(.disabled):active:focus,.show>.MuiButton-root.btn-facebook.dropdown-toggle:focus{
    box-shadow:0 0 0 0 transparent
}
.MuiButton-root.btn-facebook:not(:disabled).active,.MuiButton-root.btn-facebook:not(:disabled).active:hover,.MuiButton-root.btn-facebook:not(:disabled):active{
    background:#344e87
}
.MuiButton-root.btn-facebook:not(:disabled):not(.active):active{
    box-shadow:0 .22rem .525rem rgba(59,89,153,.4),0 .0625rem .385rem rgba(59,89,153,.54)
}
.MuiButton-root.btn-discord{
    box-shadow:0 .25rem .55rem rgba(113,137,218,.35)
}
.MuiButton-root.btn-discord,.MuiButton-root.btn-discord:hover{
    color:#fff;
    background-color:#7189da;
    border-color:#7189da
}
.MuiButton-root.btn-discord.focus,.MuiButton-root.btn-discord:focus{
    box-shadow:0 0 0 0 transparent
}
.MuiButton-root.btn-discord.disabled,.MuiButton-root.btn-discord:disabled{
    color:#fff;
    background-color:#7189da;
    border-color:#7189da
}
.MuiButton-root.btn-discord:not(:disabled):not(.disabled).active,.MuiButton-root.btn-discord:not(:disabled):not(.disabled):active,.show>.MuiButton-root.btn-discord.dropdown-toggle{
    color:#fff;
    background-color:#4967cf;
    border-color:#3e5fcd
}
.MuiButton-root.btn-discord:not(:disabled):not(.disabled).active:focus,.MuiButton-root.btn-discord:not(:disabled):not(.disabled):active:focus,.show>.MuiButton-root.btn-discord.dropdown-toggle:focus{
    box-shadow:0 0 0 0 transparent
}
.MuiButton-root.btn-discord:not(:disabled).active,.MuiButton-root.btn-discord:not(:disabled).active:hover,.MuiButton-root.btn-discord:not(:disabled):active{
    background:#5d78d5
}
.MuiButton-root.btn-discord:not(:disabled):not(.active):active{
    box-shadow:0 .22rem .525rem rgba(113,137,218,.4),0 .0625rem .385rem rgba(113,137,218,.54)
}
.MuiButton-root.btn-twitter{
    box-shadow:0 .25rem .55rem rgba(29,161,242,.35)
}
.MuiButton-root.btn-twitter,.MuiButton-root.btn-twitter:hover{
    color:#fff;
    background-color:#1da1f2;
    border-color:#1da1f2
}
.MuiButton-root.btn-twitter.focus,.MuiButton-root.btn-twitter:focus{
    box-shadow:0 0 0 0 transparent
}
.MuiButton-root.btn-twitter.disabled,.MuiButton-root.btn-twitter:disabled{
    color:#fff;
    background-color:#1da1f2;
    border-color:#1da1f2
}
.MuiButton-root.btn-twitter:not(:disabled):not(.disabled).active,.MuiButton-root.btn-twitter:not(:disabled):not(.disabled):active,.show>.MuiButton-root.btn-twitter.dropdown-toggle{
    color:#fff;
    background-color:#0c85d0;
    border-color:#0b7ec4
}
.MuiButton-root.btn-twitter:not(:disabled):not(.disabled).active:focus,.MuiButton-root.btn-twitter:not(:disabled):not(.disabled):active:focus,.show>.MuiButton-root.btn-twitter.dropdown-toggle:focus{
    box-shadow:0 0 0 0 transparent
}
.MuiButton-root.btn-twitter:not(:disabled).active,.MuiButton-root.btn-twitter:not(:disabled).active:hover,.MuiButton-root.btn-twitter:not(:disabled):active{
    background:#0d95e8
}
.MuiButton-root.btn-twitter:not(:disabled):not(.active):active{
    box-shadow:0 .22rem .525rem rgba(29,161,242,.4),0 .0625rem .385rem rgba(29,161,242,.54)
}
.MuiButton-root.btn-google{
    box-shadow:0 .25rem .55rem rgba(221,75,57,.35)
}
.MuiButton-root.btn-google,.MuiButton-root.btn-google:hover{
    color:#fff;
    background-color:#dd4b39;
    border-color:#dd4b39
}
.MuiButton-root.btn-google.focus,.MuiButton-root.btn-google:focus{
    box-shadow:0 0 0 0 transparent
}
.MuiButton-root.btn-google.disabled,.MuiButton-root.btn-google:disabled{
    color:#fff;
    background-color:#dd4b39;
    border-color:#dd4b39
}
.MuiButton-root.btn-google:not(:disabled):not(.disabled).active,.MuiButton-root.btn-google:not(:disabled):not(.disabled):active,.show>.MuiButton-root.btn-google.dropdown-toggle{
    color:#fff;
    background-color:#c23321;
    border-color:#b7301f
}
.MuiButton-root.btn-google:not(:disabled):not(.disabled).active:focus,.MuiButton-root.btn-google:not(:disabled):not(.disabled):active:focus,.show>.MuiButton-root.btn-google.dropdown-toggle:focus{
    box-shadow:0 0 0 0 transparent
}
.MuiButton-root.btn-google:not(:disabled).active,.MuiButton-root.btn-google:not(:disabled).active:hover,.MuiButton-root.btn-google:not(:disabled):active{
    background:#d73925
}
.MuiButton-root.btn-google:not(:disabled):not(.active):active{
    box-shadow:0 .22rem .525rem rgba(221,75,57,.4),0 .0625rem .385rem rgba(221,75,57,.54)
}
.MuiButton-root.btn-instagram{
    box-shadow:0 .25rem .55rem rgba(228,64,95,.35)
}
.MuiButton-root.btn-instagram,.MuiButton-root.btn-instagram:hover{
    color:#fff;
    background-color:#e4405f;
    border-color:#e4405f
}
.MuiButton-root.btn-instagram.focus,.MuiButton-root.btn-instagram:focus{
    box-shadow:0 0 0 0 transparent
}
.MuiButton-root.btn-instagram.disabled,.MuiButton-root.btn-instagram:disabled{
    color:#fff;
    background-color:#e4405f;
    border-color:#e4405f
}
.MuiButton-root.btn-instagram:not(:disabled):not(.disabled).active,.MuiButton-root.btn-instagram:not(:disabled):not(.disabled):active,.show>.MuiButton-root.btn-instagram.dropdown-toggle{
    color:#fff;
    background-color:#d31e40;
    border-color:#c81c3d
}
.MuiButton-root.btn-instagram:not(:disabled):not(.disabled).active:focus,.MuiButton-root.btn-instagram:not(:disabled):not(.disabled):active:focus,.show>.MuiButton-root.btn-instagram.dropdown-toggle:focus{
    box-shadow:0 0 0 0 transparent
}
.MuiButton-root.btn-instagram:not(:disabled).active,.MuiButton-root.btn-instagram:not(:disabled).active:hover,.MuiButton-root.btn-instagram:not(:disabled):active{
    background:#e12a4c
}
.MuiButton-root.btn-instagram:not(:disabled):not(.active):active{
    box-shadow:0 .22rem .525rem rgba(228,64,95,.4),0 .0625rem .385rem rgba(228,64,95,.54)
}
.MuiButton-root.btn-pinterest{
    box-shadow:0 .25rem .55rem rgba(189,8,28,.35)
}
.MuiButton-root.btn-pinterest,.MuiButton-root.btn-pinterest:hover{
    color:#fff;
    background-color:#bd081c;
    border-color:#bd081c
}
.MuiButton-root.btn-pinterest.focus,.MuiButton-root.btn-pinterest:focus{
    box-shadow:0 0 0 0 transparent
}
.MuiButton-root.btn-pinterest.disabled,.MuiButton-root.btn-pinterest:disabled{
    color:#fff;
    background-color:#bd081c;
    border-color:#bd081c
}
.MuiButton-root.btn-pinterest:not(:disabled):not(.disabled).active,.MuiButton-root.btn-pinterest:not(:disabled):not(.disabled):active,.show>.MuiButton-root.btn-pinterest.dropdown-toggle{
    color:#fff;
    background-color:#8c0615;
    border-color:#800513
}
.MuiButton-root.btn-pinterest:not(:disabled):not(.disabled).active:focus,.MuiButton-root.btn-pinterest:not(:disabled):not(.disabled):active:focus,.show>.MuiButton-root.btn-pinterest.dropdown-toggle:focus{
    box-shadow:0 0 0 0 transparent
}
.MuiButton-root.btn-pinterest:not(:disabled).active,.MuiButton-root.btn-pinterest:not(:disabled).active:hover,.MuiButton-root.btn-pinterest:not(:disabled):active{
    background:#a50718
}
.MuiButton-root.btn-pinterest:not(:disabled):not(.active):active{
    box-shadow:0 .22rem .525rem rgba(189,8,28,.4),0 .0625rem .385rem rgba(189,8,28,.54)
}
.MuiButton-root.btn-youtube{
    box-shadow:0 .25rem .55rem rgba(205,32,31,.35)
}
.MuiButton-root.btn-youtube,.MuiButton-root.btn-youtube:hover{
    color:#fff;
    background-color:#cd201f;
    border-color:#cd201f
}
.MuiButton-root.btn-youtube.focus,.MuiButton-root.btn-youtube:focus{
    box-shadow:0 0 0 0 transparent
}
.MuiButton-root.btn-youtube.disabled,.MuiButton-root.btn-youtube:disabled{
    color:#fff;
    background-color:#cd201f;
    border-color:#cd201f
}
.MuiButton-root.btn-youtube:not(:disabled):not(.disabled).active,.MuiButton-root.btn-youtube:not(:disabled):not(.disabled):active,.show>.MuiButton-root.btn-youtube.dropdown-toggle{
    color:#fff;
    background-color:#a11918;
    border-color:#961717
}
.MuiButton-root.btn-youtube:not(:disabled):not(.disabled).active:focus,.MuiButton-root.btn-youtube:not(:disabled):not(.disabled):active:focus,.show>.MuiButton-root.btn-youtube.dropdown-toggle:focus{
    box-shadow:0 0 0 0 transparent
}
.MuiButton-root.btn-youtube:not(:disabled).active,.MuiButton-root.btn-youtube:not(:disabled).active:hover,.MuiButton-root.btn-youtube:not(:disabled):active{
    background:#b71d1c
}
.MuiButton-root.btn-youtube:not(:disabled):not(.active):active{
    box-shadow:0 .22rem .525rem rgba(205,32,31,.4),0 .0625rem .385rem rgba(205,32,31,.54)
}
.MuiButton-root.btn-slack{
    box-shadow:0 .25rem .55rem rgba(58,175,133,.35)
}
.MuiButton-root.btn-slack,.MuiButton-root.btn-slack:hover{
    color:#fff;
    background-color:#3aaf85;
    border-color:#3aaf85
}
.MuiButton-root.btn-slack.focus,.MuiButton-root.btn-slack:focus{
    box-shadow:0 0 0 0 transparent
}
.MuiButton-root.btn-slack.disabled,.MuiButton-root.btn-slack:disabled{
    color:#fff;
    background-color:#3aaf85;
    border-color:#3aaf85
}
.MuiButton-root.btn-slack:not(:disabled):not(.disabled).active,.MuiButton-root.btn-slack:not(:disabled):not(.disabled):active,.show>.MuiButton-root.btn-slack.dropdown-toggle{
    color:#fff;
    background-color:#2d8968;
    border-color:#2a7f61
}
.MuiButton-root.btn-slack:not(:disabled):not(.disabled).active:focus,.MuiButton-root.btn-slack:not(:disabled):not(.disabled):active:focus,.show>.MuiButton-root.btn-slack.dropdown-toggle:focus{
    box-shadow:0 0 0 0 transparent
}
.MuiButton-root.btn-slack:not(:disabled).active,.MuiButton-root.btn-slack:not(:disabled).active:hover,.MuiButton-root.btn-slack:not(:disabled):active{
    background:#349c76
}
.MuiButton-root.btn-slack:not(:disabled):not(.active):active{
    box-shadow:0 .22rem .525rem rgba(58,175,133,.4),0 .0625rem .385rem rgba(58,175,133,.54)
}
.MuiButton-root.btn-dribbble{
    box-shadow:0 .25rem .55rem rgba(234,76,137,.35)
}
.MuiButton-root.btn-dribbble,.MuiButton-root.btn-dribbble:hover{
    color:#fff;
    background-color:#ea4c89;
    border-color:#ea4c89
}
.MuiButton-root.btn-dribbble.focus,.MuiButton-root.btn-dribbble:focus{
    box-shadow:0 0 0 0 transparent
}
.MuiButton-root.btn-dribbble.disabled,.MuiButton-root.btn-dribbble:disabled{
    color:#fff;
    background-color:#ea4c89;
    border-color:#ea4c89
}
.MuiButton-root.btn-dribbble:not(:disabled):not(.disabled).active,.MuiButton-root.btn-dribbble:not(:disabled):not(.disabled):active,.show>.MuiButton-root.btn-dribbble.dropdown-toggle{
    color:#fff;
    background-color:#e51e6b;
    border-color:#dc1a65
}
.MuiButton-root.btn-dribbble:not(:disabled):not(.disabled).active:focus,.MuiButton-root.btn-dribbble:not(:disabled):not(.disabled):active:focus,.show>.MuiButton-root.btn-dribbble.dropdown-toggle:focus{
    box-shadow:0 0 0 0 transparent
}
.MuiButton-root.btn-dribbble:not(:disabled).active,.MuiButton-root.btn-dribbble:not(:disabled).active:hover,.MuiButton-root.btn-dribbble:not(:disabled):active{
    background:#e7357a
}
.MuiButton-root.btn-dribbble:not(:disabled):not(.active):active{
    box-shadow:0 .22rem .525rem rgba(234,76,137,.4),0 .0625rem .385rem rgba(234,76,137,.54)
}
.MuiButton-root.btn-github{
    box-shadow:0 .25rem .55rem rgba(34,34,34,.35)
}
.MuiButton-root.btn-github,.MuiButton-root.btn-github:hover{
    color:#fff;
    background-color:#222;
    border-color:#222
}
.MuiButton-root.btn-github.focus,.MuiButton-root.btn-github:focus{
    box-shadow:0 0 0 0 transparent
}
.MuiButton-root.btn-github.disabled,.MuiButton-root.btn-github:disabled{
    color:#fff;
    background-color:#222;
    border-color:#222
}
.MuiButton-root.btn-github:not(:disabled):not(.disabled).active,.MuiButton-root.btn-github:not(:disabled):not(.disabled):active,.show>.MuiButton-root.btn-github.dropdown-toggle{
    color:#fff;
    background-color:#090909;
    border-color:#020202
}
.MuiButton-root.btn-github:not(:disabled):not(.disabled).active:focus,.MuiButton-root.btn-github:not(:disabled):not(.disabled):active:focus,.show>.MuiButton-root.btn-github.dropdown-toggle:focus{
    box-shadow:0 0 0 0 transparent
}
.MuiButton-root.btn-github:not(:disabled).active,.MuiButton-root.btn-github:not(:disabled).active:hover,.MuiButton-root.btn-github:not(:disabled):active{
    background:#151515
}
.MuiButton-root.btn-github:not(:disabled):not(.active):active{
    box-shadow:0 .22rem .525rem rgba(34,34,34,.4),0 .0625rem .385rem rgba(34,34,34,.54)
}
.btn-link:hover{
    background:transparent!important
}
.btn-link span{
    position:relative;
    transition:all .2s ease-in-out
}
.btn-link span:before{
    content:"";
    position:absolute;
    width:100%;
    height:2px;
    bottom:0;
    left:0;
    visibility:hidden;
    transform:scaleX(0);
    transition:all .3s ease-in-out 0s
}
.btn-link:hover span:before{
    visibility:visible;
    transform:scaleX(1)
}
.MuiButton-root.btn-link{
    font-weight:400;
    color:#282828;
    text-decoration:none
}
.MuiButton-root.btn-link:hover{
    color:#00892c;
    text-decoration:none;
    transform:none
}
.MuiButton-root.btn-link:active{
    text-decoration:none;
    box-shadow:none
}
.MuiButton-root.btn-link.disabled,.MuiButton-root.btn-link:disabled{
    color:#d1d2db;
    pointer-events:none
}
body .MuiButton-root.btn-gradient{
    border:0;
    position:relative;
    color:#fff
}
body .MuiButton-root.btn-gradient:after{
    position:absolute;
    width:100%;
    height:100%;
    left:0;
    top:0;
    bottom:0;
    background:#fff;
    opacity:0;
    transition:all .2s ease-in-out;
    content:""
}
body .MuiButton-root.btn-gradient:active,body .MuiButton-root.btn-gradient:hover{
    color:#fff
}
body .MuiButton-root.btn-gradient:active:after,body .MuiButton-root.btn-gradient:hover:after{
    opacity:.15
}
body .MuiButton-root.btn-gradient-inverse{
    color:#070919
}
body .MuiButton-root.btn-gradient-inverse:active,body .MuiButton-root.btn-gradient-inverse:hover{
    color:#070919!important
}
.MuiButton-root.btn-transparent:hover{
    background-color:transparent
}
.btn-icon{
    display:inline-flex!important;
    align-items:center;
    justify-content:center;
    border:0
}
.btn-animated-icon,.btn-animated-icon-sm{
    transition:all .35s;
    overflow:hidden;
    position:relative
}
.btn-animated-icon-sm .btn-wrapper--icon,.btn-animated-icon .btn-wrapper--icon{
    transform:scale(1);
    transform-origin:center center;
    transition:transform .3s
}
.btn-animated-icon-sm .btn-wrapper--icon>*,.btn-animated-icon .btn-wrapper--icon>*{
    transition:none
}
.btn-animated-icon-sm:hover .btn-wrapper--icon,.btn-animated-icon:hover .btn-wrapper--icon{
    transform:scale(1.4)
}
.btn-animated-icon-sm:hover .btn-wrapper--icon{
    transform:scale(1.2)
}
.btn-input-select{
    border:2px solid #e6e7f1;
    border-radius:.65rem;
    transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    position:relative;
    display:inline-flex;
    flex-direction:column;
    text-align:center;
    align-items:center;
    justify-content:center;
    padding:1rem 2rem .5rem
}
.btn-input-select .selected-icon{
    opacity:0;
    border-radius:100%;
    visibility:hidden;
    width:28px;
    height:28px;
    display:flex;
    align-items:center;
    justify-content:center;
    transition:all .2s ease-in-out;
    background:#1bc943;
    color:#fff;
    position:absolute;
    right:-12px;
    top:-12px;
    border:2px solid #fff
}
.btn-input-select:hover{
    border-color:#a6a6b9;
    color:#070919!important;
    background:#f8f9ff
}
.btn-input-select.active{
    border-color:#1bc943;
    background:#fff
}
.btn-input-select.active:hover{
    background:#fff
}
.btn-input-select.active .selected-icon{
    opacity:1;
    visibility:visible
}
.btn-loading-wrapper{
    position:relative
}
.btn-loading-wrapper .MuiCircularProgress-root{
    position:absolute;
    top:50%;
    left:50%
}
.MuiButton-root.Mui-disabled{
    opacity:.3;
    color:transparent
}
body .MuiCard-root{
    border-radius:.75rem
}
body .MuiCard-root .card-title{
    font-size:1.15rem
}
body .MuiCard-root .MuiCardContent-root{
    padding:1.25rem
}
button.card{
    outline:none
}
.card-text{
    color:#6c401c
}
.card-box-alt{
    text-align:center
}
.card-box-alt .card-icon-wrapper{
    height:76px!important;
    line-height:58px!important;
    width:76px!important;
    margin:-44px auto 24px;
    border:6px solid #fff
}
.card-border-top{
    border-top-width:3px;
    border-top-style:solid
}
.card-badges{
    position:absolute;
    right:.75rem;
    top:.75rem;
    z-index:2
}
.card-badges>*{
    box-shadow:0 .46875rem 2.1875rem rgba(59,62,102,.03),0 .9375rem 1.40625rem rgba(59,62,102,.03),0 .25rem .53125rem rgba(59,62,102,.05),0 .125rem .1875rem rgba(59,62,102,.03)
}
.card-badges-bottom{
    top:auto;
    bottom:.75rem
}
.card-transparent{
    background:none!important;
    box-shadow:0 0 0 0 transparent!important
}
.card{
    box-shadow:0 .46875rem 2.1875rem rgba(59,62,102,.03),0 .9375rem 1.40625rem rgba(59,62,102,.03),0 .25rem .53125rem rgba(59,62,102,.05),0 .125rem .1875rem rgba(59,62,102,.03)
}
body .MuiCard-root.card-box{
    overflow:visible
}
.card-box{
    border:1px solid rgba(122,123,151,.3)
}
.card-box .card-footer,.card-box .card-header{
    border-width:1px;
    border-color:rgba(7,9,25,.125)
}
.card-box .card-header{
    display:flex;
    align-items:center
}
.card-box .card-header--title{
    flex-grow:1
}
.card-box .card-header--title>b{
    display:block
}
.card-box .card-header--title>small{
    text-transform:uppercase;
    display:block;
    opacity:.3;
    font-weight:700
}
.card-box.card-box-border-bottom{
    border-top-color:rgba(7,9,25,.125)!important;
    border-right-color:rgba(7,9,25,.125)!important;
    border-left-color:rgba(7,9,25,.125)!important;
    border-bottom-width:4px
}
.MuiCard-root>.bg-composed-wrapper{
    top:-1px;
    margin-left:-1px;
    margin-right:-1px;
    margin-bottom:-2px;
    z-index:6
}
.task-wrapper .task-item{
    position:relative;
    padding:0 0 2rem
}
.task-wrapper .task-item:last-child{
    padding-bottom:0
}
.align-box-row{
    display:flex;
    flex-direction:row;
    align-items:center
}
.chat-wrapper .chat-item .chat-box{
    position:relative;
    opacity:1;
    border:0;
    padding:.83333rem 2rem;
    border-radius:1.5rem;
    border-top-left-radius:.29rem;
    max-width:50%;
    min-width:100%;
    font-size:.875rem
}
.chat-wrapper .chat-item .chat-box p{
    margin-bottom:.33333rem
}
.chat-wrapper .chat-item .chat-box p:last-child{
    margin-bottom:0
}
.chat-wrapper .chat-item .avatar-icon-wrapper{
    margin-right:.5rem
}
.chat-wrapper .chat-item-reverse .chat-box{
    border-top-left-radius:1.5rem;
    border-top-right-radius:.29rem
}
.chat-wrapper .chat-item-reverse .avatar-icon-wrapper{
    margin-left:.5rem;
    margin-right:0
}
.file-manager-wrapper .file-manager-item{
    background:transparent
}
.card-indicator{
    position:absolute;
    height:60%;
    top:20%;
    width:6px;
    border-radius:.75rem;
    left:-4px
}
.card-tr-actions{
    position:absolute;
    right:1rem;
    top:1rem
}
.hover-show-hide-container .hover-show-wrapper{
    display:none
}
.hover-show-hide-container .hover-hide-wrapper,.hover-show-hide-container:hover .hover-show-wrapper{
    display:block
}
.hover-show-hide-container:hover .hover-hide-wrapper{
    display:none
}
.image-title-overlay{
    position:relative
}
.image-title-overlay--bottom,.image-title-overlay img{
    transition:all .2s ease-in-out
}
.image-title-overlay--bottom{
    background:linear-gradient(180deg,rgba(7,9,25,0) 0,rgba(7,9,25,.8));
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000",endColorstr="#cc000000",GradientType=0);
    z-index:3;
    position:absolute;
    bottom:0;
    width:100%;
    padding:2.5rem 1.25rem 1.25rem;
    border-bottom-right-radius:inherit;
    border-bottom-left-radius:inherit
}
.image-title-overlay:hover img{
    opacity:.95
}
.image-title-overlay:hover .image-title-overlay--bottom{
    padding:3.75rem 1.25rem 1.25rem
}
.card-box-hover-rise{
    transition:all .2s ease-in-out!important
}
.card-box-hover-rise:hover{
    transform:translateY(-15px)
}
.card-box-hover-rise.card-box-hover:hover,.card-box-hover-rise:hover{
    box-shadow:0 .56875rem 3.3875rem rgba(59,62,102,.05),0 .9975rem 2.40625rem rgba(59,62,102,.07),0 .35rem 1.23125rem rgba(59,62,102,.05),0 .225rem 1.1875rem rgba(59,62,102,.09)
}
.card-box-hover-rise.card-box-hover:hover{
    transform:translateY(0)
}
.card-box-hover-rise-alt{
    transition:all .2s ease-in-out!important
}
.card-box-hover-rise-alt:hover{
    box-shadow:0 5rem 14rem 0 hsla(0,0%,100%,.3),0 .8rem 2.3rem rgba(0,0,0,.6),0 .2rem .3rem rgba(0,0,0,.45);
    transform:translateY(-15px)
}
.card-box-hover-rise-alt.card-box-hover:hover{
    transform:translateY(0)
}
.card-box-hover-rise-alt-lg{
    transition:all .2s ease-in-out!important
}
.card-box-hover-rise-alt-lg:hover{
    box-shadow:0 5rem 14rem 0 hsla(0,0%,100%,.3),0 .8rem 2.3rem rgba(0,0,0,.6),0 .2rem .3rem rgba(0,0,0,.45);
    transform:translateY(-35px)
}
.card-box-hover-rise-alt-lg.card-box-hover:hover{
    transform:translateY(0)
}
.sparkline-full-wrapper{
    overflow:hidden;
    height:140px;
    position:relative
}
.sparkline-full-wrapper:last-child{
    margin:0 -1px -1px;
    border-bottom-right-radius:inherit;
    border-bottom-left-radius:inherit
}
.sparkline-full-wrapper--sm{
    height:100px
}
.sparkline-full-wrapper--lg{
    height:160px
}
.sparkline-full-wrapper--xl{
    height:221px
}
.sparkline-full-wrapper--xxl{
    height:337px
}
.card-chart-overlay{
    position:absolute;
    left:0;
    width:100%;
    bottom:0;
    right:0;
    opacity:.25;
    z-index:5
}
.card-content-overlay{
    position:relative;
    z-index:6
}
.card-body-avatar{
    position:relative;
    padding-top:calc(1.25rem + 50px)!important
}
.card-body-avatar>.avatar-icon-wrapper{
    margin:0;
    position:absolute;
    height:120px;
    width:120px;
    top:-60px;
    z-index:3
}
.card-body-avatar>.avatar-icon-wrapper .avatar-icon{
    height:100%;
    width:100%;
    box-shadow:0 .46875rem 2.1875rem rgba(59,62,102,.03),0 .9375rem 1.40625rem rgba(59,62,102,.03),0 .25rem .53125rem rgba(59,62,102,.05),0 .125rem .1875rem rgba(59,62,102,.03)
}
.card-body-avatar>.avatar-icon-wrapper img{
    max-width:100%;
    height:auto;
    width:100%
}
.card-body-avatar>.avatar-icon-wrapper--sm{
    height:80px;
    width:80px;
    top:-40px
}
.card-body-avatar.text-center>.avatar-icon-wrapper{
    left:50%;
    margin-left:-60px
}
.card-body-avatar.text-center>.avatar-icon-wrapper .avatar-icon{
    margin:0
}
.card-body-avatar.text-center>.avatar-icon-wrapper--sm{
    margin-left:-40px
}
.card-body-button{
    padding-top:50px!important;
    position:relative;
    z-index:7
}
.card-body-button-wrapper{
    position:relative;
    z-index:4
}
.card-body-button-wrapper .MuiButton-sizeLarge{
    margin-top:-100px
}
.btn-swatch{
    width:32px;
    height:32px;
    margin:.5rem;
    border-radius:.29rem;
    display:inline-block;
    opacity:.7;
    z-index:10;
    position:relative;
    transition:all .2s ease-in-out;
    cursor:pointer;
    border:1px solid #fff;
    transform-origin:center
}
.btn-swatch.active,.btn-swatch:hover{
    opacity:1;
    transform:scale(1.3);
    z-index:15;
    box-shadow:0 0 0 2px #00892c
}
.btn-swatch--lg{
    width:38px;
    height:38px;
    border:2px solid #fff;
    box-shadow:0 0 0 1px #a6a6b9
}
.card-img-wrapper{
    position:relative
}
.card-img-wrapper,.card-img-wrapper .img-wrapper-overlay{
    border-top-left-radius:inherit;
    border-top-right-radius:inherit
}
.card-img-wrapper .img-wrapper-overlay{
    width:100%;
    height:100%;
    transition:all .2s ease-in-out;
    opacity:0;
    visibility:hidden;
    position:absolute;
    left:0;
    top:0;
    box-shadow:inset 0 0 2.3rem .5rem #070919;
    display:flex;
    align-items:center;
    align-content:center;
    color:#fff
}
@media (max-width:767.98px){
    .card-img-wrapper .img-wrapper-overlay+img{
        width:100%!important
    }
}
.card-img-wrapper .img-wrapper-overlay .overlay-btn-wrapper{
    position:relative;
    z-index:3;
    text-align:center;
    width:100%
}
.card-img-wrapper .img-wrapper-overlay:before{
    z-index:2;
    position:absolute;
    left:0;
    top:0;
    content:"";
    width:100%;
    height:100%;
    transition:all .2s ease-in-out;
    background:#070919;
    opacity:.75
}
.card-img-wrapper .img-wrapper-overlay--visible{
    visibility:visible;
    opacity:1;
    position:relative;
    z-index:5
}
.card-img-wrapper .card-badges{
    z-index:5
}
.card-overlay-image{
    position:absolute;
    left:0;
    top:0
}
a.card-img-wrapper{
    display:block;
    overflow:hidden
}
a.card-img-wrapper:hover .img-wrapper-overlay{
    opacity:1;
    visibility:visible
}
.MuiMenu-paper .bg-composed-wrapper{
    margin:-.5rem -1px -2px;
    z-index:5;
    top:-1px
}
.MuiMenu-paper .scroll-area{
    height:10rem
}
.dropdown-menu-lg{
    width:15rem
}
.dropdown-menu-xl{
    width:21rem
}
.dropdown-menu-xxl{
    width:30rem
}
.dropdown-toggle:after{
    position:relative;
    top:2px
}
.feature-box-img{
    position:relative;
    border:5px solid #070919;
    background:#fff;
    border-radius:.75rem
}
@media (min-width:1381px){
    .feature-box-img{
        margin-left:-5rem
    }
}
@media (min-width:1381px){
    .img-outer-bounds-sm{
        position:relative;
        left:-50px;
        transform:scale(1.6);
        width:100%
    }
    .img-outer-bounds-sm.text-right{
        left:auto;
        right:-50px
    }
}
.icon-blob{
    position:relative
}
.icon-blob .blob-icon-wrapper,.icon-blob .blob-wrapper{
    width:inherit;
    height:inherit;
    position:absolute;
    left:0;
    top:0
}
.icon-blob .blob-icon-wrapper{
    font-size:1.2rem
}
.icon-blob .blob-icon-wrapper svg{
    height:25%;
    width:25%
}
.nav-logo a{
    font-weight:700;
    display:flex;
    align-items:center;
    font-size:1.04545rem
}
.nav-logo a span{
    opacity:1;
    visibility:visible;
    transition:all .2s ease-in-out
}
.nav-logo a i{
    height:40px;
    width:40px;
    background:hsla(0,0%,100%,.5);
    display:flex;
    align-items:center;
    border-radius:.65rem;
    margin:0 1.2rem 0 0;
    position:relative;
    left:0;
    transition:all .2s ease-in-out
}
.nav-logo a i img{
    width:28px;
    margin:0 auto
}
.MuiInputBase-root:not(.MuiInput-underline){
    transition:all .2s ease-in-out
}
.MuiInputBase-root:not(.MuiInput-underline).Mui-focused{
    box-shadow:0 .313rem .719rem rgba(122,123,151,.1),0 .156rem .125rem rgba(122,123,151,.06)
}
body .MuiTextField-root.text-white-50 .MuiInputBase-root,body .MuiTextField-root.text-white-50 .MuiInputLabel-root{
    color:hsla(0,0%,100%,.8)
}
body .MuiTextField-root.text-white-50 .MuiOutlinedInput-notchedOutline,body .MuiTextField-root.text-white-50 .MuiOutlinedInput-root{
    border-color:hsla(0,0%,100%,.5)
}
body .MuiTextField-root.text-white-50 .MuiOutlinedInput-notchedOutline:hover,body .MuiTextField-root.text-white-50 .MuiOutlinedInput-root:hover{
    border-color:hsla(0,0%,100%,.8)
}
body .MuiChip-root{
    background:#00892c;
    color:#fff
}
body .MuiChip-root .MuiChip-deleteIcon{
    color:#fff
}
body .MuiChip-root.Mui-disabled{
    opacity:.65
}
.MuiStepper-root.MuiStepper-alternativeLabel.stepper-horizontal-1{
    display:flex;
    align-items:center;
    justify-content:center;
    background:transparent
}
.MuiStepper-root.MuiStepper-alternativeLabel.stepper-horizontal-1 .MuiStepConnector-alternativeLabel{
    top:50%;
    margin-top:-18px
}
.MuiStepper-root.MuiStepper-alternativeLabel.stepper-horizontal-1 .MuiStepLabel-alternativeLabel{
    font-size:1rem;
    color:#6c401c
}
.MuiStepper-root.MuiStepper-alternativeLabel .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel{
    z-index:5
}
.react-joyride__overlay{
    z-index:1200!important;
    display:none!important
}
.react-joyride__overlay .react-joyride__spotlight{
    z-index:1300!important;
    display:none!important
}
.react-joyride__beacon{
    outline:none!important
}
.__floater{
    z-index:1400!important
}
.__floater__body button{
    border-radius:.65rem!important;
    outline:none!important
}
.react-joyride__tooltip{
    box-shadow:0 .18rem .3rem rgba(166,166,185,.5),0 .326rem 3rem rgba(122,123,151,.3)!important;
    border-radius:.75rem!important
}
.react-joyride__tooltip h4{
    color:#070919!important;
    font-weight:700!important;
    font-size:1.15rem!important
}
.react-joyride__tooltip div button{
    background:#00892c!important;
    color:#fff!important
}
.joyride-button{
    border-radius:.29rem!important
}
.joyride-step__container{
    border-radius:.65rem!important;
    box-shadow:0 5rem 14rem 0 hsla(0,0%,100%,.3),0 .8rem 2.3rem rgba(0,0,0,.6),0 .2rem .3rem rgba(0,0,0,.45)!important
}
.header-nav-wrapper{
    min-height:54px;
    position:relative
}
.header-nav-wrapper,.header-nav-wrapper>.container{
    align-items:center;
    display:flex
}
.header-nav-wrapper .header-nav-actions{
    flex:1 1;
    display:flex;
    justify-content:flex-end
}
.header-nav-wrapper.header-nav-wrapper-sm{
    min-height:64px
}
.header-nav-wrapper.header-nav-wrapper-lg{
    min-height:84px
}
.header-nav-wrapper.header-nav-wrapper-xl{
    min-height:94px
}
.header-nav-wrapper .nav-collapsed-wrapper{
    position:absolute;
    left:5%;
    top:35px;
    width:90%;
    background:#fff;
    z-index:25;
    border-radius:.65rem;
    box-shadow:0 .5rem 1rem rgba(7,9,25,.55)
}
.navbar-dark ul>li>a{
    color:hsla(0,0%,100%,.7)
}
.navbar-dark ul>li:hover>a,.navbar-dark ul>li>a.active,.navbar-dark ul>li>a:hover{
    color:#fff;
    background:hsla(0,0%,100%,.15)
}
.navbar-dark .hamburger .hamburger-inner,.navbar-dark .hamburger .hamburger-inner:after,.navbar-dark .hamburger .hamburger-inner:before,.navbar-dark .hamburger.is-active .hamburger-inner,.navbar-dark .hamburger.is-active .hamburger-inner:after,.navbar-dark .hamburger.is-active .hamburger-inner:before{
    background-color:#fff
}
.navbar-light ul>li>a{
    color:rgba(7,9,25,.7)
}
.navbar-light ul>li:hover>a,.navbar-light ul>li>a.active,.navbar-light ul>li>a:hover{
    color:#070919;
    background:rgba(60,68,177,.06)
}
.navbar-light .hamburger .hamburger-inner,.navbar-light .hamburger .hamburger-inner:after,.navbar-light .hamburger .hamburger-inner:before{
    background-color:rgba(7,9,25,.8)
}
.navbar-light .hamburger.is-active .hamburger-inner,.navbar-light .hamburger.is-active .hamburger-inner:after,.navbar-light .hamburger.is-active .hamburger-inner:before{
    background-color:#070919
}
.app-nav-logo{
    flex:1 1;
    display:flex;
    align-items:center;
    transition:all .5s cubic-bezier(.685,.0473,.346,1)
}
.app-nav-logo--icon{
    background:#fff;
    display:flex;
    align-items:center;
    align-content:center;
}
.app-nav-logo--icon img{
   /* width:206px;
    margin:0 auto;*/
    /*-webkit-animation:logo-spin 20s linear infinite;
    animation:logo-spin 20s linear infinite*/
}
.app-nav-logo--text{
    line-height:1;
    padding:0 0 0 .66667rem;
    transition:all .5s cubic-bezier(.685,.0473,.346,1);
    color:#fff
}
.app-nav-logo--text span{
    display:block;
    text-transform:uppercase;
    font-size:.72917rem;
    opacity:.7;
    padding:4px 0
}
.app-nav-logo--text b{
    font-size:1.265rem;
    font-weight:700;
    text-transform:lowercase
}
.app-nav-logo:hover{
    color:#070919
}
.app-nav-logo--dark .app-nav-logo--text{
    color:#070919
}
.header-nav-menu{
    flex:2 1;
    display:flex
}
.header-nav-menu>ul{
    list-style:none;
    margin:0;
    padding:0
}
.header-nav-menu>ul>li{
    display:inline-flex;
    margin:0 4px;
    position:relative
}
.header-nav-menu>ul>li .dropdown-arrow{
    transform:rotate(0deg);
    transition:all .2s ease-in-out;
    margin-left:8px;
    height:16px;
    line-height:16px;
    width:10px
}
.header-nav-menu>ul>li .submenu-dropdown{
    position:absolute;
    top:100%;
    left:50%;
    width:280px;
    margin-left:-140px;
    visibility:hidden;
    opacity:0;
    padding-top:15px;
    transition:all .2s ease-in-out;
    z-index:20;
    transform:translateX(-18px) translateY(-18px) scale(.75)
}
.header-nav-menu>ul>li .submenu-dropdown--sm{
    width:200px;
    margin-left:-100px
}
.header-nav-menu>ul>li .submenu-dropdown--lg{
    width:420px;
    margin-left:-210px
}
.header-nav-menu>ul>li .submenu-dropdown--xl{
    width:640px;
    margin-left:-320px
}
.header-nav-menu>ul>li .submenu-dropdown--xxl{
    width:940px;
    margin-left:-470px
}
.header-nav-menu>ul>li>a{
    transition:all .2s ease-in-out;
    padding:.66667rem 1.5rem;
    display:flex;
    align-items:center
}
.header-nav-menu>ul>li:hover>a>.dropdown-arrow{
    transform:rotate(-180deg)
}
.header-nav-menu>ul>li:hover>.submenu-dropdown{
    opacity:1;
    transform:translateX(0) translateY(0) scale(1);
    visibility:visible
}
.MuiButton-root.nav-toggle-inner-btn{
    position:absolute;
    right:-15px;
    top:-15px;
    opacity:0;
    visibility:hidden
}
.collapse-page-trigger{
    position:fixed;
    left:0;
    top:0;
    right:0;
    bottom:0;
    background:transparent;
    width:100%;
    height:100%;
    z-index:25;
    transition:all .2s ease-in-out;
    opacity:0;
    visibility:hidden
}
.collapse-page-trigger.is-active{
    opacity:1;
    visibility:visible
}
.nav-collapsed-wrapper.MuiCollapse-entered .nav-toggle-inner-btn{
    opacity:1;
    visibility:visible;
    -webkit-animation-delay:2s;
    animation-delay:2s
}
.hero-wrapper{
    position:relative;
    display:flex;
    flex-direction:column
}
.hero-wrapper .header-top-section{
    z-index:28;
    width:100%;
    position:fixed
}
.hero-wrapper .hero-footer,.hero-wrapper>.divider{
    z-index:7
}
.hero-wrapper .header-nav-wrapper{
    z-index:27
}
.hero-wrapper>.divider{
    width:100%
}
.hero-wrapper--content{
    flex-grow:1;
    display:flex;
    z-index:6;
    width:100%;
    align-items:center
}
.hero-wrapper .bg-composed-wrapper--image{
    background-repeat:no-repeat;
    background-size:cover;
    background-position:50%
}
.hero-wrapper .hero-indicator{
    position:absolute;
    right:0;
    width:40px;
    text-align:center;
    left:50%;
    margin-left:-20px;
    bottom:2rem
}
.shape-container-top-1{
    margin-top:-150px;
    z-index:6;
    position:relative
}
.shape-container-top-2{
    margin-top:-100px;
    z-index:6;
    position:relative
}
.shape-container-bottom-1{
    margin-bottom:-150px;
    z-index:6;
    position:relative
}
.shape-container-bottom-2{
    margin-bottom:-100px;
    z-index:6;
    position:relative
}
.shadow-container-blocks-1{
    margin-top:-300px
}
@media (max-width:1380.98px){
    .shadow-container-blocks-1{
        margin-top:-170px
    }
}
@media (max-width:1099.98px){
    .shadow-container-blocks-1{
        margin-top:-145px
    }
}
.shadow-container-blocks-2{
    margin-top:-350px
}
@media (max-width:1380.98px){
    .shadow-container-blocks-2{
        margin-top:-250px
    }
}
.shadow-container-blocks-3{
    margin-top:-300px
}
@media (max-width:1099.98px){
    .shadow-container-blocks-3{
        margin-top:-160px
    }
}
.shadow-container-blocks-4{
    margin-top:-50px
}
@media (max-width:1099.98px){
    .shadow-container-blocks-4{
        margin-top:-150px
    }
}
.shadow-container-content-5{
    padding-bottom:165px
}
@media (max-width:1380.98px){
    .shadow-container-content-5{
        padding-bottom:120px
    }
}
.shadow-container-blocks-5{
    position:absolute;
    left:0;
    right:0;
    bottom:0
}
.nav-icon-wrapper{
    width:32px;
    text-align:center
}
.MuiListItem-root.MuiListItem-button .MuiTouchRipple-root{
    opacity:.5
}
body .MuiListItem-root{
    width:auto
}
body .MuiList-root.nav-transparent .MuiListItem-button .MuiTouchRipple-root{
    opacity:0!important
}
body .MuiList-root.nav-transparent .MuiListItem-button.text-white-50 .MuiTouchRipple-root{
    opacity:.2!important
}
body .MuiList-root.nav-transparent .MuiListItem-button.text-white-50.Mui-selected,body .MuiList-root.nav-transparent .MuiListItem-button.text-white-50:hover{
    color:#fff!important;
    background:hsla(0,0%,100%,.05)
}
body .list-group-flush{
    padding-top:0;
    padding-bottom:0
}
body .list-group-flush .MuiListItem-root{
    padding:.75rem 1.25rem;
    background-color:#fff;
    border-radius:0;
    margin:0;
    border:solid rgba(7,9,25,.125);
    border-width:0 0 1px
}
body .list-group-flush .MuiListItem-root.MuiListItem-button{
    border-radius:0;
    border-width:0 0 1px
}
body .list-group-flush .MuiListItem-root.MuiListItem-button .MuiTouchRipple-root{
    opacity:.2
}
body .list-group-flush .MuiListItem-root.list-group-item-action{
    transition:all .2s ease-in-out
}
body .list-group-flush .MuiListItem-root.list-group-item-action:hover,body .list-group-flush .MuiListItem-root.MuiListItem-button:hover{
    background:#f4f5fd;
    z-index:1
}
body .list-group-flush .MuiListItem-root.list-group-item-action:active,body .list-group-flush .MuiListItem-root.MuiListItem-button:active{
    color:#000;
    background-color:#eeeff8
}
body .list-group-flush .MuiListItem-root:first-child{
    border-top:0!important
}
body .list-group-flush .MuiListItem-root:last-child{
    border-bottom:0!important
}
body .list-group-bordered .MuiListItem-root{
    border-bottom:1px solid rgba(7,9,25,.125);
    border-left:1px solid rgba(7,9,25,.125);
    border-right:1px solid rgba(7,9,25,.125);
    margin:0
}
body .list-group-bordered .MuiListItem-root:first-child{
    border-top:1px solid rgba(7,9,25,.125);
    border-top-left-radius:.65rem;
    border-top-right-radius:.65rem
}
body .list-group-bordered .MuiListItem-root:last-child,body .MuiCard-root>.MuiList-root:last-child .MuiListItem-root:last-child{
    border-bottom-right-radius:.65rem;
    border-bottom-left-radius:.65rem
}
body .modal-content{
    box-shadow:0 5rem 14rem 0 hsla(0,0%,100%,.3),0 .8rem 2.3rem rgba(0,0,0,.6),0 .2rem .3rem rgba(0,0,0,.45)
}
.MuiDialog-paper .MuiDialogTitle-root{
    border-bottom:1px solid #e6e7f1
}
.MuiDialog-paper .MuiDialogTitle-root>.MuiTypography-root{
    font-weight:700;
    font-size:1.15rem
}
.MuiDialog-paper .MuiDialogActions-root{
    border-top:1px solid #e6e7f1
}
.MuiDialog-paper.modal-dark,.MuiDialog-paper.modal-dark .MuiDialogContentText-root{
    color:#fff
}
.MuiDialog-paper.modal-dark .MuiDialogActions-root,.MuiDialog-paper.modal-dark .MuiDialogTitle-root{
    border-color:hsla(0,0%,100%,.1)
}
.grid-menu .MuiGrid-item{
    border-right:1px solid #eeeff8;
    border-bottom:1px solid #eeeff8;
    position:relative
}
@media (max-width:1380.98px){
    .grid-menu .MuiGrid-item:nth-last-child(-n+2){
        border-bottom-width:1px
    }
    .grid-menu .MuiGrid-item:nth-child(1n){
        border-right-width:1px
    }
    .grid-menu .MuiGrid-item:nth-child(2n){
        border-right-width:0
    }
}
.grid-menu .MuiGrid-item:nth-last-child(-n+1){
    border-bottom-width:0
}
@media (min-width:1100px){
    .grid-menu.grid-menu-3col .MuiGrid-item:nth-last-child(-n+3){
        border-bottom-width:0
    }
    .grid-menu.grid-menu-3col .MuiGrid-item:nth-child(2n){
        border-right-width:1px
    }
    .grid-menu.grid-menu-3col .MuiGrid-item:nth-child(3n){
        border-right-width:0
    }
}
.grid-menu>.btn{
    display:block;
    border:0;
    min-width:100%
}
.divider-v{
    position:absolute;
    top:0;
    height:100%;
    min-height:26px;
    right:0;
    width:1px;
    background:#eeeff8;
    overflow:hidden
}
@media (max-width:1380.98px){
    .divider-v.divider-v-lg{
        top:auto;
        bottom:0;
        right:auto;
        left:0;
        width:100%;
        height:1px;
        min-height:1px
    }
}
@media (max-width:1099.98px){
    .divider-v.divider-v-md{
        top:auto;
        bottom:0;
        width:100%;
        height:1px;
        min-height:1px
    }
}
@media (max-width:767.98px){
    .divider-v.divider-v-md{
        display:none
    }
}
.MuiList-root .MuiListItem-root{
    margin:2px 0
}
.MuiList-root .MuiListItem-root.MuiListItem-button{
    border-radius:.29rem;
    transition:all .2s ease-in-out
}
.MuiList-root.nav-tabs{
    padding:0 .66667rem;
    border-bottom:1px solid #dfe0ea
}
.MuiList-root.nav-tabs .MuiListItem-root.MuiListItem-button{
    padding:.66667rem 1.5rem;
    margin:2px .66667rem 0 0;
    border:1px solid transparent;
    border-bottom-right-radius:0;
    border-bottom-left-radius:0;
    font-size:.875rem;
    text-transform:uppercase;
    color:#a6a6b9;
    background:transparent;
    transition:all .2s ease-in-out;
    cursor:pointer;
    width:auto
}
.MuiList-root.nav-tabs .MuiListItem-root.MuiListItem-button:hover{
    color:#070919;
    background:transparent;
    border-color:#dfe0ea #dfe0ea transparent
}
.MuiList-root.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected{
    margin-top:0;
    margin-bottom:-1px;
    color:#00892c;
    background:#fff;
    border-color:#dfe0ea #dfe0ea #fff;
    border-top:3px solid #00892c
}
.MuiList-root.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:hover{
    color:#00892c
}
.MuiList-root.nav-line .MuiListItem-root.MuiListItem-button{
    color:#a6a6b9;
    position:relative;
    transition:all .2s ease-in-out;
    padding:0 1rem 1rem;
    background:transparent;
    width:auto
}
.MuiList-root.nav-line .MuiListItem-root.MuiListItem-button .divider{
    width:34px;
    border-radius:.75rem;
    height:3px;
    background:#00892c;
    visibility:hidden;
    opacity:0;
    transform:translateY(-10px);
    transition:all .2s ease-in-out;
    position:absolute;
    left:1rem;
    bottom:0
}
.MuiList-root.nav-line .MuiListItem-root.MuiListItem-button:hover{
    color:#070919;
    background:transparent
}
.MuiList-root.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected{
    color:#00892c;
    background:transparent
}
.MuiList-root.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected:hover{
    color:#00892c
}
.MuiList-root.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected .divider{
    visibility:visible;
    opacity:1;
    transform:translateY(0)
}
.MuiList-root.nav-line.nav-line-alt{
    position:relative;
    z-index:4;
    padding:0
}
.MuiList-root.nav-line.nav-line-alt:after{
    background:#f4f5fd;
    height:3px;
    border-radius:.75rem;
    display:block;
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    content:"";
    z-index:5
}
.MuiList-root.nav-line.nav-line-alt .MuiListItem-root.MuiListItem-button+.MuiListItem-root.MuiListItem-button{
    margin-left:2rem
}
.MuiList-root.nav-line.nav-line-alt .MuiListItem-root.MuiListItem-button{
    padding:0 0 1rem;
    margin:0
}
.MuiList-root.nav-line.nav-line-alt .MuiListItem-root.MuiListItem-button .divider{
    z-index:8;
    left:0;
    width:100%
}
.MuiList-root.nav-fill .MuiListItem-root{
    flex:1 1 auto;
    text-align:center
}
.MuiList-root.nav-justified .MuiListItem-root.MuiListItem-button{
    flex-basis:0;
    flex-grow:1;
    text-align:center;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-left:.375rem;
    margin-right:.375rem
}
.MuiList-root.tabs-animated.nav-tabs{
    border:0;
    padding:0
}
.MuiList-root.tabs-animated.nav-tabs .MuiListItem-root.MuiListItem-button{
    border:0!important;
    position:relative;
    padding:.75rem 1.5rem;
    margin:0 .75rem 0 0;
    color:#282828;
    text-transform:none;
    font-size:1rem;
    cursor:pointer;
    width:auto
}
.MuiList-root.tabs-animated.nav-tabs .MuiListItem-root.MuiListItem-button:before{
    transform:scale(0);
    opacity:1;
    width:100%;
    left:0;
    bottom:-2px;
    content:"";
    position:absolute;
    display:block;
    border-radius:.75rem;
    background:#00892c;
    transition:all .2s;
    height:4px
}
.MuiList-root.tabs-animated.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.tabs-animated.nav-tabs .MuiListItem-root.MuiListItem-button:hover{
    color:#00892c;
    border:0
}
.MuiList-root.tabs-animated.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:before,.MuiList-root.tabs-animated.nav-tabs .MuiListItem-root.MuiListItem-button:hover:before{
    transform:scale(1)
}
.MuiList-root.tabs-animated.nav-tabs .MuiListItem-root.MuiListItem-button:last-child{
    margin-right:0
}
.MuiList-root.tabs-animated-shadow.nav-tabs{
    position:relative;
    z-index:9
}
.MuiList-root.tabs-animated-shadow.nav-tabs .MuiListItem-root.MuiListItem-button{
    padding:.75rem 1.5rem;
    margin-bottom:.75rem;
    cursor:pointer
}
.MuiList-root.tabs-animated-shadow.nav-tabs .MuiListItem-root.MuiListItem-button>span:not(.MuiTouchRipple-root){
    position:relative;
    z-index:5;
    display:inline-block;
    width:100%
}
.MuiList-root.tabs-animated-shadow.nav-tabs .MuiListItem-root.MuiListItem-button:before{
    height:100%;
    top:0;
    z-index:4;
    bottom:auto;
    box-shadow:0 16px 26px -10px rgba(60,68,177,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(60,68,177,.2);
    border-radius:.13333rem;
    opacity:.5
}
.MuiList-root.tabs-animated-shadow.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.tabs-animated-shadow.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected>span:not(.MuiTouchRipple-root),.MuiList-root.tabs-animated-shadow.nav-tabs .MuiListItem-root.MuiListItem-button:hover,.MuiList-root.tabs-animated-shadow.nav-tabs .MuiListItem-root.MuiListItem-button:hover>span:not(.MuiTouchRipple-root){
    color:#fff!important
}
.MuiList-root.tabs-animated-shadow.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:before,.MuiList-root.tabs-animated-shadow.nav-tabs .MuiListItem-root.MuiListItem-button:hover:before{
    border-radius:.29rem;
    opacity:1
}
.MuiList-root.tabs-animated-shadow.nav-tabs.tabs-bordered .MuiListItem-root.MuiListItem-button{
    margin:.75rem 0 .75rem .75rem
}
.MuiList-root.nav-tabs-primary.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected{
    color:#00892c;
    border-top:3px solid #00892c
}
.MuiList-root.nav-tabs-primary.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:hover,.MuiList-root.nav-tabs-primary.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-primary.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover{
    color:#00892c
}
.MuiList-root.nav-tabs-primary.nav-line .MuiListItem-root.MuiListItem-button .divider,.MuiList-root.nav-tabs-primary.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:before,.MuiList-root.nav-tabs-primary.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover:before{
    background:#00892c
}
.MuiList-root.nav-tabs-primary.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-primary.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected:hover{
    color:#00892c
}
.MuiList-root.nav-tabs-primary.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button{
    color:#282828
}
.MuiList-root.nav-tabs-primary.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:before{
    background:#00892c!important;
    box-shadow:0 16px 26px -10px rgba(60,68,177,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(60,68,177,.2)
}
.MuiList-root.nav-tabs-primary.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-primary.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button.Mui-selected>span:not(.MuiTouchRipple-root),.MuiList-root.nav-tabs-primary.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:hover,.MuiList-root.nav-tabs-primary.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:hover>span:not(.MuiTouchRipple-root){
    color:#fff!important
}
.MuiList-root.nav-tabs-secondary.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected{
    color:#f8f9ff;
    border-top:3px solid #f8f9ff
}
.MuiList-root.nav-tabs-secondary.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:hover,.MuiList-root.nav-tabs-secondary.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-secondary.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover{
    color:#f8f9ff
}
.MuiList-root.nav-tabs-secondary.nav-line .MuiListItem-root.MuiListItem-button .divider,.MuiList-root.nav-tabs-secondary.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:before,.MuiList-root.nav-tabs-secondary.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover:before{
    background:#f8f9ff
}
.MuiList-root.nav-tabs-secondary.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-secondary.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected:hover{
    color:#f8f9ff
}
.MuiList-root.nav-tabs-secondary.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button{
    color:#282828
}
.MuiList-root.nav-tabs-secondary.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:before{
    background:#f8f9ff!important;
    box-shadow:0 16px 26px -10px rgba(248,249,255,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(248,249,255,.2)
}
.MuiList-root.nav-tabs-secondary.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-secondary.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button.Mui-selected>span:not(.MuiTouchRipple-root),.MuiList-root.nav-tabs-secondary.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:hover,.MuiList-root.nav-tabs-secondary.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:hover>span:not(.MuiTouchRipple-root){
    color:#282828!important
}
.MuiList-root.nav-tabs-success.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected{
    color:#1bc943;
    border-top:3px solid #1bc943
}
.MuiList-root.nav-tabs-success.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:hover,.MuiList-root.nav-tabs-success.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-success.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover{
    color:#1bc943
}
.MuiList-root.nav-tabs-success.nav-line .MuiListItem-root.MuiListItem-button .divider,.MuiList-root.nav-tabs-success.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:before,.MuiList-root.nav-tabs-success.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover:before{
    background:#1bc943
}
.MuiList-root.nav-tabs-success.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-success.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected:hover{
    color:#1bc943
}
.MuiList-root.nav-tabs-success.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button{
    color:#282828
}
.MuiList-root.nav-tabs-success.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:before{
    background:#1bc943!important;
    box-shadow:0 16px 26px -10px rgba(27,201,67,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(27,201,67,.2)
}
.MuiList-root.nav-tabs-success.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-success.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button.Mui-selected>span:not(.MuiTouchRipple-root),.MuiList-root.nav-tabs-success.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:hover,.MuiList-root.nav-tabs-success.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:hover>span:not(.MuiTouchRipple-root){
    color:#fff!important
}
.MuiList-root.nav-tabs-info.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected{
    color:#11c5db;
    border-top:3px solid #11c5db
}
.MuiList-root.nav-tabs-info.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:hover,.MuiList-root.nav-tabs-info.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-info.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover{
    color:#11c5db
}
.MuiList-root.nav-tabs-info.nav-line .MuiListItem-root.MuiListItem-button .divider,.MuiList-root.nav-tabs-info.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:before,.MuiList-root.nav-tabs-info.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover:before{
    background:#11c5db
}
.MuiList-root.nav-tabs-info.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-info.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected:hover{
    color:#11c5db
}
.MuiList-root.nav-tabs-info.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button{
    color:#282828
}
.MuiList-root.nav-tabs-info.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:before{
    background:#11c5db!important;
    box-shadow:0 16px 26px -10px rgba(17,197,219,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(17,197,219,.2)
}
.MuiList-root.nav-tabs-info.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-info.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button.Mui-selected>span:not(.MuiTouchRipple-root),.MuiList-root.nav-tabs-info.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:hover,.MuiList-root.nav-tabs-info.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:hover>span:not(.MuiTouchRipple-root){
    color:#fff!important
}
.MuiList-root.nav-tabs-warning.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected{
    color:#f4772e;
    border-top:3px solid #f4772e
}
.MuiList-root.nav-tabs-warning.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:hover,.MuiList-root.nav-tabs-warning.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-warning.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover{
    color:#f4772e
}
.MuiList-root.nav-tabs-warning.nav-line .MuiListItem-root.MuiListItem-button .divider,.MuiList-root.nav-tabs-warning.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:before,.MuiList-root.nav-tabs-warning.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover:before{
    background:#f4772e
}
.MuiList-root.nav-tabs-warning.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-warning.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected:hover{
    color:#f4772e
}
.MuiList-root.nav-tabs-warning.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button{
    color:#282828
}
.MuiList-root.nav-tabs-warning.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:before{
    background:#f4772e!important;
    box-shadow:0 16px 26px -10px rgba(244,119,46,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(244,119,46,.2)
}
.MuiList-root.nav-tabs-warning.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-warning.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button.Mui-selected>span:not(.MuiTouchRipple-root),.MuiList-root.nav-tabs-warning.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:hover,.MuiList-root.nav-tabs-warning.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:hover>span:not(.MuiTouchRipple-root){
    color:#fff!important
}
.MuiList-root.nav-tabs-danger.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected{
    color:#f83245;
    border-top:3px solid #f83245
}
.MuiList-root.nav-tabs-danger.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:hover,.MuiList-root.nav-tabs-danger.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-danger.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover{
    color:#f83245
}
.MuiList-root.nav-tabs-danger.nav-line .MuiListItem-root.MuiListItem-button .divider,.MuiList-root.nav-tabs-danger.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:before,.MuiList-root.nav-tabs-danger.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover:before{
    background:#f83245
}
.MuiList-root.nav-tabs-danger.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-danger.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected:hover{
    color:#f83245
}
.MuiList-root.nav-tabs-danger.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button{
    color:#282828
}
.MuiList-root.nav-tabs-danger.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:before{
    background:#f83245!important;
    box-shadow:0 16px 26px -10px rgba(248,50,69,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(248,50,69,.2)
}
.MuiList-root.nav-tabs-danger.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-danger.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button.Mui-selected>span:not(.MuiTouchRipple-root),.MuiList-root.nav-tabs-danger.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:hover,.MuiList-root.nav-tabs-danger.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:hover>span:not(.MuiTouchRipple-root){
    color:#fff!important
}
.MuiList-root.nav-tabs-light.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected{
    color:#f4f5fd;
    border-top:3px solid #f4f5fd
}
.MuiList-root.nav-tabs-light.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:hover,.MuiList-root.nav-tabs-light.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-light.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover{
    color:#f4f5fd
}
.MuiList-root.nav-tabs-light.nav-line .MuiListItem-root.MuiListItem-button .divider,.MuiList-root.nav-tabs-light.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:before,.MuiList-root.nav-tabs-light.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover:before{
    background:#f4f5fd
}
.MuiList-root.nav-tabs-light.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-light.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected:hover{
    color:#f4f5fd
}
.MuiList-root.nav-tabs-light.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button{
    color:#282828
}
.MuiList-root.nav-tabs-light.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:before{
    background:#f4f5fd!important;
    box-shadow:0 16px 26px -10px rgba(244,245,253,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(244,245,253,.2)
}
.MuiList-root.nav-tabs-light.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-light.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button.Mui-selected>span:not(.MuiTouchRipple-root),.MuiList-root.nav-tabs-light.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:hover,.MuiList-root.nav-tabs-light.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:hover>span:not(.MuiTouchRipple-root){
    color:#282828!important
}
.MuiList-root.nav-tabs-gray.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected{
    color:#f8f9ff;
    border-top:3px solid #f8f9ff
}
.MuiList-root.nav-tabs-gray.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:hover,.MuiList-root.nav-tabs-gray.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-gray.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover{
    color:#f8f9ff
}
.MuiList-root.nav-tabs-gray.nav-line .MuiListItem-root.MuiListItem-button .divider,.MuiList-root.nav-tabs-gray.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:before,.MuiList-root.nav-tabs-gray.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover:before{
    background:#f8f9ff
}
.MuiList-root.nav-tabs-gray.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-gray.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected:hover{
    color:#f8f9ff
}
.MuiList-root.nav-tabs-gray.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button{
    color:#282828
}
.MuiList-root.nav-tabs-gray.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:before{
    background:#f8f9ff!important;
    box-shadow:0 16px 26px -10px rgba(248,249,255,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(248,249,255,.2)
}
.MuiList-root.nav-tabs-gray.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-gray.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button.Mui-selected>span:not(.MuiTouchRipple-root),.MuiList-root.nav-tabs-gray.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:hover,.MuiList-root.nav-tabs-gray.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:hover>span:not(.MuiTouchRipple-root){
    color:#282828!important
}
.MuiList-root.nav-tabs-dark.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected{
    color:#6c401c;
    border-top:3px solid #6c401c
}
.MuiList-root.nav-tabs-dark.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:hover,.MuiList-root.nav-tabs-dark.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-dark.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover{
    color:#6c401c
}
.MuiList-root.nav-tabs-dark.nav-line .MuiListItem-root.MuiListItem-button .divider,.MuiList-root.nav-tabs-dark.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:before,.MuiList-root.nav-tabs-dark.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover:before{
    background:#6c401c
}
.MuiList-root.nav-tabs-dark.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-dark.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected:hover{
    color:#6c401c
}
.MuiList-root.nav-tabs-dark.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button{
    color:#282828
}
.MuiList-root.nav-tabs-dark.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:before{
    background:#6c401c!important;
    box-shadow:0 16px 26px -10px rgba(122,123,151,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(122,123,151,.2)
}
.MuiList-root.nav-tabs-dark.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-dark.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button.Mui-selected>span:not(.MuiTouchRipple-root),.MuiList-root.nav-tabs-dark.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:hover,.MuiList-root.nav-tabs-dark.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:hover>span:not(.MuiTouchRipple-root){
    color:#fff!important
}
.MuiList-root.nav-tabs-first.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected{
    color:#00892c;
    border-top:3px solid #00892c
}
.MuiList-root.nav-tabs-first.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:hover,.MuiList-root.nav-tabs-first.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-first.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover{
    color:#00892c
}
.MuiList-root.nav-tabs-first.nav-line .MuiListItem-root.MuiListItem-button .divider,.MuiList-root.nav-tabs-first.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:before,.MuiList-root.nav-tabs-first.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover:before{
    background:#00892c
}
.MuiList-root.nav-tabs-first.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-first.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected:hover{
    color:#00892c
}
.MuiList-root.nav-tabs-first.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button{
    color:#282828
}
.MuiList-root.nav-tabs-first.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:before{
    background:#00892c!important;
    box-shadow:0 16px 26px -10px rgba(65,145,255,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(65,145,255,.2)
}
.MuiList-root.nav-tabs-first.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-first.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button.Mui-selected>span:not(.MuiTouchRipple-root),.MuiList-root.nav-tabs-first.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:hover,.MuiList-root.nav-tabs-first.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:hover>span:not(.MuiTouchRipple-root){
    color:#fff!important
}
.MuiList-root.nav-tabs-second.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected{
    color:#070919;
    border-top:3px solid #070919
}
.MuiList-root.nav-tabs-second.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:hover,.MuiList-root.nav-tabs-second.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-second.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover{
    color:#070919
}
.MuiList-root.nav-tabs-second.nav-line .MuiListItem-root.MuiListItem-button .divider,.MuiList-root.nav-tabs-second.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:before,.MuiList-root.nav-tabs-second.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover:before{
    background:#070919
}
.MuiList-root.nav-tabs-second.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-second.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected:hover{
    color:#070919
}
.MuiList-root.nav-tabs-second.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button{
    color:#282828
}
.MuiList-root.nav-tabs-second.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:before{
    background:#070919!important;
    box-shadow:0 16px 26px -10px rgba(7,9,25,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(7,9,25,.2)
}
.MuiList-root.nav-tabs-second.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-tabs-second.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button.Mui-selected>span:not(.MuiTouchRipple-root),.MuiList-root.nav-tabs-second.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:hover,.MuiList-root.nav-tabs-second.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:hover>span:not(.MuiTouchRipple-root){
    color:#fff!important
}
.nav-link-icon{
    width:16px;
    text-align:center;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-right:10px;
    font-size:1.15rem
}
.nav-link-icon>svg{
    width:70%
}
.Mui-selected>.nav-link-icon{
    opacity:1!important
}
.MuiList-root.nav-list-square .MuiListItem-root.MuiListItem-button{
    border-radius:0
}
.MuiList-root.nav-pills .MuiListItem-root.MuiListItem-button{
    border-radius:.65rem
}
.MuiList-root.nav-lg .MuiListItem-root.MuiListItem-button{
    padding:.75rem 1.5rem;
    position:relative
}
.MuiList-root.nav-alt .MuiListItem-root.MuiListItem-button{
    border-top-left-radius:0;
    border-bottom-left-radius:0
}
.MuiList-root.nav-alt .MuiListItem-root.MuiListItem-button:before{
    position:absolute;
    left:0;
    top:0;
    width:4px;
    content:"";
    transition:all .2s ease-in-out;
    opacity:0;
    height:0;
    visibility:hidden
}
.MuiList-root.nav-alt .MuiListItem-root.MuiListItem-button.Mui-selected:before,.MuiList-root.nav-alt .MuiListItem-root.MuiListItem-button:hover:before{
    opacity:1;
    height:100%;
    visibility:visible
}
.MuiList-root.nav-pills-rounded .MuiListItem-root.MuiListItem-button{
    border-radius:1.5rem
}
.nav-neutral-primary .MuiListItem-root.MuiListItem-button.Mui-selected,.nav-neutral-primary .MuiListItem-root.MuiListItem-button:hover{
    color:#00892c;
    background:rgba(60,68,177,.05)
}
.nav-neutral-primary .MuiListItem-root.MuiListItem-button.Mui-selected:before,.nav-neutral-primary .MuiListItem-root.MuiListItem-button:hover:before{
    background:#00892c
}
.nav-primary .MuiListItem-root.MuiListItem-button.Mui-selected,.nav-primary .MuiListItem-root.MuiListItem-button:hover{
    color:#fff;
    background:#00892c
}
.nav-primary .MuiListItem-root.MuiListItem-button.Mui-selected:before,.nav-primary .MuiListItem-root.MuiListItem-button:hover:before{
    background:#00892c
}
.nav-neutral-secondary .MuiListItem-root.MuiListItem-button.Mui-selected,.nav-neutral-secondary .MuiListItem-root.MuiListItem-button:hover{
    color:#f8f9ff;
    background:rgba(248,249,255,.05)
}
.nav-neutral-secondary .MuiListItem-root.MuiListItem-button.Mui-selected:before,.nav-neutral-secondary .MuiListItem-root.MuiListItem-button:hover:before{
    background:#f8f9ff
}
.nav-secondary .MuiListItem-root.MuiListItem-button.Mui-selected,.nav-secondary .MuiListItem-root.MuiListItem-button:hover{
    color:#282828;
    background:#f8f9ff
}
.nav-secondary .MuiListItem-root.MuiListItem-button.Mui-selected:before,.nav-secondary .MuiListItem-root.MuiListItem-button:hover:before{
    background:#f8f9ff
}
.nav-neutral-success .MuiListItem-root.MuiListItem-button.Mui-selected,.nav-neutral-success .MuiListItem-root.MuiListItem-button:hover{
    color:#1bc943;
    background:rgba(27,201,67,.05)
}
.nav-neutral-success .MuiListItem-root.MuiListItem-button.Mui-selected:before,.nav-neutral-success .MuiListItem-root.MuiListItem-button:hover:before{
    background:#1bc943
}
.nav-success .MuiListItem-root.MuiListItem-button.Mui-selected,.nav-success .MuiListItem-root.MuiListItem-button:hover{
    color:#fff;
    background:#1bc943
}
.nav-success .MuiListItem-root.MuiListItem-button.Mui-selected:before,.nav-success .MuiListItem-root.MuiListItem-button:hover:before{
    background:#1bc943
}
.nav-neutral-info .MuiListItem-root.MuiListItem-button.Mui-selected,.nav-neutral-info .MuiListItem-root.MuiListItem-button:hover{
    color:#11c5db;
    background:rgba(17,197,219,.05)
}
.nav-neutral-info .MuiListItem-root.MuiListItem-button.Mui-selected:before,.nav-neutral-info .MuiListItem-root.MuiListItem-button:hover:before{
    background:#11c5db
}
.nav-info .MuiListItem-root.MuiListItem-button.Mui-selected,.nav-info .MuiListItem-root.MuiListItem-button:hover{
    color:#fff;
    background:#11c5db
}
.nav-info .MuiListItem-root.MuiListItem-button.Mui-selected:before,.nav-info .MuiListItem-root.MuiListItem-button:hover:before{
    background:#11c5db
}
.nav-neutral-warning .MuiListItem-root.MuiListItem-button.Mui-selected,.nav-neutral-warning .MuiListItem-root.MuiListItem-button:hover{
    color:#f4772e;
    background:rgba(244,119,46,.05)
}
.nav-neutral-warning .MuiListItem-root.MuiListItem-button.Mui-selected:before,.nav-neutral-warning .MuiListItem-root.MuiListItem-button:hover:before{
    background:#f4772e
}
.nav-warning .MuiListItem-root.MuiListItem-button.Mui-selected,.nav-warning .MuiListItem-root.MuiListItem-button:hover{
    color:#fff;
    background:#f4772e
}
.nav-warning .MuiListItem-root.MuiListItem-button.Mui-selected:before,.nav-warning .MuiListItem-root.MuiListItem-button:hover:before{
    background:#f4772e
}
.nav-neutral-danger .MuiListItem-root.MuiListItem-button.Mui-selected,.nav-neutral-danger .MuiListItem-root.MuiListItem-button:hover{
    color:#f83245;
    background:rgba(248,50,69,.05)
}
.nav-neutral-danger .MuiListItem-root.MuiListItem-button.Mui-selected:before,.nav-neutral-danger .MuiListItem-root.MuiListItem-button:hover:before{
    background:#f83245
}
.nav-danger .MuiListItem-root.MuiListItem-button.Mui-selected,.nav-danger .MuiListItem-root.MuiListItem-button:hover{
    color:#fff;
    background:#f83245
}
.nav-danger .MuiListItem-root.MuiListItem-button.Mui-selected:before,.nav-danger .MuiListItem-root.MuiListItem-button:hover:before{
    background:#f83245
}
.nav-neutral-light .MuiListItem-root.MuiListItem-button.Mui-selected,.nav-neutral-light .MuiListItem-root.MuiListItem-button:hover{
    color:#f4f5fd;
    background:rgba(244,245,253,.05)
}
.nav-neutral-light .MuiListItem-root.MuiListItem-button.Mui-selected:before,.nav-neutral-light .MuiListItem-root.MuiListItem-button:hover:before{
    background:#f4f5fd
}
.nav-light .MuiListItem-root.MuiListItem-button.Mui-selected,.nav-light .MuiListItem-root.MuiListItem-button:hover{
    color:#282828;
    background:#f4f5fd
}
.nav-light .MuiListItem-root.MuiListItem-button.Mui-selected:before,.nav-light .MuiListItem-root.MuiListItem-button:hover:before{
    background:#f4f5fd
}
.nav-neutral-gray .MuiListItem-root.MuiListItem-button.Mui-selected,.nav-neutral-gray .MuiListItem-root.MuiListItem-button:hover{
    color:#f8f9ff;
    background:rgba(248,249,255,.05)
}
.nav-neutral-gray .MuiListItem-root.MuiListItem-button.Mui-selected:before,.nav-neutral-gray .MuiListItem-root.MuiListItem-button:hover:before{
    background:#f8f9ff
}
.nav-gray .MuiListItem-root.MuiListItem-button.Mui-selected,.nav-gray .MuiListItem-root.MuiListItem-button:hover{
    color:#282828;
    background:#f8f9ff
}
.nav-gray .MuiListItem-root.MuiListItem-button.Mui-selected:before,.nav-gray .MuiListItem-root.MuiListItem-button:hover:before{
    background:#f8f9ff
}
.nav-neutral-dark .MuiListItem-root.MuiListItem-button.Mui-selected,.nav-neutral-dark .MuiListItem-root.MuiListItem-button:hover{
    color:#6c401c;
    background:rgba(122,123,151,.05)
}
.nav-neutral-dark .MuiListItem-root.MuiListItem-button.Mui-selected:before,.nav-neutral-dark .MuiListItem-root.MuiListItem-button:hover:before{
    background:#6c401c
}
.nav-dark .MuiListItem-root.MuiListItem-button.Mui-selected,.nav-dark .MuiListItem-root.MuiListItem-button:hover{
    color:#fff;
    background:#6c401c
}
.nav-dark .MuiListItem-root.MuiListItem-button.Mui-selected:before,.nav-dark .MuiListItem-root.MuiListItem-button:hover:before{
    background:#6c401c
}
.nav-neutral-first .MuiListItem-root.MuiListItem-button.Mui-selected,.nav-neutral-first .MuiListItem-root.MuiListItem-button:hover{
    color:#00892c;
    background:rgba(65,145,255,.05)
}
.nav-neutral-first .MuiListItem-root.MuiListItem-button.Mui-selected:before,.nav-neutral-first .MuiListItem-root.MuiListItem-button:hover:before{
    background:#00892c
}
.nav-first .MuiListItem-root.MuiListItem-button.Mui-selected,.nav-first .MuiListItem-root.MuiListItem-button:hover{
    color:#fff;
    background:#00892c
}
.nav-first .MuiListItem-root.MuiListItem-button.Mui-selected:before,.nav-first .MuiListItem-root.MuiListItem-button:hover:before{
    background:#00892c
}
.nav-neutral-second .MuiListItem-root.MuiListItem-button.Mui-selected,.nav-neutral-second .MuiListItem-root.MuiListItem-button:hover{
    color:#070919;
    background:rgba(7,9,25,.05)
}
.nav-neutral-second .MuiListItem-root.MuiListItem-button.Mui-selected:before,.nav-neutral-second .MuiListItem-root.MuiListItem-button:hover:before{
    background:#070919
}
.nav-second .MuiListItem-root.MuiListItem-button.Mui-selected,.nav-second .MuiListItem-root.MuiListItem-button:hover{
    color:#fff;
    background:#070919
}
.nav-second .MuiListItem-root.MuiListItem-button.Mui-selected:before,.nav-second .MuiListItem-root.MuiListItem-button:hover:before{
    background:#070919
}
.MuiList-root.nav-transparent-alt .MuiListItem-root.MuiListItem-button.Mui-selected,.MuiList-root.nav-transparent-alt .MuiListItem-root.MuiListItem-button:hover{
    background:transparent;
    color:#00892c
}
.MuiList-root.nav-transparent-alt .MuiListItem-root.MuiListItem-button.Mui-selected:before,.MuiList-root.nav-transparent-alt .MuiListItem-root.MuiListItem-button:hover:before{
    background:transparent
}
.MuiList-root.nav-transparent-alt .MuiListItem-root.MuiListItem-button.text-white-50.Mui-selected,.MuiList-root.nav-transparent-alt .MuiListItem-root.MuiListItem-button.text-white-50:hover{
    color:#fff!important
}
.MuiList-root.nav-transparent-alt .MuiListItem-root.MuiListItem-button.text-black-50.Mui-selected,.MuiList-root.nav-transparent-alt .MuiListItem-root.MuiListItem-button.text-black-50:hover{
    color:#070919!important
}
.MuiList-root.nav-transparent-alt .MuiListItem-root.MuiListItem-button .MuiTouchRipple-root{
    opacity:0
}
.alerts-alternate.MuiAlert-root{
    border-style:solid;
    border-radius:.29rem;
    border-width:2px 2px 2px 6px
}
.MuiSnackbar-root{
    z-index:2500
}
.MuiSnackbar-root .MuiSnackbarContent-root{
    color:#fff
}
.toastr-primary .MuiSnackbarContent-root{
    background:#00892c;
    box-shadow:0 .313rem .8rem rgba(60,68,177,.5),0 .126rem .225rem rgba(60,68,177,.3)!important
}
.toastr-secondary .MuiSnackbarContent-root{
    background:#f8f9ff;
    box-shadow:0 .313rem .8rem rgba(248,249,255,.5),0 .126rem .225rem rgba(248,249,255,.3)!important
}
.toastr-success .MuiSnackbarContent-root{
    background:#1bc943;
    box-shadow:0 .313rem .8rem rgba(27,201,67,.5),0 .126rem .225rem rgba(27,201,67,.3)!important
}
.toastr-info .MuiSnackbarContent-root{
    background:#11c5db;
    box-shadow:0 .313rem .8rem rgba(17,197,219,.5),0 .126rem .225rem rgba(17,197,219,.3)!important
}
.toastr-warning .MuiSnackbarContent-root{
    background:#f4772e;
    box-shadow:0 .313rem .8rem rgba(244,119,46,.5),0 .126rem .225rem rgba(244,119,46,.3)!important
}
.toastr-danger .MuiSnackbarContent-root{
    background:#f83245;
    box-shadow:0 .313rem .8rem rgba(248,50,69,.5),0 .126rem .225rem rgba(248,50,69,.3)!important
}
.toastr-light .MuiSnackbarContent-root{
    background:#f4f5fd;
    box-shadow:0 .313rem .8rem rgba(244,245,253,.5),0 .126rem .225rem rgba(244,245,253,.3)!important
}
.toastr-gray .MuiSnackbarContent-root{
    background:#f8f9ff;
    box-shadow:0 .313rem .8rem rgba(248,249,255,.5),0 .126rem .225rem rgba(248,249,255,.3)!important
}
.toastr-dark .MuiSnackbarContent-root{
    background:#6c401c;
    box-shadow:0 .313rem .8rem rgba(122,123,151,.5),0 .126rem .225rem rgba(122,123,151,.3)!important
}
.toastr-first .MuiSnackbarContent-root{
    background:#00892c;
    box-shadow:0 .313rem .8rem rgba(65,145,255,.5),0 .126rem .225rem rgba(65,145,255,.3)!important
}
.toastr-second .MuiSnackbarContent-root{
    background:#070919;
    box-shadow:0 .313rem .8rem rgba(7,9,25,.5),0 .126rem .225rem rgba(7,9,25,.3)!important
}
.MuiAlert-standardWarning{
    border-color:#f4772e
}
.MuiAlert-standardError{
    border-color:#f83245
}
.MuiAlert-standardSuccess{
    border-color:#1bc943
}
.MuiAlert-standardInfo{
    border-color:#11c5db
}
.MuiPagination-root{
    margin:0
}
.MuiPagination-root .MuiPaginationItem-root{
    border-radius:.2rem!important;
    margin:0 .28571rem;
    transition:all .2s ease-in-out;
    position:relative;
    font-weight:700;
    border-color:#d1d2db;
    padding:0;
    width:36px;
    min-width:36px;
    height:36px;
    line-height:36px;
    text-align:center
}
.MuiPagination-root .MuiPaginationItem-root.MuiPaginationItem-ellipsis{
    background:transparent!important;
    color:#d1d2db!important
}
.MuiPagination-root .MuiPaginationItem-root:hover{
    color:#00892c;
    transform:translateY(0)!important
}
.MuiPagination-root .MuiPaginationItem-root.Mui-selected{
    box-shadow:0 .313rem .8rem rgba(122,123,151,.5),0 .126rem .225rem rgba(122,123,151,.3)
}
.MuiPagination-root .MuiPaginationItem-root.MuiPaginationItem-sizeSmall{
    width:26px;
    min-width:26px;
    height:26px;
    line-height:26px
}
.MuiPagination-root .MuiPaginationItem-root.MuiPaginationItem-sizeLarge{
    width:48px;
    min-width:48px;
    height:48px;
    line-height:48px
}
.MuiPagination-root .MuiPaginationItem-root.Mui-disabled{
    opacity:.65
}
.MuiPagination-root.pagination-primary .MuiPaginationItem-root:hover{
    color:#00892c;
    background:rgba(60,68,177,.05);
    border-color:#00892c
}
.MuiPagination-root.pagination-primary .MuiPaginationItem-root.Mui-selected{
    background:#00892c;
    color:#fff;
    border-color:#00892c;
    box-shadow:0 .313rem .8rem rgba(60,68,177,.5),0 .126rem .225rem rgba(60,68,177,.3)
}
.MuiPagination-root.pagination-secondary .MuiPaginationItem-root:hover{
    color:#f8f9ff;
    background:rgba(248,249,255,.05);
    border-color:#f8f9ff
}
.MuiPagination-root.pagination-secondary .MuiPaginationItem-root.Mui-selected{
    background:#f8f9ff;
    color:#282828;
    border-color:#f8f9ff;
    box-shadow:0 .313rem .8rem rgba(248,249,255,.5),0 .126rem .225rem rgba(248,249,255,.3)
}
.MuiPagination-root.pagination-success .MuiPaginationItem-root:hover{
    color:#1bc943;
    background:rgba(27,201,67,.05);
    border-color:#1bc943
}
.MuiPagination-root.pagination-success .MuiPaginationItem-root.Mui-selected{
    background:#1bc943;
    color:#fff;
    border-color:#1bc943;
    box-shadow:0 .313rem .8rem rgba(27,201,67,.5),0 .126rem .225rem rgba(27,201,67,.3)
}
.MuiPagination-root.pagination-info .MuiPaginationItem-root:hover{
    color:#11c5db;
    background:rgba(17,197,219,.05);
    border-color:#11c5db
}
.MuiPagination-root.pagination-info .MuiPaginationItem-root.Mui-selected{
    background:#11c5db;
    color:#fff;
    border-color:#11c5db;
    box-shadow:0 .313rem .8rem rgba(17,197,219,.5),0 .126rem .225rem rgba(17,197,219,.3)
}
.MuiPagination-root.pagination-warning .MuiPaginationItem-root:hover{
    color:#f4772e;
    background:rgba(244,119,46,.05);
    border-color:#f4772e
}
.MuiPagination-root.pagination-warning .MuiPaginationItem-root.Mui-selected{
    background:#f4772e;
    color:#fff;
    border-color:#f4772e;
    box-shadow:0 .313rem .8rem rgba(244,119,46,.5),0 .126rem .225rem rgba(244,119,46,.3)
}
.MuiPagination-root.pagination-danger .MuiPaginationItem-root:hover{
    color:#f83245;
    background:rgba(248,50,69,.05);
    border-color:#f83245
}
.MuiPagination-root.pagination-danger .MuiPaginationItem-root.Mui-selected{
    background:#f83245;
    color:#fff;
    border-color:#f83245;
    box-shadow:0 .313rem .8rem rgba(248,50,69,.5),0 .126rem .225rem rgba(248,50,69,.3)
}
.MuiPagination-root.pagination-light .MuiPaginationItem-root:hover{
    color:#f4f5fd;
    background:rgba(244,245,253,.05);
    border-color:#f4f5fd
}
.MuiPagination-root.pagination-light .MuiPaginationItem-root.Mui-selected{
    background:#f4f5fd;
    color:#282828;
    border-color:#f4f5fd;
    box-shadow:0 .313rem .8rem rgba(244,245,253,.5),0 .126rem .225rem rgba(244,245,253,.3)
}
.MuiPagination-root.pagination-gray .MuiPaginationItem-root:hover{
    color:#f8f9ff;
    background:rgba(248,249,255,.05);
    border-color:#f8f9ff
}
.MuiPagination-root.pagination-gray .MuiPaginationItem-root.Mui-selected{
    background:#f8f9ff;
    color:#282828;
    border-color:#f8f9ff;
    box-shadow:0 .313rem .8rem rgba(248,249,255,.5),0 .126rem .225rem rgba(248,249,255,.3)
}
.MuiPagination-root.pagination-dark .MuiPaginationItem-root:hover{
    color:#6c401c;
    background:rgba(122,123,151,.05);
    border-color:#6c401c
}
.MuiPagination-root.pagination-dark .MuiPaginationItem-root.Mui-selected{
    background:#6c401c;
    color:#fff;
    border-color:#6c401c;
    box-shadow:0 .313rem .8rem rgba(122,123,151,.5),0 .126rem .225rem rgba(122,123,151,.3)
}
.MuiPagination-root.pagination-first .MuiPaginationItem-root:hover{
    color:#00892c;
    background:rgba(65,145,255,.05);
    border-color:#00892c
}
.MuiPagination-root.pagination-first .MuiPaginationItem-root.Mui-selected{
    background:#00892c;
    color:#fff;
    border-color:#00892c;
    box-shadow:0 .313rem .8rem rgba(65,145,255,.5),0 .126rem .225rem rgba(65,145,255,.3)
}
.MuiPagination-root.pagination-second .MuiPaginationItem-root:hover{
    color:#070919;
    background:rgba(7,9,25,.05);
    border-color:#070919
}
.MuiPagination-root.pagination-second .MuiPaginationItem-root.Mui-selected{
    background:#070919;
    color:#fff;
    border-color:#070919;
    box-shadow:0 .313rem .8rem rgba(7,9,25,.5),0 .126rem .225rem rgba(7,9,25,.3)
}
.popover-custom-lg{
    max-width:414px
}
.popover-custom-md{
    width:414px;
    max-width:414px
}
.popover-custom-xl{
    width:552px;
    max-width:552px
}
.popover-custom-xxl{
    width:828px;
    max-width:828px
}
body .MuiLinearProgress-root{
    box-shadow:inset 0 1px 2px rgba(0,0,0,.1);
    border-radius:.2rem;
    height:1rem
}
body .MuiLinearProgress-root.MuiLinearProgress-query{
    box-shadow:none
}
body .MuiLinearProgress-root.progress-xs{
    height:.3rem
}
body .MuiLinearProgress-root.progress-sm{
    height:.52rem
}
body .MuiLinearProgress-root.progress-lg{
    height:1.2rem
}
body .MuiLinearProgress-root .MuiLinearProgress-bar{
    border-radius:.2rem
}
body .MuiLinearProgress-root .MuiLinearProgress-bar:last-child{
    border-top-right-radius:.2rem;
    border-bottom-right-radius:.2rem
}
body .MuiLinearProgress-root.progress-bar-rounded{
    border-radius:30px
}
body .MuiLinearProgress-root.progress-bar-rounded .MuiLinearProgress-bar:last-child{
    border-top-right-radius:30px;
    border-bottom-right-radius:30px
}
body .MuiLinearProgress-root.progress-animated-alt .MuiLinearProgress-bar:after{
    content:"";
    opacity:0;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:#fff;
    -webkit-animation:progress-active 2s ease infinite;
    animation:progress-active 2s ease infinite
}
body .MuiLinearProgress-root.progress-constrained{
    height:24px;
    padding:0 24px 0 0;
    display:flex;
    align-items:center;
    justify-content:center
}
body .MuiLinearProgress-root.progress-constrained .MuiLinearProgress-bar{
    height:8px;
    position:relative!important
}
@-webkit-keyframes progress-active{
    0%{
        opacity:.4;
        width:0
    }
    to{
        opacity:0;
        width:100%
    }
}
@keyframes progress-active{
    0%{
        opacity:.4;
        width:0
    }
    to{
        opacity:0;
        width:100%
    }
}
.MuiLinearProgress-root.progress-bar-primary{
    background:#f4f5fd
}
.MuiLinearProgress-root.progress-bar-primary .MuiLinearProgress-bar{
    background:#00892c
}
.MuiLinearProgress-determinate.progress-bar-primary,.MuiLinearProgress-root.progress-bar-primary.MuiLinearProgress-query{
    background:rgba(60,68,177,.1)
}
.MuiCircularProgress-root.progress-bar-primary{
    color:#00892c
}
.MuiLinearProgress-root.progress-bar-secondary{
    background:#f4f5fd
}
.MuiLinearProgress-root.progress-bar-secondary .MuiLinearProgress-bar{
    background:#f8f9ff
}
.MuiLinearProgress-determinate.progress-bar-secondary,.MuiLinearProgress-root.progress-bar-secondary.MuiLinearProgress-query{
    background:rgba(248,249,255,.1)
}
.MuiCircularProgress-root.progress-bar-secondary{
    color:#f8f9ff
}
.MuiLinearProgress-root.progress-bar-success{
    background:#f4f5fd
}
.MuiLinearProgress-root.progress-bar-success .MuiLinearProgress-bar{
    background:#1bc943
}
.MuiLinearProgress-determinate.progress-bar-success,.MuiLinearProgress-root.progress-bar-success.MuiLinearProgress-query{
    background:rgba(27,201,67,.1)
}
.MuiCircularProgress-root.progress-bar-success{
    color:#1bc943
}
.MuiLinearProgress-root.progress-bar-info{
    background:#f4f5fd
}
.MuiLinearProgress-root.progress-bar-info .MuiLinearProgress-bar{
    background:#11c5db
}
.MuiLinearProgress-determinate.progress-bar-info,.MuiLinearProgress-root.progress-bar-info.MuiLinearProgress-query{
    background:rgba(17,197,219,.1)
}
.MuiCircularProgress-root.progress-bar-info{
    color:#11c5db
}
.MuiLinearProgress-root.progress-bar-warning{
    background:#f4f5fd
}
.MuiLinearProgress-root.progress-bar-warning .MuiLinearProgress-bar{
    background:#f4772e
}
.MuiLinearProgress-determinate.progress-bar-warning,.MuiLinearProgress-root.progress-bar-warning.MuiLinearProgress-query{
    background:rgba(244,119,46,.1)
}
.MuiCircularProgress-root.progress-bar-warning{
    color:#f4772e
}
.MuiLinearProgress-root.progress-bar-danger{
    background:#f4f5fd
}
.MuiLinearProgress-root.progress-bar-danger .MuiLinearProgress-bar{
    background:#f83245
}
.MuiLinearProgress-determinate.progress-bar-danger,.MuiLinearProgress-root.progress-bar-danger.MuiLinearProgress-query{
    background:rgba(248,50,69,.1)
}
.MuiCircularProgress-root.progress-bar-danger{
    color:#f83245
}
.MuiLinearProgress-root.progress-bar-light,.MuiLinearProgress-root.progress-bar-light .MuiLinearProgress-bar{
    background:#f4f5fd
}
.MuiLinearProgress-determinate.progress-bar-light,.MuiLinearProgress-root.progress-bar-light.MuiLinearProgress-query{
    background:rgba(244,245,253,.1)
}
.MuiCircularProgress-root.progress-bar-light{
    color:#f4f5fd
}
.MuiLinearProgress-root.progress-bar-gray{
    background:#f4f5fd
}
.MuiLinearProgress-root.progress-bar-gray .MuiLinearProgress-bar{
    background:#f8f9ff
}
.MuiLinearProgress-determinate.progress-bar-gray,.MuiLinearProgress-root.progress-bar-gray.MuiLinearProgress-query{
    background:rgba(248,249,255,.1)
}
.MuiCircularProgress-root.progress-bar-gray{
    color:#f8f9ff
}
.MuiLinearProgress-root.progress-bar-dark{
    background:#f4f5fd
}
.MuiLinearProgress-root.progress-bar-dark .MuiLinearProgress-bar{
    background:#6c401c
}
.MuiLinearProgress-determinate.progress-bar-dark,.MuiLinearProgress-root.progress-bar-dark.MuiLinearProgress-query{
    background:rgba(122,123,151,.1)
}
.MuiCircularProgress-root.progress-bar-dark{
    color:#6c401c
}
.MuiLinearProgress-root.progress-bar-first{
    background:#f4f5fd
}
.MuiLinearProgress-root.progress-bar-first .MuiLinearProgress-bar{
    background:#00892c
}
.MuiLinearProgress-determinate.progress-bar-first,.MuiLinearProgress-root.progress-bar-first.MuiLinearProgress-query{
    background:rgba(65,145,255,.1)
}
.MuiCircularProgress-root.progress-bar-first{
    color:#00892c
}
.MuiLinearProgress-root.progress-bar-second{
    background:#f4f5fd
}
.MuiLinearProgress-root.progress-bar-second .MuiLinearProgress-bar{
    background:#070919
}
.MuiLinearProgress-determinate.progress-bar-second,.MuiLinearProgress-root.progress-bar-second.MuiLinearProgress-query{
    background:rgba(7,9,25,.1)
}
.MuiCircularProgress-root.progress-bar-second{
    color:#070919
}
.MuiLinearProgress-root.progress-bar-white{
    background:hsla(0,0%,100%,.3)
}
.MuiLinearProgress-root.progress-bar-white .MuiLinearProgress-bar{
    background:#fff
}
.MuiLinearProgress-root.progress-bar-white.MuiLinearProgress-query{
    background:hsla(0,0%,100%,.1)
}
.ribbon-angle{
    position:absolute;
    z-index:8;
    width:120px;
    height:120px;
    overflow:hidden
}
.ribbon-angle>small{
    position:absolute;
    display:block;
    width:100%;
    padding:.25rem;
    text-align:center;
    text-transform:uppercase;
    font-weight:700;
    font-size:.76923rem
}
.ribbon-angle--top-right{
    top:0;
    right:0
}
.ribbon-angle--top-right>small{
    transform:rotate(45deg);
    top:16px;
    left:27px
}
.ribbon-angle--top-left{
    top:0;
    left:0
}
.ribbon-angle--top-left>small{
    transform:rotate(-45deg);
    top:16px;
    left:-27px
}
.ribbon-angle--bottom-right{
    bottom:0;
    right:0
}
.ribbon-angle--bottom-right>small{
    transform:rotate(-50deg);
    bottom:21px;
    right:-31px
}
.ribbon-angle--bottom-left{
    bottom:0;
    left:0
}
.ribbon-angle--bottom-left>small{
    transform:rotate(50deg);
    bottom:21px;
    left:-31px
}
.ribbon-primary>small{
    color:#fff;
    background-color:#00892c
}
.ribbon-secondary>small{
    color:#282828;
    background-color:#f8f9ff
}
.ribbon-success>small{
    color:#fff;
    background-color:#1bc943
}
.ribbon-info>small{
    color:#fff;
    background-color:#11c5db
}
.ribbon-warning>small{
    color:#fff;
    background-color:#f4772e
}
.ribbon-danger>small{
    color:#fff;
    background-color:#f83245
}
.ribbon-light>small{
    color:#282828;
    background-color:#f4f5fd
}
.ribbon-gray>small{
    color:#282828;
    background-color:#f8f9ff
}
.ribbon-dark>small{
    color:#fff;
    background-color:#6c401c
}
.ribbon-first>small{
    color:#fff;
    background-color:#00892c
}
.ribbon-second>small{
    color:#fff;
    background-color:#070919
}
.ribbon-horizontal{
    display:block;
    width:calc(100% + 20px);
    height:50px;
    line-height:50px;
    text-align:center;
    margin-left:-10px;
    margin-right:-10px;
    top:1rem;
    position:absolute;
    z-index:8;
    font-weight:700;
    box-shadow:0 .46875rem 2.1875rem rgba(59,62,102,.03),0 .9375rem 1.40625rem rgba(59,62,102,.03),0 .25rem .53125rem rgba(59,62,102,.05),0 .125rem .1875rem rgba(59,62,102,.03)
}
.ribbon-horizontal:after,.ribbon-horizontal:before{
    content:"";
    position:absolute;
    height:0;
    width:0;
    bottom:-10px;
    border-top:10px solid transparent
}
.ribbon-horizontal:before{
    left:0;
    border-left:10px solid transparent
}
.ribbon-horizontal:after{
    right:0;
    border-right:10px solid transparent
}
.ribbon-horizontal--bottom{
    top:auto;
    bottom:1rem
}
.ribbon-horizontal--primary{
    background:#00892c;
    color:#fff
}
.ribbon-horizontal--primary:after,.ribbon-horizontal--primary:before{
    border-top-color:#222765
}
.ribbon-horizontal--secondary{
    background:#f8f9ff;
    color:#282828
}
.ribbon-horizontal--secondary:after,.ribbon-horizontal--secondary:before{
    border-top-color:#92a2ff
}
.ribbon-horizontal--success{
    background:#1bc943;
    color:#fff
}
.ribbon-horizontal--success:after,.ribbon-horizontal--success:before{
    border-top-color:#0f6f25
}
.ribbon-horizontal--info{
    background:#11c5db;
    color:#fff
}
.ribbon-horizontal--info:after,.ribbon-horizontal--info:before{
    border-top-color:#0a707c
}
.ribbon-horizontal--warning{
    background:#f4772e;
    color:#fff
}
.ribbon-horizontal--warning:after,.ribbon-horizontal--warning:before{
    border-top-color:#b34809
}
.ribbon-horizontal--danger{
    background:#f83245;
    color:#fff
}
.ribbon-horizontal--danger:after,.ribbon-horizontal--danger:before{
    border-top-color:#be0618
}
.ribbon-horizontal--light{
    background:#f4f5fd;
    color:#282828
}
.ribbon-horizontal--light:after,.ribbon-horizontal--light:before{
    border-top-color:#9ea7ed
}
.ribbon-horizontal--gray{
    background:#f8f9ff;
    color:#282828
}
.ribbon-horizontal--gray:after,.ribbon-horizontal--gray:before{
    border-top-color:#92a2ff
}
.ribbon-horizontal--dark{
    background:#6c401c;
    color:#fff
}
.ribbon-horizontal--dark:after,.ribbon-horizontal--dark:before{
    border-top-color:#4b4c60
}
.ribbon-horizontal--first{
    background:#00892c;
    color:#fff
}
.ribbon-horizontal--first:after,.ribbon-horizontal--first:before{
    border-top-color:#005cda
}
.ribbon-horizontal--second{
    background:#070919;
    color:#fff
}
.ribbon-horizontal--second:after,.ribbon-horizontal--second:before{
    border-top-color:#000
}
.ribbon-vertical{
    position:absolute;
    width:60px;
    padding:.66667rem 0 .5rem;
    top:-6px;
    left:1.5rem;
    text-align:center;
    border-top-left-radius:3px;
    z-index:8;
    font-weight:700;
    box-shadow:0 .5rem 1rem rgba(7,9,25,.55)
}
.ribbon-vertical--right{
    left:auto;
    right:1.5rem
}
.ribbon-vertical:before{
    height:0;
    width:0;
    right:-5.5px;
    top:.1px;
    border-bottom:6px solid transparent;
    border-right:6px solid transparent
}
.ribbon-vertical:after,.ribbon-vertical:before{
    content:"";
    position:absolute
}
.ribbon-vertical:after{
    height:0;
    width:0;
    bottom:-29.5px;
    left:0;
    border-left:30px solid transparent;
    border-right:30px solid transparent;
    border-bottom:30px solid transparent
}
.ribbon-vertical--primary{
    background:#00892c;
    color:#fff
}
.ribbon-vertical--primary:before{
    border-bottom-color:#222765
}
.ribbon-vertical--primary:after{
    border-left-color:#00892c;
    border-right-color:#00892c
}
.ribbon-vertical--secondary{
    background:#f8f9ff;
    color:#282828
}
.ribbon-vertical--secondary:before{
    border-bottom-color:#92a2ff
}
.ribbon-vertical--secondary:after{
    border-left-color:#f8f9ff;
    border-right-color:#f8f9ff
}
.ribbon-vertical--success{
    background:#1bc943;
    color:#fff
}
.ribbon-vertical--success:before{
    border-bottom-color:#0f6f25
}
.ribbon-vertical--success:after{
    border-left-color:#1bc943;
    border-right-color:#1bc943
}
.ribbon-vertical--info{
    background:#11c5db;
    color:#fff
}
.ribbon-vertical--info:before{
    border-bottom-color:#0a707c
}
.ribbon-vertical--info:after{
    border-left-color:#11c5db;
    border-right-color:#11c5db
}
.ribbon-vertical--warning{
    background:#f4772e;
    color:#fff
}
.ribbon-vertical--warning:before{
    border-bottom-color:#b34809
}
.ribbon-vertical--warning:after{
    border-left-color:#f4772e;
    border-right-color:#f4772e
}
.ribbon-vertical--danger{
    background:#f83245;
    color:#fff
}
.ribbon-vertical--danger:before{
    border-bottom-color:#be0618
}
.ribbon-vertical--danger:after{
    border-left-color:#f83245;
    border-right-color:#f83245
}
.ribbon-vertical--light{
    background:#f4f5fd;
    color:#282828
}
.ribbon-vertical--light:before{
    border-bottom-color:#9ea7ed
}
.ribbon-vertical--light:after{
    border-left-color:#f4f5fd;
    border-right-color:#f4f5fd
}
.ribbon-vertical--gray{
    background:#f8f9ff;
    color:#282828
}
.ribbon-vertical--gray:before{
    border-bottom-color:#92a2ff
}
.ribbon-vertical--gray:after{
    border-left-color:#f8f9ff;
    border-right-color:#f8f9ff
}
.ribbon-vertical--dark{
    background:#6c401c;
    color:#fff
}
.ribbon-vertical--dark:before{
    border-bottom-color:#4b4c60
}
.ribbon-vertical--dark:after{
    border-left-color:#6c401c;
    border-right-color:#6c401c
}
.ribbon-vertical--first{
    background:#00892c;
    color:#fff
}
.ribbon-vertical--first:before{
    border-bottom-color:#005cda
}
.ribbon-vertical--first:after{
    border-left-color:#00892c;
    border-right-color:#00892c
}
.ribbon-vertical--second{
    background:#070919;
    color:#fff
}
.ribbon-vertical--second:before{
    border-bottom-color:#000
}
.ribbon-vertical--second:after{
    border-left-color:#070919;
    border-right-color:#070919
}
.scroll-area{
    overflow-x:hidden;
    height:300px
}
.scroll-area-xs{
    height:150px;
    overflow-x:hidden
}
.scroll-area-sm{
    height:215px;
    overflow-x:hidden
}
.scroll-area-lg{
    height:420px;
    overflow-x:hidden
}
.scroll-area-xl{
    height:600px;
    overflow-x:hidden
}
.scroll-area-x{
    overflow-x:auto;
    width:100%;
    max-width:100%
}
.shadow-overflow{
    position:relative
}
.shadow-overflow:after,.shadow-overflow:before{
    width:100%;
    bottom:auto;
    top:0;
    left:0;
    height:1rem;
    position:absolute;
    z-index:4;
    content:"";
    background:linear-gradient(180deg,#fff 20%,hsla(0,0%,100%,0))
}
.shadow-overflow:after,.shadow-overflow:before{
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="$white",endColorstr="$white",GradientType=0)
}
.shadow-overflow:after{
    bottom:0;
    top:auto;
    border-bottom-right-radius:inherit;
    border-bottom-left-radius:inherit;
    background:linear-gradient(180deg,hsla(0,0%,100%,0) 0,#fff 80%)
}
.shadow-overflow-horizontal{
    position:relative
}
.shadow-overflow-horizontal:after,.shadow-overflow-horizontal:before{
    width:1rem;
    top:0;
    bottom:0;
    left:0;
    height:100%;
    position:absolute;
    z-index:4;
    content:"";
    background:linear-gradient(90deg,#fff 20%,hsla(0,0%,100%,0))
}
.shadow-overflow-horizontal:after,.shadow-overflow-horizontal:before{
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="$white",endColorstr="$white",GradientType=0)
}
.shadow-overflow-horizontal:after{
    right:0;
    left:auto;
    background:linear-gradient(90deg,hsla(0,0%,100%,0) 0,#fff 80%)
}
.search-wrapper{
    position:relative;
    transition:all .2s ease-in-out
}
.search-wrapper .MuiOutlinedInput-notchedOutline{
    border-width:2px
}
.search-wrapper .MuiTextField-root{
    border-width:2px;
    transition:all .2s ease-in-out;
    background:#f8f9ff
}
.search-wrapper:hover .MuiTextField-root{
    background:#f4f5fd;
    border-color:#c3c4d0
}
.search-wrapper.is-active .MuiTextField-root{
    background:#fff;
    border-color:#00892c
}
.search-wrapper.is-active .icon-wrapper{
    opacity:.8
}
.search-wrapper--grow .MuiTextField-root{
    width:200px
}
.search-wrapper--grow:hover .MuiTextField-root{
    width:210px
}
.search-wrapper--grow.is-active .MuiTextField-root{
    width:100%
}
.search-wrapper--alternate .MuiOutlinedInput-notchedOutline{
    background:transparent;
    border-color:transparent
}
.search-wrapper--alternate .MuiTextField-root{
    background:transparent
}
.search-wrapper .search-options{
    position:absolute;
    text-align:right;
    top:5px;
    z-index:22;
    right:15px
}
.search-wrapper .search-options div{
    position:relative;
    display:inline-block;
    margin:4px 5px 0;
    cursor:pointer
}
.search-wrapper .search-options div input{
    position:absolute;
    top:0;
    left:0;
    opacity:.01;
    cursor:pointer
}
.search-wrapper .search-options div span{
    position:absolute;
    display:block;
    text-align:center;
    left:50%;
    transform:translateX(-50%);
    opacity:0;
    background:#282828;
    color:#f8f9ff;
    font-size:12px;
    font-weight:700;
    line-height:1;
    text-transform:uppercase;
    padding:6px 15px;
    border-radius:8px;
    top:-22px;
    transition:all .2s ease-in-out
}
.search-wrapper .search-options div span:after{
    content:"";
    position:absolute;
    bottom:-6px;
    left:50%;
    transform:translateX(-50%);
    border-top:8px solid #282828;
    border-left:8px solid transparent;
    border-right:8px solid transparent;
    transition:all .2s ease-in-out
}
.search-wrapper .search-options div:hover span{
    opacity:1;
    top:-32px
}
.search-wrapper .search-options div label{
    display:block;
    cursor:pointer;
    margin:0
}
.search-wrapper .search-options div svg{
    opacity:.6
}
.search-wrapper .search-options div:hover svg{
    opacity:1
}
.search-wrapper .search-options div input:checked+label svg{
    opacity:.9
}
.search-wrapper .search-options div input:checked+label svg path{
    fill:#00892c!important
}
.search-wrapper .search-options div input:checked+label span{
    background:#00892c
}
.search-wrapper .search-options div input:checked+label span:after{
    border-top-color:#00892c
}
.search-wrapper--expandable{
    width:44px;
    cursor:pointer
}
.search-wrapper--expandable .MuiOutlinedInput-adornedStart{
    padding-left:10px
}
.search-wrapper--expandable.is-active,.search-wrapper--expandable:active,.search-wrapper--expandable:focus,.search-wrapper--expandable:hover{
    width:100%
}
.search-wrapper .input-border-0 .MuiOutlinedInput-notchedOutline{
    border:0
}
.MuiSlider-root .MuiSlider-rail{
    height:4px;
    border-radius:.75rem;
    background:#e6e7f1;
    opacity:1
}
.MuiSlider-root .MuiSlider-track{
    height:4px;
    border-radius:.75rem
}
.MuiSlider-root .MuiSlider-valueLabel{
    top:-28px;
    left:calc(-50% - 13px)
}
.MuiSlider-root .MuiSlider-valueLabel>span:first-child{
    transform:none;
    border-radius:.2rem;
    width:50px;
    height:28px
}
.MuiSlider-root .MuiSlider-valueLabel>span:first-child:after{
    position:absolute;
    content:"";
    bottom:-5px;
    margin-left:-5px;
    left:50%;
    border-color:currentcolor transparent transparent;
    border-style:solid;
    border-width:5px 5px 0
}
.MuiSlider-root .MuiSlider-valueLabel>span:first-child>span:first-child{
    transform:none
}
.MuiSlider-root .MuiSlider-thumb{
    width:16px;
    height:16px;
    margin-top:-6px;
    margin-left:-8px;
    border:2px solid transparent;
    background:#fff
}
.MuiSlider-root .MuiSlider-mark{
    width:4px;
    height:4px;
    border-radius:20px
}
.MuiSlider-root.MuiSlider-trackInverted .MuiSlider-mark{
    background:#fff;
    opacity:.8
}
.MuiSlider-root.MuiSlider-trackInverted .MuiSlider-mark.MuiSlider-markActive{
    opacity:1
}
.MuiSlider-root .MuiSlider-markLabel{
    font-size:.76923rem;
    font-weight:700;
    color:#a6a6b9
}
.MuiSlider-root .MuiSlider-markLabel.MuiSlider-markLabelActive{
    color:#070919
}
.MuiSlider-root.slider-primary{
    color:#00892c
}
.MuiSlider-root.slider-primary.MuiSlider-trackInverted .MuiSlider-markActive,.MuiSlider-root.slider-primary.MuiSlider-trackInverted .MuiSlider-track{
    background:#00892c
}
.MuiSlider-root.slider-primary .MuiSlider-thumb{
    border-color:#00892c
}
.MuiSlider-root.slider-primary .MuiSlider-thumb.Mui-focusVisible,.MuiSlider-root.slider-primary .MuiSlider-thumb:hover{
    border-color:#096125;
    box-shadow:0 0 0 6px rgba(60,68,177,.16)
}
.MuiSlider-root.slider-primary .MuiSlider-thumb.MuiSlider-active{
    box-shadow:0 .46875rem 2.1875rem rgba(60,68,177,.8),0 .125rem .5875rem rgba(60,68,177,.9)
}
.MuiSlider-root.slider-secondary{
    color:#f8f9ff
}
.MuiSlider-root.slider-secondary.MuiSlider-trackInverted .MuiSlider-markActive,.MuiSlider-root.slider-secondary.MuiSlider-trackInverted .MuiSlider-track{
    background:#f8f9ff
}
.MuiSlider-root.slider-secondary .MuiSlider-thumb{
    border-color:#f8f9ff
}
.MuiSlider-root.slider-secondary .MuiSlider-thumb.Mui-focusVisible,.MuiSlider-root.slider-secondary .MuiSlider-thumb:hover{
    border-color:#acb7ff;
    box-shadow:0 0 0 6px rgba(248,249,255,.16)
}
.MuiSlider-root.slider-secondary .MuiSlider-thumb.MuiSlider-active{
    box-shadow:0 .46875rem 2.1875rem rgba(248,249,255,.8),0 .125rem .5875rem rgba(248,249,255,.9)
}
.MuiSlider-root.slider-success{
    color:#1bc943
}
.MuiSlider-root.slider-success.MuiSlider-trackInverted .MuiSlider-markActive,.MuiSlider-root.slider-success.MuiSlider-trackInverted .MuiSlider-track{
    background:#1bc943
}
.MuiSlider-root.slider-success .MuiSlider-thumb{
    border-color:#1bc943
}
.MuiSlider-root.slider-success .MuiSlider-thumb.Mui-focusVisible,.MuiSlider-root.slider-success .MuiSlider-thumb:hover{
    border-color:#12862d;
    box-shadow:0 0 0 6px rgba(27,201,67,.16)
}
.MuiSlider-root.slider-success .MuiSlider-thumb.MuiSlider-active{
    box-shadow:0 .46875rem 2.1875rem rgba(27,201,67,.8),0 .125rem .5875rem rgba(27,201,67,.9)
}
.MuiSlider-root.slider-info{
    color:#11c5db
}
.MuiSlider-root.slider-info.MuiSlider-trackInverted .MuiSlider-markActive,.MuiSlider-root.slider-info.MuiSlider-trackInverted .MuiSlider-track{
    background:#11c5db
}
.MuiSlider-root.slider-info .MuiSlider-thumb{
    border-color:#11c5db
}
.MuiSlider-root.slider-info .MuiSlider-thumb.Mui-focusVisible,.MuiSlider-root.slider-info .MuiSlider-thumb:hover{
    border-color:#0b8594;
    box-shadow:0 0 0 6px rgba(17,197,219,.16)
}
.MuiSlider-root.slider-info .MuiSlider-thumb.MuiSlider-active{
    box-shadow:0 .46875rem 2.1875rem rgba(17,197,219,.8),0 .125rem .5875rem rgba(17,197,219,.9)
}
.MuiSlider-root.slider-warning{
    color:#f4772e
}
.MuiSlider-root.slider-warning.MuiSlider-trackInverted .MuiSlider-markActive,.MuiSlider-root.slider-warning.MuiSlider-trackInverted .MuiSlider-track{
    background:#f4772e
}
.MuiSlider-root.slider-warning .MuiSlider-thumb{
    border-color:#f4772e
}
.MuiSlider-root.slider-warning .MuiSlider-thumb.Mui-focusVisible,.MuiSlider-root.slider-warning .MuiSlider-thumb:hover{
    border-color:#cb520b;
    box-shadow:0 0 0 6px rgba(244,119,46,.16)
}
.MuiSlider-root.slider-warning .MuiSlider-thumb.MuiSlider-active{
    box-shadow:0 .46875rem 2.1875rem rgba(244,119,46,.8),0 .125rem .5875rem rgba(244,119,46,.9)
}
.MuiSlider-root.slider-danger{
    color:#f83245
}
.MuiSlider-root.slider-danger.MuiSlider-trackInverted .MuiSlider-markActive,.MuiSlider-root.slider-danger.MuiSlider-trackInverted .MuiSlider-track{
    background:#f83245
}
.MuiSlider-root.slider-danger .MuiSlider-thumb{
    border-color:#f83245
}
.MuiSlider-root.slider-danger .MuiSlider-thumb.Mui-focusVisible,.MuiSlider-root.slider-danger .MuiSlider-thumb:hover{
    border-color:#d6071b;
    box-shadow:0 0 0 6px rgba(248,50,69,.16)
}
.MuiSlider-root.slider-danger .MuiSlider-thumb.MuiSlider-active{
    box-shadow:0 .46875rem 2.1875rem rgba(248,50,69,.8),0 .125rem .5875rem rgba(248,50,69,.9)
}
.MuiSlider-root.slider-light{
    color:#f4f5fd
}
.MuiSlider-root.slider-light.MuiSlider-trackInverted .MuiSlider-markActive,.MuiSlider-root.slider-light.MuiSlider-trackInverted .MuiSlider-track{
    background:#f4f5fd
}
.MuiSlider-root.slider-light .MuiSlider-thumb{
    border-color:#f4f5fd
}
.MuiSlider-root.slider-light .MuiSlider-thumb.Mui-focusVisible,.MuiSlider-root.slider-light .MuiSlider-thumb:hover{
    border-color:#b3baf1;
    box-shadow:0 0 0 6px rgba(244,245,253,.16)
}
.MuiSlider-root.slider-light .MuiSlider-thumb.MuiSlider-active{
    box-shadow:0 .46875rem 2.1875rem rgba(244,245,253,.8),0 .125rem .5875rem rgba(244,245,253,.9)
}
.MuiSlider-root.slider-gray{
    color:#f8f9ff
}
.MuiSlider-root.slider-gray.MuiSlider-trackInverted .MuiSlider-markActive,.MuiSlider-root.slider-gray.MuiSlider-trackInverted .MuiSlider-track{
    background:#f8f9ff
}
.MuiSlider-root.slider-gray .MuiSlider-thumb{
    border-color:#f8f9ff
}
.MuiSlider-root.slider-gray .MuiSlider-thumb.Mui-focusVisible,.MuiSlider-root.slider-gray .MuiSlider-thumb:hover{
    border-color:#acb7ff;
    box-shadow:0 0 0 6px rgba(248,249,255,.16)
}
.MuiSlider-root.slider-gray .MuiSlider-thumb.MuiSlider-active{
    box-shadow:0 .46875rem 2.1875rem rgba(248,249,255,.8),0 .125rem .5875rem rgba(248,249,255,.9)
}
.MuiSlider-root.slider-dark{
    color:#6c401c
}
.MuiSlider-root.slider-dark.MuiSlider-trackInverted .MuiSlider-markActive,.MuiSlider-root.slider-dark.MuiSlider-trackInverted .MuiSlider-track{
    background:#6c401c
}
.MuiSlider-root.slider-dark .MuiSlider-thumb{
    border-color:#6c401c
}
.MuiSlider-root.slider-dark .MuiSlider-thumb.Mui-focusVisible,.MuiSlider-root.slider-dark .MuiSlider-thumb:hover{
    border-color:#56576e;
    box-shadow:0 0 0 6px rgba(122,123,151,.16)
}
.MuiSlider-root.slider-dark .MuiSlider-thumb.MuiSlider-active{
    box-shadow:0 .46875rem 2.1875rem rgba(122,123,151,.8),0 .125rem .5875rem rgba(122,123,151,.9)
}
.MuiSlider-root.slider-first{
    color:#00892c
}
.MuiSlider-root.slider-first.MuiSlider-trackInverted .MuiSlider-markActive,.MuiSlider-root.slider-first.MuiSlider-trackInverted .MuiSlider-track{
    background:#00892c
}
.MuiSlider-root.slider-first .MuiSlider-thumb{
    border-color:#00892c
}
.MuiSlider-root.slider-first .MuiSlider-thumb.Mui-focusVisible,.MuiSlider-root.slider-first .MuiSlider-thumb:hover{
    border-color:#0067f4;
    box-shadow:0 0 0 6px rgba(65,145,255,.16)
}
.MuiSlider-root.slider-first .MuiSlider-thumb.MuiSlider-active{
    box-shadow:0 .46875rem 2.1875rem rgba(65,145,255,.8),0 .125rem .5875rem rgba(65,145,255,.9)
}
.MuiSlider-root.slider-second{
    color:#070919
}
.MuiSlider-root.slider-second.MuiSlider-trackInverted .MuiSlider-markActive,.MuiSlider-root.slider-second.MuiSlider-trackInverted .MuiSlider-track{
    background:#070919
}
.MuiSlider-root.slider-second .MuiSlider-thumb{
    border-color:#070919
}
.MuiSlider-root.slider-second .MuiSlider-thumb.Mui-focusVisible,.MuiSlider-root.slider-second .MuiSlider-thumb:hover{
    border-color:#000;
    box-shadow:0 0 0 6px rgba(7,9,25,.16)
}
.MuiSlider-root.slider-second .MuiSlider-thumb.MuiSlider-active{
    box-shadow:0 .46875rem 2.1875rem rgba(7,9,25,.8),0 .125rem .5875rem rgba(7,9,25,.9)
}
body .MuiSwitch-root{
    padding:0;
    border-radius:50px;
    transition:all .2s ease-in-out;
    overflow:visible
}
body .MuiSwitch-root .MuiSwitch-track{
    border-radius:50px;
    background-color:#f4f5fd;
    border:1px solid #d1d2db;
    transition:all .2s ease-in-out
}
body .MuiSwitch-root:hover{
    box-shadow:0 .16875rem .1875rem rgba(7,9,25,.05),0 .125rem .1275rem rgba(7,9,25,.06)
}
body .MuiSwitch-root:hover .MuiSwitch-switchBase:not(.Mui-disabled) .MuiSwitch-thumb{
    transform:scale(1.1);
    -webkit-animation-name:SwitchOn;
    animation-name:SwitchOn
}
body .MuiSwitch-root:hover .MuiSwitch-switchBase:not(.Mui-disabled)+.MuiSwitch-track{
    border-color:#a6a6b9
}
body .MuiSwitch-root .MuiSwitch-switchBase{
    padding:0;
    top:2px;
    transform:translateX(2px)
}
body .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked{
    transform:translateX(28px)
}
body .MuiSwitch-root .MuiSwitch-switchBase .MuiIconButton-label,body .MuiSwitch-root .MuiSwitch-switchBase .MuiSwitch-thumb{
    width:100%;
    height:100%
}
body .MuiSwitch-root .MuiSwitch-switchBase .MuiSwitch-thumb{
    background:#fff;
    background:linear-gradient(180deg,#fff,#f8f9ff);
    background-size:100% 100%;
    box-shadow:inset 0 1px 1px #fff,0 1px 3px rgba(0,0,0,.25);
    display:flex;
    align-items:center;
    justify-content:center;
    transform:scale(1);
    transition:all .3s cubic-bezier(.35,0,.25,1);
    -webkit-animation-timing-function:cubic-bezier(.35,0,.25,1);
    animation-timing-function:cubic-bezier(.35,0,.25,1);
    -webkit-animation-duration:.3s;
    animation-duration:.3s;
    -webkit-animation-name:SwitchOff;
    animation-name:SwitchOff
}
body .MuiSwitch-root .MuiSwitch-switchBase+.MuiSwitch-track{
    opacity:1
}
body .MuiSwitch-root.switch-small{
    height:22px;
    width:44px
}
body .MuiSwitch-root.switch-small .MuiSwitch-switchBase{
    height:18px;
    width:18px
}
body .MuiSwitch-root.switch-small .MuiSwitch-switchBase.Mui-checked{
    transform:translateX(24px)
}
body .MuiSwitch-root.switch-medium{
    height:32px
}
body .MuiSwitch-root.switch-medium .MuiSwitch-switchBase{
    height:28px;
    width:28px
}
body .MuiSwitch-root.switch-large{
    height:42px;
    width:84px
}
body .MuiSwitch-root.switch-large .MuiSwitch-switchBase{
    height:38px;
    width:38px
}
body .MuiSwitch-root.switch-large .MuiSwitch-switchBase.Mui-checked{
    transform:translateX(44px)
}
body .MuiSwitch-root.toggle-switch-line{
    height:8px!important
}
body .MuiSwitch-root.toggle-switch-line .MuiSwitch-switchBase{
    top:-10px
}
body .MuiSwitch-root.toggle-switch-line.switch-large .MuiSwitch-switchBase{
    top:-16px
}
body .MuiSwitch-root.toggle-switch-line.switch-small .MuiSwitch-switchBase{
    top:-5px
}
body .MuiSwitch-root.toggle-switch-line .MuiSwitch-track{
    height:8px
}
body .MuiSwitch-root.toggle-switch-square .MuiSwitch-thumb,body .MuiSwitch-root.toggle-switch-square .MuiSwitch-track{
    border-radius:.29rem
}
body .MuiSwitch-root.toggle-switch-primary:hover .MuiSwitch-track{
    border-color:#00892c
}
body .MuiSwitch-root.toggle-switch-primary .Mui-checked{
    color:#00892c
}
body .MuiSwitch-root.toggle-switch-primary .Mui-checked+.MuiSwitch-track{
    background:#00892c;
    border-color:#00892c
}
body .MuiSwitch-root.toggle-switch-secondary:hover .MuiSwitch-track{
    border-color:#f8f9ff
}
body .MuiSwitch-root.toggle-switch-secondary .Mui-checked{
    color:#f8f9ff
}
body .MuiSwitch-root.toggle-switch-secondary .Mui-checked+.MuiSwitch-track{
    background:#f8f9ff;
    border-color:#f8f9ff
}
body .MuiSwitch-root.toggle-switch-success:hover .MuiSwitch-track{
    border-color:#1bc943
}
body .MuiSwitch-root.toggle-switch-success .Mui-checked{
    color:#1bc943
}
body .MuiSwitch-root.toggle-switch-success .Mui-checked+.MuiSwitch-track{
    background:#1bc943;
    border-color:#1bc943
}
body .MuiSwitch-root.toggle-switch-info:hover .MuiSwitch-track{
    border-color:#11c5db
}
body .MuiSwitch-root.toggle-switch-info .Mui-checked{
    color:#11c5db
}
body .MuiSwitch-root.toggle-switch-info .Mui-checked+.MuiSwitch-track{
    background:#11c5db;
    border-color:#11c5db
}
body .MuiSwitch-root.toggle-switch-warning:hover .MuiSwitch-track{
    border-color:#f4772e
}
body .MuiSwitch-root.toggle-switch-warning .Mui-checked{
    color:#f4772e
}
body .MuiSwitch-root.toggle-switch-warning .Mui-checked+.MuiSwitch-track{
    background:#f4772e;
    border-color:#f4772e
}
body .MuiSwitch-root.toggle-switch-danger:hover .MuiSwitch-track{
    border-color:#f83245
}
body .MuiSwitch-root.toggle-switch-danger .Mui-checked{
    color:#f83245
}
body .MuiSwitch-root.toggle-switch-danger .Mui-checked+.MuiSwitch-track{
    background:#f83245;
    border-color:#f83245
}
body .MuiSwitch-root.toggle-switch-light:hover .MuiSwitch-track{
    border-color:#f4f5fd
}
body .MuiSwitch-root.toggle-switch-light .Mui-checked{
    color:#f4f5fd
}
body .MuiSwitch-root.toggle-switch-light .Mui-checked+.MuiSwitch-track{
    background:#f4f5fd;
    border-color:#f4f5fd
}
body .MuiSwitch-root.toggle-switch-gray:hover .MuiSwitch-track{
    border-color:#f8f9ff
}
body .MuiSwitch-root.toggle-switch-gray .Mui-checked{
    color:#f8f9ff
}
body .MuiSwitch-root.toggle-switch-gray .Mui-checked+.MuiSwitch-track{
    background:#f8f9ff;
    border-color:#f8f9ff
}
body .MuiSwitch-root.toggle-switch-dark:hover .MuiSwitch-track{
    border-color:#6c401c
}
body .MuiSwitch-root.toggle-switch-dark .Mui-checked{
    color:#6c401c
}
body .MuiSwitch-root.toggle-switch-dark .Mui-checked+.MuiSwitch-track{
    background:#6c401c;
    border-color:#6c401c
}
body .MuiSwitch-root.toggle-switch-first:hover .MuiSwitch-track{
    border-color:#00892c
}
body .MuiSwitch-root.toggle-switch-first .Mui-checked{
    color:#00892c
}
body .MuiSwitch-root.toggle-switch-first .Mui-checked+.MuiSwitch-track{
    background:#00892c;
    border-color:#00892c
}
body .MuiSwitch-root.toggle-switch-second:hover .MuiSwitch-track{
    border-color:#070919
}
body .MuiSwitch-root.toggle-switch-second .Mui-checked{
    color:#070919
}
body .MuiSwitch-root.toggle-switch-second .Mui-checked+.MuiSwitch-track{
    background:#070919;
    border-color:#070919
}
body .MuiSwitch-root.Mui-disabled .MuiSwitch-track{
    background:#d1d2db
}
@-webkit-keyframes SwitchOn{
    0%{
        transform:scale(1)
    }
    50%{
        transform:scale(1.2)
    }
    to{
        transform:scale(1.1)
    }
}
@keyframes SwitchOn{
    0%{
        transform:scale(1)
    }
    50%{
        transform:scale(1.2)
    }
    to{
        transform:scale(1.1)
    }
}
@-webkit-keyframes SwitchOff{
    0%{
        transform:scale(1.1)
    }
    to{
        transform:scale(1)
    }
}
@keyframes SwitchOff{
    0%{
        transform:scale(1.1)
    }
    to{
        transform:scale(1)
    }
}
body .MuiTabs-root{
    min-height:42px;
    height:42px
}
body .MuiTabs-root,body .MuiTabs-root .MuiTabs-scroller{
    overflow:visible!important
}
body .MuiTabs-root .MuiTabs-flexContainer{
    position:relative;
    z-index:6
}
body .MuiTabs-root .MuiTab-root{
    padding:0 1.5rem;
    min-height:42px;
    height:42px;
    transition:all .2s ease-in-out;
    margin-right:.5rem
}
body .MuiTabs-root .MuiTab-root.MuiTab-font-sm{
    font-size:.875rem!important
}
body .MuiTabs-root .MuiTab-root .MuiTouchRipple-root{
    border-radius:.29rem!important
}
body .MuiTabs-root .MuiTab-root.MuiTab-fullWidth{
    margin:0;
    padding:0 1rem
}
body .MuiTabs-root .MuiTabs-indicator{
    min-height:42px;
    height:42px;
    opacity:1;
    z-index:4;
    border-radius:.29rem;
    transition:all .2s ease-in-out
}
body .MuiTabs-root.nav-tabs-primary .MuiTabs-indicator{
    background:#00892c!important;
    box-shadow:0 16px 26px -10px rgba(60,68,177,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(60,68,177,.2)
}
body .MuiTabs-root.nav-tabs-primary .MuiTab-root:hover{
    color:#00892c
}
body .MuiTabs-root.nav-tabs-primary .MuiTab-root.Mui-selected{
    color:#fff!important
}
body .MuiTabs-root.nav-tabs-secondary .MuiTabs-indicator{
    background:#f8f9ff!important;
    box-shadow:0 16px 26px -10px rgba(248,249,255,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(248,249,255,.2)
}
body .MuiTabs-root.nav-tabs-secondary .MuiTab-root:hover{
    color:#f8f9ff
}
body .MuiTabs-root.nav-tabs-secondary .MuiTab-root.Mui-selected{
    color:#282828!important
}
body .MuiTabs-root.nav-tabs-success .MuiTabs-indicator{
    background:#1bc943!important;
    box-shadow:0 16px 26px -10px rgba(27,201,67,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(27,201,67,.2)
}
body .MuiTabs-root.nav-tabs-success .MuiTab-root:hover{
    color:#1bc943
}
body .MuiTabs-root.nav-tabs-success .MuiTab-root.Mui-selected{
    color:#fff!important
}
body .MuiTabs-root.nav-tabs-info .MuiTabs-indicator{
    background:#11c5db!important;
    box-shadow:0 16px 26px -10px rgba(17,197,219,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(17,197,219,.2)
}
body .MuiTabs-root.nav-tabs-info .MuiTab-root:hover{
    color:#11c5db
}
body .MuiTabs-root.nav-tabs-info .MuiTab-root.Mui-selected{
    color:#fff!important
}
body .MuiTabs-root.nav-tabs-warning .MuiTabs-indicator{
    background:#f4772e!important;
    box-shadow:0 16px 26px -10px rgba(244,119,46,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(244,119,46,.2)
}
body .MuiTabs-root.nav-tabs-warning .MuiTab-root:hover{
    color:#f4772e
}
body .MuiTabs-root.nav-tabs-warning .MuiTab-root.Mui-selected{
    color:#fff!important
}
body .MuiTabs-root.nav-tabs-danger .MuiTabs-indicator{
    background:#f83245!important;
    box-shadow:0 16px 26px -10px rgba(248,50,69,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(248,50,69,.2)
}
body .MuiTabs-root.nav-tabs-danger .MuiTab-root:hover{
    color:#f83245
}
body .MuiTabs-root.nav-tabs-danger .MuiTab-root.Mui-selected{
    color:#fff!important
}
body .MuiTabs-root.nav-tabs-light .MuiTabs-indicator{
    background:#f4f5fd!important;
    box-shadow:0 16px 26px -10px rgba(244,245,253,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(244,245,253,.2)
}
body .MuiTabs-root.nav-tabs-light .MuiTab-root:hover{
    color:#f4f5fd
}
body .MuiTabs-root.nav-tabs-light .MuiTab-root.Mui-selected{
    color:#282828!important
}
body .MuiTabs-root.nav-tabs-gray .MuiTabs-indicator{
    background:#f8f9ff!important;
    box-shadow:0 16px 26px -10px rgba(248,249,255,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(248,249,255,.2)
}
body .MuiTabs-root.nav-tabs-gray .MuiTab-root:hover{
    color:#f8f9ff
}
body .MuiTabs-root.nav-tabs-gray .MuiTab-root.Mui-selected{
    color:#282828!important
}
body .MuiTabs-root.nav-tabs-dark .MuiTabs-indicator{
    background:#6c401c!important;
    box-shadow:0 16px 26px -10px rgba(122,123,151,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(122,123,151,.2)
}
body .MuiTabs-root.nav-tabs-dark .MuiTab-root:hover{
    color:#6c401c
}
body .MuiTabs-root.nav-tabs-dark .MuiTab-root.Mui-selected{
    color:#fff!important
}
body .MuiTabs-root.nav-tabs-first .MuiTabs-indicator{
    background:#00892c!important;
    box-shadow:0 16px 26px -10px rgba(65,145,255,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(65,145,255,.2)
}
body .MuiTabs-root.nav-tabs-first .MuiTab-root:hover{
    color:#00892c
}
body .MuiTabs-root.nav-tabs-first .MuiTab-root.Mui-selected{
    color:#fff!important
}
body .MuiTabs-root.nav-tabs-second .MuiTabs-indicator{
    background:#070919!important;
    box-shadow:0 16px 26px -10px rgba(7,9,25,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(7,9,25,.2)
}
body .MuiTabs-root.nav-tabs-second .MuiTab-root:hover{
    color:#070919
}
body .MuiTabs-root.nav-tabs-second .MuiTab-root.Mui-selected{
    color:#fff!important
}
.tabs-bordered{
    position:relative
}
.tabs-bordered:after{
    content:"";
    left:0;
    width:100%;
    bottom:0;
    margin:0;
    position:absolute;
    height:1px;
    background:#e6e7f1
}
.tab-item-wrapper{
    visibility:hidden;
    opacity:0;
    height:0;
    overflow:hidden;
    transition:all .2s ease-in-out
}
.tab-item-wrapper.active{
    opacity:1;
    height:auto;
    overflow:auto;
    visibility:visible
}
.table{
    font-size:1rem
}
.table thead th{
    text-transform:uppercase;
    background:#f4f5fd;
    font-size:.875rem;
    vertical-align:middle
}
.table tr{
    transition:all .2s ease-in-out
}
.table td,.table th{
    vertical-align:middle
}
.table.table-alternate thead th{
    text-transform:none;
    background:transparent;
    font-size:1.1rem;
    font-weight:400;
    color:#282828
}
.table.table-alternate-spaced{
    border:0
}
.table.table-alternate-spaced thead th{
    background:transparent;
    font-weight:700;
    background:none;
    font-size:.79545rem;
    border:0;
    padding-top:0;
    padding-bottom:.66667rem
}
.table.table-alternate-spaced tbody tr{
    background:transparent
}
.table.table-alternate-spaced tbody tr:hover td:before{
    background:#fffbf2
}
.table.table-alternate-spaced tbody tr td{
    background:transparent;
    position:relative
}
.table.table-alternate-spaced tbody tr td>*{
    position:relative;
    z-index:3
}
.table.table-alternate-spaced tbody tr td:before{
    position:absolute;
    left:0;
    top:0;
    transition:all .2s ease-in-out;
    height:100%;
    width:100%;
    content:"";
    background:#fff;
    border-top:1px solid #e6e7f1;
    border-bottom:1px solid #e6e7f1;
    pointer-events:none
}
.table.table-alternate-spaced tbody tr td:first-child:before{
    border-top-left-radius:.75rem;
    border-bottom-left-radius:.75rem;
    border-left:1px solid #e6e7f1
}
.table.table-alternate-spaced tbody tr td:last-child:before{
    border-top-right-radius:.75rem;
    border-bottom-right-radius:.75rem;
    border-right:1px solid #e6e7f1
}
.table.table-alternate-spaced tbody tr.divider{
    height:1rem
}
.table.table-alternate-spaced tbody td{
    border:0
}
.timeline-list{
    width:100%;
    position:relative;
    padding:1rem 0 0
}
.timeline-list:after{
    content:"";
    display:table;
    clear:both
}
.timeline-list:before{
    content:"";
    position:absolute;
    top:0;
    left:14px;
    height:100%;
    width:4px;
    border-radius:.65rem
}
.timeline-list .timeline-item--icon,.timeline-list:before{
    background:#dfe0ea
}
.timeline-list .timeline-item{
    position:relative;
    padding:.33333rem 0 .33333rem 36px;
    margin:0 0 1rem
}
.timeline-list .timeline-item p{
    margin:0
}
.timeline-list .timeline-item-icon{
    padding:.5rem 0 .5rem 46px
}
.timeline-list .timeline-item--icon{
    height:14px;
    width:14px;
    position:absolute;
    left:-27px;
    top:1px;
    display:block;
    border-radius:20px
}
.timeline-list .timeline-item--icon:after{
    content:"";
    position:absolute;
    background:#fff;
    left:50%;
    top:50%;
    margin:-4px 0 0 -4px;
    display:block;
    width:8px;
    height:8px;
    border-radius:20px
}
.timeline-list .timeline-item--icon.timeline-icon-alternate{
    top:12px
}
.timeline-list .timeline-item--icon.timeline-icon-alternate+.timeline-item--label{
    margin-left:-6px
}
.timeline-list .timeline-item--icon-wrapper{
    border-radius:.75rem;
    width:34px;
    height:34px;
    line-height:34px;
    text-align:center;
    display:block;
    position:absolute;
    left:-47px;
    top:-9px
}
.timeline-list .timeline-item--content{
    font-size:.875rem;
    color:#6c401c;
    position:relative
}
.timeline-list .timeline-item--label{
    font-size:.875rem;
    margin:0;
    color:#070919;
    display:flex;
    align-content:center;
    align-items:center;
    line-height:1.5
}
.timeline-list-offset:before{
    left:76px!important
}
.timeline-list-offset .timeline-item-offset{
    position:absolute;
    left:0;
    opacity:.5;
    width:50px;
    text-align:right
}
.timeline-list-offset .timeline-item-icon{
    padding-left:108px!important
}
.timeline-list-offset .timeline-item-icon .timeline-item-offset{
    top:6px!important;
    font-weight:700
}
.timeline-list-offset .timeline-item{
    padding-left:88px
}
.timeline-list-offset .timeline-item .timeline-item-offset{
    top:3px
}
.timeline-list-offset.timeline-list-offset-dot:before{
    left:66px!important
}
.timeline-list.timeline-list--primary .timeline-item--icon,.timeline-list.timeline-list--primary:before{
    background:#00892c
}
.timeline-list.timeline-list--secondary .timeline-item--icon,.timeline-list.timeline-list--secondary:before{
    background:#f8f9ff
}
.timeline-list.timeline-list--success .timeline-item--icon,.timeline-list.timeline-list--success:before{
    background:#1bc943
}
.timeline-list.timeline-list--info .timeline-item--icon,.timeline-list.timeline-list--info:before{
    background:#11c5db
}
.timeline-list.timeline-list--warning .timeline-item--icon,.timeline-list.timeline-list--warning:before{
    background:#f4772e
}
.timeline-list.timeline-list--danger .timeline-item--icon,.timeline-list.timeline-list--danger:before{
    background:#f83245
}
.timeline-list.timeline-list--light .timeline-item--icon,.timeline-list.timeline-list--light:before{
    background:#f4f5fd
}
.timeline-list.timeline-list--gray .timeline-item--icon,.timeline-list.timeline-list--gray:before{
    background:#f8f9ff
}
.timeline-list.timeline-list--dark .timeline-item--icon,.timeline-list.timeline-list--dark:before{
    background:#6c401c
}
.timeline-list.timeline-list--first .timeline-item--icon,.timeline-list.timeline-list--first:before{
    background:#00892c
}
.timeline-list.timeline-list--second .timeline-item--icon,.timeline-list.timeline-list--second:before{
    background:#070919
}
.timeline-list-horizontal{
    width:100%;
    position:relative;
    padding:0;
    overflow-y:hidden;
    overflow-x:auto
}
.timeline-list-horizontal:before{
    display:none
}
.timeline-list-horizontal ul{
    padding-left:0;
    list-style:none;
    margin:0
}
.timeline-list-horizontal ul li{
    display:table-cell;
    float:none;
    max-width:400px;
    min-width:300px
}
.timeline-list-horizontal ul:before{
    content:"";
    position:absolute;
    top:14px;
    left:0;
    height:4px;
    width:100%;
    border-radius:.65rem;
    background:#dfe0ea
}
.timeline-list-horizontal ul:after{
    content:"";
    display:table;
    clear:both
}
.timeline-list-horizontal .timeline-item--icon{
    background:#dfe0ea
}
.timeline-list-horizontal .timeline-item{
    text-align:center;
    padding:0 1rem
}
.timeline-list-horizontal .timeline-item .timeline-item--content{
    text-align:center;
    margin-top:32px
}
.timeline-list-horizontal .timeline-item .timeline-item--icon{
    top:-23px;
    left:50%;
    margin-left:-7px
}
.timeline-list-horizontal .timeline-item .timeline-item--label{
    font-size:1rem;
    display:block
}
.timeline-list-horizontal .timeline-item-icon .timeline-item--icon-wrapper{
    top:-48px;
    left:50%;
    margin-left:-17px
}
.timeline-list-horizontal .timeline-item-icon .timeline-item--content{
    margin-top:48px
}
.MuiTooltip-tooltip{
    box-shadow:0 .313rem .8rem rgba(122,123,151,.5),0 .126rem .225rem rgba(122,123,151,.3)
}
.tooltip-lg{
    width:300px
}
.MuiTooltip-tooltip.tooltip-primary{
    background:#00892c;
    color:#fff
}
.MuiTooltip-tooltip.tooltip-primary .MuiTooltip-arrow{
    color:#00892c
}
.MuiTooltip-tooltip.tooltip-secondary{
    background:#f8f9ff;
    color:#282828
}
.MuiTooltip-tooltip.tooltip-secondary .MuiTooltip-arrow{
    color:#f8f9ff
}
.MuiTooltip-tooltip.tooltip-success{
    background:#1bc943;
    color:#fff
}
.MuiTooltip-tooltip.tooltip-success .MuiTooltip-arrow{
    color:#1bc943
}
.MuiTooltip-tooltip.tooltip-info{
    background:#11c5db;
    color:#fff
}
.MuiTooltip-tooltip.tooltip-info .MuiTooltip-arrow{
    color:#11c5db
}
.MuiTooltip-tooltip.tooltip-warning{
    background:#f4772e;
    color:#fff
}
.MuiTooltip-tooltip.tooltip-warning .MuiTooltip-arrow{
    color:#f4772e
}
.MuiTooltip-tooltip.tooltip-danger{
    background:#f83245;
    color:#fff
}
.MuiTooltip-tooltip.tooltip-danger .MuiTooltip-arrow{
    color:#f83245
}
.MuiTooltip-tooltip.tooltip-light{
    background:#f4f5fd;
    color:#282828
}
.MuiTooltip-tooltip.tooltip-light .MuiTooltip-arrow{
    color:#f4f5fd
}
.MuiTooltip-tooltip.tooltip-gray{
    background:#f8f9ff;
    color:#282828
}
.MuiTooltip-tooltip.tooltip-gray .MuiTooltip-arrow{
    color:#f8f9ff
}
.MuiTooltip-tooltip.tooltip-dark{
    background:#6c401c;
    color:#fff
}
.MuiTooltip-tooltip.tooltip-dark .MuiTooltip-arrow{
    color:#6c401c
}
.MuiTooltip-tooltip.tooltip-first{
    background:#00892c;
    color:#fff
}
.MuiTooltip-tooltip.tooltip-first .MuiTooltip-arrow{
    color:#00892c
}
.MuiTooltip-tooltip.tooltip-second{
    background:#070919;
    color:#fff
}
.MuiTooltip-tooltip.tooltip-second .MuiTooltip-arrow{
    color:#070919
}
.treeview-primary.MuiTreeView-root .MuiTreeItem-root{
    margin:2px 0
}
.treeview-primary.MuiTreeView-root .MuiTreeItem-root .MuiTreeItem-content{
    padding:.25rem .5rem;
    border-radius:.29rem;
    transition:all .2s ease-in-out;
    color:#282828
}
.treeview-primary.MuiTreeView-root .MuiTreeItem-root .MuiTreeItem-content:hover{
    background:rgba(60,68,177,.08);
    color:#00892c
}
.treeview-primary.MuiTreeView-root .MuiTreeItem-root:focus>.MuiTreeItem-content{
    background:rgba(60,68,177,.6);
    color:#fff
}
.treeview-alt.MuiTreeView-root .MuiTreeItem-root{
    margin:2px 0
}
.treeview-alt.MuiTreeView-root .MuiTreeItem-root .MuiTreeItem-content{
    padding:.25rem .5rem;
    border-radius:.29rem;
    transition:all .2s ease-in-out;
    color:#282828
}
.treeview-alt.MuiTreeView-root .MuiTreeItem-root .MuiTreeItem-content:hover{
    background:#f4f5fd;
    color:#00892c
}
.treeview-alt.MuiTreeView-root .MuiTreeItem-root:focus>.MuiTreeItem-content{
    background:#eeeff8;
    color:#070919
}
.app-header{
    height:74px;
    padding:0 1rem;
    background:#fff;
    justify-content:space-between;
    position:relative;
    z-index:1111;
    transition:all .5s cubic-bezier(.685,.0473,.346,1)
}
.app-header,.app-header--pane{
    align-items:center;
    display:flex
}
@media (max-width:1099.98px){
    .app-header .app-header--pane+.app-header--pane{
        transition:all .2s ease-in-out;
        transform:scale(1);
        visibility:visible
    }
}
.app-header--shadow{
    box-shadow:0 .46875rem 2.1875rem rgba(59,62,102,.03),0 .9375rem 1.40625rem rgba(59,62,102,.03),0 .25rem .53125rem rgba(59,62,102,.05),0 .125rem .1875rem rgba(59,62,102,.03)
}
.app-header--opacity-bg{
    background:hsla(0,0%,100%,.95)
}
.app-header .hamburger-inner,.app-header .hamburger-inner:after,.app-header .hamburger-inner:before{
    background:#00892c!important
}
.app-header .toggle-mobile-sidebar-btn{
    display:none;
    padding:0
}
.app-header .toggle-mobile-sidebar-btn .MuiButton-label{
    display:flex
}
.app-header:hover{
    background:#fff!important
}
@media (min-width:1100px){
    .app-header-fixed .app-header{
        position:fixed;
        left:320px;
        right:0;
        margin-left:0
    }
}
@media (min-width:1100px){
    .app-sidebar-fixed:not(.app-header-fixed) .app-header{
        margin-left:320px
    }
}
@media (min-width:1100px){
    .app-sidebar-collapsed:not(.app-header-fixed) .app-header{
        margin-left:100px;
        left:0
    }
    .app-sidebar-collapsed:not(.app-header-fixed).app-header-fixed .app-header{
        left:100px;
        margin-left:0
    }
}
@media (min-width:1100px) and (min-width:1100px){
    .app-sidebar-collapsed.app-header-fixed .app-header{
        left:100px
    }
}
@media (max-width:1099.98px){
    .app-header{
        left:0;
        flex-shrink:0;
        padding:0 1rem
    }
    .app-header .toggle-mobile-sidebar-btn{
        display:flex
    }
}
.header-search-wrapper{
    border-right:1px solid rgba(7,9,25,.1);
    padding:.28571rem 1rem .28571rem 0;
    margin:0 2rem 0 0;
    cursor:pointer
}
.header-search-wrapper .icon-wrapper{
    position:absolute;
    left:12px;
    top:7px;
    z-index:10
}
.header-search-wrapper .search-wrapper .MuiFormControl-root{
    padding:0;
    width:42px;
    opacity:0;
    transition:all .2s ease-in-out;
    cursor:pointer
}
.header-search-wrapper .search-wrapper .MuiInputBase-root{
    height:36px
}
.header-search-wrapper .search-wrapper .MuiInputBase-root input{
    cursor:pointer
}
.header-search-wrapper .search-wrapper.is-active .MuiInputBase-root input{
    cursor:text
}
@media (max-width:1099.98px){
    .header-search-wrapper{
        border-left:1px solid rgba(7,9,25,.1);
        border-right:1px solid transparent;
        padding:.28571rem .5rem;
        margin:0 1rem
    }
}
.search-wrapper-open .header-search-wrapper{
    border-right-color:transparent
}
.search-wrapper-open .header-search-wrapper .search-wrapper .MuiFormControl-root{
    padding:0;
    width:100%;
    opacity:1;
    visibility:visible
}
.search-wrapper-open .header-search-wrapper .search-wrapper .MuiFormControl-root .MuiInputBase-input{
    padding-left:42px
}
.search-wrapper-open .app-header-widget{
    transform:scale(0);
    opacity:0;
    visibility:hidden
}
@media (max-width:1099.98px){
    .search-wrapper-open .app-header--pane+.app-header--pane{
        transform:scale(0);
        opacity:0;
        visibility:hidden
    }
}
.d-44{
    height:44px;
    line-height:44px;
    width:44px
}
.d-44 .badge-circle{
    position:absolute;
    left:-5px;
    top:-2px!important;
    box-shadow:0 0 0 3px #fff
}
.d-44 .badge-header-alt{
    border:0;
    top:1px!important;
    box-shadow:0 0 0 0 transparent;
    height:6px;
    width:6px;
    left:50%;
    margin-left:-3px
}
.app-header-widget{
    position:relative;
    transition:all .2s ease-in-out;
    transform:scale(1);
    visibility:visible;
    transition-delay:.2s
}
@media (max-width:1099.98px){
    .app-header-widget{
        display:none
    }
}
.app-sidebar{
    width:320px;
    flex-direction:column;
    display:flex;
    height:100%;
    transition:all .5s cubic-bezier(.685,.0473,.346,1);
    z-index:1115;
    position:relative
}
@media (min-width:1100px){
    .app-sidebar--shadow{
        box-shadow:.46875rem 0 2.1875rem rgba(59,62,102,.03),.9375rem 0 1.40625rem rgba(59,62,102,.03),.25rem 0 .53125rem rgba(59,62,102,.05),.125rem 0 .1875rem rgba(59,62,102,.03)
    }
}
.app-sidebar--content{
    overflow-y:auto;
    overflow-x:visible;
    height:auto
}
.app-sidebar--collapsed{
    width:100px
}
.app-sidebar--collapsed .app-sidebar--header{
    justify-content:center
}
.app-sidebar-fixed .app-sidebar{
    position:fixed;
    left:0
}
.app-sidebar-fixed .app-sidebar--content{
    height:100%
}
.app-sidebar-overlay{
    width:0;
    height:0;
    opacity:0;
    visibility:hidden;
    transition:opacity .2s;
    position:fixed;
    left:0;
    top:0;
    right:0;
    bottom:0;
    background:rgba(7,9,25,.4);
    z-index:1113
}
@media (max-width:1380.98px){
    .app-sidebar-overlay.is-active{
        opacity:1;
        visibility:visible;
        width:100%;
        height:100%
    }
}
@media (max-width:1099.98px){
    .app-sidebar{
        position:fixed!important;
        left:-320px!important
    }
    .app-sidebar-mobile-open .app-sidebar{
        left:0!important;
        box-shadow:.46875rem 0 2.1875rem rgba(59,62,102,.03),.9375rem 0 1.40625rem rgba(59,62,102,.03),.25rem 0 .53125rem rgba(59,62,102,.05),.125rem 0 .1875rem rgba(59,62,102,.03)
    }
}
@media (min-width:1100px){
    .app-sidebar-collapsed .app-sidebar{
        width:100px;
        position:fixed;
        left:0;
        display:flex
    }
    .app-sidebar-collapsed .app-sidebar:hover:not(.app-sidebar--mini){
        width:320px;
        position:fixed;
        box-shadow:.46875rem 0 2.1875rem rgba(59,62,102,.03),.9375rem 0 1.40625rem rgba(59,62,102,.03),.25rem 0 .53125rem rgba(59,62,102,.05),.125rem 0 .1875rem rgba(59,62,102,.03)
    }
}
.app-sidebar--dark{
    background:#253053
}
.app-sidebar--dark--content{
    background:transparent
}
.app-sidebar--dark .ps .ps__rail-y:hover,.app-sidebar--dark perfect-scrollbar>.ps.ps--scrolling-y>.ps__rail-y{
    background-color:rgba(7,9,25,.05)!important
}
.app-sidebar--dark .ps__thumb-y{
    background-color:rgba(7,9,25,.15)!important
}
.app-sidebar--light{
    background:#fff
}
.app-sidebar--light--content{
    background:transparent
}
.app-sidebar--light .ps .ps__rail-y:hover,.app-sidebar--light perfect-scrollbar>.ps.ps--scrolling-y>.ps__rail-y{
    background-color:rgba(7,9,25,.03)!important
}
.app-sidebar--light .ps__thumb-y{
    background-color:rgba(7,9,25,.05)!important
}
.app-sidebar--header{
    padding:0 1.5rem;
    min-height:74px;
    height:74px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    position:relative;
    margin-bottom:.75rem
}
.app-sidebar--header .expand-sidebar-btn{
    display:flex;
    align-items:center;
    justify-content:center
}
.app-sidebar--header:after{
    content:"";
    width:100%;
    left:0;
    bottom:0;
    height:1px;
    position:absolute
}
.app-sidebar .toggle-mobile-sidebar-btn{
    display:none;
    padding:0
}
.app-sidebar .toggle-mobile-sidebar-btn .MuiButton-label{
    display:flex
}
@media (max-width:1099.98px){
    .app-sidebar .toggle-mobile-sidebar-btn{
        display:block
    }
}
.app-sidebar-logo{
    display:flex;
    align-items:center;
    transition:all .5s cubic-bezier(.685,.0473,.346,1)
}
.app-sidebar-logo--icon{
    border-radius:.29rem;
    width:40px;
    height:40px;
    background:#fff;
    display:flex;
    align-items:center;
    align-content:center;
    box-shadow:1px 1px 3px rgba(7,9,25,.2)
}
.app-sidebar-logo--icon img{
    width:26px;
    margin:0 auto;
    -webkit-animation:logo-spin 20s linear infinite;
    animation:logo-spin 20s linear infinite
}
.app-sidebar-logo--text{
    line-height:1;
    padding:0 0 0 .66667rem;
    transition:all .5s cubic-bezier(.685,.0473,.346,1)
}
.app-sidebar-logo--text span{
    display:block;
    text-transform:uppercase;
    font-size:.72917rem;
    color:#fff;
    opacity:.7;
    padding:4px 0
}
.app-sidebar-logo--text b{
    font-size:1.265rem;
    font-weight:700;
    color:#fff;
    text-transform:lowercase
}
.app-sidebar-logo:hover{
    color:#070919
}
@-webkit-keyframes logo-spin{
    0%{
        transform:rotate(0deg)
    }
    to{
        transform:rotate(1turn)
    }
}
@keyframes logo-spin{
    0%{
        transform:rotate(0deg)
    }
    to{
        transform:rotate(1turn)
    }
}
.app-sidebar--dark .app-sidebar--header{
    background:transparent
}
.app-sidebar--dark .app-sidebar--header .collapse-sidebar-btn{
    background:transparent;
    color:hsla(0,0%,100%,.8);
    opacity:1
}
.app-sidebar--dark .app-sidebar--header .collapse-sidebar-btn:hover{
    background:rgba(7,9,25,.2);
    color:#fff
}
.app-sidebar--dark .app-sidebar--header .expand-sidebar-btn{
    border:2px solid #fff;
    background:#fff;
    color:#00892c;
    box-shadow:0 .313rem .8rem rgba(122,123,151,.5),0 .126rem .225rem rgba(122,123,151,.3)
}
.app-sidebar--dark .app-sidebar--header:after{
    background:hsla(0,0%,100%,.15)
}
.app-sidebar--dark .app-sidebar-logo--icon{
    background:#fff;
    box-shadow:1px 1px 3px rgba(7,9,25,.2)
}
.app-sidebar--dark .app-sidebar-logo--text span{
    color:#fff;
    opacity:.7
}
.app-sidebar--dark .app-sidebar-logo--text b{
    color:#fff
}
.app-sidebar--dark .app-sidebar-logo:hover{
    color:#070919
}
.app-sidebar--dark .hamburger-inner,.app-sidebar--dark .hamburger-inner:after,.app-sidebar--dark .hamburger-inner:before{
    background:#fff!important
}
.app-sidebar--light .app-sidebar--header{
    background:transparent
}
.app-sidebar--light .app-sidebar--header .collapse-sidebar-btn{
    background:transparent;
    color:rgba(60,68,177,.8);
    opacity:1
}
.app-sidebar--light .app-sidebar--header .collapse-sidebar-btn:hover{
    background:rgba(60,68,177,.2);
    color:#00892c
}
.app-sidebar--light .app-sidebar--header .expand-sidebar-btn{
    border:2px solid #00892c;
    background:#00892c;
    color:#fff;
    box-shadow:0 .313rem .8rem rgba(122,123,151,.5),0 .126rem .225rem rgba(122,123,151,.3)
}
.app-sidebar--light .app-sidebar--header:after{
    background:rgba(7,9,25,.08)
}
.app-sidebar--light .app-sidebar-logo--icon{
    background:#00892c;
    box-shadow:1px 1px 3px rgba(7,9,25,.2)
}
.app-sidebar--light .app-sidebar-logo--text span{
    color:#00892c;
    opacity:.7
}
.app-sidebar--light .app-sidebar-logo--text b,.app-sidebar--light .app-sidebar-logo:hover{
    color:#070919
}
.app-sidebar--light .hamburger-inner,.app-sidebar--light .hamburger-inner:after,.app-sidebar--light .hamburger-inner:before{
    background:#00892c!important
}
.app-sidebar--header .collapse-sidebar-btn{
    visibility:visible;
    transition:all .2s ease-in-out;
    padding:0;
    width:36px;
    height:36px;
    line-height:34px
}
.app-sidebar--header .expand-sidebar-btn{
    transition:all .2s ease-in-out;
    position:absolute;
    right:0;
    width:36px;
    height:36px;
    line-height:34px;
    margin:-18px -18px 0 0;
    top:50%;
    padding:0;
    visibility:hidden;
    opacity:0
}
@media (max-width:1099.98px){
    .app-sidebar--header .collapse-sidebar-btn,.app-sidebar--header .expand-sidebar-btn{
        display:none
    }
}
@media (min-width:1100px){
    .app-sidebar-collapsed .app-sidebar .collapse-sidebar-btn{
        visibility:hidden;
        opacity:0;
        display:none
    }
    .app-sidebar-collapsed .app-sidebar--header .app-sidebar-logo{
        margin:0 auto
    }
    .app-sidebar-collapsed .app-sidebar--header .app-sidebar-logo--text{
        opacity:0;
        height:0;
        width:0;
        overflow:hidden;
        visibility:hidden;
        padding:0
    }
    .app-sidebar-collapsed .app-sidebar:not(.app-sidebar--mini):hover .app-sidebar--header .app-sidebar-logo{
        margin:0
    }
    .app-sidebar-collapsed .app-sidebar:not(.app-sidebar--mini):hover .app-sidebar--header .app-sidebar-logo--text{
        opacity:1;
        height:auto;
        width:auto;
        overflow:visible;
        visibility:visible;
        padding:0 0 0 .66667rem
    }
    .app-sidebar-collapsed .app-sidebar:not(.app-sidebar--mini):hover .app-sidebar--header .expand-sidebar-btn{
        visibility:visible
    }
    .app-sidebar-collapsed .app-sidebar:hover .app-sidebar--header .expand-sidebar-btn{
        opacity:1
    }
}
.sidebar-navigation .sidebar-menu-box{
    border-radius:.29rem;
    margin:1rem;
    padding:1rem
}
.sidebar-navigation .sidebar-menu-box ul li a{
    font-size:.875rem;
    border-radius:.29rem;
    padding:0 .75rem
}
.sidebar-navigation .sidebar-menu-box ul li a .sidebar-item-label .badge{
    right:.75rem
}
.sidebar-navigation .sidebar-menu-box ul li ul li a{
    padding:0 0 0 1.5rem
}
.sidebar-navigation .sidebar-menu-box ul li ul li a:after{
    display:none
}
.sidebar-navigation .sidebar-menu-box .sidebar-header{
    padding:0 .75rem .75rem
}
.sidebar-navigation .sidebar-header{
    text-transform:uppercase;
    font-size:.83333rem;
    padding:1rem 1.5rem .75rem;
    height:auto;
    transition:all .2s ease-in-out;
    white-space:nowrap;
    margin:0;
    position:relative
}
.sidebar-navigation .sidebar-header:after{
    position:absolute;
    height:1px;
    left:0;
    bottom:0;
    width:100%;
    opacity:0;
    visibility:hidden;
    transition:all .2s ease-in-out;
    content:""
}
.sidebar-navigation ul{
    padding-left:0;
    list-style:none;
    display:flex;
    flex-direction:column;
    margin:0
}
.sidebar-navigation ul li{
    display:flex;
    flex-direction:column;
    position:relative;
    padding:1px 0
}
.sidebar-navigation ul li>a{
    position:relative;
    display:flex;
    align-items:center;
    align-content:center;
    height:44px;
    padding:0 1.5rem;
    font-size:1.06rem;
    white-space:nowrap;
    font-weight:700
}
.sidebar-navigation ul li>a .sidebar-item-label{
    height:22px;
    line-height:22px;
    transition:all .2s ease-in-out;
    opacity:1;
    width:auto;
    visibility:visible;
    overflow:visible
}
.sidebar-navigation ul li>a .sidebar-item-label .badge{
    position:absolute;
    right:1.5rem;
    height:22px;
    line-height:24px;
    top:50%;
    margin-top:-11px;
    transition:all .2s ease-in-out;
    opacity:1;
    visibility:visible
}
.sidebar-navigation ul li>a .sidebar-icon-indicator{
    transform:rotate(0deg);
    transition:all .2s ease-in-out;
    opacity:.4;
    margin-left:auto;
    width:18px;
    height:18px
}
.sidebar-navigation ul li>a .sidebar-icon-indicator>*{
    max-height:100%;
    width:100%;
    display:block
}
.sidebar-navigation ul li>a.nav-link-simple .sidebar-icon-indicator-right{
    transform:rotate(0deg)!important
}
.sidebar-navigation ul li>a .sidebar-icon{
    margin:0 .55556rem 0 0;
    width:28px;
    min-width:28px;
    height:28px;
    display:flex;
    font-size:1.495rem;
    transition:all .2s ease-in-out;
    align-items:center;
    align-content:center
}
.sidebar-navigation ul li>a .sidebar-icon>*{
    max-height:90%;
    width:90%;
    display:block;
    transition:all .2s ease-in-out
}
.sidebar-navigation ul li>a.active .sidebar-icon-indicator{
    transform:rotate(-90deg)
}
.sidebar-navigation ul li>a.active.nav-link-simple .sidebar-icon-indicator-right{
    transform:rotate(0deg)!important
}
.sidebar-navigation ul li ul{
    overflow:hidden;
    padding:.75rem 0 1rem;
    transition:all .2s ease-in-out
}
.sidebar-navigation ul li ul li{
    padding:1px 1.5rem
}
.sidebar-navigation ul li ul li a{
    border-radius:.65rem;
    font-size:1rem;
    position:relative;
    margin:0;
    font-weight:400;
    padding:0 2.30769rem;
    height:36px;
    white-space:nowrap;
    transition:transform .2s,height .3s,opacity .3s,margin .3s,color .3s,background-color .3s;
    display:flex
}
.sidebar-navigation ul li ul li a .sidebar-item-label{
    transition:none
}
.sidebar-navigation ul li ul li a:after{
    content:"";
    left:12px;
    transition:all .2s ease-in-out;
    width:8px;
    height:8px;
    top:50%;
    position:absolute;
    margin:-4px 0 0 -4px;
    opacity:1;
    border-radius:.75rem
}
.sidebar-navigation ul li ul li a.active,.sidebar-navigation ul li ul li a:hover{
    background:transparent!important
}
.app-sidebar--dark .sidebar-navigation .sidebar-menu-box{
    background:rgba(7,9,25,.15)
}
.app-sidebar--dark .sidebar-navigation .sidebar-header{
    color:hsla(0,0%,100%,.35)
}
.app-sidebar--dark .sidebar-navigation ul li>a{
    color:hsla(0,0%,100%,.65)
}
.app-sidebar--dark .sidebar-navigation ul li>a .sidebar-icon>*{
    color:#fff;
    opacity:.3
}
.app-sidebar--dark .sidebar-navigation ul li ul li a:after{
    background:rgba(7,9,25,.2)
}
.app-sidebar--dark .sidebar-navigation ul li ul li a.active:after,.app-sidebar--dark .sidebar-navigation ul li ul li a:hover:after{
    background:rgba(7,9,25,.5)
}
.app-sidebar--dark .sidebar-navigation ul li>a.active,.app-sidebar--dark .sidebar-navigation ul li>a:hover{
    color:#fff;
    background:rgba(7,9,25,.15)
}
.app-sidebar--dark .sidebar-navigation ul li>a.active .sidebar-icon-indicator,.app-sidebar--dark .sidebar-navigation ul li>a:hover .sidebar-icon-indicator{
    opacity:.7
}
.app-sidebar--dark .sidebar-navigation ul li>a.active .sidebar-icon>*,.app-sidebar--dark .sidebar-navigation ul li>a:hover .sidebar-icon>*{
    opacity:1
}
.app-sidebar-collapsed .app-sidebar--dark:not(:hover) .app-sidebar--content .sidebar-navigation .sidebar-header:after{
    background:hsla(0,0%,100%,.15)
}
.app-sidebar--light .sidebar-navigation .sidebar-menu-box{
    background:rgba(60,68,177,.03)
}
.app-sidebar--light .sidebar-navigation .sidebar-header{
    color:rgba(7,9,25,.85)
}
.app-sidebar--light .sidebar-navigation ul li>a{
    color:rgba(7,9,25,.8)
}
.app-sidebar--light .sidebar-navigation ul li>a .sidebar-icon>*{
    color:#00892c;
    opacity:.45
}
.app-sidebar--light .sidebar-navigation ul li>a .sidebar-icon-indicator{
    opacity:.4
}
.app-sidebar--light .sidebar-navigation ul li ul li a:after{
    background:rgba(7,9,25,.08)
}
.app-sidebar--light .sidebar-navigation ul li ul li a.active:after,.app-sidebar--light .sidebar-navigation ul li ul li a:hover:after{
    background:rgba(7,9,25,.12)
}
.app-sidebar--light .sidebar-navigation ul li>a.active,.app-sidebar--light .sidebar-navigation ul li>a:hover{
    color:#00892c;
    background:rgba(60,68,177,.05)
}
.app-sidebar--light .sidebar-navigation ul li>a.active .sidebar-icon-indicator,.app-sidebar--light .sidebar-navigation ul li>a:hover .sidebar-icon-indicator{
    opacity:.7
}
.app-sidebar--light .sidebar-navigation ul li>a.active .sidebar-icon>*,.app-sidebar--light .sidebar-navigation ul li>a:hover .sidebar-icon>*{
    opacity:1;
    color:#00892c
}
.app-sidebar-collapsed .app-sidebar--light:not(:hover) .app-sidebar--content .sidebar-navigation .sidebar-header:after{
    background:rgba(7,9,25,.15)
}
@media (min-width:1100px){
    .app-sidebar-collapsed .app-sidebar:not(:hover) .app-sidebar--content .sidebar-navigation .sidebar-menu-box{
        padding:.5rem
    }
    .app-sidebar-collapsed .app-sidebar:not(:hover) .app-sidebar--content .sidebar-navigation .sidebar-menu-box .sidebar-header{
        display:none
    }
    .app-sidebar-collapsed .app-sidebar:not(:hover) .app-sidebar--content .sidebar-navigation .sidebar-header{
        padding-top:0;
        padding-bottom:0;
        height:0;
        margin:1.5rem
    }
    .app-sidebar-collapsed .app-sidebar:not(:hover) .app-sidebar--content .sidebar-navigation .sidebar-header:after{
        visibility:visible;
        opacity:1;
        height:1px;
        content:"";
        position:absolute;
        width:80%;
        left:10%
    }
    .app-sidebar-collapsed .app-sidebar:not(:hover) .app-sidebar--content .sidebar-navigation .sidebar-header span{
        visibility:hidden;
        opacity:0;
        height:0;
        overflow:hidden
    }
    .app-sidebar-collapsed .app-sidebar:not(:hover) .app-sidebar--content .sidebar-navigation ul li a{
        font-size:0
    }
    .app-sidebar-collapsed .app-sidebar:not(:hover) .app-sidebar--content .sidebar-navigation ul li a .sidebar-icon{
        margin:0 auto
    }
    .app-sidebar-collapsed .app-sidebar:not(:hover) .app-sidebar--content .sidebar-navigation ul li a .sidebar-icon-indicator{
        opacity:0;
        visibility:hidden;
        height:0;
        width:0;
        overflow:hidden;
        display:none
    }
    .app-sidebar-collapsed .app-sidebar:not(:hover) .app-sidebar--content .sidebar-navigation ul li a .sidebar-item-label{
        opacity:0;
        height:0;
        overflow:hidden;
        width:0;
        visibility:hidden
    }
    .app-sidebar-collapsed .app-sidebar:not(:hover) .app-sidebar--content .sidebar-navigation ul li a .sidebar-item-label .badge{
        opacity:0;
        visibility:hidden
    }
    .app-sidebar-collapsed .app-sidebar:not(:hover) .app-sidebar--content .sidebar-navigation ul li ul{
        height:0;
        padding:0;
        overflow:hidden
    }
}
.app-sidebar-spacer{
    padding:0 1.5rem;
    display:flex;
    justify-content:space-between;
    flex-direction:column;
    transition:all .2s ease-in-out;
    opacity:1;
    visibility:visible;
    overflow:auto;
    overflow-x:hidden
}
.sidebar-widget-footer{
    position:relative;
    padding:3rem 0 1.5rem;
    height:120px
}
.sidebar-widget-footer .sidebar-widget-btn{
    position:relative;
    z-index:4;
    display:flex;
    justify-content:center;
    padding-top:1.5rem
}
.sidebar-widget-footer .sidebar-chart-wrapper{
    z-index:3;
    position:absolute;
    top:0;
    left:0;
    right:0;
    width:100%
}
.sidebar-widget-footer .sidebar-chart-wrapper>div{
    display:flex;
    justify-content:center
}
.app-sidebar-collapsed .app-sidebar .app-sidebar--widget{
    height:0;
    opacity:0;
    visibility:hidden
}
.app-sidebar-collapsed .app-sidebar:not(.app-sidebar--mini):hover .app-sidebar--widget{
    height:auto;
    opacity:1;
    visibility:visible
}
.app-sidebar--overlay:after{
    content:"";
    background:#070919;
    opacity:.45;
    z-index:3;
    position:absolute;
    width:100%;
    height:100%;
    left:0;
    top:0
}
.app-sidebar--overlay>*{
    z-index:4
}
.app-sidebar--dark .app-sidebar--widget .app-sidebar-spacer{
    color:#fff!important
}
.app-sidebar--dark .app-sidebar--widget .text-danger svg{
    color:#fff
}
.app-sidebar--dark .app-sidebar--widget .badge{
    background:hsla(0,0%,100%,.15)!important;
    color:#fff!important
}
.app-sidebar--footer{
    height:54px;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:0;
    transition:all .2s ease-in-out;
    border-radius:.65rem;
    margin:0 1rem
}
.app-sidebar--footer .MuiButton-root{
    box-shadow:none!important;
    font-size:1rem
}
.app-sidebar--footer ul{
    padding-left:0;
    list-style:none;
    margin:0;
    display:flex;
    justify-content:center
}
.app-sidebar--footer ul li{
    border-right:1px solid transparent;
    padding:.25rem 0
}
.app-sidebar--footer ul li:last-child{
    border-right:0
}
.app-sidebar-fixed .app-sidebar--footer{
    margin:0;
    border-radius:0
}
.app-sidebar--dark .app-sidebar--footer{
    background:hsla(0,0%,100%,.06);
    box-shadow:0 -.36875rem .1675rem rgba(7,9,25,.04),0 -.8375rem .30625rem rgba(7,9,25,.04),0 -.2rem .11125rem rgba(7,9,25,.06),0 -.1rem .0775rem rgba(7,9,25,.04)
}
.app-sidebar--dark .app-sidebar--footer .MuiButton-root{
    color:hsla(0,0%,100%,.5)
}
.app-sidebar--dark .app-sidebar--footer .MuiButton-root.active,.app-sidebar--dark .app-sidebar--footer .MuiButton-root:active,.app-sidebar--dark .app-sidebar--footer .MuiButton-root:focus,.app-sidebar--dark .app-sidebar--footer .MuiButton-root:hover{
    color:hsla(0,0%,100%,.9);
    background:hsla(0,0%,100%,.09)
}
.app-sidebar--dark .app-sidebar--footer ul li{
    border-right-color:hsla(0,0%,100%,.08)
}
.app-sidebar--light .app-sidebar--footer{
    background:rgba(60,68,177,.025);
    box-shadow:0 -.46875rem .1375rem rgba(209,210,219,.08),0 -.9375rem .32625rem rgba(209,210,219,.08),0 -.25rem .12125rem rgba(209,210,219,.15),0 -.125rem .0775rem rgba(209,210,219,.08)
}
.app-sidebar--light .app-sidebar--footer .MuiButton-root{
    color:rgba(60,68,177,.7)
}
.app-sidebar--light .app-sidebar--footer .MuiButton-root svg{
    fill:rgba(60,68,177,.7)
}
.app-sidebar--light .app-sidebar--footer .MuiButton-root.active,.app-sidebar--light .app-sidebar--footer .MuiButton-root:active,.app-sidebar--light .app-sidebar--footer .MuiButton-root:focus,.app-sidebar--light .app-sidebar--footer .MuiButton-root:hover{
    color:#00892c;
    background:rgba(60,68,177,.1)
}
.app-sidebar--light .app-sidebar--footer .MuiButton-root.active svg,.app-sidebar--light .app-sidebar--footer .MuiButton-root:active svg,.app-sidebar--light .app-sidebar--footer .MuiButton-root:focus svg,.app-sidebar--light .app-sidebar--footer .MuiButton-root:hover svg{
    fill:#00892c
}
.app-sidebar--light .app-sidebar--footer ul li{
    border-right-color:rgba(60,68,177,.1)
}
@media (min-width:1100px){
    .app-sidebar-collapsed .app-sidebar--footer{
        opacity:0;
        visibility:hidden;
        height:0
    }
    .app-sidebar-collapsed .app-sidebar:not(.app-sidebar--mini):hover .app-sidebar--footer{
        opacity:1;
        visibility:visible;
        height:54px
    }
}
.app-footer{
    height:70px;
    display:flex;
    align-items:center;
    background:#fff;
    width:100%;
    padding:0 3rem;
    transition:all .5s cubic-bezier(.685,.0473,.346,1);
    font-size:.875rem;
    justify-content:space-between
}
.app-footer .MuiList-root{
    justify-content:center
}
.app-footer .MuiList-root .MuiButtonBase-root{
    text-align:center;
    display:flex;
    align-items:center;
    justify-content:center;
    white-space:nowrap
}
.app-footer .MuiList-root .MuiListItem-root{
    width:auto
}
@media (max-width:1380.98px){
    .app-footer{
        display:block;
        text-align:center;
        height:auto;
        padding:.33333rem .5rem .5rem
    }
}
.app-footer--opacity-bg{
    background:hsla(0,0%,100%,.9)
}
.app-footer:hover{
    background:#fff!important
}
.app-footer--shadow{
    box-shadow:0 -.46875rem 2.1875rem rgba(59,62,102,.03),0 -.9375rem 1.40625rem rgba(59,62,102,.03),0 -.25rem .53125rem rgba(59,62,102,.05),0 -.125rem .1875rem rgba(59,62,102,.03)
}
@media (max-width:1380.98px){
    .app-footer--second span:first-child{
        display:block;
        margin-bottom:.5rem
    }
}
.app-footer-fixed .app-footer{
    position:fixed;
    bottom:0;
    z-index:22;
    left:320px;
    right:0;
    width:auto
}
.app-footer-fixed .app-content--inner__wrapper{
    padding-bottom:70px
}
@media (min-width:1100px){
    .app-footer-fixed.app-sidebar-collapsed .app-footer{
        left:100px
    }
}
.popover-share-box{
    padding:15px;
    width:552px;
    max-width:552px
}
.promo-section-wrapper{
    position:fixed;
    right:0;
    top:0;
    z-index:1163;
    display:block;
    height:100vh;
    transition:all .5s cubic-bezier(.685,.0473,.346,1)
}
@media (max-width:1099.98px){
    .promo-section-wrapper{
        display:none;
        opacity:0;
        visibility:hidden
    }
}
.promo-section-buttons-wrapper{
    position:absolute;
    right:0;
    top:45%
}
.promo-section-buttons{
    box-shadow:0 .313rem .8rem rgba(122,123,151,.5),0 .126rem .225rem rgba(122,123,151,.3);
    display:flex;
    padding:5px;
    flex-direction:column;
    align-items:center;
    background:#fff;
    border-top-left-radius:.65rem;
    border-bottom-left-radius:.65rem
}
.promo-section-buttons .btn-squared{
    width:50px;
    height:50px;
    line-height:50px;
    cursor:pointer
}
.app-page-title{
    margin:-3rem -3rem 3rem;
    padding:3rem;
    display:flex;
    justify-content:space-between;
    align-items:center;
    background:hsla(0,0%,100%,.8);
    transition:all .2s ease-in-out
}
@media (max-width:767.98px){
    .app-page-title{
        margin:-1rem -1rem 1rem;
        padding:1rem
    }
}
.app-page-title--shadow{
    box-shadow:0 .46875rem 2.1875rem rgba(59,62,102,.03),0 .9375rem 1.40625rem rgba(59,62,102,.03),0 .25rem .53125rem rgba(59,62,102,.05),0 .125rem .1875rem rgba(59,62,102,.03)
}
@media (max-width:1099.98px){
    .app-page-title{
        flex-direction:column
    }
}
.app-page-title--dark{
    color:#fff
}
.app-page-title--first{
    flex-grow:1;
    display:flex;
    align-items:center
}
@media (max-width:1099.98px){
    .app-page-title--first{
        flex-direction:column;
        margin-bottom:1.5rem
    }
}
.app-page-title--second{
    display:flex;
    align-items:center
}
.app-page-title--heading{
    padding-right:1rem
}
@media (max-width:1099.98px){
    .app-page-title--heading{
        padding-right:0;
        text-align:center
    }
}
.app-page-title--heading h1{
    font-size:1.54545rem;
    font-weight:700;
    margin:0
}
.app-page-title--iconbox{
    background:#fff;
    border-radius:.29rem;
    box-shadow:0 .46875rem 2.1875rem rgba(59,62,102,.03),0 .9375rem 1.40625rem rgba(59,62,102,.03),0 .25rem .53125rem rgba(59,62,102,.05),0 .125rem .1875rem rgba(59,62,102,.03);
    display:flex;
    margin:0 1.5rem 0 0;
    justify-content:center;
    align-items:center
}
@media (max-width:1099.98px){
    .app-page-title--iconbox{
        margin:0 0 1.5rem
    }
}
@media (max-width:767.98px){
    .app-page-title--iconbox{
        display:none
    }
}
.app-page-title--description{
    margin:.5rem 0 0;
    font-size:1.1rem;
    opacity:.6;
    font-weight:400
}
.app-page-title.app-page-title-alt-1{
    background:#fff;
    padding-top:1rem;
    padding-bottom:1rem
}
.app-page-title.app-page-title-alt-1 .app-page-title--heading{
    display:flex;
    align-items:center
}
.app-page-title.app-page-title-alt-1 .app-page-title--heading h1{
    font-size:1.2rem;
    white-space:nowrap
}
.app-page-title.app-page-title-alt-1 .app-page-title--description{
    width:75%;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    margin:0 0 0 .5rem;
    font-size:1rem
}
.app-page-title.app-page-title-alt-1 .app-page-title--iconbox{
    display:none!important
}
.app-page-title.app-page-title-alt-2{
    margin:-3rem 0 3rem;
    padding:3rem;
    border-bottom-right-radius:.65rem;
    border-bottom-left-radius:.65rem
}
.app-page-title.app-page-title-alt-3{
    margin:0 0 3rem;
    padding:2rem 3rem;
    border-radius:.65rem
}
.app-page-title.app-page-title-alt-3 .app-page-title--iconbox{
    border-radius:100%
}
body,html{
    -ms-text-size-adjust:100%;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
    font-smooth:auto;
    height:100%
}
body{
    overflow-x:hidden;
    overflow-y:auto!important;
    padding:0!important
}
.app-wrapper{
    display:flex;
    flex:1 1;
    background:#f4f5fd;
    overflow-x:hidden;
    transition:all .5s cubic-bezier(.685,.0473,.346,1)
}
.app-main{
    display:flex;
    flex:1 1 auto;
    min-width:0;
    flex-direction:column;
    position:relative;
    min-height:100vh
}
.app-content{
    flex:1 1;
    display:flex;
    padding:0;
    flex-direction:column;
    transition:all .5s cubic-bezier(.685,.0473,.346,1)
}
.app-content--inner{
    overflow:hidden;
    padding:3rem;
    display:flex;
    flex:1 1
}
@media (max-width:767.98px){
    .app-content--inner{
        padding:1rem
    }
}
.app-content--inner__wrapper{
    display:block;
    width:100%
}
.app-sidebar-fixed .app-content{
    padding-left:320px
}
@media (max-width:1099.98px){
    .app-content{
        z-index:500;
        padding-left:0!important
    }
}
@media (min-width:1100px){
    .app-header-fixed .app-content{
        padding-top:74px
    }
}
@media (min-width:1100px){
    .app-sidebar-collapsed .app-content{
        padding-left:100px
    }
}
.fade-enter{
    opacity:.01
}
.fade-enter.fade-enter-active{
    opacity:1;
    transition:opacity .5s ease-in
}
.fade-exit{
    opacity:1
}
.fade-exit.fade-exit-active{
    opacity:.01;
    transition:opacity .3s ease-in
}
