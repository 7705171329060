@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
html body{
  font-family: "Poppins",-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif!important;
  background: rgb(248, 246, 246);
  background-position: center;
  background-size: cover;
  height: 100vh;
  padding: 0;
  margin: 0;
}
.App {
  display: flex;
  justify-content: center;
  padding: 0;

}
.myconatiner{
  width: 100%;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.fruitdeco{
  position:fixed;
  z-index:1;
  width: 150px;
  z-index: -1;
}
.fruit0{
  top:150px;
  left: 0;
}
.fruit1{
  top:100px;
  right: 0;
}
.fruit2{
  bottom:0;
  left: 0;
}
.fruit3{
  bottom:0;
  right: 0;
}
.grassfooter{
  position:fixed;
  bottom:-50px;
  z-index: -2;
  width: 100%;
  left: 0;
  right: 0;
}
.header-top-section{
    z-index: 28;
    width: 100%;
    position: relative;
}
.shadow-xxl {
    box-shadow: 0 .18rem .3rem rgba(166,166,185,.5),0 .326rem 3rem rgba(122,123,151,.3)!important;
}
.bg-white {
    background-color: #fff!important;
}
.header-nav-wrapper {
    z-index: 27;
}
.header-nav-wrapper, .header-nav-wrapper>.container {
    align-items: center;
    display: flex;
}
.header-nav-wrapper {
    min-height: 74px;
    position: relative;
}
.flex-column {
    flex-direction: column!important;
}
.MuiContainer-root {
    width: 100%;
    display: block;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
}
.align-items-center {
    align-items: center!important;
}
@media (min-width: 576px){
.MuiContainer-root {
    padding-left: 24px;
    padding-right: 24px;
}
}

@media (min-width: 1100px){
.MuiContainer-maxWidthLg {
    max-width: 1100px;
}
}

.MuiButton-text {
    padding: 10px 22px;
    font-size: 14px;
}
.MuiButtonBase-root {
    color: inherit;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}
.MuiButton-root {
    color: rgba(0, 0, 0, 0.87);
    padding: 10px 22px;
    font-size: 14px;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: Heebo,sans-serif;
    font-weight: normal;
    line-height: 1.75;
    border-radius: 0.2rem;
    text-transform: none;
}
.find{
  display: flex;
  border: 1px solid #c7c7cd;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: .875rem;
  margin-right:10px;
}
.searchicon{
  margin-left:8px;
  margin-right:10px;
  font-size: 20px;
}

.searchInput{
  border:none;
  margin-right: 8px;
  width: 100%;
}
.closeicon{
  margin-right:8px;
  background-color: rgba(0,0,0,0);
  border: 0;
  font-size: 24px;
}
.btnicon{
  font-size: 20px;
}

.header-nav-actions a{
  color: white!important;
}
.header-nav-actions a:hover{
  color: #05375a!important;
}
@media (max-width:1099.98px){
    .myconatiner{
      width: 100%;
    }
    .maincontainer{
      padding-top: 140px!important;
    }
    
}
.btniconcart{
      font-size: 23px!important;
    }
@media (max-width:730px){
    .app-nav-logo--icon img{
      width: 50px!important;
    }
    .maincontainer{
      padding-top: 80px!important;
    }
}
.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  border:none
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2099d5d5;
}
.sidebarcont{
  display: flex;
  width: 80%;
  background-color: white;
  z-index: 9999999999999;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: -100vw;
  position: fixed;
  transition: all 0.5s;
  overflow-y: auto;
}
.sidebartouchable{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 20%;;
  height: 100vh;
  background-color: rgba(0,0,0,0.32);
  z-index: 9999999999998;
  transition: all 0.5s;
}
.sidebarHeader{
  background-color: rgba(166,166,185,.5);
  width: 100%;
  padding: 5px 8px;
}
.shadow-0{
  padding: 0px!important;
  -webkit-box-shadow: 1px 2px 5px -1px rgba(255,255,255,0)!important;;
  -moz-box-shadow: 1px 2px 5px -1px rgba(255,255,255,0)!important;;
  box-shadow: 1px 2px 5px -1px rgba(255,255,255,0)!important;;
  border-radius: 0px!important;
}
.closeBtn{
  cursor:pointer;
  align-self: flex-end;
}
.closeBtn:hover i{
  size: 25px!important;
}
.mobilesearchCont{
  width: 90%!important;
}
.flex-1{
  flex: 1;
}
.mobilesearchCont form{
  height: 30px;
}
.pcsearchCont form{
  height: 40px!important;
}
.maincontainer{
  display: block;
  padding-top: 90px;
  width: 100%;
}
.categorie-container{
  background: white;
  padding: 10px;
  -webkit-box-shadow: 1px 2px 5px -1px rgba(117,108,117,0.5);
    -moz-box-shadow: 1px 2px 5px -1px rgba(117,108,117,0.5);
    box-shadow: 1px 2px 5px -1px rgba(117,108,117,0.5);
border-radius: 10px;
overflow: hidden;
}
.categorie-container:hover{
  background: white;
}
.categorie-container a{
  color: #282828;
  font-size: .90rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  display: block;
  margin-bottom: 8px;
  
}
.categorie-container a i{
  color: #49280e;
  font-size: 1rem;
  margin-right: 10px;
}
.mobilesearchCont form button[type="submit"]{
  border: 1px solid #c7c7cd;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  background: transparent;
}
.sliderimg{
  max-width: 100%;
}
.slidercontainer{
   -webkit-box-shadow: 1px 2px 5px -1px rgba(117,108,117,0.5);
    -moz-box-shadow: 1px 2px 5px -1px rgba(117,108,117,0.5);
    box-shadow: 1px 2px 5px -1px rgba(117,108,117,0.5);
    border-radius: 10px;
    overflow: hidden;
    background-color: white;
    padding: 0%;
}


.speedlink a{
  background-color: white!important;
  padding: 10px 10px;
  border-radius: 5px;
  display: block;
}
.myshadow{
  -webkit-box-shadow: 1px 2px 5px -1px rgba(117,108,117,0.5);
    -moz-box-shadow: 1px 2px 5px -1px rgba(117,108,117,0.5);
    box-shadow: 1px 2px 5px -1px rgba(117,108,117,0.5);
}
.speedlinkIcon{
  height: 40px!important;
  width: 40px!important;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  color: white;
}
.catlink-2 .ttext{
  text-align: center;
  font-size: 12px;
}
.mobilespeedlink{
  background-color: white;
  display: flex;
  justify-content: space-between;
}
.mobilespeedlink .linkmob{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.mobilespeedlink .linkmob .label{
  font-size: 12px;
}
.br-5{
  border-radius: 5px;
  overflow: hidden;
}
.mytitle{
  min-height: 48px;
  font-weight: 500;
  font-size: 1.25rem;
  background-color: #49280e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 15px;
}
.mytitle a{
  color: white!important;
  font-size: 1rem;
}
.article{
    min-width: calc(25% - 16px);
    overflow: hidden;
    cursor: pointer;
    display: block;
    margin: 8px;
    background-color: #fff!important;
    box-shadow: 0 0 12px 0 rgba(0,0,0,0.12);
    position: relative;
}
.slick-prev{
  left: 0!important;
}
.slick-next{
  right: 0!important;
}
.articleimg{
  height: 250px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all .5s;
  position: relative;
}

.article:hover{
    box-shadow: 0 0 12px 0 rgba(0,0,0,0.32);
}
.articlename{
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 8px 8px 4px 8px;
  direction: ltr;
  overflow: hidden;
  color: #807c7c;
  font-size: 16px;
  font-weight: 500;
}
.articleprice{
  text-align: left;
  padding: 8px 4px 4px 8px;
  direction: ltr;
  font-size: 1rem;
  color:#d45b05 ;
  font-weight: bold;
}
.qterestant{
  padding: 8px 4px 4px 8px;
  color: #6b6868;
  font-size: 15px;
  font-weight: bold;
  justify-content: space-between;
}
.qterestant i{
  font-size: 20px;
}
.articlereduction{
  position: absolute;
    color: white;
    padding: 10px;
    border-radius: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    background:#00892c;
    top: 0;
    right: 0;
}
@media (max-width: 768px){
  .articlereduction {
    right: 15px;
  }
}
.articlepromo{
  font-size: .800rem;
  text-decoration: line-through;
  font-weight: 400;
  padding: 4px 8px 4px 8px;
  color: #75757a;
}
.slick-prev:before, .slick-next:before {
    color: #2098d5!important;
}
.footer{
  width: 100%;
}
.footer .f-1{
  background-color: rgb(11, 156, 23);
  color: #054710;
  padding: 10px;
}
.footer .f-2{
  background-color: #0b1425;
  color: white;
  padding: 10px;
}
.footer .f-3{
  background-color: #0f0f0f;
  color: white;
  padding: 10px;
  font-weight: lighter;
  font-size: 13px;
}
.media-body h4{
  font-weight: bolder;
  font-size: 20px;
  font-family: 'Poppins', "Roboto";
}
.media-body p{
  font-weight: lighter;
  font-size: 14px;
  font-family: 'Poppins', "Roboto";
}
@media (max-width:480px){
    .mytitle span {
      font-size: 15px!important;
    }
    .mytitle a {
      font-size: 13px!important;
    }
    .articleimg{
      height: 250px;
      width: 100%;
    }
    .mobilespeedlink .linkmob .label{
      font-size: 9px;
      text-align: center;
      margin: 0!important;
      margin-top: 5px!important;
    }
    .auth-inner {
      width: 100%!important;
      padding: 10px 15px 15px 15px!important;
    }
}
.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: left;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}

.auth-wrapper .form-control:focus {
  border-color: #00892c;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #00892c;
}
.dashmenulinkCont{
  background-color: #dedcdc!important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.dashmenulink{
  padding: 10px 5px;
  margin:0px!important;
  color:#000000!important;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  border-bottom: 2px solid #dedcdc;
  
}
.dashmenucont{
  background-color: #002b89!important;
  padding:0!important;
}

.dashmenulink:hover,.dahsmenuactive{
  color: #0077b5!important;
  border-bottom-color: #0077b5;
}
.dashmenulink:hover i, .dahsmenuactive i{
    color: #ffffff!important;
}
.dashmenulink i{
    color: #ffffff!important;
}
.selectedLink i{
  color: #002f5e!important;
}
.selectedLink span{
  color: #002f5e!important;
}
.dashcontainer{
  background-color: rgb(253, 251, 251);
  border-radius: 10px!important;
  overflow: hidden;
  padding: 20px 40px;
}
.inscbtn{
  border: 1px solid #00892c!important;
}
.previewimgcont{
  border:1px solid #00892c!important;
  height: 100px;
  width: 100px;
  border-radius: 10px;
  position: relative;
  display: block;
  cursor: pointer;
  margin-right: 10px;
}
.photo-principale{
  color:white;
  position:absolute;
  text-align: center;
  margin:auto;
  width: 90px;
  bottom:10px;
  z-index:9;
  background-color: #00892c;
  font-size: 10px;
  border-radius: 10px;
  left: 0;
  right: 0;
}
.picturecont{
  display:flex;
}
.deleteimage{
  display:flex;
    position: absolute;
    top: 0;
    right: 0;
    background: #e10f58;
    border: none;
    width: 20px;
    height: 20px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 16;
    display: none;
    justify-content: center;
    align-content: center;
    align-items: center;
    cursor: pointer;
}
.prev_img {
    position: absolute;
    width: 105%;
    height: 105%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px;
    top: -3px;
    left: -3px;
    z-index: 4;
}

.removepicbtn{
  background-color: red;
  border-radius: 10px;
  position:absolute;
  z-index: 5;
  right: 10px;
  top: 0px;
  padding: 2px;
  cursor: pointer;
}
.removepicbtn i{
  color: white;
  font-weight: 5px;
}
.prevcont{
  position: relative;
}
.menusous{
  display: flex;
  justify-content: center;
}
.menusous div{
  flex:1;
  border-radius: 0!important;
}

/* style filter */
.MuiMenu-paper {
    -webkit-overflow-scrolling: touch;
}
.MuiPopover-paper {
    outline: 0;
    position: absolute!important;
    z-index: 9999;
    background-color: #fff;
}
.MuiPaper-elevation8 {
    box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3);
}
.MuiPaper-rounded {
    border-radius: 0.2rem;
}
.MuiPaper-root {
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    
}
.rounded {
    border-radius: .65rem!important;
}
.MuiGrid-item, .MuiPaper-root {
    position: relative;
}
.dropdown-menu-xxl {
    width: 30rem;
}
.divider {
    background: #eeeff8;
    height: 1px;
    overflow: hidden;
}
.MuiGrid-spacing-xs-6 {
    width: calc(100% + 48px);
    margin: -24px;
}
.MuiGrid-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}
.MuiGrid-spacing-xs-6 > .MuiGrid-item {
    padding: 24px;
}

@media (min-width: 768px){
.MuiGrid-grid-md-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
}
}
.MuiGrid-item {
    margin: 0;
    box-sizing: border-box;
}
.MuiGrid-item, .MuiPaper-root {
    position: relative;
}
.MuiInputBase-root:not(.MuiInput-underline) {
    transition: all .2s ease-in-out;
}

.MuiOutlinedInput-root {
    position: relative;
    border-radius: 0.2rem;
}
.MuiInputBase-fullWidth {
    width: 100%;
}
.containerfilter{
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  width:100%;
  height: 100%;
}
#mybadge{
  position: absolute;
    right: -10px;
    top: -13px;
    background: black;
    color: white!important;
    padding: 5px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border-radius: 50px;
}
/* cart page */

#site-header, #site-footer {
	background: #fff;
}

#site-header {
	margin: 0 0 30px 0;
}

#site-header h1 {
	font-size: 31px;
	font-weight: 300;
	padding: 40px 0;
	position: relative;
	margin: 0;
}

#site-header h1 span {
	color: #00892c;
}

#site-header h1:hover span.last-span, #site-header h1 span.is-open {
	left: 363px;
}

#site-header h1 em {
	font-size: 16px;
	font-style: normal;
	vertical-align: middle;
}

.container {
	font-family: 'Open Sans', sans-serif;
	margin: 0 auto;
	width: 980px;
}

#cart {
	width: 100%;
}

#cart h1 {
	font-weight: 300;
}

.product {
	margin: 20px 0;
	width: 100%;
	position: relative;
	-webkit-transition: margin .2s linear, opacity .2s linear;
	-moz-transition: margin .2s linear, opacity .2s linear;
	-ms-transition: margin .2s linear, opacity .2s linear;
	-o-transition: margin .2s linear, opacity .2s linear;
	transition: margin .2s linear, opacity .2s linear;
}

.product img {
	width: 75px;
}

.product header, .product .content {
	background-color: #fff;
	border: 1px solid #ccc;
	border-style: none none solid none;
	float: left;
}

.product header {
	background: #000;
	margin: 0 1% 20px 0;
	overflow: hidden;
	padding: 0;
	position: relative;
	width: 24%;
	height: 155px;
}

.product header:hover img {
	opacity: .7;
}

.product header:hover h3 {
	bottom: 73px;
}

.product header h3 {
	background: #00892c;
	color: #fff;
	font-size: 22px;
	font-weight: 300;
	line-height: 49px;
	margin: 0;
	padding: 0 30px;
	position: absolute;
	bottom: -50px;
	right: 0;
	left: 0;

	-webkit-transition: bottom .2s linear;
	-moz-transition: bottom .2s linear;
	-ms-transition: bottom .2s linear;
	-o-transition: bottom .2s linear;
	transition: bottom .2s linear;
}

.remove {
	cursor: pointer;
}

.product .content {
	box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 0px;
  width: 100%;
}

.product h1 {
	color: #00892c;
	font-size: 25px;
	font-weight: 300;
	margin: 17px 0 20px 0;
}

.product footer.content {
	height: 50px;
	margin: 6px 0 0 0;
  padding: 0;
  margin-bottom: 20px;
}

.product footer .price {
	background: #fcfcfc;
	color: #000;
	float: right;
	font-size: 15px;
	font-weight: 300;
	line-height: 49px;
	margin: 0;
	padding: 0 30px;
}

.product footer .full-price {
	background: #00892c;
	color: #fff;
	float: right;
	font-size: 22px;
	font-weight: 300;
	line-height: 49px;
	margin: 0;
	padding: 0 30px;

	-webkit-transition: margin .15s linear;
	-moz-transition: margin .15s linear;
	-ms-transition: margin .15s linear;
	-o-transition: margin .15s linear;
	transition: margin .15s linear;
}



.qt {
	font-size: 19px;
  text-align: center;
  border: 1px solid gray;
  height: 50px!important;
  width: 50px!important;
}

.qt-plus, .qt-minus {
	background: #fcfcfc;
	border: none;
	font-size: 30px;
	font-weight: 300;
	height: 100%;
	padding: 0 20px;
	-webkit-transition: background .2s linear;
	-moz-transition: background .2s linear;
	-ms-transition: background .2s linear;
	-o-transition: background .2s linear;
	transition: background .2s linear;
}

.qt-plus:hover, .qt-minus:hover {
	background: #00892c;
	color: #fff;
	cursor: pointer;
}

.qt-plus {
	line-height: 50px;
}

.qt-minus {
	line-height: 47px;
}

#site-footer {
	margin: 20px 0 0 0;
}

#site-footer {
  padding: 10px;
  border:1px solid #ccc;
}

#site-footer h1 {
	background: #fcfcfc;
	border: 1px solid #ccc;
	border-style: none none solid none;
	font-size: 24px;
	font-weight: 300;
	margin: 0 0 7px 0;
	padding: 14px 40px;
	text-align: center;
}

#site-footer h2 {
	font-size: 24px;
	font-weight: 300;
	margin: 10px 0 0 0;
}

#site-footer h3 {
	font-size: 19px;
	font-weight: 300;
	margin: 15px 0;
}

.left {
	float: left;
}

.right {
	float: right;
}

.btnn {
	background: #00892c!important;
	border: 1px solid #999;
	border-style: none none solid none;
	cursor: pointer;
	display: block;
	color: #fff!important;
	font-size: 20px;
	font-weight: 300;
	padding: 16px 0;
	width: 100%;
	text-align: center;

	-webkit-transition: all .2s linear;
	-moz-transition: all .2s linear;
	-ms-transition: all .2s linear;
	-o-transition: all .2s linear;
	transition: all .2s linear;
}

.btnn:hover {
	color: #fff;
	background: #429188;
}

.type {
	background: #fcfcfc;
	font-size: 13px;
	padding: 10px 16px;
	left: 100%;
}

.type, .color {
	border: 1px solid #ccc;
	border-style: none none solid none;
	position: absolute;
}

.color {
	width: 40px;
	height: 40px;
	right: -40px;
}

.red {
	background: #cb5a5e;
}

.yellow {
	background: #f1c40f;
}

.blue {
	background: #3598dc;
}

.minused {
	margin: 0 50px 0 0 !important;
}

.added {
	margin: 0 -50px 0 0 !important;
}

.resultarea{
  transition:.5s;
  background:white;
  padding:10px 20px;
  position: absolute;
  top: 50px;
  width: 100%;
  z-index:5;
  max-height: 400px;
  overflow-y: scroll;
}

.bg-bluev{
    background-color:#2098d5!important
}
.logoimage{
    width: 70px;
    margin-right: 20px;
}
.separator{
  border:#122580 solid 1px;
  width: 50px;
  margin-top: 10px;
}
.articleseparator{
  border:#79767677 solid 1px;
  width: 100%;
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
.animate-flicker {
   -webkit-animation: flickerAnimation 1s infinite;
   -moz-animation: flickerAnimation 1s infinite;
   -o-animation: flickerAnimation 1s infinite;
    animation: flickerAnimation 1s infinite;
}
.jachetebtn{
  text-transform: uppercase;
  background-color:#d45b05!important;
  border-radius: 20px!important;
  padding-left: 15px!important;
  padding-right: 15px!important;
}
.jachetebtn i{
  font-size: 20px;
}

.counterCont{
  position: absolute;
  left: 0 ;
  right: 0;
  margin: 0 auto;
  width: 100%;
  bottom: 20px;
  background-color: rgba(255, 255, 255, 0.878);
  padding: 3px;
  color: #00a2ff;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  border: 1px solid #00a2ff;
}
.articletitle{

}
.text-gray2{
  color:gray;
  font-weight: 600;
}
.articletitleIn{
  color:gray
}
.counterContIn{
  border: 3px solid black;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.tempsRestant{
font-weight: 500;
}
.timerIn{
  font-size: 22px;
}
.actionBar{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.venteprimary{
  color:#0077b5
}
.bg-venteprimary{
  background-color:#0077b5!important
}
.QteCont{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  border-top: 1px rgba(117,108,117,0.3) solid;
  border-bottom: 1px rgba(117,108,117,0.3) solid;
}
.shareCont{
   border-top: 1px rgba(117,108,117,0.3) solid;
    border-bottom: 1px rgba(117,108,117,0.3) solid;
}
.shareCont i{
  font-size: 50px;
}
.btnQte{
  height: 50px!important;
  width: 50px!important;
  border-radius: 100px!important;
  color:white!important;
}
.inputQte{
  border-radius: 30px!important;
  width: 150px!important;
  height: 50px!important;
  text-align: center!important;
  font-weight: bold!important;
}

.ContinueBar{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999999;
  background-color: rgba(0, 0, 0,0.5);
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.ClickZone{
  flex:1;
  width: 100%;
}
.blackbtn{
  border-radius: 0!important;
  background-color: black!important;
  color:white!important;
  letter-spacing: 1px;
  font-weight: 100;
  display: block!important;
  width: 100%!important;
}

.form-controlC{
  border: 1px solid black;
  height: 40px;
  width: 100%;
  padding: 5px 15px;
}
.numberselect .dropdown{
  width: 100%!important;
}

.numberselect .dropdown button{
    width: 100%!important;
    border-radius: unset!important;
    border: 1px solid black!important;
}
.numberselect .dropdown ul li{
    height: unset!important;
}
.profilImg{
  height: 70px;
  width: 70px;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 2px solid black;
  margin-left: auto;
  margin-right: auto;
}
.nameProfil{
  font-weight: bold;
  font-size: 15px;
}
.numProfil{
  font-size: 12px;
}
.details-s{
  font-size: 11px;
}
.prevImgTranche{
  height: 70px;
  width: 70px;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 2px solid black;
}
.mobile-menu{
  position: fixed;
  bottom:0;
  -webkit-box-shadow: 1px 2px 5px -1px rgba(117,108,117,0.5);
  -moz-box-shadow: 1px 2px 5px -1px rgba(117,108,117,0.5);
  box-shadow: 1px 2px 5px -1px rgba(117,108,117,0.5);
  height: 60px;
  width: 100vw;
  padding-right: 10px;
  padding-left: 10px;
  z-index: 99999;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #229cda!important;
  transition: .5s;
}
.mobile-menu .title{
  font-size: 12px;
}
.selectedLink2 i{
  color: #229cda!important;
}
.selectedLink2 span,.selectedLink2 .title{
  color: #229cda!important;
}
.plusCont{
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw; 
  height: 100vh;
  background-color: gainsboro;
  z-index: 99998;
}
.plusbtn:hover,.activebtn{
  color: #229cda!important;
  cursor:pointer
}
@media (max-width:730px){
    .article{
      margin: 2px!important;
    }
    .counterCont{
      font-size: 16px;
    }
    .container{
      padding-left: 5px!important;
      padding-right: 5px!important;
    }
    .timerIn{
      font-size: 15px;
    }
    .jachetebtn{
      text-transform: uppercase;
      font-size: 11px!important;
      font-weight: bold;
    }
    .jachetebtn i{
      font-size: 18px;
    }
    .descCart,.step2Cont{
    font-size: 12px!important;
    }
    .nomCart{
        font-size: 13px!important;
    }
    .priceCart{
      font-size: 12px!important;
    }
    .descCart2{
      font-size: 10px!important;
    }
    .actionBar{
      width: 100%;
    }
    .ActionBarCont{
      position: fixed;
      bottom:0;
      -webkit-box-shadow: 1px 2px 5px -1px rgba(117,108,117,0.5);
      -moz-box-shadow: 1px 2px 5px -1px rgba(117,108,117,0.5);
      box-shadow: 1px 2px 5px -1px rgba(117,108,117,0.5);
      height: 60px;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
      right: 0;
      left: 0;
      z-index: 99998;
      background: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: .5s;
      
    }
}
.ActionBarCont{
  border-top: 1px rgba(117,108,117,0.3) solid;
  border-bottom: 1px rgba(117,108,117,0.3) solid;
  padding-bottom: 10px;
  padding-top: 10px;
}
.iconcont{
  height: 50px!important;
  width: 50px!important;
  border-radius: 100%;
  border: 1.5px solid #0077b5;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: rgba(3, 3, 3, 0.692);
}
.font-size-10{
  font-size: 10px;
}
.font-size-11{
  font-size: 11px;
}
.font-size-12{
  font-size: 12px;
}
.font-size-13{
  font-size: 13px;
}
.font-size-14{
  font-size: 14px;
}

.descCart{
    color: darkgray;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 13px;
}
.nomCart{
    color: darkgray;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 15px;
}
.priceCart{
  font-size: 14px;
  color:#1445af;
  
}

.cartbtn{
  width: 40px!important;
  height: 40px!important;
}
.shareCont2{
  border: 2px solid black;
  padding: 10px;
}
.descCart2{
  color:gray;
  font-size: 13px;
}

.step2Cont{
  color:black;
  font-size: 13px;
}

.SettingClass{
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;
}