.mainwrapper {
			width: 100%;
			height: 600px;
			/* background-image: url(https://i.sdlcdn.com/img/marketing-mailers/mailer/2016/UserGrowth/2018/19dec/mainbg.png); */
			background-repeat: repeat;
			background-size: contain;
			background-position: center;
			position: relative;
		}
		.disabledScr {
			position: relative;
			top: 0;
			left: 0;
			margin: auto;
			pointer-events: none;
			z-index: 14;
		}
		.disabledScr .loader-mask {
			width: 40px;
			text-align: center;
			position: fixed;
			background: url("https://m.snapdeal.com/static/mobile/img/mobile/mobileviews/loader-xhdpi.gif?v=4") #fff no-repeat center center;
			height: 40px;
			background-size: 25px 25px;
			pointer-events: none;
			top: 50%;
			left: 45%;
			right: 0;
			bottom: 0;
			border-radius: 50%;
		}

		@font-face {
			font-family: lemon;
			src: url(https://i.sdlcdn.com/img/marketing-mailers/mailer/2016/UserGrowth/2018/19dec/LemonJellyPersonalUse.ttf);
		}

		@font-face {
			font-family: maison;
			src: url(https://i.sdlcdn.com/img/marketing-mailers/mailer/2016/UserGrowth/2018/13aug/MaisonNeueAPP-Book.otf);
		}

		@font-face {
			font-family: gotham;
			src: url(https://i.sdlcdn.com/img/marketing-mailers/mailer/2016/UserGrowth/2018/19dec/Gotham-Black_0.otf);
		}

		.clr {
			clear: both;
		}

		.answerText {
			font-weight: bold;
			text-align: center;
			margin: 5px 10px;
			font-size: 25px;
			display: inline-block;
			font-family: maison;
			cursor: pointer;
		}

		.answerText span {
			background: #fff;
			padding: 10px;
			width: 100px;
			display: inline-block;
			border-radius: 5px;
		}

		.questionText {
			font-family: maison;
			font-size: 30px;
			text-align: center;
			color: #000;
			margin-bottom: 10px;
		}

		.head1 {
			font-family: lemon;
			font-size: 62px;
			text-align: center;
			color: #ffffff;
			padding-top: 15px;
			text-shadow: 1px 3px 0 #000000;
		}

		.text1 {
			font-family: maison;
			text-align: center;
			color: #ffffff;
			font-size: 20px;
			padding-top: 15px;
			border: none;
		}

		.text2a {
			font-family: maison;
			text-align: center;
			color: #000;
			font-size: 20px;
			line-height: 25px;
			padding: 1%;
			margin-bottom: 10px;
		}

		.text2 {
			font-family: "gotham";
			font-size: 35px;
			text-align: center;
			color: #fce38b;
			text-shadow: 1px 3px 0 #262626;
			padding-top: 15px;
		}

		.hidden {
			display: none;
		}

		.invisible {
			visibility: hidden;
		}

		.wrapper {
			position: absolute;
			-webkit-transform: translate(-50%);
			-ms-transform: translate(-50%);
			transform: translate(-50%);
			left: 50%;
			padding-top: 30px;
		}

		.wrapper .wheel {
			width: 300px;
			height: 300px;
			border-radius: 50%;
			position: relative;
			overflow: hidden;
			border: 10px solid #000000;
			-webkit-transform: rotate(0deg);
			-ms-transform: rotate(0deg);
			transform: rotate(0deg);
		}

		.wrapper #inner-wheel {
			clip-path: circle(50%);
			width: 100%;
			height: 100%;
			-webkit-transition: all 6s cubic-bezier(0, 0.99, 0.44, 0.99);
			-o-transition: all 6s cubic-bezier(0, 0.99, 0.44, 0.99);
			transition: all 6s cubic-bezier(0, 0.99, 0.44, 0.99);
		}

		.wrapper .wheel div.sec {
			position: absolute;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 150px 90px 0;
			border-color: #19c transparent;
			-webkit-transform-origin: 90px 150px;
			-ms-transform-origin: 90px 150px;
			transform-origin: 90px 150px;
			left: 50px;
			top: -10px;
			opacity: 1;
		}

		.wrapper #spin {
			width: 68px;
			height: 68px;
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -34px 0 0 -34px;
			border-radius: 50%;
			-webkit-box-shadow: rgba(0, 0, 0, 1) 0px 0px 30px;
			box-shadow: rgba(0, 0, 0, 1) 0px 0px 30px;
			z-index: 10;
			background: #fff;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			-o-user-select: none;
			user-select: none;
		}

		.wrapper #spin:after {
			content: "";
			font-family: "Georgia", sans-serif;
			font-style: italic;
			font-size: 19px;
			font-weight: bold;
			text-align: center;
			line-height: 68px;
			color: #fff;
			position: relative;
			z-index: 10;
			width: 68px;
			height: 68px;
			display: block;
		}

		.wrapper #spin:before {
			content: "";
			position: absolute;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 20px 28px 20px;
			border-color: transparent transparent #ffffff transparent;
			top: -12px;
			left: 14px;
		}

		.wrapper #inner-spin {
			width: 54px;
			height: 54px;
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -27px 0 0 -27px;
			border-radius: 50%;
			background: #1b698a;
			z-index: 10;
			vertical-align: middle;
    		font-family: "Georgia", sans-serif;
    		font-style: italic;
    		font-size: 17px;
    		font-weight: bold;
    		text-align: center;
    		line-height: 52px;
    		color: #fff;
		}

		.wrapper #spin:active:after {
			font-size: 15px;
		}

		@-webkit-keyframes tick {

			0%,
			100% {
				transform: rotate(0deg);
				-webkit-transform: rotate(0deg);
			}

			50% {
				transform: rotate(7deg);
				-webkit-transform: rotate(7deg);
			}
		}

		@keyframes tick {

			0%,
			100% {
				transform: rotate(0deg);
				-webkit-transform: rotate(0deg);
			}

			50% {
				transform: rotate(7deg);
				-webkit-transform: rotate(7deg);
			}
		}

		.spin {
			-webkit-animation: tick 0.1s;
			animation: tick 0.1s;
		}
        .rotate-wheel{
            animation: rotate-wheel .5s linear infinite;
        }
        @keyframes rotate-wheel{
            100%{
                transform: rotate(360deg);
            }
        }
		.shine {
			width: 300px;
			height: 300px;
			position: absolute;
			top: 0;
			left: 0;
			background: -webkit-radial-gradient(center,
				ellipse,
				rgba(255, 255, 255, 1) 0%,
				rgba(255, 255, 255, 0.99) 1%,
				rgba(255, 255, 255, 0.91) 9%,
				rgba(255, 255, 255, 0) 100%);
			background: -o-radial-gradient(center,
				ellipse,
				rgba(255, 255, 255, 1) 0%,
				rgba(255, 255, 255, 0.99) 1%,
				rgba(255, 255, 255, 0.91) 9%,
				rgba(255, 255, 255, 0) 100%);
			background: radial-gradient(ellipse at center,
				rgba(255, 255, 255, 1) 0%,
				rgba(255, 255, 255, 0.99) 1%,
				rgba(255, 255, 255, 0.91) 9%,
				rgba(255, 255, 255, 0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
			/* IE6-9 fallback on horizontal gradient */
			opacity: 0.1;
		}

		.glow {
			width: 5px;
			height: 5px;
		}

		.circle {
			border-radius: 100%;
			width: 90%;
			height: 90%;
			background: #00ffff;
			-webkit-box-shadow: 0px 0px 5px 5px #00ffff;
			box-shadow: 0px 0px 5px 5px #00ffff;
		}

		.primaryOfferText {
			-webkit-transform: translate(-65px, -130px);
			-ms-transform: translate(-65px, -130px);
			transform: translate(-65px, -130px);
			width: 130px;
			text-align: center;
			text-transform: uppercase;
			font-family: gotham;
			color: #fff;
		}

		.primaryOfferText span {
			height: 140px;
			position: absolute;
			width: 1px;
			left: 0;
			top: 0;
			font-size: 18px;
			color: white;
			text-align: center;
			display: inline-block;
			-webkit-transform-origin: bottom center;
			-ms-transform-origin: bottom center;
			transform-origin: bottom center;
		}

		.secondaryOfferText {
			-webkit-transform: translate(-40px, -128px);
			-ms-transform: translate(-40px, -128px);
			transform: translate(-40px, -128px);
			width: 80px;
			text-align: center;
			font-family: maison;
			color: #fff;
			overflow: hidden;
			font-size: 13px;
		}

		.secondaryOfferText span {
			height: 100px;
			position: absolute;
			width: 1px;
			left: 0;
			top: 0;
			font-size: 12px;
			color: white;
			text-align: center;
			display: inline-block;
			-webkit-transform-origin: bottom center;
			-ms-transform-origin: bottom center;
			transform-origin: bottom center;
		}


		.toast {
			display: none;
			border-radius: 20px;
			line-height: 40px;
			color: #fff;
			max-height: 40px;
			height: 40px;
			background: rgba(0, 0, 0, 0.6);
			position: fixed;
			bottom: 3%;
			margin: auto;
			right: 0;
			left: 0;
			max-width: 200px;
			text-align: center;
			z-index: 70;
		}
		.captchaPage {
			position: absolute;
			z-index: 1;
			left: 50%;
			top: 0;
			width: 100%;
			height: 100%;
			overflow: auto;
			background-color: rgba(0,0,0,0.6);
			transform: translateX(-50%);
		}
		.captchaModal {
			background-color: #ffffff;
			margin: auto;
			padding: 0px 0px 20px 0px;
			border-radius: 20px;
			width: 330px;
			text-align: center;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
		.animateSpinButton {
			-webkit-animation: beat 0.5s infinite alternate;
			animation: beat 0.5s infinite alternate;
		}
		@-webkit-keyframes beat {
			to {
				-webkit-transform: scale(1.1);
						transform: scale(1.1);
			}
		}
		@keyframes beat {
			to {
				-webkit-transform: scale(1.1);
						transform: scale(1.1);
			}
		}
		#tncLink{
			position: relative;
			top: 10px;
			left: 50%;
			color: white;
			font-size: 12px;
			transform: translateX(-50%);
			line-height: 35px;
            cursor: pointer;
        }

        .wheelBg {
            background: #04021F;
            background: -moz-linear-gradient(45deg, #04021F 0%, #1C6EA4 85%, #1C6EA4 100%);
            background: -webkit-linear-gradient(45deg, #04021F 0%, #1C6EA4 85%, #1C6EA4 100%);
            background: linear-gradient(45deg, #04021F 0%, #1C6EA4 85%, #1C6EA4 100%);
		}
		.wheelcontainer{
			position: fixed;
			top: 0;
			bottom: 0;
			width: 100%;
			left: 0;
			right: 0;
			background-color: rgba(0, 0, 0, 0.0);
			z-index: 999999;
		}
		.wheelBonusCloser{
			position: fixed;
			top: 0;
			bottom: 0;
			width: 60%;
			left: 0;
			background-color: rgba(0, 0, 0, 0.5);
			transition: .5s;
		}
		.wheelBonusContainer{
			position: fixed;
			top: 0;
			bottom: 0;
			width: 40%;
			right: 0;
			background-color: white;
			height: 100%;
			transition: .5s;
		}
		@media (max-width:730px){
			.wheelBonusCloser{
				width: 20%;
			}
			.wheelBonusContainer{
				width: 80%;
			}
		}
		.bg-black{
			background-color: black;
		}